import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import ErrorPage from './ErrorPage'
import { withRouter } from 'react-router'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    const { history } = this.props
    this.state = { error: null, errorInfo: null, hasError: false }
    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        })
      }
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      hasError: true
    })
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) return <ErrorPage title="404" />
    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
