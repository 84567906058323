import { action, observable } from 'mobx'

import companyApi from '../services/companyApi'
import venueApi from '../services/venueApi'
import globalStore from './global'
import { validate, parseErrors } from '../utilities/formUtils'

const validateForm = validate

export const defaultEmployeeParams = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company: '',
  notes: '',
  medias: [],
  role: 1,
  is_active: true
}

class EmployeeStore {
  @observable
  isOpenNewEmployeeForm = false
  @observable
  isOpenConfirmAddUserToEmployee = false

  @observable
  filterBy = 'all'

  @observable
  sortBy = null

  @observable.deep
  employees = []
  @observable
  isLoadingEmployees = false
  @observable
  isLoadingEmployee = false
  @observable
  isSavingEmployees = false
  @observable
  isSavingEmployee = false

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable.deep
  employeeParams = {
    ...defaultEmployeeParams
  }

  @observable.deep
  employee = {
    person: {
      user: null
    }
  }

  @observable.deep
  newEmployee = null

  @observable.deep
  employeeErrors = {}

  @observable
  employeeIsValid = false

  employeeParamsValidations = {
    first_name: {
      required: true
    },
    last_name: {
      required: true
    },
    email: {
      required: true,
      pattern: '^([A-Za-z0-9_\\-.+])+@([A-Za-z0-9_\\-.])+\\.([A-Za-z]{2,})$'
    }
  }

  constructor() {
    this.globalStore = globalStore
  }

  @action
  setItemsPerPage = value => {
    this.pagination.perPage = value
  }

  @action
  handleOpenNewEmployeeFormDialog = () => {
    this.isOpenNewEmployeeForm = true
  }

  @action
  handleCloseNewEmployeeFormDialog = () => {
    this.isOpenNewEmployeeForm = false
  }

  @action
  handleOpenConfirmAddUserToEmployee = () => {
    this.isOpenNewEmployeeForm = false
    this.isOpenConfirmAddUserToEmployee = true
  }

  @action
  handleCloseConfirmAddUserToEmployee = () => {
    this._resetEmployeeValues()

    this.isOpenConfirmAddUserToEmployee = false
  }

  @action
  handleConfirmAddUserToEmployee = () => {
    this.createEmployee({ add_user_as_employee: true })
    this.handleCloseConfirmAddUserToEmployee()
  }

  @action
  handleChangePage = page => {
    this.pagination = {
      ...this.pagination,
      page
    }
  }

  @action
  handleChangeFilter = value => {
    this.filterBy = value
    this.pagination = {
      ...this.pagination,
      page: 1
    }
  }

  @action
  handleChangeSort = value => {
    this.sortBy = value
    this.pagination = {
      ...this.pagination,
      page: 1
    }
  }

  @action
  handleChangeEmployeeFieldValue = evt => {
    this.employeeParams = {
      ...this.employeeParams,
      [evt.target.name]: evt.target.value
    }

    const [isValid] = validateForm(
      this.employeeParams,
      this.employeeParamsValidations
    )
    this.employeeIsValid = isValid
  }

  @action
  handleChangeMedias = medias => {
    this.employeeParams = {
      ...this.employeeParams,
      medias
    }
  }

  @action
  handleCancelNewEmployee = () => {
    this._resetEmployeeValues()

    this.handleCloseNewEmployeeFormDialog()
  }

  _resetEmployeeValues = () => {
    this.employeeParams = {
      ...defaultEmployeeParams
    }

    this.employeeErrors = {}
  }

  @action
  fetchCompanyEmployees = async companyId => {
    this.isLoadingEmployees = true
    try {
      const {
        data: { employees = [], pagination = {} }
      } = await companyApi.getEmployees(companyId, {
        filter_by: this.filterBy,
        pagination: this.pagination
      })
      this.employees = employees
      this.pagination = pagination
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error fetching employees`
      )
    } finally {
      this.isLoadingEmployees = false
    }
    return true
  }

  @action
  fetchCompanyEmployee = async (companyId, employeeId) => {
    try {
      this.isLoadingEmployee = true
      const { data: employee } = await companyApi.fetchEmployee(
        companyId,
        employeeId
      )
      this.employee = employee

      this._loadEmployeeData(employee)

      return true
    } catch (error) {
      return []
    } finally {
      this.isLoadingEmployee = false
    }
  }

  @action
  createCompanyEmployee = async (companyId, params = {}) => {
    const { add_user_as_employee = false } = params
    try {
      this.isSavingEmployee = true
      const { data: employee } = await companyApi.createEmployee(companyId, {
        employee: this.employeeParams,
        add_user_as_employee
      })
      this.newEmployee = employee
      this.fetchCompanyEmployees(companyId)
      this.globalStore.openNotificationSnackbar(
        `The employee was successfully created.`
      )
      if (add_user_as_employee) this._resetEmployeeValues()
      return true
    } catch ({ data, status }) {
      if (status === 422) {
        this.employeeErrors = parseErrors(data)
        return false
      }
      if (status === 409) {
        this.handleOpenConfirmAddUserToEmployee()
        return false
      }
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error creating the employee`
      )
    } finally {
      this.isSavingEmployee = false
    }
    return true
  }

  @action
  updateCompanyEmployee = async (
    companyId,
    employeeId,
    successMsg = `The employee was successfully updated.`,
    errorMsg = `The employee was successfully updated.`
  ) => {
    try {
      this.isSavingEmployee = true
      const { data: employee } = await companyApi.updateEmployee(
        companyId,
        employeeId,
        {
          employee: {
            ...this.employeeParams
          }
        }
      )
      this.employee = employee
      this._loadEmployeeData(employee)
      this.globalStore.openNotificationSnackbar(successMsg)
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(errorMsg)
      return false
    } finally {
      this.isSavingEmployee = false
    }
    return true
  }

  @action
  fetchVenueEmployees = async venueId => {
    this.isLoadingEmployees = true
    try {
      const {
        data: { employees = [], pagination = {} }
      } = await venueApi.getEmployees(venueId, {
        filter_by: this.filterBy,
        pagination: this.pagination
      })
      this.employees = employees
      this.pagination = pagination
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error fetching employees`
      )
    } finally {
      this.isLoadingEmployees = false
    }
    return true
  }

  @action
  fetchVenueEmployee = async (venueId, employeeId) => {
    try {
      this.isLoadingEmployee = true
      const { data: employee } = await venueApi.fetchEmployee(
        venueId,
        employeeId
      )
      this.employee = employee

      this._loadEmployeeData(employee)

      return true
    } catch (error) {
      return []
    } finally {
      this.isLoadingEmployee = false
    }
  }

  @action
  createVenueEmployee = async (venueId, params = {}) => {
    const { add_user_as_employee = false } = params
    try {
      this.isSavingEmployee = true
      const { data: employee } = await venueApi.createEmployee(venueId, {
        employee: this.employeeParams,
        add_user_as_employee
      })
      this.newEmployee = employee
      this.employee = employee
      this._loadEmployeeData(employee)
      this.fetchVenueEmployees(venueId)
      this.globalStore.openNotificationSnackbar(
        `The employee was successfully created.`
      )
      if (add_user_as_employee) this._resetEmployeeValues()
    } catch ({ data, status }) {
      if (status === 422) {
        this.employeeErrors = parseErrors(data)
        return false
      }
      if (status === 409) {
        this.handleOpenConfirmAddUserToEmployee()
        return false
      }
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error creating the employee`
      )
    } finally {
      this.isSavingEmployee = false
    }
    return true
  }

  @action
  updateVenueEmployee = async (
    venueId,
    employeeId,
    successMsg = `The employee was successfully updated.`,
    errorMsg = `The employee was successfully updated.`
  ) => {
    try {
      this.isSavingEmployee = true
      const { data: employee } = await venueApi.updateEmployee(
        venueId,
        employeeId,
        {
          employee: {
            ...this.employeeParams
          }
        }
      )
      this.employee = employee
      this._loadEmployeeData(employee)
      this.globalStore.openNotificationSnackbar(successMsg)
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(errorMsg)
      return false
    } finally {
      this.isSavingEmployee = false
    }
  }

  @action
  updateCompanyVenueEmployeeActive = async (
    companyId,
    venueId,
    employeeId,
    is_active
  ) => {
    try {
      this.isSavingEmployee = true
      const { data: employee } = await companyApi.setVenueEmployeeIsActive(
        companyId,
        venueId,
        employeeId,
        {
          is_active
        }
      )
      this.employee.venues = employee.venues
      this.globalStore.openNotificationSnackbar(
        `The employee was successfully updated.`
      )
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error updating the employee`
      )
      return false
    } finally {
      this.isSavingEmployee = false
    }
  }

  @action
  setNewEmployee = newEmployee => {
    this.newEmployee = newEmployee
  }

  _loadEmployeeData = employee => {
    const {
      id,
      email,
      first_name,
      last_name,
      phone,
      company,
      medias,
      __meta__: {
        pivot_role: role,
        pivot_notes: notes,
        pivot_is_active: is_active
      }
    } = employee

    this.employeeParams = {
      id,
      first_name,
      last_name,
      email,
      phone,
      company,
      notes,
      medias,
      role,
      is_active
    }

    const [isValid] = validateForm(
      this.employeeParams,
      this.employeeParamsValidations
    )
    this.employeeIsValid = isValid
  }

  @action
  isDefaultVenue = venue => {
    return (
      this.employee.id &&
      venue.id === this.employee.__meta__.pivot_default_venue_id
    )
  }
}

export default new EmployeeStore()
