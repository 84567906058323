import { action, observable, when } from 'mobx'
import moment from 'moment-timezone'

import userStore from './user'
import venueApi from '@services/venueApi'
import userApi from '@services/userApi'
import calendarApi from '@services/calendarApi'

import { filteredCalendarDays } from '@utilities/calendarUtils'
import { TERMS } from '@constants/TERMS'
import { convertItemsInArrayToLocalTimezone } from '@utilities/dateUtils'
import UserStore from './user'
import globalStore from './global'

import { trackEvent } from '@utilities/trackingUtils'

export const defaultSubscriptionForm = {
  startDate: null,
  endDate: null,
  termId: TERMS.MONTHLY.ID,
  months: 1,
  useLastTransaction: true,
  individualBilling: false,
  stripeToken: null,
  isLoadingStripeToken: false,
  auto_renew: true,
  options: []
}

export const subscriptionsDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

export const subscriptionsDefaultFilters = {
  sort: 'desc',
  type: 'all',
  time: null,
  date_type: 'month',
  start_date_value: moment().startOf('month'),
  end_date_value: moment().endOf('month')
}

class SubscriptionStore {
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0

  @observable.deep
  subscription = {
    product: {
      venue: {}
    }
  }
  @observable.deep
  subscriptionForm = {
    ...defaultSubscriptionForm
  }
  @observable
  subscriptionFormError = null
  @observable
  isLoadingSubscription = false
  @observable
  isSavingSubscription = false
  @observable
  isLoadingAutoRenew = false

  @observable.deep
  calendarDays = []
  @observable
  isLoadingCalendar = false

  @observable.deep
  futureSubscriptions = []
  @observable
  isLoadingFutureSubscriptions = false
  @observable.deep
  pastSubscriptions = []
  @observable
  isLoadingPastSubscriptions = false
  @observable.deep
  subscriptions = []
  @observable
  isLoadingSubscriptions = false

  @observable.deep
  subscriptionsPagination = {
    ...subscriptionsDefaultPagination
  }
  @observable.deep
  subscriptionsFilters = {
    ...subscriptionsDefaultFilters
  }

  @observable.deep
  subscriptionHistory = []
  @observable
  isLoadingSubscriptionHistory = false

  @observable.deep
  subscriptionHistoryPagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable
  subscriptionHistorySortBy = 'desc'

  @observable
  isSubscriptionStepperOpen = false

  @observable
  resetFormOnMount = true

  @observable
  isSubscriptionAvailableError = null

  constructor() {
    this.userStore = UserStore
    this.globalStore = globalStore
  }

  @action.bound
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.subscriptionForm = {
      ...defaultSubscriptionForm
    }
  }

  @action
  resetFormError = () => {
    this.subscriptionFormError = null
  }

  @action
  setSubcriptionFormProp = (key, value) => {
    this.subscriptionForm[key] = value
  }

  @action
  setMonthlyDate = startDate => {
    const { subscriptionForm } = this

    subscriptionForm.startDate = startDate
      ? startDate.clone().startOf('day')
      : null
    subscriptionForm.endDate = startDate
      ? startDate
          .clone()
          .startOf('day')
          .add(subscriptionForm.months, 'month')
          .subtract(1, 'day')
      : null
  }

  @action
  _formatFormDates = data => {
    data.start_date = this.subscriptionForm.startDate.format(
      'YYYY/MM/DD HH:mm:ss'
    )
    data.end_date = this.subscriptionForm.endDate.format('YYYY/MM/DD HH:mm:ss')
  }

  @action
  saveSubscription = async product => {
    const {
      subscriptionForm: { termId, stripeToken, auto_renew, card_id, options }
    } = this

    const activeTerm = product.terms.find(term => term.id === termId)

    const checkedOptions = options.filter(option => option.is_checked)
    const totalFees =
      activeTerm.pivot.setup_fee +
      checkedOptions.reduce(
        (setup_fee, option) => setup_fee + option.setup_fee,
        0
      )
    const totalPrice =
      activeTerm.pivot.rate +
      checkedOptions.reduce((price, option) => price + option.price, 0) +
      totalFees

    const data = {
      term_id: activeTerm.id,
      stripe_token: stripeToken ? stripeToken.id : null,
      price: Math.round(totalPrice * 100) / 100,
      card_id,
      auto_renew,
      options: checkedOptions
    }

    this.isSavingSubscription = true

    this._formatFormDates(data)

    let result = null
    try {
      result = await venueApi.createSubscription(
        product.venue.id,
        product.id,
        data
      )
      if (result.data.error) {
        if (result.status === 401) {
          userStore.openAuthDialog()
        }
        this.subscriptionFormError = result.data.error
        this.globalStore.openNotificationSnackbar(
          `Error: ${this.subscriptionFormError}`
        )
      } else {
        this.subscription = result.data
        trackEvent({
          title: 'subscribed to service',
          data: 'subscrption scheduled'
        })
      }
    } catch (error) {
      console.warn(error)
      this.subscriptionFormError = error.data.error
      this.globalStore.openNotificationSnackbar(
        `Error: ${this.subscriptionFormError}`
      )
    } finally {
      this.isSavingSubscription = false
    }

    return result
  }

  @action
  updateSubscription = async (product, subscription, billing_type_changed) => {
    const {
      subscriptionForm: { termId, stripeToken, auto_renew, card_id, options }
    } = this

    const activeTerm = product.terms.find(term => term.id === termId)

    const checkedOptions = options.filter(option => option.is_checked)
    const totalFees =
      activeTerm.pivot.setup_fee +
      checkedOptions.reduce(
        (setup_fee, option) => setup_fee + option.setup_fee,
        0
      )
    const totalPrice =
      activeTerm.pivot.rate +
      checkedOptions.reduce((price, option) => price + option.price, 0) +
      totalFees

    const data = {
      term_id: activeTerm.id,
      stripe_token: stripeToken ? stripeToken.id : null,
      price: Math.round(totalPrice * 100) / 100,
      card_id,
      auto_renew,
      billing_type_changed,
      options: checkedOptions
    }

    this.isSavingSubscription = true

    this._formatFormDates(data)

    let result = null
    try {
      result = await venueApi.updateSubscription(
        product.venue.id,
        product.id,
        subscription.id,
        data
      )
      if (result.data.error) {
        if (result.status === 401) {
          userStore.openAuthDialog()
        }
        this.subscriptionFormError = result.data.error
        this.globalStore.openNotificationSnackbar(
          `Error: ${this.subscriptionFormError}`
        )
      } else {
        this.subscription = result.data
        trackEvent({
          title: 'subscribed to service',
          data: 'subscrption scheduled'
        })
      }
    } catch (error) {
      console.warn(error)
      this.subscriptionFormError = error.data.error
      this.globalStore.openNotificationSnackbar(
        `Error: ${this.subscriptionFormError}`
      )
    } finally {
      this.isSavingSubscription = false
    }

    return result
  }

  @action
  cancelSubscription = async (venueId, productId, subscriptionId) => {
    this.isSavingSubscription = true
    try {
      const { data: subscription } = await venueApi.cancelSubscription(
        venueId,
        productId,
        subscriptionId
      )
      this.subscription = subscription
    } catch (error) {
      console.error(error)
    } finally {
      this.isSavingSubscription = false
    }
  }

  @action
  isSubscriptionAvailable = async product => {
    this.isLoadingSubscriptionAvailable = true

    let isSubscriptionAvailable = false
    try {
      const { endDate, startDate, termId, options = [] } = this.subscriptionForm
      const result = await venueApi.isSubscriptionAvailable(
        product.venue.id,
        product.id,
        {
          endDate: endDate.format(),
          subscriptionId: this.subscription ? this.subscription.id : null,
          startDate: startDate.format(),
          termId,
          optionIds: options.map(option => option.id)
        }
      )
      isSubscriptionAvailable = result.data.isSubscriptionAvailable
    } catch (response) {
      console.error(response.data)
      this.isSubscriptionAvailableError = response.data.error
    } finally {
      this.isLoadingSubscriptionAvailable = false
    }
    return isSubscriptionAvailable
  }

  @action
  getBlockedSubscriptionCalendarDatesByProductId = async (
    product_id,
    month,
    year,
    numberOfMonthsToGet,
    includeHours = false,
    subscription_id = null,
    timezone = null
  ) => {
    this.isLoadingCalendar = true
    try {
      const {
        data
      } = await calendarApi.getBlockedSubscriptionCalendarDatesByProductId(
        product_id,
        month,
        year,
        numberOfMonthsToGet,
        includeHours,
        subscription_id
      )
      this.calendarDays = filteredCalendarDays(
        data,
        this.calendarDays,
        timezone
      )
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingCalendar = false
    }
  }

  @action
  fetchForUserById = async id => {
    this.isLoadingSubscription = true
    try {
      const { data: subscription } = await userApi.fetchSubscriptionById(id)
      this.subscription = subscription
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSubscription = false
    }
  }

  @action
  fetchForVenueById = async (venueId, subscriptionId) => {
    this.isLoadingSubscription = true
    try {
      const { data: subscription } = await venueApi.getVenueSubscriptionById(
        venueId,
        subscriptionId
      )
      this.subscription = subscription
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSubscription = false
    }
  }

  @action
  doesPassEditPolicy = (user, subscription) => {
    return (
      this.doesPassCancellationPolicy(subscription) &&
      [subscription.person_id].includes(user.id)
    )
  }

  @action
  doesPassCancellationPolicy(subscription, currentVenueAbility = null) {
    const {
      product: {
        venue: { timezone: venueTimeZone }
      }
    } = subscription

    if (
      currentVenueAbility &&
      currentVenueAbility.can('update', 'Subscription')
    ) {
      return subscription.is_locked ? false : true
    }

    const subscriptionStartDate = moment
      .utc(subscription.start_date)
      .tz(venueTimeZone)
    let daysToSubtract
    switch (subscription.term_id) {
      case TERMS.HOURLY.ID:
        break
      case TERMS.DAILY.ID:
        break
      case TERMS.MONTHLY.ID:
        daysToSubtract = 30
        break
      default:
        daysToSubtract = 1
        break
    }

    return (
      subscription.cancel_date === null &&
      moment().isBefore(subscriptionStartDate.subtract(daysToSubtract, 'day'))
    )
  }

  @action
  toggleAutoRenew = async subscription => {
    if (subscription.is_locked) return false
    this.isLoadingAutoRenew = true
    try {
      const { data } = await userApi.setSubscriptionAutoRenew(subscription.id, {
        auto_renew: !subscription.auto_renew
      })
      this.subscription = data
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingAutoRenew = false
    }
    return true
  }

  @action
  getMyFutureSubscriptions = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingFutureSubscription = true
    try {
      this.subscriptionsFilters.time = 'future'
      this.subscriptionsFilters.sort = 'asc'
      const {
        data: { subscriptions, pagination }
      } = await userApi.getMySubscriptions({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.subscriptionsFilters,
          start_date_value: moment().format(),
          end_date_value: moment()
            .add(1, 'year')
            .format()
        }
      })
      this.futureSubscriptions = subscriptions
      this.subscriptionsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingFutureSubscriptions = false
    }
  }

  @action
  getMyPastSubscriptions = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingPastSubscriptions = true
    try {
      this.subscriptionsFilters.time = 'past'
      this.subscriptionsFilters.sort = 'desc'
      const {
        data: { subscriptions, pagination }
      } = await userApi.getMySubscriptions({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.subscriptionsFilters,
          start_date_value: moment()
            .subtract(1, 'year')
            .format(),
          end_date_value: moment().format()
        }
      })
      this.pastSubscriptions = subscriptions
      this.subscriptionsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingPastSubscriptions = false
    }
  }

  @action
  getMySubscriptions = async ({
    total = 0,
    perPage = 3,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingSubscriptions = true
    try {
      const {
        data: { subscriptions, pagination }
      } = await userApi.getMySubscriptions({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.subscriptionsFilters,
          start_date_value: moment()
            .startOf('month')
            .format(),
          end_date_value: moment()
            .endOf('month')
            .format()
        }
      })
      this.subscriptions = subscriptions
      this.subscriptionsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSubscriptions = false
    }
  }

  @action
  getVenueSubscriptions = async (
    venueId,
    { total = 0, perPage = 20, page = 1, lastPage = 1 }
  ) => {
    this.isLoadingSubscriptions = true
    try {
      const {
        data: { subscriptions, pagination }
      } = await venueApi.getSubscriptions(venueId, {
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.subscriptionsFilters,
          start_date_value: this.subscriptionsFilters.start_date_value.format(),
          end_date_value: this.subscriptionsFilters.end_date_value.format()
        }
      })
      this.subscriptions = subscriptions
      this.subscriptionsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSubscriptions = false
    }
  }

  @action
  handleSubscriptionHistoryChangeSort = value => {
    this.subscriptionHistorySortBy = value
    this.subscriptionHistoryPagination = {
      ...this.subscriptionHistoryPagination,
      page: 1
    }
  }

  @action
  fetchSubscriptionHistory = async (venueId, productId) => {
    try {
      this.isLoadingSubscriptionHistory = true
      const paginationData = {
        pagination: this.subscriptionHistoryPagination,
        filters: { sort: this.subscriptionHistorySortBy }
      }
      const {
        data: { history, pagination }
      } = venueId
        ? await venueApi.fetchSubscriptionHistory(
            venueId,
            productId,
            this.subscription.id,
            paginationData
          )
        : await userApi.fetchSubscriptionHistory(
            this.subscription.id,
            paginationData
          )

      const tzHistory = convertItemsInArrayToLocalTimezone({
        keys: ['created_at'],
        arrayOfItems: history,
        dateFormat: 'YYYY-MM-DD HH:mm:ss'
      })
      this.subscriptionHistory = tzHistory
      this.subscriptionHistoryPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSubscriptionHistory = false
    }
  }

  @action
  setSubscriptionsPaginationProp = (prop, value) => {
    this.subscriptionsPagination[prop] = value
  }

  @action
  resetSubscriptionsPagination = () => {
    this.subscriptionsPagination = {
      ...subscriptionsDefaultPagination
    }
  }

  @action
  setSubscriptionsFiltersProp = (prop, value) => {
    this.subscriptionsFilters[prop] = value
  }

  @action
  resetSubscriptionsFilters = () => {
    this.subscriptionsFilters = {
      ...subscriptionsDefaultFilters
    }
  }

  @action
  openSubscriptionStepper = (resetFormOnMount = true) => {
    this.resetFormOnMount = resetFormOnMount

    if (!this.userStore.loggedIn) {
      this.userStore.openAuthDialog('register')
      when(
        () => this.userStore.loggedIn,
        () => (this.isSubscriptionStepperOpen = true)
      )
      return
    }

    this.isSubscriptionStepperOpen = true
  }

  @action
  closeSubscriptionStepper = () => {
    this.isSubscriptionStepperOpen = false
  }

  @action
  cancelSubscriptionStepper = () => {
    this.closeSubscriptionStepper()
  }
}

export default new SubscriptionStore()
