import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import Popover from '@material-ui/core/Popover'
import { observer, inject } from 'mobx-react'
import BellIcon from '@material-ui/icons/Notifications'
import NotificationList from './NotificationList'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

const styles = theme => ({
  popoverPaper: {
    paddingBottom: 8
  },
  buttonWrapper: {
    position: 'relative',
    display: 'inline-block'
  },
  notificationIcon: {
    color: 'white'
  },
  notificationPopup: {},
  anchor: {
    backgroundColor: theme.palette.primary.main,
    width: 7,
    height: 7,
    borderRadius: '50%',
    position: 'absolute',
    top: 12,
    right: 12
  }
})
@inject('notificationStore')
@observer
class NotificationPopover extends Component {
  anchorEL = null
  static propTypes = {
    notificationStore: PropTypes.object,
    classes: PropTypes.object
  }

  state = {
    open: false
  }

  handleClickButton = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {
      classes,
      notificationStore: { latestFiveNotifications, hasUnreadNotifications }
    } = this.props
    return (
      <div className={classes.buttonWrapper}>
        <IconButton
          buttonRef={node => {
            this.anchorEl = node
          }}
          onClick={this.handleClickButton}
        >
          <BellIcon classes={{ root: classes.notificationIcon }} />
        </IconButton>
        {hasUnreadNotifications && (
          <div id="unread-notification-icon" className={classes.anchor} />
        )}
        <Popover
          open={this.state.open}
          anchorEl={this.anchorEl}
          anchorReference={'anchorEl'}
          onClose={this.handleClose}
          anchorPosition={{ top: 15, left: -5 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{ paper: classes.popoverPaper }}
        >
          <NotificationList notifications={latestFiveNotifications} />
          {Boolean(latestFiveNotifications.length) && (
            <Button component={Link} to="/admin/notifications" color="primary">
              View All
            </Button>
          )}
        </Popover>
      </div>
    )
  }
}
export default withStyles(styles)(NotificationPopover)
