import BaseApi from '../baseApi'
import userStore from '../../stores/user'

class ConversationApi extends BaseApi {
  constructor() {
    super({ routeName: 'conversations' })
  }

  create = params => {
    return this.request(userStore.jwtToken).post('', params)
  }

  archive = (conversationId, archived) => {
    const url = `/${conversationId}/archive`
    return this.request(userStore.jwtToken).post(url, { archived })
  }

  destroy = conversationId => {
    const url = `/${conversationId}/delete`
    return this.request(userStore.jwtToken).post(url)
  }
}

export default new ConversationApi()
