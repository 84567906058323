import 'regenerator-runtime/runtime'
import { action, observable } from 'mobx'
import userStore from '../stores/user'
import adonisWs from '@adonisjs/websocket-client'

const { REACT_APP_WS_DOMAIN } = process.env

class WebSocketStore {
  adonisWs
  @observable
  connection = null
  @observable
  isConnected = false
  @observable
  isConnecting = false

  constructor() {
    this.adonisWs = adonisWs
  }

  @action
  connect = () => {
    this.isConnecting = true
    if (this.connection === null && userStore.jwtToken) {
      this.connection = this.adonisWs(REACT_APP_WS_DOMAIN)
        .withJwtToken(userStore.jwtToken)
        .connect()
      this._hooks(this.connection)
    }
    this.isConnecting = false
  }

  @action
  disconnect = () => {
    if (this.connection !== null) {
      this.connection.close()
    }
  }

  @action
  subscribe = (channelName, id = null) => {
    if (!channelName) throw new Error('A channel name is required.')
    if (!this.connection || !this.isConnected) {
      this.connect()
    }
    const channel = id ? `${channelName}:${id}` : channelName
    const existingSubscription = this.connection.getSubscription(channel)
    if (existingSubscription) {
      return existingSubscription
    } else {
      return this.connection.subscribe(channel)
    }
  }

  _hooks(connection) {
    connection.on('open', () => {
      this.isConnected = true
      // eslint-disable-next-line
      console.info('Spacemx: Websocket is open')
    })

    connection.on('close', () => {
      this.isConnected = false
      // eslint-disable-next-line
      console.info('Spacemx: Websocket is closed')
    })
  }
}

export default new WebSocketStore()
