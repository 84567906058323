import BaseApi from './baseApi'
import userStore from '../stores/user'
import qs from 'qs'

class ReviewsApi extends BaseApi {
  constructor() {
    super({ routeName: 'reviews' })
  }

  getForUser = (params = {}) => {
    return this.request(userStore.jwtToken).get('forUser', {
      params: {
        ...params
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getByUser = (params = {}) => {
    return this.request(userStore.jwtToken).get('byUser', {
      params: {
        ...params
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getForSpace = spaceId => {
    return this.request(userStore.jwtToken).get(`forSpace/${spaceId}`)
  }

  create = review => {
    if (!review) return Error('No review data provided')
    return this.request(userStore.jwtToken).post('', review)
  }

  fetchRatings = (params = {}) => {
    return this.request(userStore.jwtToken).get('/ratings', {
      params: {
        ...params
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

const reviewsApi = new ReviewsApi()
export default reviewsApi
