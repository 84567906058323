import BaseApi from './baseApi'
import userStore from '../stores/user'
import qs from 'qs'

class FavoriteApi extends BaseApi {
  constructor() {
    super({ routeName: 'favorites' })
  }

  getFavoritedCities = (type = 'space') => {
    return this.request(userStore.jwtToken).get('cities', {
      params: { type },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getMyFavorites = params => {
    const url = ''
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

const favoriteApi = new FavoriteApi()
export default favoriteApi
