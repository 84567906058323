import BaseApi from './baseApi'
import userStore from '../stores/user'

class TermsApi extends BaseApi {
  getTerms = () => {
    return this.request(userStore.jwtToken).get('terms')
  }
}

export const TERMS = {
  HOURLY: 1,
  DAILY: 2,
  // TODO DISABLED for MVP
  MONTHLY: 3
  // YEARLY: 4
}

const termsApi = new TermsApi()
export default termsApi
