import BaseApi from './baseApi'
import userStore from '../stores/user'

class CalendarApi extends BaseApi {
  constructor() {
    super({ routeName: 'calendar' })
  }

  getBlockedBookingCalendarDatesBySpaceId = (
    space_id,
    month,
    year,
    numberOfMonthsToGet,
    includeHours = false,
    booking_id = null
  ) => {
    if (!space_id) throw Error('Space Id is required.')
    if (month === undefined) throw Error('month is required.')
    const params = {
      space_id: space_id,
      month: month,
      year: year,
      count: numberOfMonthsToGet
    }
    if (booking_id !== null) params.booking_id = booking_id
    if (includeHours) {
      params.includeHours = includeHours
    }
    return this.session.get('bookings', {
      params
    })
  }

  getBlockedSubscriptionCalendarDatesByProductId = (
    product_id,
    month,
    year,
    numberOfMonthsToGet,
    includeHours = false,
    subscription_id = null
  ) => {
    if (!product_id) throw Error('Service Id is required.')
    if (month === undefined) throw Error('month is required.')
    const params = {
      product_id,
      month,
      year,
      count: numberOfMonthsToGet
    }
    if (subscription_id !== null) params.subscription_id = subscription_id
    if (includeHours) {
      params.includeHours = includeHours
    }
    return this.request(userStore.jwtToken).get('subscriptions', {
      params
    })
  }

  getBlockedTourCalendarDatesByPlaceId = (
    place_id,
    place_type = 'venues',
    month,
    year,
    numberOfMonthsToGet,
    includeHours = false,
    tour_id = null
  ) => {
    if (!place_id || !place_type) throw Error('Place Id and Type is required.')
    if (month === undefined) throw Error('month is required.')
    const params = {
      place_id,
      place_type,
      month,
      year,
      count: numberOfMonthsToGet
    }
    if (tour_id !== null) params.tour_id = tour_id
    if (includeHours) {
      params.includeHours = includeHours
    }
    return this.session.get('tours', {
      params
    })
  }
}

const calendarApi = new CalendarApi()
export default calendarApi
