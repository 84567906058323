import BaseApi from './baseApi'
import { createWithParams } from '../utilities/apiUtils'
import userStore from '../stores/user'
import qs from 'qs'

class UserApi extends BaseApi {
  constructor() {
    super({ routeName: 'user' })
  }

  getMe = include => {
    const url = `/me${include ? createWithParams(include) : ''}`
    return this.request(userStore.jwtToken)
      .get(url)
      .then(res => res.data)
  }

  fetchMyCards = () => {
    return this.request(userStore.jwtToken).get('/cards')
  }

  updateFavorite = (object_id, object_type, user_id, favorited_state) => {
    const payload = {
      object_id,
      object_type,
      user_id,
      favorited_state
    }
    return this.request(userStore.jwtToken).post('/update-favorite', payload)
  }

  update = (userId, user) => {
    if (!userId) throw Error('User id is required.')
    return this.request(userStore.jwtToken).put('/' + userId, user)
  }

  fetchConversations = ({
    archived = 0,
    pagination: { page, perPage },
    filters
  }) => {
    return this.request(userStore.jwtToken).get('/conversations', {
      params: { archived, pagination: { page, per_page: perPage }, filters },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchUserDashboardCounts = () => {
    return this.request(userStore.jwtToken)
      .get('/dashboard-counts')
      .then(res => res.data)
  }

  getMySubscriptions = params => {
    const url = '/subscriptions'
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchSubscriptionById = id => {
    return this.request(userStore.jwtToken).get(`/subscriptions/${id}`)
  }

  setSubscriptionAutoRenew = (id, params) => {
    return this.request(userStore.jwtToken).post(
      `/subscriptions/${id}/autoRenew`,
      params
    )
  }

  fetchSubscriptionHistory = (subscriptionId, params) => {
    return this.request(userStore.jwtToken).get(
      `/subscriptions/${subscriptionId}/history`,
      {
        params,
        paramsSerializer: params => qs.stringify(params, { encode: false })
      }
    )
  }

  fetchReferrals = params => {
    return this.request(userStore.jwtToken)
      .get('/referrals', {
        params,
        paramsSerializer: params => qs.stringify(params, { encode: false })
      })
      .then(res => res.data)
  }

  createNewReferral = newReferral => {
    const payload = {
      ...newReferral
    }
    return this.request(userStore.jwtToken).post('/referrals', payload)
  }

  fetchReferralMetrics = () => {
    return this.request(userStore.jwtToken).get('/referral-counts')
  }

  finalizePaymentAccount = accountCode => {
    if (!accountCode) throw new Error('No account code provided')

    return this.request(userStore.jwtToken).post(`/finalize-payment-account`, {
      account_code: accountCode
    })
  }

  cashOutEarnedReferrals = () => {
    return this.request(userStore.jwtToken).post('/referral-cashout')
  }

  saveCard = ({ token }) => {
    return this.request(userStore.jwtToken).post('/cards', { token })
  }

  saveDefaultCard = ({ defaultCardId }) => {
    return this.request(userStore.jwtToken).post('/cards/default', {
      defaultCardId
    })
  }

  removeCard = cardId => {
    return this.request(userStore.jwtToken).delete(`/cards/${cardId}`)
  }
}

export default new UserApi()
