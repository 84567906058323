import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PageContent from './PageContent'
import Header from './Header'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Warning from '@material-ui/icons/Warning'

const styles = theme => ({
  stepContainer: {
    marginTop: theme.spacing.unit * 6,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: 116,
      paddingBottom: 116,
      paddingRight: 144,
      paddingLeft: 144
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 24,
      paddingBottom: 24,
      paddingRight: 24,
      paddingLeft: 24
    }
  },
  titleText: {
    color: theme.palette.secondary.mainDarker,
    marginBottom: 44
  },
  iconContainer: {
    height: 51,
    width: 51,
    marginBottom: theme.spacing.unit * 3
  },
  confirmationText: {
    marginBottom: theme.spacing.unit * 3,
    textAlign: 'center',
    fontWeight: 300
  },
  captionText: {
    color: theme.palette.secondary.mainDarker,
    marginBottom: theme.spacing.unit * 3,
    opacity: 0.5
  }
})

const ErrorPage = ({ classes }) => {
  return (
    <Grid container spacing={0} style={styles.page}>
      <Header />
      <PageContent narrow>
        <Grid container spacing={0}>
          <Paper className={classes.stepContainer}>
            <Grid
              container
              spacing={0}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Fragment>
                <Typography variant="h4" className={classes.titleText}>
                  {'Uh oh! There was an issue.'}
                </Typography>
                <Warning color="primary" className={classes.iconContainer} />
                <Typography variant="h6" className={classes.confirmationText}>
                  {/* TODO update text */}
                  Please click on the refresh button{' '}
                </Typography>
              </Fragment>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.reload(true)}
              >
                {'Refresh'}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </PageContent>
    </Grid>
  )
}

export default withStyles(styles)(ErrorPage)
