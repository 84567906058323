import { action, observable } from 'mobx'

import contactApi from '../services/admin/contactApi'
import companyApi from '../services/companyApi'
import venueApi from '../services/venueApi'
import globalStore from './global'
import { validate, parseErrors } from '../utilities/formUtils'

const validateForm = validate

const defaultContactParams = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company: '',
  notes: '',
  medias: [],
  is_venue_billing: false
}

class ContactStore {
  @observable
  isOpenNewContactForm = false
  @observable
  isOpenConfirmAddUserToContact = false
  @observable
  isOpenImportContactForm = false

  @observable
  filterBy = 'all'

  @observable
  sortBy = null

  @observable.deep
  contacts = []
  @observable
  isLoadingContacts = false
  @observable
  isSavingContacts = false

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable.deep
  contactParams = {
    ...defaultContactParams
  }

  @observable.deep
  contact = {
    person: {
      user: null
    }
  }

  @observable.deep
  newContact = null

  @observable.deep
  contactErrors = {}

  @observable
  contactIsValid = false

  contactParamsValidations = {
    first_name: {
      required: true
    },
    last_name: {
      required: true
    },
    email: {
      required: true,
      pattern: '^([A-Za-z0-9_\\-.+])+@([A-Za-z0-9_\\-.])+\\.([A-Za-z]{2,})$'
    }
  }

  @observable
  attendeeIds = []

  @observable
  organizerId = null

  @observable
  isSendingInvitation = false

  @observable
  search = ''

  constructor() {
    this.globalStore = globalStore
  }

  @action
  setSearch = search => {
    this.search = search
  }

  @action.bound
  addAttendeeId(id, index) {
    const ids = []
    this.attendeeIds = this.attendeeIds.filter(attendeeId => {
      if (attendeeId !== null && !ids.includes(attendeeId)) {
        ids.push(attendeeId)
        return true
      } else {
        return false
      }
    })

    if (index) {
      this.attendeeIds[index] = id
    } else {
      this.attendeeIds.push(id)
    }
  }

  @action.bound
  removeAttendeeId(id) {
    this.attendeeIds = this.attendeeIds.filter(attendeeId => attendeeId !== id)
  }

  @action.bound
  setAttendeeIds(ids) {
    this.attendeeIds = ids
  }

  @action.bound
  setOrganizerId(organizerId) {
    this.organizerId = organizerId
  }

  @action
  setItemsPerPage = value => {
    this.pagination.perPage = value
  }

  @action
  handleOpenNewContactFormDialog = () => {
    this.isOpenNewContactForm = true
  }

  @action
  handleCloseNewContactFormDialog = () => {
    this.isOpenNewContactForm = false
  }

  @action
  handleOpenConfirmAddUserToContact = () => {
    this.isOpenNewContactForm = false
    this.isOpenConfirmAddUserToContact = true
  }

  @action
  handleCloseConfirmAddUserToContact = () => {
    this._resetContactValues()

    this.isOpenConfirmAddUserToContact = false
  }

  @action
  handleConfirmAddUserToContact = () => {
    this.createContact({ add_user_as_contact: true })
    this.handleCloseConfirmAddUserToContact()
  }

  @action
  handleOpenImportContactFormDialog = () => {
    this.isOpenImportContactForm = true
  }

  @action
  handleCloseImportContactFormDialog = () => {
    this.isOpenImportContactForm = false
  }

  @action
  handleChangePage = page => {
    this.pagination = {
      ...this.pagination,
      page
    }
  }

  @action
  handleChangeFilter = value => {
    this.filterBy = value
    this.pagination = {
      ...this.pagination,
      page: 1
    }
  }

  @action
  handleChangeSort = value => {
    this.sortBy = value
    this.pagination = {
      ...this.pagination,
      page: 1
    }
  }

  @action
  handleChangeContactFieldValue = evt => {
    this.contactParams = {
      ...this.contactParams,
      [evt.target.name]: evt.target.value
    }

    const [isValid] = validateForm(
      this.contactParams,
      this.contactParamsValidations
    )
    this.contactIsValid = isValid
  }

  @action
  handleChangeMedias = medias => {
    this.contactParams = {
      ...this.contactParams,
      medias
    }
  }

  @action
  handleCancelNewContact = () => {
    this._resetContactValues()

    this.handleCloseNewContactFormDialog()
  }

  _resetContactValues = () => {
    this.contactParams = {
      ...defaultContactParams
    }

    this.contactErrors = {}
  }

  @action
  resetAttendees = () => {
    this.attendeeIds = []
    this.organizerId = null
  }

  @action
  fetchContacts = async () => {
    this.isLoadingContacts = true
    try {
      this._resetContactValues()

      const {
        data: { contacts = [], metadata = {} }
      } = await contactApi.fetch({
        filter_by: this.filterBy,
        page: this.pagination.page,
        items_per_page: this.pagination.perPage
      })
      this.contacts = contacts
      this.pagination = metadata.pagination
      return true
    } catch (error) {
      return []
    } finally {
      this.isLoadingContacts = false
    }
  }

  @action
  fetchCompanyContacts = async companyId => {
    this.isLoadingContacts = true
    try {
      const {
        data: { contacts = [], pagination = {} }
      } = await companyApi.getContacts(companyId, {
        search: this.search,
        filter_by: this.filterBy,
        pagination: this.pagination
      })
      this.contacts = contacts
      this.pagination = pagination
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error fetching contacts`
      )
    } finally {
      this.isLoadingContacts = false
    }
    return true
  }

  @action
  fetchCompanyContact = async (companyId, contactId) => {
    try {
      this.isLoadingContacts = true
      const { data: contact } = await companyApi.fetchContact(
        companyId,
        contactId
      )
      this.contact = contact

      this._loadContactData(contact)

      return true
    } catch (error) {
      return []
    } finally {
      this.isLoadingContacts = false
    }
  }

  @action
  createCompanyContact = async (companyId, params = {}) => {
    const { add_user_as_contact = false } = params
    try {
      this.isSavingContacts = true
      const { data: contact } = await companyApi.createContact(companyId, {
        contact: this.contactParams,
        add_user_as_contact
      })
      this.newContact = contact
      this.fetchCompanyContacts(companyId)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully created.`
      )
      if (add_user_as_contact) this._resetContactValues()
      return true
    } catch ({ data, status }) {
      if (status === 422) {
        this.contactErrors = parseErrors(data)
      }
      if (status === 409) {
        this.handleOpenConfirmAddUserToContact()
        return false
      }
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error creating the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  deleteCompanyContact = async (companyId, contactId) => {
    try {
      this.isSavingContacts = true
      await companyApi.deleteContact(companyId, contactId)
      this.fetchCompanyContacts(companyId)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully deleted.`
      )
      return true
    } catch ({ data, status }) {
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error deleting the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  deleteCompanyVenueContacts = async (companyId, contactId) => {
    try {
      await companyApi.deleteVenueContacts(companyId, contactId)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully deleted at all of your venues.`
      )
      return true
    } catch ({ data, status }) {
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error deleting the contact for all of your venues`
      )
      return false
    }
  }

  @action
  importCompanyContacts = async (companyId, contacts = []) => {
    try {
      this.isSavingContacts = true
      if (!contacts.length) {
        this.globalStore.openNotificationSnackbar(
          `There were no contacts to import.`
        )
        return
      }
      const { data } = await companyApi.importCompanyContacts(
        companyId,
        contacts
      )
      this.fetchCompanyContacts(companyId)
      this.globalStore.openNotificationSnackbar(
        `The ${data.length} of ${
          contacts.length
        } contacts import was successfully completed.`
      )
      return
    } catch ({ data, status }) {
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error importing your contacts`
      )
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  updateCompanyContact = async (companyId, contactId) => {
    try {
      this.isSavingContacts = true
      const { data: contact } = await companyApi.updateContact(
        companyId,
        contactId,
        {
          contact: {
            ...this.contactParams
          }
        }
      )
      this.contact = contact
      this._loadContactData(contact)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully updated.`
      )
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error updating the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  fetchVenueContacts = async venueId => {
    this.isLoadingContacts = true
    try {
      const {
        data: { contacts = [], pagination = {} }
      } = await venueApi.getContacts(venueId, {
        search: this.search,
        filter_by: this.filterBy,
        pagination: this.pagination
      })
      this.contacts = contacts
      this.pagination = pagination
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error fetching contacts`
      )
    } finally {
      this.isLoadingContacts = false
    }
    return true
  }

  @action
  fetchVenueContact = async (venueId, contactId) => {
    try {
      this.isLoadingContacts = true
      const { data: contact } = await venueApi.fetchContact(venueId, contactId)
      this.contact = contact

      this._loadContactData(contact)

      return true
    } catch (error) {
      return []
    } finally {
      this.isLoadingContacts = false
    }
  }

  @action
  createVenueContact = async (venueId, params = {}) => {
    const { add_user_as_contact = false } = params
    try {
      this.isSavingContacts = true
      const { data: contact } = await venueApi.createContact(venueId, {
        contact: this.contactParams,
        add_user_as_contact
      })
      this.addAttendeeId(contact.id)
      this.fetchVenueContacts(venueId)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully created.`
      )
      if (add_user_as_contact) this._resetContactValues()
      this.handleCloseNewContactFormDialog()
      return true
    } catch ({ data, status }) {
      if (status === 422) {
        this.contactErrors = parseErrors(data)
      }
      if (status === 409) {
        this.handleOpenConfirmAddUserToContact()
        return false
      }
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error creating the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  deleteVenueContact = async (venueId, contactId) => {
    try {
      this.isSavingContacts = true
      await venueApi.deleteContact(venueId, contactId)
      this.fetchVenueContacts(venueId)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully deleted.`
      )
      return true
    } catch ({ data, status }) {
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error deleting the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  importVenueContacts = async (venueId, contacts) => {
    try {
      this.isSavingContacts = true
      if (!contacts.length) {
        this.globalStore.openNotificationSnackbar(
          `There were no contacts to import.`
        )
        return
      }
      const { data } = await venueApi.importVenueContacts(venueId, contacts)
      this.fetchVenueContacts(venueId)
      this.globalStore.openNotificationSnackbar(
        `The ${data.length} of ${
          contacts.length
        } contacts import was successfully completed.`
      )
      return
    } catch ({ data, status }) {
      console.error(data)
      this.globalStore.openNotificationSnackbar(
        `There was an error importing your contacts`
      )
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  updateVenueContact = async (venueId, contactId) => {
    try {
      this.isSavingContacts = true
      const { data: contact } = await venueApi.updateContact(
        venueId,
        contactId,
        {
          contact: {
            ...this.contactParams
          }
        }
      )
      this.contact = contact
      this._loadContactData(contact)
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully updated.`
      )
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error updating the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  fetchContact = async contactId => {
    try {
      this.isLoadingContacts = true
      const { data: contact } = await contactApi.fetchOne(contactId)
      this.contact = contact

      this._loadContactData(contact)

      return true
    } catch (error) {
      return []
    } finally {
      this.isLoadingContacts = false
    }
  }

  @action
  createPersonContact = async (params = {}) => {
    const { add_user_as_contact } = params
    try {
      this.isSavingContacts = true
      const { data: contact } = await contactApi.create({
        contact: this.contactParams,
        add_user_as_contact
      })
      this.addAttendeeId(contact.id)
      this.fetchContacts()
      this.globalStore.openNotificationSnackbar(
        `The contact was successfully created.`
      )
      this.handleCancelNewContact()
      return true
    } catch ({ data, status }) {
      if (status === 422) {
        this.contactErrors = parseErrors(data)
      }
      if (status === 406) {
        this.globalStore.openNotificationSnackbar(data[0].message)
      }
      if (status === 409) {
        this.handleOpenConfirmAddUserToContact()
      }
      this.globalStore.openNotificationSnackbar(
        `There was an error creating the contact`
      )
      return false
    } finally {
      this.isSavingContacts = false
    }
  }

  @action
  setNewContact = newContact => {
    this.newContact = newContact
  }

  _loadContactData = contact => {
    const {
      email,
      first_name,
      last_name,
      phone,
      company,
      medias,
      __meta__: { pivot_notes: notes, pivot_is_venue_billing: is_venue_billing }
    } = contact

    this.contactParams = {
      first_name,
      last_name,
      email,
      phone,
      company,
      notes,
      medias,
      is_venue_billing
    }

    const [isValid] = validateForm(
      this.contactParams,
      this.contactParamsValidations
    )
    this.contactIsValid = isValid
  }

  @action
  resendInvitation = async contactId => {
    try {
      this.isSendingInvitation = true
      await contactApi.resendInvitation(contactId)
      this.globalStore.openNotificationSnackbar(
        `The invitation was successfully sent.`
      )
      return true
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an error resending the invitation.`
      )
    } finally {
      this.isSendingInvitation = false
    }
    return true
  }
}

export default new ContactStore()
