import BaseApi from './baseApi'
import userStore from '../stores/user'

class AmenitiesApi extends BaseApi {
  constructor() {
    super({ routeName: 'amenities' })
  }

  getAll = () => {
    return this.request(userStore.jwtToken).get()
  }
}

const amenitiesApi = new AmenitiesApi()
export default amenitiesApi
