import React from 'react'

const privacyPolicy = () => (
  <div style={{ paddingBottom: '24px' }}>
    <span style={{ fontFamily: 'Roboto, sans-serif' }}>
      This privacy policy (this “Privacy Policy”) describes how{' '}
      <b>SpaceMX LLC</b> (“The Company”) collects and uses the personal
      information you provide on our website <b>www.spacemx.com</b> and Mobile
      Software. It also describes the choices available to you regarding our use
      of your personal information and how you can access and update this
      information. By using, accessing or participating in the Service, you
      agree to the terms of this Privacy Policy. Capitalized terms not defined
      in this Privacy Policy have the meanings set forth in the Terms of Use
      (located at{' '}
      <a href="www.spacemx.com/legal" rel="noopener noreferrer" target="_blank">
        <b>www.spacemx.com/legal.</b>
      </a>{' '}
      )<br />
      <br /> If you have questions or complaints regarding our Privacy Policy or
      practices, please contact us at{' '}
      <b>
        <a href="mailto:legal@spacemx.com">legal@spacemx.com.</a>
      </b>
      <br />
      <br /> The Company complies with the U.S.–E.U. Safe Harbor framework and
      the U.S.–Swiss Safe Harbor framework as set forth by the U.S. Department
      of Commerce regarding the collection, use, and retention of personal data
      from European Union member countries and Switzerland. The Company has
      certified that it adheres to the Safe Harbor Privacy Principles of notice,
      choice, onward transfer, security, data integrity, access, and
      enforcement. To learn more about the Safe Harbor program, and{' '}
      <b>[to view The Company’s certification]</b>, please visit
      <a
        href="http://www.export.gov/safeharbor/"
        rel="noopener noreferrer"
        target="_blank"
      >
        {' '}
        <b>http://www.export.gov/safeharbor/.</b>
      </a>
      <br />
      <br />
      We reserve the right to change our Privacy Policy at any time. If we do
      this, we will post a notice that we have made material changes to this
      Privacy Policy on the Site for at least 7 days before the changes are
      posted and will indicate at the bottom of the Privacy Policy the date this
      policy was last revised. Any revisions to this Privacy Policy will become
      effective (i) on the effective date or (ii) the first time you access or
      use the Service after any such changes. If you do not agree to abide by
      this or any future Privacy Policy, do not use, access or participate in
      (or continue to use, access or participate in) the Service. We encourage
      you to periodically review this page for the latest information on our
      privacy practices.
      <br />
      <br /> <b>The Information We Collect</b> <br />
      <br />
      We collect and store information to provide you a better overall
      experience and present you with the most relevant products and ads
      possible. When you use the Service you provide us with three types of
      information: (i) information you submit via the Service, (ii) information
      regarding your use of the Service collected by us as you interact with the
      Service and (iii) information from other sources.
      <br />
      <br /> <i>(i) Information you submit via the Service</i>
      <br />
      <br />
      <ul>
        <li>
          Through the registration process you may provide us with your name,
          company affiliation, email address, telephone number, physical
          address, and other information that may be requested during the
          registration process on the Site.
        </li>
        <li>
          If you wish to create an account that requires payment of a fee, or
          use services offered via the Site that require payment of a fee, a
          valid credit card number or other payment information may be required.
          By submitting your payment information, you authorize The Company to
          access and use this information in order to provide you with and
          accept payment for the Service.
        </li>
        <li>
          When you use the Service you may submit information and content to
          your Profile, send messages, perform searches, submit reviews, and
          otherwise transmit information to other Users. We store this
          information and registration information so that we can provide you
          the service and offer personalized features.
        </li>
      </ul>
      <br />
      <br /> <i>(ii) Information we get from your use of the Service</i>
      <br />
      <br />
      <ul>
        <li>
          When you enter the Site, we collect your browser type and IP address.
          This information is gathered for all Company visitors.
        </li>
        <li>
          When you download and use our Mobile Software, we automatically
          collect information on the type of device you use, operating system
          version, and the device identifier (or {'"UDID"'}
          ).
        </li>
        <li>
          We store certain information from your browser using “cookies.” A
          cookie is a piece of data stored on the user’s computer tied to
          information about the user. We use session ID cookies to confirm that
          users are logged in. We also use persistent cookies to remember
          sign-in status, coupon availability, search preferences, and Space
          origination information so that you do not have to enter it again the
          next time you visit.
        </li>
        <li>
          You can remove persistent cookies by following directions provided in
          your Internet browser’s “help” directory. If you do not want
          information collected through the use of cookies, there is a simple
          procedure in most browsers that allows you to deny or accept the
          cookie feature; however, you should note that cookies may be necessary
          to provide you certain features (e.g., customized delivery of
          information) available on the Site.
        </li>
        <li>
          <b>[We are a member]</b> of the Network Advertising Initiative (“NAI”)
          and adhere to the NAI Code of Conduct. You can opt-out of receiving
          targeted advertising based on data collected via your browser by using
          the NAI’s opt-out tool, which can be found here. When you opt out, we
          will stop (a) collecting information about your interests via your
          browser and (b) serving you targeted advertisements based on the data
          collected via your browser.
        </li>
        <li>
          Our third party partners employ clear gifs (a.k.a. Web Beacons/Web
          Bugs), images, and scripts that help them better manage content,
          advertising and marketing on our site as well as off our site
        </li>
        <li>
          When you use the Service you may submit information and content to
          your Profile, send messages, perform searches, submit reviews, and
          otherwise transmit information to other Users. We store this
          information and registration information so that we can provide you
          the service and offer personalized features.
        </li>
        <li>
          While you may disable the usage of cookies through your browser
          settings, we do not respond to a “Do Not Track” signal in the HTTP
          header from your browser or mobile application. We may use analytics
          to track responses from third-party ads such as search engines and
          social networks.
        </li>
        <li>
          We use analytics tools like Google Analytics and Pingdom to track
          application usage, performance, and availability as well as your
          response to The Company content, ads and placements, including on
          search engines and social networks. For more information on how Google
          uses data when you use our site or app, please visit [sic].
        </li>
        <li>
          We may, either directly or through Third Parties we partner with,
          track your interaction with the Service and ads on the site and off
          the site for the purposes of The Company customer support, analytics,
          research, product development, fraud prevention, risk assessment,
          regulatory compliance and investigation.
        </li>
        <li>
          The use of cookies by our partners, affiliates, tracking utility
          company, service providers is not covered by our privacy policy. We do
          not have access or control over these cookies.
        </li>
        <li>
          If you use the Mobile Software, your location may be tracked on the
          mobile device on which the Mobile Software is installed. The Company
          will not collect, transmit or otherwise use this information without
          your permission. We use location data to provide you relevant search
          results for spaces in your area. As a Guest, you can control when
          Hosts or other Guests can see your location through your privacy
          settings. To opt out of location tracking, please change your settings
          at the device level.
        </li>
      </ul>
      <br />
      <br /> <i>(iii) Information from other sources</i>
      <br />
      <br />
      <ul>
        <li>
          If you sign up for The Company using your social media account, link
          your The Company account to your social media account, or use other
          The Company social media features, we may access information about you
          via that social media provider in accordance with the provider’s
          policies.
        </li>
      </ul>
      We are not responsible for circumvention of any privacy settings or
      security measures contained on the Site. You understand and acknowledge
      that, even after removal, copies of User Content may remain viewable in
      cached and archived pages or if other Users have copied or stored your
      User Content.
      <br />
      <br /> <b>Minors</b> <br />
      <br />
      The Company does not knowingly collect or solicit information from anyone
      under the age of 18 or knowingly allow such persons to register. If you
      are under 18, please do not attempt to register for The Company or send
      any information about you to us, including your name, address, telephone
      number, or email address. No one under age 18 may provide any information
      to or on The Company. If you believe that we might have any information
      from or about a minor under 18, please contact us at{' '}
      <b>
        <a href="mailto:legal@spacemx.com">legal@spacemx.com</a>
      </b>
      . <br />
      <br />
      <b>Use of Information Obtained by The Company</b>
      <br />
      <br /> We use the information we collect from our services to enable your
      access to our products, enhance our products, protect the site and our
      customers, and to offer tailored content, like more relevant search
      results and ads. <br />
      <br />
      Once you register with The Company, you may create your Profile. Your
      Profile and contact information are displayed to other Users, including
      Hosts at Spaces at which you make or request reservations and tours.{' '}
      <br />
      <br />
      We may also send you service-related announcements and marketing
      communications on the site, across other websites, and through email.
      Examples include promotions, new product/service messages, ads for the
      service and partner offers. Generally, you may opt out of such emails by
      following the unsubscribe option included in each email; however, The
      Company reserves the right to send you account-related notices such as
      reservation confirmation emails even if you opt out of all
      marketing-related emails.
      <br />
      <br /> The Company provides push notifications for upcoming reservations.
      To turn off push notifications, please edit your personal settings on your
      device.
      <br />
      <br /> Profile information and your reservation activity is used by The
      Company primarily to be presented back to and edited by you when you
      access the Service and to be presented to other Users. The amount of such
      information presented to other Users will depend on your relationship with
      those Users (e.g., whether they are following you, or you are following
      them, as permitted by the Service, or whether you were referred to the
      Service by them).
      <br />
      <br /> If you register for the Service via a referral from a Professional,
      The Company will inform the Professional when you register for the Service
      and begin browsing listings on the Service, and will provide the
      Professional with information regarding reservations that you book. In
      some cases, other Users may be able to supplement your Profile or
      contribute information about your Space (if you are a Host), including by
      submitting comments and reviews. You consent to The Company making such
      information available to other Users and the public.
      <br />
      <br /> You may change the privacy settings of your profile through your
      account portal. Information from your Profile may be available to our
      third party service provider for search engines. This is primarily so that
      potential users may be able to find you.
      <br />
      <br /> The Company may use non-identifiable aggregate data (based partly
      on IP addresses) collected through the Service to analyze Service usage
      and system performance. Such data may be used by The Company and shared
      with third parties in any manner.
      <br />
      <br /> <b>Sharing Your Information with Third Parties</b>
      <br />
      <br /> You may choose to provide information about you, your company or
      organization or, if you are a Host, your Space to other Users and to the
      public by providing such information on public areas of the Site (“Public
      Information”). Public Information consists of information that is posted
      by you on areas of the Site accessible by other Users and/or the public,
      such as Space descriptions. Public Information does not include
      information you submit to us but do not post on areas of the Site
      accessible by other Users and/or the public, such as billing and payment
      information (“Non-Public Information”).
      <br />
      <br /> We will share your personal information with third parties only in
      the ways that are described in this Privacy Policy. We do not sell your
      personal information to third parties. We share your Non-Public
      Information with third parties only in limited circumstances where we
      believe such sharing is reasonably necessary to offer the Service, legally
      required, or permitted by you. For example:
      <ul>
        <li>
          We may provide Non-Public Information to service providers who help us
          bring you the services we offer, such as hosting the service at a
          co-location facility, sending email updates about The Company,
          removing repetitive information from our User lists, processing
          payments, or to provide search results or links. In connection with
          these offerings and business operations, our service providers may
          have access to your Non-Public Information for use for a limited time
          in connection with these business activities. Where we utilize third
          parties for the processing of any of your Non-Public Information, we
          implement reasonable contractual protections limiting the use of that
          Non-Public Information to the provision of services to The Company.
        </li>
        <li>
          We may be required to disclose Non-Public Information pursuant to
          lawful requests, such as subpoenas or court orders, or in compliance
          with applicable laws. Additionally, we may share Non-Public
          Information when we believe it is necessary to comply with law, to
          protect our interests or property, to prevent fraud or other illegal
          activity perpetrated through the Service or using the The Company
          name, or to prevent imminent harm. This may include sharing Non-Public
          Information with other companies, lawyers, agents, government agencies
          or to any third party with your prior consent to do so.
        </li>
        <li>
          We may offer to provide services jointly with other companies on the
          Service, and we may share Non-Public Information with that company in
          connection with such offer or your use of that service.
        </li>
        <li>
          If the ownership of all or substantially all of The Company business,
          or individual business units or assets owned by The Company that are
          related to the Site, were to change, your Non-Public Information may
          be transferred to the new owner. In any such transfer of information,
          your Non-Public Information would remain subject to this Privacy
          Policy. You will be notified via email and/or a prominent notice on
          our website of any change in ownership or uses of your personal
          information, as well as any choices you may have regarding your
          personal information.
        </li>
        <li>
          If we have a separate agreement with a company we believe to be your
          employer or company for whom we believe you work as a contractor (as
          determined in each case by your email domain), we may provide your
          User ID, email address and reservation activity to that company so
          that you may be included within an arrangement that we have with such
          company. We will notify you if we provide this information to a
          company and you may elect not to participate in any such arrangement
          by contacting us at{' '}
          <b>
            <a href="mailto:legal@spacemx.com">legal@spacemx.com</a>
          </b>{' '}
          and stating that you do not wish to participate in such arrangement.
        </li>
        <li>
          When you use the Service, certain information you post or share with
          third parties (including other Users), such as comments, reviews or
          other information, may be shared with other Users in accordance with
          the privacy settings you select. All such sharing of information is
          done at your own risk. Please keep in mind that if you disclose
          confidential information in your Profile or when posting comments,
          reviews or other submissions, this information may become publicly
          available.
        </li>
        <li>
          {' '}
          Your posting of Public Information to public areas of the Site
          constitutes your consent to share such Public Information publicly.
          Please be aware that Public Information will be accessible by both
          Users and the general public. Nothing in this Privacy Policy shall
          restrict The Company’s right to use Public Information. To request
          removal of your personal information from public areas of the Site,
          please contact us. In some cases, we may not be able to remove your
          personal information, in which case we will let you know if we are
          unable to do so and why.{' '}
        </li>
        <li>
          <b>[We use Plaid Technologies, Inc.]</b> (“Plaid”) to gather user data
          from financial institutions. By using our service, you grant us and
          Plaid the right, power, and authority to act on your behalf to access
          and transmit your personal and financial information from the relevant
          financial institution. You agree to your personal and financial
          information being transferred, stored, and processed by Plaid in
          accordance with the Plaid Privacy Policy located on their website{' '}
          <a
            href="https://plaid.com/legal"
            rel="noopener noreferrer"
            target="_blank"
          >
            <b>https://plaid.com/legal.</b>
          </a>
        </li>
        <li>
          <b>[We use Stripe, Inc.]</b> (“Stripe”) to gather user data from
          financial institutions. By using our service, you grant us and Stripe
          the right, power, and authority to act on your behalf to access and
          transmit your personal and financial information from the relevant
          financial institution. You agree to your personal and financial
          information being transferred, stored, and processed by Stripe in
          accordance with the Stripe Privacy Policy located on their website{' '}
          <a
            href="https://stripe.com/us/legal"
            rel="noopener noreferrer"
            target="_blank"
          >
            <b>https://stripe.com/us/legal.</b>
          </a>
        </li>
      </ul>
      <br />
      <br />
      <b>Links</b>
      <br />
      <br /> The Company may contain links to other websites. We are not
      responsible for the privacy practices of other websites. We encourage
      Users to be aware when they leave the Site to read the privacy statements
      of other websites that collect personally identifiable information. This
      Privacy Policy applies solely to information collected by The Company via
      the Site.
      <br />
      <br /> <b>Single Sign-on</b>
      <br />
      <br /> You can log in to our site using sign-in services such as an Open
      ID provider. These services will authenticate your identity and provide
      you the option to share certain personal information with us such as your
      name and email address to pre-populate our sign up form. Services like
      Linked In Open ID provider give you the option to post information about
      your activities on this website to your profile page to share with others
      within your network.
      <br />
      <br /> <b>Social Media Widgets</b>
      <br />
      <br /> <b>[Our website includes social media features]</b>, such as the
      Facebook Like button and widgets, such as the ShareThis button or
      interactive mini-programs that run on our site. These features may collect
      your IP address, which page you are visiting on our site, and may set a
      cookie to enable the feature to function properly. Social media features
      and widgets are either hosted by a third party or hosted directly on our
      site. Your interactions with these features are governed by the privacy
      policy of the company providing it.
      <br />
      <br /> <b>Third Party Advertising</b>
      <br />
      <br /> Advertisements that appear on The Company are sometimes delivered
      (or “served”) directly to Users by third party advertisers. They
      automatically receive your IP address when this happens. These third party
      advertisers may also download cookies to your computer, or use other
      technologies such as JavaScript and “web beacons” (also known as “1×1
      gifs”) to measure the effectiveness of their ads and to personalize
      advertising content. Doing this allows the advertising network to
      recognize your computer each time they send you an advertisement in order
      to measure the effectiveness of their ads and to personalize advertising
      content. In this way, they may compile information about where individuals
      using your computer or browser saw their advertisements and determine
      which advertisements are clicked. The Company does not have access to or
      control of the cookies or other technologies that may be used by the third
      party advertisers. Third party advertisers have no access to your contact
      information stored on The Company unless you choose to share it with them.
      If you wish to not have this information used for the purpose of serving
      you targeted ads, you may opt-out by contacting us at{' '}
      <b>
        <a href="mailto:legal@spacemx.com">legal@spacemx.com</a>
      </b>
      . Please note this does not opt you out of being served advertising. You
      will continue to receive generic ads.
      <br />
      <br /> This Privacy Policy covers the use of cookies by The Company and
      does not cover the use of cookies or other tracking technologies by any of
      its advertisers.
      <br />
      <br /> <b>Changing or Removing Information</b>
      <br />
      <br /> Users may modify or delete their Profile information by logging
      into their account. Removed information may persist in backup copies but
      will not be generally available to other Users. Where you make use of the
      communication features of the service to share information with other
      Users (e.g., sending a message to another User) you generally cannot
      remove such communications from the Site.
      <br />
      <br /> Upon your request, we will close your account and remove your
      personal information from view as soon as reasonably possible, based on
      your account activity and in accordance with applicable law. We do retain
      personal information from closed accounts to comply with law, prevent
      fraud, collect any fees owed, resolve disputes, troubleshoot problems,
      assist with any investigations, enforce our Terms of Use and take other
      actions otherwise permitted by law. <br />
      <br />
      <b>Security</b>
      <br />
      <br /> The Company takes reasonable precautions to protect Users’
      Non-Public Information. Your account information is located on a secured
      server behind a firewall. When you enter sensitive information, such as a
      credit card number on our order forms or transmit location data, we
      encrypt the transmission of that information using secure socket layer
      technology (SSL). However, no method of transmission over the Internet, or
      method of electronic storage, is 100% secure. Therefore we cannot
      guarantee absolute security. If you have any questions about security
      please contact us.
      <br />
      <br /> <b>Data Transfer</b>
      <br />
      <br /> The data protection laws in the United States differ from those
      applicable in other countries, including the country in which you reside.
      By providing The Company with your personal information, you consent to
      the collection, transfer and processing of your personal information to
      and from the United States and agree that your personal information may be
      subject to access requests from governments, courts, or law enforcement in
      the United States according to the laws of the United States.
      <br />
      <br /> <b>Contacting The Company</b>
      <br />
      <br /> If you have any questions about this Privacy Policy or The
      Company’s privacy practices in general, please contact us as provided for
      below. You may also contact us to require that we provide you with a copy
      of your Non-Public Information within our control, to have us correct any
      erroneous information, to discontinue receipt of certain types of
      communications or to ask us to delete any of your Non-Public Information
      we have stored that is no longer required for the purpose for which it was
      collected. We will respond to your request to access Non-Public
      Information within 30 days. <br />
      <br />
      <b>
        SpaceMX LLC
        <br />
        Attention: Legal
        <br />
        388 Market Street, Suite 1300 San Francisco, CA 94111
        <br />
        Phone: 415-837-3200
        <br />
        Email: <a href="mailto:legal@spacemx.com">legal@spacemx.com</a>
      </b>
    </span>
  </div>
)

export default privacyPolicy
