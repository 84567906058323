import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
const styles = theme => ({
  outerContainer: {
    [theme.breakpoints.up('md')]: {
      ...theme.global.maxWidthContainer
    },
    minHeight: 'calc(100vh - 212px)',
    flex: '1 1 auto'
  },
  narrowMaxWidth: {
    [theme.breakpoints.up('md')]: {
      ...theme.global.maxWidthNarrowContainer
    }
  },
  wizardWidth: {
    [theme.breakpoints.up('md')]: {
      ...theme.global.wizardWidth
    }
  }
})
const PageContent = ({ classes, children, narrow, wizardWidth, ...props }) => (
  <div
    {...props}
    className={classnames(
      classes.outerContainer,
      !!wizardWidth && classes.wizardWidth,
      !!narrow && classes.narrowMaxWidth,
      props.className
    )}
  >
    {children}
  </div>
)

export default withStyles(styles)(PageContent)
