import { observable, action, runInAction } from 'mobx'
import venueApi from '../services/venueApi'
import { createBrowserHistory } from 'history'
import bookingApi from '../services/bookingApi'
import reviewsApi from '../services/reviewsApi'
import adminReviewApi from '../services/admin/reviewApi'
import userStore from './user'
import tourApi from '../services/tourApi'
import globalStore from './global'
import bookingStore from './booking'
import tourStore from './tour'
import qs from 'qs'

const history = createBrowserHistory()

const defaultReviewsPagination = {
  total: 0,
  perPage: 10,
  page: 1,
  lastPage: 1
}

class DashboardStore {
  static get defaultReviewFilters() {
    return {
      sort_by: 'most_recent',
      rating: '',
      type: 'spaces'
    }
  }

  @observable.deep
  reviewsFilters = {
    sort_by: 'most_recent',
    rating: '',
    type: 'spaces'
  }

  @observable.deep
  reviewsAboutMePagination = {
    ...defaultReviewsPagination
  }

  @observable.deep
  reviewsByMePagination = {
    ...defaultReviewsPagination
  }

  @observable
  currentBookingDetail = {
    space: {
      name: '',
      address: {},
      venue: {},
      medias: []
    },
    transaction: {}
  }
  @observable
  isLoadingCurrentBookingDetail = false

  @observable
  currentBookings = []
  @observable
  futureBookings = []
  @observable
  pastBookings = []
  @observable
  isLoadingMyBookings = false
  @observable
  isLoadingFutureBookings = false
  @observable
  isLoadingPastBookings = false

  @observable
  cancelBookingDialogOpen = false
  @observable
  isCancellingBooking = false
  @observable
  currentTours = []
  @observable
  pastTours = []
  @observable
  futureTours = []
  @observable
  isLoadingMyTours = false
  @observable
  isLoadingFutureTours = false
  @observable
  isLoadingPastTours = false

  @observable
  isEmailDialogOpen = false
  @observable
  emailDialogSubject = null
  @observable
  emailDialogSubjectType = 'Booking / Tour'
  @observable
  bookingDetailEmailLoading = false
  @observable
  bookingDetailEmailError = false

  @observable
  isEmailDialogOpen = false
  @observable
  subscriptionDetailEmailLoading = false
  @observable
  subscriptionDetailEmailError = false
  @observable
  detailEmailReceipients = []

  @observable
  reviewsAboutMe = []
  @observable
  reviewsByMe = []
  @observable
  isLoadingReviewsAboutMe = false
  @observable
  isLoadingReviewsByMe = false

  @observable
  isLoading = false

  @observable
  venueDetailTab = 0
  @observable
  spaceDetailTab = 0

  @observable
  pendingReviews = []
  @observable
  ratings = {}
  @observable
  averageRatings = 0
  @observable
  rating = DashboardStore.defaultReviewFilters.rating
  @observable
  sort_by = DashboardStore.defaultReviewFilters.sort_by
  @observable
  type = DashboardStore.defaultReviewFilters.type
  @observable
  isFetchingRatingData = false

  constructor() {
    this.globalStore = globalStore
  }

  @action.bound
  setVenueDetailTab = tabValue => {
    this.venueDetailTab = +tabValue
  }

  @action
  setSpaceDetailTab = tabValue => {
    this.spaceDetailTab = tabValue
  }

  @action
  setEmailDialogVisibility = (isVisible, item, type = 'Booking / Tour') => {
    this.isEmailDialogOpen = isVisible
    this.emailDialogSubject = isVisible && item ? item : null
    this.emailDialogSubjectType = type
  }

  @action
  updateDetailEmailReceipients = receipients => {
    this.detailEmailReceipients = receipients
  }

  @action
  sendBookingDetailsEmail = async bookingId => {
    this.bookingDetailEmailLoading = true
    try {
      const emailStatus = await bookingApi.sendBookingDetails(
        bookingId,
        this.detailEmailReceipients
      )
      if (emailStatus) {
        this.setEmailDialogVisibility(false)
        this.detailEmailReceipients = []
        this.globalStore.openNotificationSnackbar(
          'Booking details have been sent successfully!'
        )
      }
    } catch (error) {
      this.bookingDetailEmailError = error
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error sending your email.'
      )
    } finally {
      this.bookingDetailEmailLoading = false
    }
  }

  @action
  sendTourDetailsEmail = async tourId => {
    this.tourDetailEmailLoading = true
    try {
      const emailStatus = await tourApi.sendTourDetails(
        tourId,
        this.detailEmailReceipients
      )
      if (emailStatus) {
        this.setEmailDialogVisibility(false)
        this.detailEmailReceipients = []
        this.globalStore.openNotificationSnackbar(
          'Tour details have been sent successfully!'
        )
      }
    } catch (error) {
      this.tourDetailEmailError = error
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error sending your email.'
      )
    } finally {
      this.tourDetailEmailLoading = false
    }
  }

  sendSubscriptionDetailsEmail = async (venueId, productId, subscriptionId) => {
    this.subscriptionDetailEmailLoading = true
    try {
      const emailStatus = await venueApi.sendSubscriptionDetails(
        venueId,
        productId,
        subscriptionId,
        this.detailEmailReceipients
      )
      if (emailStatus) {
        this.setEmailDialogVisibility(false)
        this.detailEmailReceipients = []
        this.globalStore.openNotificationSnackbar(
          'Subscription details have been sent successfully!'
        )
      }
    } catch (error) {
      this.subscriptionDetailEmailError = error
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error sending your email.'
      )
    } finally {
      this.subscriptionDetailEmailLoading = false
    }
  }

  @action
  getBookingsForVenue = async (venueId, limit = 3) => {
    this.isLoadingMyBookings = true
    const bookings = await bookingApi.fetchBookingsByVenueId({ venueId, limit })
    runInAction(() => {
      this.currentBookings = bookings.data.data
      this.isLoadingMyBookings = false
    })
  }

  @action
  cancelTour = async tour => {
    this.isCancellingTour = true
    const tourId = tour.id
    let message

    if (!tourStore.doesPassCancellationPolicy(tour)) {
      this.isCancellingTour = false
      message = 'Tour cannot be canceled within 24 hours.'
      console.error(message)
      this.globalStore.openNotificationSnackbar(message)
      return
    }

    try {
      const cancelled = await tourApi.cancelTour(tourId)
      if (!cancelled.data.error) {
        this.currentTours = [
          ...this.currentTours.filter(tour => tour.id !== tourId)
        ]
      }
      message = 'You have successfully cancelled your tour.'
    } catch (error) {
      message = error.data ? error.data.error : error
    } finally {
      this.isCancellingTour = false
      this.globalStore.openNotificationSnackbar(message)
    }
  }

  @action
  cancelBooking = async (user, booking, currentVenueAbility) => {
    this.isCancellingBooking = true
    const bookingId = booking.id
    let message

    if (
      !bookingStore.doesPassCancellationPolicy(
        user,
        booking,
        currentVenueAbility
      )
    ) {
      this.isCancellingBooking = false
      message = 'Booking cannot be canceled within 24 hours.'
      console.error(message)
      this.globalStore.openNotificationSnackbar(message)
      return
    }

    try {
      const cancelled = await bookingApi.cancelBooking(bookingId)
      if (!cancelled.data.error) {
        this.currentBookings = [
          ...(this.currentBookings && this.currentBookings.length
            ? [this.currentBookings.filter(booking => booking.id !== bookingId)]
            : [])
        ]
      }
      message = 'You have successfully cancelled your booking.'
      await userStore.getMe(['favorites', 'medias', 'companies.medias'])
    } catch (error) {
      message = error.data ? error.data.error : error
    } finally {
      this.isCancellingBooking = false
      this.globalStore.openNotificationSnackbar(message)
    }
  }

  @action
  getCurrentBookingDetail = async (bookingId, force = false) => {
    this.isLoadingCurrentBookingDetail = true
    if (
      this.currentBookingDetail &&
      this.currentBookingDetail.id === bookingId &&
      !force
    ) {
      this.isLoadingCurrentBookingDetail = false
      return
    }
    const booking = await bookingApi.fetchOne(bookingId)
    runInAction(() => {
      if (!booking.data.error) {
        this.currentBookingDetail = booking.data
      }
      this.isLoadingCurrentBookingDetail = false
    })
  }

  @action
  openCancelBookingDialog() {
    this.cancelBookingDialogOpen = true
  }

  @action
  getMyFutureTours = async (limit = 3, sort = 'asc') => {
    this.isLoadingFutureTours = true
    try {
      const { data } = await tourApi.getMyFutureTours({ limit, sort })
      this.futureTours = data
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingFutureTours = false
    }
  }

  @action
  getMyPastTours = async (limit = 3, sort = 'desc') => {
    this.isLoadingPastTours = true
    try {
      const { data } = await tourApi.getMyPastTours({ limit, sort })
      this.pastTours = data.map(tour => {
        tour.isPast = true
        return tour
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingPastTours = false
    }
  }

  @action
  getMyTours = async (limit = 3, sort = 'asc') => {
    this.isLoadingMyTours = true
    try {
      const { data } = await tourApi.getMyTours({ limit, sort })
      this.currentTours = data
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingMyTours = false
    }
  }

  @action
  _uptdateUrlFilter = (name, value) => {
    const { location } = window
    const { search } = location

    const searchParams = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    if (value && value !== DashboardStore.defaultReviewFilters[name]) {
      searchParams[name] = value
    } else {
      delete searchParams[name]
    }

    return history.push({
      pathname: location.pathname,
      search: qs.stringify(searchParams, { addQueryPrefix: true })
    })
  }

  @action
  handleReviewsFilterByOption = ({ target: { name, value } }, fetchReviews) => {
    this._uptdateUrlFilter(name, value)
    this.reviewsFilters[name] = value
    fetchReviews()
  }

  @action.bound
  @action
  getReviewsAboutMe = async (reviewsAboutMePagination = {}) => {
    const {
      total = 0,
      perPage = 10,
      page = 1,
      lastPage = 1
    } = reviewsAboutMePagination
    this.isLoadingReviewsAboutMe = true
    const {
      data: { reviews = [], pagination = {} }
    } = await reviewsApi.getForUser({
      filters: {
        ...this.reviewsFilters
      },
      pagination: {
        total,
        perPage,
        page,
        lastPage
      }
    })
    this.reviewsAboutMe = reviews
    this.reviewsAboutMePagination = pagination
    this.isLoadingReviewsAboutMe = false
  }

  @action
  getReviewsByMe = async (reviewsByMePagination = {}) => {
    const {
      total = 0,
      perPage = 10,
      page = 1,
      lastPage = 1
    } = reviewsByMePagination
    this.isLoadingReviewsByMe = true
    const {
      data: { reviews = [], pagination = {} }
    } = await reviewsApi.getByUser({
      filters: {
        ...this.reviewsFilters
      },
      pagination: {
        total,
        perPage,
        page,
        lastPage
      }
    })
    this.reviewsByMe = reviews
    this.reviewsByMePagination = pagination
    this.isLoadingReviewsByMe = false
  }

  @action.bound
  async saveReview(review) {
    this.isLoading = true
    try {
      const response = await reviewsApi.create(review)
      if (response.data.error) {
        if (response.status === 401) {
          userStore.openAuthDialog()
        }
      } else {
        if (response.data.length === 1) {
          this.reviewsByMe.push(response.data)
        }
      }
    } catch (error) {
      console.warn(error)
    } finally {
      await this.getReviewsByMe()
      await this.fetchPendingReviews()
      this.isLoading = false
    }
  }

  @action
  fetchPendingReviews = async () => {
    try {
      this.isLoading = true
      const {
        data: { bookings = [] }
      } = await adminReviewApi.fetchPendings()
      this.pendingReviews = bookings
      return true
    } catch (error) {
      return {}
    } finally {
      this.isLoading = false
    }
  }

  @action
  fetchReviewRatings = async (params = {}) => {
    try {
      this.isFetchingRatingData = true
      const filters = { ...DashboardStore.defaultReviewFilters }
      if (Object.keys(params).length > 0) {
        filters.type = params.type || DashboardStore.defaultReviewFilters.rating
        filters.rating =
          params.rating || DashboardStore.defaultReviewFilters.rating
        filters.sort_by =
          params.sort_by || DashboardStore.defaultReviewFilters.sort_by
      }
      const {
        data: { ratings }
      } = await reviewsApi.fetchRatings(filters)
      this.ratings = ratings
      this.averageRatings = this._avarageCalculator(this.ratings)
      return true
    } catch (error) {
      return {}
    } finally {
      this.isFetchingRatingData = false
    }
  }

  @action
  _avarageCalculator = ratings => {
    let totalScore = 0
    let reviewsCount = 0

    Object.keys(ratings).forEach(rating => {
      totalScore += parseInt(rating, 10) * ratings[rating]
      reviewsCount += ratings[rating]
    })

    return totalScore && reviewsCount && totalScore / reviewsCount
  }
}

export default new DashboardStore()
