import { action, observable } from 'mobx'
import { createBrowserHistory } from 'history'
import operatorReviewApi from '../../services/admin/operatorReviewApi'
import reviewsApi from '../../services/reviewsApi'
import userStore from '../user'
import qs from 'qs'

const history = createBrowserHistory()

class ReviewStore {
  static get defaultFilters() {
    return {
      sort_by: 'most_recent',
      rating: '',
      type: 'spaces'
    }
  }

  @observable
  reviews = []

  @observable
  pendingReviews = []

  @observable
  ratings = {}

  @observable
  averageRatings = 0

  @observable
  rating = ReviewStore.defaultFilters.rating

  @observable
  sort_by = ReviewStore.defaultFilters.sort_by

  @observable
  type = ReviewStore.defaultFilters.type

  @observable
  isFetchingData = false

  @observable
  isFetchingRatingData = false

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @action
  fetch = async (venueId, params = {}) => {
    try {
      this.isFetchingData = true
      this._loadParams(params)
      const { data } = await operatorReviewApi.fetch(venueId, {
        ...this._getFilters(),
        page: this.pagination.page,
        per_page: this.pagination.perPage
      })
      this._parsePayload(data)
      return true
    } catch (error) {
      return {}
    } finally {
      this.isFetchingData = false
    }
  }

  @action
  fetchRatings = async (venueId, params = {}) => {
    try {
      this.isFetchingRatingData = true
      this._loadParams(params)
      const {
        data: { ratings }
      } = await operatorReviewApi.fetchRatings(venueId, {
        ...this._getFilters()
      })
      this.ratings = ratings
      return true
    } catch (error) {
      return {}
    } finally {
      this.isFetchingRatingData = false
    }
  }

  @action
  fetchPendings = async venueId => {
    try {
      this.isFetchingData = true
      const {
        data: { bookings = [] }
      } = await operatorReviewApi.fetchPendings(venueId)
      this.pendingReviews = bookings
      return true
    } catch (error) {
      return {}
    } finally {
      this.isFetchingData = false
    }
  }

  @action
  saveReview = async review => {
    this.isFetchingData = true
    try {
      const response = await reviewsApi.create(review)
      this.isFetchingData = false
      if (response.data.error) {
        if (response.status === 401) {
          userStore.openAuthDialog()
        }
      } else {
        await this.fetchPendings(response.data.booking.space.venue_id)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      this.isFetchingData = false
    }
  }

  @action
  _avarageCalculator = ratings => {
    let totalScore = 0
    let reviewsCount = 0

    Object.keys(ratings).forEach(rating => {
      totalScore += parseInt(rating, 10) * ratings[rating]
      reviewsCount += ratings[rating]
    })

    return totalScore && reviewsCount && totalScore / reviewsCount
  }

  @action
  handleFilterByOption = ({ target: { name, value } }, venueId) => {
    this.pagination.page = 1
    this._uptdateUrlFilter(name, value)
    this[name] = value
    this.fetch(venueId)
  }

  @action
  _getFilters = () => {
    const filters = { sort_by: this.sort_by, type: this.type }
    if (this.rating) {
      filters['rating'] = this.rating
    }
    return filters
  }

  @action
  _loadParams = params => {
    if (Object.keys(params).length > 0) {
      this.type = params.type || ReviewStore.defaultFilters.rating
      this.rating = params.rating || ReviewStore.defaultFilters.rating
      this.sort_by = params.sort_by || ReviewStore.defaultFilters.sort_by
    }
  }

  @action
  _parsePayload = (payload = {}) => {
    const { reviews = [], metadata = {} } = payload
    this.reviews = reviews
    this.pagination = metadata.pagination
    this.averageRatings = this._avarageCalculator(this.ratings)
  }

  @action
  _uptdateUrlFilter = (name, value) => {
    const { location } = window
    const { search } = location

    const searchParams = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    if (value && value !== ReviewStore.defaultFilters[name]) {
      searchParams[name] = value
    } else {
      delete searchParams[name]
    }

    return history.push({
      pathname: location.pathname,
      search: qs.stringify(searchParams, { addQueryPrefix: true })
    })
  }

  @action
  handleChangePage = (venueId, page) => {
    this.pagination = {
      ...this.pagination,
      page
    }
    this.fetch(venueId)
  }
}

export default new ReviewStore()
