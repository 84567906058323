export default {
  typography: {
    useNextVariants: true,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  palette: {
    type: 'light',
    primary: {
      light: '#FF8F95',
      dark: '#E07075',
      main: '#FF7F85',
      contrastText: '#fff'
    },
    secondary: {
      light: '#a0a9b2',
      dark: '#496D91',
      main: '#34495E',
      mainDarker: '#34495E',
      mainLighter: '#5A85B0',
      contrastText: '#fff'
    },
    error: {
      light: '#E53935',
      dark: '#E53935',
      main: '#E53935'
    },
    success: {
      light: '#40B458',
      dark: '#40B458',
      main: '#40B458'
    },
    company: {
      light: '#4A90E2',
      main: '#4A90E2',
      dark: '#4A90E2'
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.60)',
      light: '#99A4AE'
    },
    background: {
      main: '#ecf0f1'
    },
    borderColor: {
      main: '#D8D8D8'
    }
  },
  global: {
    maxWidthContainer: {
      width: '100%',
      paddingLeft: 24,
      paddingRight: 24,
      '@media (min-width: 1308px)': {
        width: '100%',
        maxWidth: 1260,
        margin: '0px auto',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    wizardWidth: {
      '@media (min-width: 1308px)': {
        maxWidth: 1043
      }
    },
    maxWidthNarrowContainer: {
      '@media (min-width: 1308px)': {
        maxWidth: 800
      }
    },
    unselectableText: {
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      ' -khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none'
    },
    clickable: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    verticalDivider: {
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid'
    },
    borderColor: '#CDD9DC',
    inputLabelOverflow: {
      // Prevents overflow on the long labels
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    textEllipsis: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  overrides: {
    MuiButton: {
      sizeSmall: {
        fontWeight: 400
      }
    },
    MuiStepLabel: {
      label: {
        fontSize: '0.75rem',
        lineHeight: '0.87rem',
        '&$active': {
          fontWeight: 'normal'
        },
        '&$completed': {
          fontWeight: 'normal'
        }
      }
    },
    MuiMenuItem: {
      root: {
        color: '#34495E',
        fontSize: 14
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiDialog: {
      paper: {
        margin: 24
      }
    }
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
}

//
// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger
