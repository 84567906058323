import { action, observable } from 'mobx'
import venueApi from '../services/venueApi'

import globalStore from './global'

class SpacePreviewStore {
  @observable.deep
  space = null
  @observable
  isLoadingSpacePreview = false

  constructor() {
    this.globalStore = globalStore
  }

  @action
  getSpacePreview = async (venueId, stateId) => {
    this.isLoadingSpacePreview = true
    try {
      const { data: space } = await venueApi.getSpacePreview(venueId, stateId)
      this.space = space
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error fetching the space preview.'
      )
    } finally {
      this.isLoadingSpacePreview = false
    }
    return true
  }
}

export default new SpacePreviewStore()
