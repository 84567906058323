import BaseApi from './baseApi'
import qs from 'qs'

class ProductApi extends BaseApi {
  constructor() {
    super({ routeName: 'products' })
  }

  getCarousels = (lat, lng, carouselLimit = 3) => {
    if (!lat || !lng) return Error('Must provide both lat and lng')
    return this.request().get('/carousels', {
      params: { lat, lng, carouselLimit },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getByBounds = (
    bounds,
    productTypeGroupId,
    productTypeId,
    productCategoryId,
    termId,
    page,
    limit,
    price
  ) => {
    if (!bounds) return Error('Must provide bounds')
    const NE = bounds.getNorthEast()
    const SW = bounds.getSouthWest()
    const body = {
      NE: {
        lat: NE.lat(),
        lng: NE.lng()
      },
      SW: {
        lat: SW.lat(),
        lng: SW.lng()
      },
      page,
      limit
    }
    if (productTypeGroupId) body.productTypeGroupId = productTypeGroupId
    if (productTypeId) body.productTypeId = productTypeId
    if (productCategoryId && productCategoryId !== 'All Services')
      body.productCategoryId = productCategoryId
    if (termId && termId !== 'All Terms') body.termId = termId
    if (price) body.price = price
    return this.request().post('/bounds', body)
  }

  getAllProductTypes = () => {
    return this.request().get('/productTypes')
  }

  getAllProductTypeGroups = () => {
    return this.request().get('/productTypeGroups')
  }

  getFeaturedProduct = () => {
    const url = `/featured`
    return this.request().get(url)
  }
}

export default new ProductApi()
