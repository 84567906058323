import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class ReferralApi extends BaseApi {
  constructor() {
    super({ routeName: 'referrals' })
  }

  fetch = (filters = {}) => {
    return this.request(userStore.jwtToken).get('', {
      params: {
        ...filters
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchOne = referralId => {
    const url = `/${referralId}`
    return this.request(userStore.jwtToken).get(url)
  }

  create = (params = {}) => {
    return this.request(userStore.jwtToken).post('', params)
  }

  update = (referralId, params = {}) => {
    const url = `/${referralId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  destroy = referralId => {
    const url = `/${referralId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  resend = referralId => {
    const url = `/${referralId}/resend`
    return this.request(userStore.jwtToken).post(url)
  }
}

export default new ReferralApi()
