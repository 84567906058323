import BaseApi from './baseApi'
import userStore from '../stores/user'

class RulesApi extends BaseApi {
  getAll = () => {
    return this.request(userStore.jwtToken).get('rules')
  }
}

export default new RulesApi()
