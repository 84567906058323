import { observable, action, computed, runInAction } from 'mobx'
import authApi from '../services/authApi'
import userApi from '../services/userApi'
import companyApi from '../services/companyApi'
import venueApi from '../services/venueApi'
import adminVenueApi from '../services/admin/venueApi'
import notificationStore from './notificationStore'
import globalStore from './global'
import favoritesStore from './favorites'
import { parseErrors } from '../utilities/formUtils'
import GlobalStore from './global'
import { Ability } from '@casl/ability'
import { unpackRules } from '@casl/ability/extra'
import WebSocketStore from './webSocketStore'
import { CHANNELS, EVENTS } from '../constants/WEBSOCKETS'
import { generateStripeOAuthLink } from 'utilities/stripeUtils'
import { trackEvent } from '@utilities/trackingUtils'

const LOCAL_STORE_USER = 'spacemx:user'
const LOCAL_STORE_COMPANY_ABILITY = 'spacemx:companyAbility'
const LOCAL_STORE_VENUE_ABILITY = 'spacemx:venueAbility'

const defaultUserProps = {
  fullName: '',
  job_title: '',
  company_id: 0,
  city: '',
  about: '',
  medias: [],
  stripe_customer_id: ''
}

class UserStore {
  @observable
  loggedIn = false
  @observable
  jwtToken = null
  @observable.deep
  user = {
    ...defaultUserProps
  }

  @observable
  currentCompanyAbility = new Ability([])
  @observable
  currentVenueAbility = new Ability([])

  @observable
  isLoading = false
  @observable
  userSidebarCounts = {}
  @observable
  isLoadingUserSidebarCounts = false

  @observable
  isEmailVerificationLoading = false
  @observable
  isEmailVerificationError = false
  @observable
  emailVerificationResponse = null

  @observable
  isDeactivationLoading = false
  @observable
  isDeactivationError = false

  @observable
  isChangePasswordLoading = false
  @observable
  isChangePasswordError = false

  @observable
  isSavingUser = false
  @observable
  isSavingUserError = null

  @observable
  isUserLoggingIn = false

  @observable
  isResettingPassword = false

  @observable
  isUserRegistering = false

  @observable
  updateEmailErrors = {}

  @observable
  updatePasswordErrors = {}

  @observable
  authDialogs = {
    login: {
      isOpen: false
    },
    register: {
      isOpen: false,
      redirectTo: null
    },
    forgot_password: {
      isOpen: false
    }
  }

  @observable
  registrationForm = this._defaultRegistrationForm

  _defaultRegistrationForm = {
    first_name: {
      value: '',
      error: null
    },
    last_name: {
      value: '',
      error: null
    },
    email: {
      value: '',
      error: null
    },
    password: {
      value: '',
      error: null
    }
  }

  @observable
  wsCurrentUserSubscription = null

  @observable
  paymentAccountResponse = ''
  @observable
  finalizePaymentAccountError = ''
  @observable
  isLoadingFinalizePaymentAccount = true

  @observable
  isSavingCard = false
  @observable
  isSavingDefaultCard = false
  @observable
  isRemovingCard = false

  @observable
  isLoadingCurrentCompany = false
  @observable
  isLoadingCurrentVenue = false

  @observable
  idleTimeout = null

  constructor() {
    this._webSocketStore = WebSocketStore
    this.globalStore = globalStore

    if (!this.loggedIn) {
      if (this._getLocalStoreUser()) {
        const tokenUser = this._getLocalStoreUser()
        this.jwtToken = tokenUser.token
        this.user = { ...this.user, ...tokenUser.user }
        this.currentCompanyAbility.update(this._getLocalStoreCompanyAbility())
        this.currentVenueAbility.update(this._getLocalStoreVenueAbility())
        this.loggedIn = true
      }
    }
  }

  @action
  reloadCurrentCompany = async () => {
    try {
      this.isLoadingCurrentCompany = true
      const { data: company } = await companyApi.fetchOne(
        this.user.currentCompany.id
      )
      await this.setCurrentCompany(company)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingCurrentCompany = false
    }
  }

  @action
  setCurrentCompany = async company => {
    const currentCompany = this.user.currentCompany
    const currentCompanyId = currentCompany ? currentCompany.id : null
    const newCompanyId = company ? company.id : null
    const companyContextChanged = currentCompanyId !== newCompanyId

    this.user = {
      ...this.user,
      currentCompany: company
    }
    this._setLocalStoreUser({ token: this.jwtToken, user: this.user })
    if (company) {
      await this.fetchAndUpdateCompanyAbility(company.id)
    } else {
      this.currentCompanyAbility = new Ability([])
    }

    if (this.wsCurrentUserSubscription && companyContextChanged) {
      this.wsCurrentUserSubscription.emit(EVENTS.USER_CONTEXT_SWITCHED, {
        type: 'company',
        id: newCompanyId
      })
    }

    return this.currentCompanyAbility
  }

  @action
  fetchAndUpdateCompanyAbility = async companyId => {
    try {
      this.isLoading = true
      const { data: ability } = await companyApi.getAbility(companyId)
      this._setLocalStoreCompanyAbility({ token: this.jwtToken, ability })
      this.currentCompanyAbility.update(this._getLocalStoreCompanyAbility())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  @computed
  get isCurrentCompanySet() {
    return (
      Boolean(this.user.currentCompany) && Boolean(this.user.currentCompany.id)
    )
  }

  @action
  reloadCurrentVenue = async () => {
    try {
      this.isLoadingCurrentVenue = true
      const { data: venue } = await adminVenueApi.fetchOne(
        this.user.currentVenue.id
      )
      await this.setCurrentVenue(venue)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingCurrentVenue = false
    }
  }

  @action
  setCurrentVenue = async (venue, fetchLatestUser) => {
    const currentVenue = this.user.currentVenue
    const currentVenueId = currentVenue ? currentVenue.id : null
    const newVenueId = venue ? venue.id : null
    const venueContextChanged = currentVenueId !== newVenueId

    if (fetchLatestUser) {
      await this.getMe()
    }
    this.updateUserCurrentVenue(venue)
    if (venue) {
      await this.fetchAndUpdateVenueAbility(venue.id)
    } else {
      this.currentVenueAbility = new Ability([])
    }

    if (this.wsCurrentUserSubscription && venueContextChanged) {
      this.wsCurrentUserSubscription.emit(EVENTS.USER_CONTEXT_SWITCHED, {
        type: 'venue',
        id: newVenueId
      })
    }

    return this.currentVenueAbility
  }

  @action
  updateUserCurrentVenue = venue => {
    this.user = {
      ...this.user,
      currentVenue: venue
        ? Object.assign(
            this.user.currentVenue ? this.user.currentVenue : {},
            venue
          )
        : null
    }
    this._setLocalStoreUser({ token: this.jwtToken, user: this.user })
  }

  @action
  setCurrentVenueByVenueId = async (venueId, fetchLatestUser = false) => {
    if (fetchLatestUser) {
      await this.getMe()
    }
    this._getLocalStoreUser()
    const companies = this.user.companies
      ? this.user.companies
      : [this.user.currentCompany]
    const company = companies.find(company =>
      company.venues.find(venue => venue.id === venueId)
    )
    if (!company) {
      console.error(`No company found with venue ${venueId}.`)
      return null
    }
    await this.setCurrentCompany(company)
    if (!company.venues || !company.venues.length) {
      console.error(`No venue ${venueId} found in company ${company.id}.`)
      return null
    }
    const venue = company.venues.find(venue => venue.id === venueId)
    if (!venue) {
      console.error(`No venue ${venueId} found in company ${company.id}.`)
      return null
    }
    await this.setCurrentVenue(venue)
    return venue
  }

  @action
  setCurrentVenueByVenueIdAndRedirect = async ({
    venueId,
    handleNavigate,
    fetchLatestUser = false
  }) => {
    try {
      if (fetchLatestUser) {
        await this.getMe()
      }
      const company = this.user.companies.find(company =>
        company.venues.find(venue => venue.id === venueId)
      )
      if (!company) throw `No company found with venueId: ${venueId}`
      await this.setCurrentCompany(company)
      if (!company.venues || !company.venues.length) return null
      const venue = company.venues.find(venue => venue.id === venueId)
      if (!venue) throw `No Venue found in company: ${company.id}`
      await this.setCurrentVenue(venue)
      handleNavigate()
      return venue
    } catch (error) {
      console.error(error)
      return null
    }
  }

  setCurrentVenueAndRedirect = async ({ venue, handleNavigate }) => {
    await this.setCurrentVenue(venue)
    handleNavigate()
  }

  @action
  fetchAndUpdateVenueAbility = async venueId => {
    try {
      this.isLoading = true
      const { data: ability } = await venueApi.getAbility(venueId)
      this._setLocalStoreVenueAbility({ token: this.jwtToken, ability })
      this.currentVenueAbility.update(this._getLocalStoreVenueAbility())
    } catch (error) {
      console.error('FETCH VENUE ABILITY ERROR', error)
      this.currentVenueAbility = new Ability([])
    } finally {
      this.isLoading = false
    }
  }

  @computed
  get isCurrentVenueSet() {
    return Boolean(this.user.currentVenue)
  }

  @computed
  get currentVenueSpacesCount() {
    const { currentVenue } = this.user
    if (!currentVenue) {
      return 0
    }
    if (currentVenue.spaces_count) {
      return currentVenue.spaces_count
    }
    if (currentVenue.__meta__ && currentVenue.__meta__.spaces_count) {
      return currentVenue.__meta__.spaces_count || 0
    }
    if (currentVenue.allSpaces) {
      return currentVenue.allSpaces.length
    }
    if (currentVenue.spaces) {
      return currentVenue.spaces.length
    }
    return 0
  }

  @action
  setUserProp = (key, value) => {
    this.user[key] = value
  }

  @action
  getMe = async include => {
    if (this.loggedIn === false) {
      return
    }
    this.isLoading = true
    try {
      const {
        data,
        meta: { sidebarCounts, lastTransaction, lastCreditCardTransaction }
      } = await userApi.getMe(include)
      this.user = {
        ...this.user,
        ...data,
        lastTransaction,
        lastCreditCardTransaction
      }
      this.userSidebarCounts = sidebarCounts
      notificationStore.wsSubscribe(this.user.id)
      this.wsCurrentUserSubscribe(this.user.id)
      this._setLocalStoreUser({ token: this.jwtToken, user: this.user })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  @action
  saveUser = async () => {
    this.isSavingUser = true
    try {
      const {
        data,
        meta: { sidebarCounts }
      } = await userApi.update(this.user.id, this.user)

      const combinedUser = { ...this.user, ...data.data }
      this._setLoggedInUser(this.jwtToken, combinedUser)
      this.userSidebarCounts = sidebarCounts
      this.isSavingUserError = null
    } catch (error) {
      this.isSavingUserError = error
    } finally {
      this.isSavingUser = false
    }
  }

  @action
  toggleLocationFavorite = async payload => {
    const { objectId, objectType, currentFavoriteStatus } = payload
    if (
      objectId === undefined ||
      objectType === undefined ||
      currentFavoriteStatus === undefined
    ) {
      throw Error('Missing objectId, objectType, and state are required.')
    }

    if (!this.loggedIn) {
      this.openAuthDialog('login')
    }

    this.isLoading = true
    try {
      const selectedFavorite = this.user.favorites.find(
        favorite =>
          favorite.object_id === objectId && favorite.object_type === objectType
      )

      const { data } = await userApi.updateFavorite(
        objectId,
        objectType,
        this.user.id,
        !currentFavoriteStatus
      )

      if (!selectedFavorite) {
        this.user.favorites.push(data.favorite)
        this.isLoading = false
        return
      } else {
        trackEvent({
          title: 'favorite',
          data: 'marked favorite'
        })
      }

      const updatedFavorites = this.user.favorites.map(favorite => {
        if (
          favorite.object_id === objectId &&
          favorite.object_type === objectType
        ) {
          return data.favorite
        }
        return favorite
      })
      this.user = {
        ...this.user,
        favorites: updatedFavorites
      }

      favoritesStore.favorites = favoritesStore.favorites.filter(
        favorite => favorite.id !== selectedFavorite.id
      )

      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      return
    }
  }

  isLocationFavorited = (itemId, itemType) =>
    computed(() => {
      const favorites =
        this.user && this.user.favorites
          ? this.user.favorites.filter(favorite => {
              const { object_id, object_type, is_active } = favorite
              return (
                object_id === itemId && object_type === itemType && is_active
              )
            })
          : []
      return favorites.length > 0
    }).get()

  @action
  login = async (email, password) => {
    this.isUserLoggingIn = true
    try {
      const loginResponse = await authApi.login(email, password)
      runInAction(() => {
        const { token, user } = loginResponse.data
        this._setLoggedInUser(token, user)
      })
      return true
    } catch (errorResponse) {
      if ([400, 401].includes(errorResponse.status)) {
        const fields = errorResponse.data
        const errors = {}
        for (const fieldData of fields) {
          const { field, message, validation } = fieldData
          if (validation === 'verified') {
            window.location.href = '/email-verification-required'
          }
          errors[field] = message
        }

        return { errors }
      }
    } finally {
      await this.getMe()
      this.isUserLoggingIn = false
    }
    return true
  }

  @action
  logout = (redirectToLoggedOut = false) => {
    notificationStore.wsUnsubscribe()
    this.wsCurrentUserUnsubscribe()
    this._removeLocalStoreUser()
    if (redirectToLoggedOut) {
      window.location.href = '/logged-out'
    }
  }

  @action
  register = async (email, password, first_name, last_name, redirectTo) => {
    this.isUserRegistering = true
    try {
      await authApi.register(email, password, first_name, last_name, redirectTo)
      window.location.href = '/email-verification-required'
    } catch (errorResponse) {
      if ([400, 401].includes(errorResponse.status)) {
        const fields = errorResponse.data
        const errors = {}
        for (const fieldData of fields) {
          const { field, message } = fieldData
          errors[field] = message
        }
        return { errors }
      }
    } finally {
      this.isUserRegistering = false
    }
    return true
  }

  @action
  referralRegistrationSubmit = async ({
    email,
    first_name,
    last_name,
    password,
    referral_code,
    referral_site
  }) => {
    try {
      this.isUserRegistering = true
      await authApi.referralRegister({
        email,
        first_name,
        last_name,
        password,
        referral_code,
        referral_site
      })
      window.location.href = '/email-verification-required'
    } catch (error) {
      throw error
    } finally {
      this.isUserRegistering = false
    }
  }

  @action
  requestPasswordReset = async email => {
    this.isResettingPassword = true
    try {
      await authApi.requestPasswordReset(email)
    } catch (errorResponse) {
      if ([400, 401].includes(errorResponse.status)) {
        const fields = errorResponse.data
        const errors = {}
        for (const fieldData of fields) {
          const { field, message } = fieldData
          errors[field] = message
        }
        return { errors }
      }
    } finally {
      this.globalStore.openConfirmationDialog({
        message: 'Password reset email sent.',
        description:
          'If an active account exists an email has been sent with a link provided to reset your password.',
        cancelText: 'Close',
        cancelOnly: true
      })
      this.isResettingPassword = false
    }
    return true
  }

  @action
  resetPassword = async (email, resetToken, password, passwordConfirm) => {
    await authApi.resetPassword(email, resetToken, password, passwordConfirm)
  }

  @computed
  get userFullName() {
    const fullName =
      this.user.fullname || `${this.user.first_name} ${this.user.last_name}`
    return fullName
  }

  getUserFullNameFromSplitNames({ first_name, last_name }) {
    return `${first_name} ${last_name}`
  }

  splitUserFullName(fullName) {
    const splitFullName = fullName.split(' ')
    const first_name = splitFullName[0]
    const last_name = splitFullName[splitFullName.length - 1]
    return { first_name, last_name }
  }

  @action
  changePassword = async (password, newPassword, newPasswordConfirm) => {
    try {
      this.isChangePasswordLoading = true
      const {
        data: { token, user }
      } = await authApi.changePassword(
        this.user.id,
        password,
        newPassword,
        newPasswordConfirm
      )
      this.jwtToken = token
      this.user = {
        ...this.user,
        email: user.email
      }
      this._setLocalStoreUser({ token, user })
      this.updatePasswordErrors = {}
      GlobalStore.openNotificationSnackbar(
        'Password has been updated successfully!'
      )
      this.isChangePasswordLoading = false
      return true
    } catch (error) {
      const { data = [] } = error || {}
      this.updatePasswordErrors = parseErrors(data)
      this.isChangePasswordLoading = false
      return false
    }
  }

  @action
  changeEmail = async (newEmail, newEmailConfirmation, password) => {
    try {
      this.globalStore.openConfirmationDialog({
        message: 'Are you sure you want to change your email address?',
        description: 'You will be logged out to complete the process.',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        callback: async confirmed => {
          if (confirmed) {
            const {
              data: { token, user }
            } = await authApi.changeEmail(
              this.user.id,
              newEmail,
              newEmailConfirmation,
              password
            )

            this.jwtToken = token
            this.user = {
              ...this.user,
              email: user.email
            }
            this._setLocalStoreUser({ token, user })
            this.updateEmailErrors = {}
            GlobalStore.openNotificationSnackbar(
              'Email has been updated successfully.  Please log back in.'
            )
            this.logout()
          }
        }
      })
    } catch (error) {
      const { data = [] } = error || {}
      this.updateEmailErrors = parseErrors(data)
    }
  }

  @action
  deactivateAccount = async () => {
    runInAction(async () => {
      try {
        this.isDeactivationLoading = true
        await authApi.deactivateAccount(this.user.id)
        await this.logout()
        this.isDeactivationLoading = false
      } catch (error) {
        this.isDeactivationError = error
        this.isDeactivationLoading = false
      }
    })
  }

  @action
  verifyEmail = async (token, email) => {
    runInAction(async () => {
      this.isEmailVerificationLoading = true
      try {
        const response = await authApi.verifyEmail({ token, email })
        this.isEmailVerificationLoading = false
        const { message = null } = response.data
        this.emailVerificationResponse = message
        trackEvent({
          title: 'register',
          data: 'user registered',
          overrides: { fb: ['sign up'] }
        })
        return response
      } catch (errorResponse) {
        if ([400, 401].includes(errorResponse.status)) {
          const { message = null } = errorResponse.data
          this.emailVerificationResponse = message
        }
        this.isEmailVerificationLoading = false
      }
      return true
    })
  }

  @action
  clearEmailVerificationResponse = () => {
    this.emailVerificationResponse = null
    this.isEmailVerificationError = false
    this.isEmailVerificationLoading = false
  }

  /**
   * @param dialog 'login' | 'register' | 'forgot_password'
   */
  @action
  openAuthDialog = (dialog, redirectTo = null, formValues = null) => {
    if ((dialog === 'login' || dialog === 'register') && this.loggedIn) {
      return
    }
    const keys = Object.keys(this.authDialogs)
    for (const key of keys) {
      if (key === dialog) {
        if (formValues && key === 'register') {
          this._setRegistrationForm(formValues)
        }
        this.authDialogs[key].isOpen = true
        this.authDialogs[key].redirectTo = redirectTo
      } else {
        this.authDialogs[key].isOpen = false
      }
    }
  }

  @action
  closeAuthDialog = dialog => {
    this.authDialogs[dialog].isOpen = false
    this.openAuthDialog()
  }

  @action
  submitRegistrationForm = async ({ redirectTo = null }) => {
    const { first_name, last_name, email, password } = this._prepareForm(
      this.registrationForm
    )
    if (redirectTo === null) {
      const pathName = window.location.pathname
      redirectTo =
        pathName !== '/' && !pathName.includes('undefined') ? pathName : null
    }
    try {
      const register = await this.register(
        email,
        password,
        first_name,
        last_name,
        redirectTo
      )
      if (register && register.errors) {
        this.registrationForm = {
          first_name: {
            ...this.registrationForm.first_name,
            error: register.errors['first_name']
          },
          last_name: {
            ...this.registrationForm.last_name,
            error: register.errors['last_name']
          },
          email: {
            ...this.registrationForm.email,
            error: register.errors['email']
          },
          password: {
            ...this.registrationForm.password,
            error: register.errors['password']
          }
        }
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  handleRegistrationFormChange = ({ name, valueObject }) => {
    this.registrationForm = {
      ...this.registrationForm,
      [name]: valueObject
    }
  }

  @action
  resetRegistrationForm = () => {
    this.registrationForm = this._defaultRegistrationForm
    this.closeAuthDialog('register')
    window.history.replaceState(null, null, window.location.pathname)
  }

  isRegistrationFormValid = () => {
    const { first_name, last_name, email, password } = this.registrationForm
    if (
      !first_name.value ||
      !last_name.value ||
      !email.value ||
      !password.value
    ) {
      this.registrationForm = {
        first_name: {
          ...first_name,
          error: !first_name.value ? 'Must enter a first name' : null
        },
        last_name: {
          ...last_name,
          error: !last_name.value ? 'Must enter a last name' : null
        },
        email: {
          ...email,
          error: !email.value ? 'Must enter an email' : null
        },
        password: {
          ...password,
          error: !password.value ? 'Must enter a password' : null
        }
      }
      return false
    }
    return true
  }

  _setRegistrationForm(formValues) {
    this.registrationForm = Object.assign(this.registrationForm, {
      email: {
        value: formValues.email,
        error: null
      },
      first_name: {
        value: formValues.first_name,
        error: null
      },
      last_name: {
        value: formValues.last_name,
        error: null
      }
    })
  }

  /*
  COMPUTED
  */
  @computed
  get userModalLoginClosed() {
    return !this.isLoginDialogVisible && this.loggedIn
  }

  @action
  _setLoggedInUser = (token, user) => {
    this.loggedIn = true
    this.jwtToken = token
    this.user = user
    this._setLocalStoreUser({ token, user })
    notificationStore.wsSubscribe(this.user.id)
  }

  @action
  fetchUserDashboardCounts = async () => {
    this.isLoadingUserSidebarCounts = true
    try {
      const { data } = await userApi.fetchUserDashboardCounts()
      if (!data) throw Error()
      this.userSidebarCounts = data
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingUserSidebarCounts = false
    }
  }

  connectUserStripeURL = () => {
    const currentUser = this.user
    const stripe_user_params = {
      email: currentUser.email,
      country: 'US',
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      business_type: 'individual'
    }
    return generateStripeOAuthLink({
      stripe_user_params,
      state: 'is_user_account'
    })
  }

  @action
  finalizePaymentAccount = async ({ accountCode, venueId = null }) => {
    try {
      this.isLoadingFinalizePaymentAccount = true
      const isVenuePaymentAccount = !!venueId
      if (!accountCode) {
        throw new Error('Cannot finalize your account.')
      }
      const { data, error } = isVenuePaymentAccount
        ? await venueApi.finalizePaymentAccount(venueId, accountCode)
        : await userApi.finalizePaymentAccount(accountCode)
      if (error) {
        throw new Error(error)
      }
      if (!isVenuePaymentAccount) {
        this.user = {
          ...this.user,
          payment_account: data.payment_account
        }
      }
      this.paymentAccountResponse = data
    } catch (error) {
      this.finalizePaymentAccountError = error.data
      console.error(error)
    } finally {
      this.isLoadingFinalizePaymentAccount = false
    }
  }

  @action
  wsCurrentUserSubscribe = async personId => {
    this.wsCurrentUserSubscription = this._webSocketStore.subscribe(
      CHANNELS.CURRENT_USER,
      personId
    )
    await this._wsCurrentUserHooks(this.wsCurrentUserSubscription)
  }

  wsCurrentUserUnsubscribe = () => {
    if (this.wsCurrentUserSubscription) {
      this.wsCurrentUserSubscription.close()
      this.wsCurrentUserSubscription = null
    }
  }

  @action
  _wsCurrentUserHooks = async subscription => {
    const _this = this
    subscription.on(
      EVENTS.USER_ABILITIES_CHANGED,
      async ({ companyId, venueId }) => {
        if (
          companyId &&
          _this.isCurrentCompanySet &&
          +companyId === _this.user.currentCompany.id
        ) {
          await _this.fetchAndUpdateCompanyAbility(companyId)
          this.user.currentCompany = { ...this.user.currentCompany }
        }
        if (
          venueId &&
          _this.isCurrentVenueSet &&
          +venueId === _this.user.currentVenue.id
        ) {
          await _this.fetchAndUpdateVenueAbility(venueId)
          this.user.currentVenue = { ...this.user.currentVenue }
        }
      }
    )
    subscription.on(EVENTS.USER_IDLE_CHECK, () => {
      if (!window.idleTimeout) {
        subscription.emit(EVENTS.USER_NOT_IDLE, _this.user.id)
      }
    })
    subscription.on(EVENTS.USER_LOGOUT_ALL, () => {
      // TODO this NOT working and not logging them out
      window.idleTimeout = null
      window.isUserIdle = false
      this.logout(true)
    })
    subscription.on(EVENTS.USER_NOT_IDLE, () => {
      this.clearIdleTimeout()
    })
    subscription.on(
      EVENTS.USER_CONTEXT_SWITCHED,
      ({ type = null, id = null }) => {
        window.location.href =
          id === null ? `/admin/dashboard` : `/admin/${type}/dashboard`
      }
    )
    subscription.on('close', () => {
      this.wsCurrentUserSubscription = null
      // eslint-disable-next-line no-console
      console.info('spacemx ws Current User closed')
    })
  }

  wsCheckOtherUserSubsAreIdle = () => {
    if (!this.wsCurrentUserSubscription) {
      return
    }
    this.wsCurrentUserSubscription.emit(EVENTS.USER_IDLE_CHECK, this.user.id)
  }

  // @action
  setIdleTimeout = () => {
    if (!this.wsCurrentUserSubscription) {
      setTimeout(() => this.logout(true), 10000)
      return
    }
    this.wsCheckOtherUserSubsAreIdle()
    window.idleTimeout = setTimeout(() => {
      this.wsCurrentUserSubscription.emit(EVENTS.USER_LOGOUT_ALL, this.user.id)
    }, 10000)
  }

  // @action
  clearIdleTimeout = () => {
    clearTimeout(window.idleTimeout)
    window.idleTimeout = null
  }

  _setLocalStoreUser(tokenUser = undefined) {
    localStorage.setItem(LOCAL_STORE_USER, JSON.stringify(tokenUser))
  }

  _setLocalStoreCompanyAbility(ability = undefined) {
    localStorage.setItem(LOCAL_STORE_COMPANY_ABILITY, JSON.stringify(ability))
  }

  _setLocalStoreVenueAbility(ability = undefined) {
    localStorage.setItem(LOCAL_STORE_VENUE_ABILITY, JSON.stringify(ability))
  }

  _getLocalStoreUser() {
    const localStoreUser = localStorage.getItem(LOCAL_STORE_USER)
    if (localStoreUser) {
      try {
        return JSON.parse(localStoreUser)
      } catch (error) {
        return null
      }
    }
    return null
  }

  _getLocalStoreCompanyAbility() {
    const localStoreCompanyAbility = localStorage.getItem(
      LOCAL_STORE_COMPANY_ABILITY
    )
    if (localStoreCompanyAbility) {
      try {
        return unpackRules(JSON.parse(localStoreCompanyAbility).ability)
      } catch (error) {
        return null
      }
    }
    return null
  }

  _getLocalStoreVenueAbility() {
    const localStoreVenueAbility = localStorage.getItem(
      LOCAL_STORE_VENUE_ABILITY
    )
    if (localStoreVenueAbility) {
      try {
        return unpackRules(JSON.parse(localStoreVenueAbility).ability)
      } catch (error) {
        return null
      }
    }
    return null
  }

  _removeLocalStoreUser() {
    localStorage.removeItem(LOCAL_STORE_USER)
    localStorage.removeItem(LOCAL_STORE_COMPANY_ABILITY)
    localStorage.removeItem(LOCAL_STORE_VENUE_ABILITY)
    this.user = {
      ...defaultUserProps
    }
    this.jwtToken = null
    this.loggedIn = false
  }

  _prepareForm(formValues) {
    return Object.keys(formValues).reduce((form, currentFormValue) => {
      const value = this.registrationForm[currentFormValue].value
      if (!value) return form
      form[currentFormValue] = value
      return form
    }, {})
  }

  @action
  fetchUsersCards = async () => {
    this.isLoading = true
    try {
      const { data: userCards } = await userApi.fetchMyCards()
      this.user = { ...this.user, cards: userCards }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  @action
  saveCard = async ({ token }) => {
    this.isSavingCard = true
    try {
      const { data: card } = await userApi.saveCard({ token })
      return card
    } catch (error) {
      console.error(error)
    } finally {
      this.isSavingCard = false
    }
    return false
  }

  @action
  saveDefaultCard = async ({ defaultCardId }) => {
    this.isSavingDefaultCard = true
    try {
      const { data: card } = await userApi.saveDefaultCard({ defaultCardId })
      return card
    } catch (error) {
      console.error(error)
    } finally {
      this.isSavingDefaultCard = false
    }
    return false
  }

  @action
  removeCard = async card => {
    this.isRemovingCard = true
    const _card = card
    try {
      this.globalStore.openConfirmationDialog({
        message: 'Are you sure you want to remove this card?',
        description: `You will deactivate the ${card.brand} card ending in ${
          card.last4
        } from SpaceMX and delete it from Stripe. This action is unreversable.`,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        callback: async confirmed => {
          if (confirmed) {
            await userApi.removeCard(_card.id)
            this.globalStore.openNotificationSnackbar(
              'Your card has been removed.'
            )
            await this.fetchUsersCards()
          }
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.isRemovingCard = false
    }
    return false
  }
}

const user = new UserStore()
export default user
