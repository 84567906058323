import axios from 'axios'
import { getApiUrl } from '../utilities/apiUtils'

const axiosOptions = Object.freeze({
  baseURL: getApiUrl(),
  validateStatus: function() {
    return true
  },
  headers: { Accept: 'application/json' }
})

export default class BaseApi extends Object {
  axiosOptions = { ...axiosOptions }

  constructor(props = {}) {
    super(props)
    if (props.routeName) {
      this.axiosOptions.baseURL = axiosOptions.baseURL + '/' + props.routeName
    }
    this.session = axios.create(this.axiosOptions)
    this.session.interceptors.response.use(this._responseInterceptor)
  }

  request = token => {
    if (token) {
      this._setAuthHeader(token)
    }
    return this.session
  }

  _responseInterceptor = response => {
    if (response.status.toString().match(/4\d{2}|5\d{2}/)) {
      if ([401].includes(response.status)) {
        const userStore = require('../stores/user')
        userStore.default.logout()
      }
      if ([403].includes(response.status)) {
        window.location.href = '/unauthorized'
      }
      if ([404].includes(response.status)) {
        const globalStore = require('../stores/global')
        globalStore.default.error404 = true
      }
      if ([500].includes(response.status)) {
        if (
          response.data &&
          !response.data.error &&
          response.data.includes('User is not a member of this')
        ) {
          const userStore = require('../stores/user')
          const globalStore = require('../stores/global')
          userStore.default.logout()
          globalStore.default.openNotificationSnackbar(
            'You do not have access to that Company and have been logged out.'
          )
        }
      }
      return Promise.reject(response)
    }
    return response
  }

  _setAuthHeader = token => {
    if (token.length) {
      this.session.defaults.headers = {
        ...this.session.defaults.headers,
        Authorization: `Bearer ${token}`
      }
    }
  }
}
