import React, { Component } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import moment from 'moment'

import { inject, observer } from 'mobx-react'
import HourlyDateItem from '../Global/Wizards/booking/pickers/HourlyDateItem'
import ProgressButton from '../Global/ProgressButton'
import { filteredCalendarDays } from '../../utilities/calendarUtils'

const styles = theme => ({
  sectionTitle: {
    color: theme.palette.secondary.mainDarker,
    opacity: 0.5
  }
})

@inject('globalStore', 'tourStore')
@observer
class ScheduleTourDialog extends Component {
  static propTypes = {}

  defaultSelectedDate = {
    date: this.initialDate(),
    startTime: '',
    endTime: ''
  }

  state = {
    focusedInput: false,
    selectedDate: { ...this.defaultSelectedDate },
    monthsFetched: [],
    calendarBookingDays: []
  }

  initialDate() {
    const now = moment()
    // if later than 8pm set tomorrow.
    // TODO should be latest time that venue/space has set
    if (now.hour() > 20) {
      return now.add(1, 'day')
    }
    return now
  }

  getInitialCalendarDays = async () => {
    const {
      globalStore: {
        currentTourDialog: { objectId, objectType }
      },
      tourStore: { getBlockedTourCalendarDatesByPlaceId }
    } = this.props
    const today = moment()
    const todayMonth = today.month() + 1
    const newCalendarBookingDays = await getBlockedTourCalendarDatesByPlaceId(
      objectId,
      objectType,
      todayMonth,
      today.year(),
      3,
      true
    )

    if (!newCalendarBookingDays.data.length) {
      return
    }
    this.setState({
      calendarBookingDays: filteredCalendarDays(
        newCalendarBookingDays.data,
        []
      ),
      monthsFetched: [
        { month: todayMonth - 1, year: today.year() },
        { month: todayMonth, year: today.year() }
      ]
    })
  }

  onMonthChange = async nextMonthObject => {
    const { monthsFetched } = this.state
    const nextMonthNumber = nextMonthObject.month() + 1
    const nextYearNumber = nextMonthObject.year()

    if (
      monthsFetched.some(
        month =>
          month.month === nextMonthNumber && month.year === nextYearNumber
      )
    ) {
      return
    }
    await this._setExistingBookingDays(nextMonthNumber, nextMonthObject.year())
  }

  onDateChange = async newDate => {
    const { selectedDate } = this.state
    this.setState({
      selectedDate: { ...selectedDate, date: newDate }
    })
  }

  _setExistingBookingDays = async (monthNumber, yearNumber) => {
    const {
      globalStore: {
        currentTourDialog: { objectId, objectType }
      },
      tourStore: { getBlockedTourCalendarDatesByPlaceId }
    } = this.props

    const { monthsFetched, calendarBookingDays } = this.state
    const newCalendarBookingDaysResponse = await getBlockedTourCalendarDatesByPlaceId(
      objectId,
      objectType,
      monthNumber + 1 === 13 ? 1 : monthNumber + 1,
      monthNumber + 1 === 13 ? yearNumber + 1 : yearNumber,
      1,
      true
    )
    if (!newCalendarBookingDaysResponse.data.length) return
    const newCalendarBookingDays = filteredCalendarDays(
      newCalendarBookingDaysResponse.data,
      calendarBookingDays
    )
    this.setState({
      calendarBookingDays: newCalendarBookingDays,
      monthsFetched: [
        ...monthsFetched,
        { month: monthNumber, year: yearNumber }
      ]
    })
  }

  selectableHours(date) {
    const { calendarBookingDays = [] } = this.state
    const calendarDay = calendarBookingDays.find(calendarDay => {
      return moment(calendarDay.date, 'MM-DD-YYYY').isSame(
        moment(date, 'MM-DD-YYYY'),
        'day'
      )
    })
    if (!calendarDay || !calendarDay.hours) return []
    const hours = calendarDay.hours.map(hour => {
      const hourDisabled = this._isHalfHourDisabled(hour)
      return {
        date: date,
        hour,
        disabled: hourDisabled
      }
    })
    return hours
  }

  onTimeChange = event => {
    const { selectedDate } = this.state
    selectedDate.startTime = event.target.value
    if (selectedDate.startTime) {
      const endTime = moment(selectedDate.startTime, 'HHmm')
        .add('minutes', 30)
        .format('HHmm')
      selectedDate.endTime = endTime
    }
    this.setState({
      selectedDate: { ...selectedDate }
    })
  }

  onCancelClick = () => {
    const {
      globalStore: { closeTourDialog }
    } = this.props
    this.setState(
      { selectedDate: { ...this.defaultSelectedDate } },
      closeTourDialog()
    )
  }

  onSubmit = async () => {
    const {
      globalStore: { saveTour }
    } = this.props
    const { selectedDate } = this.state
    if (selectedDate && selectedDate.startTime) {
      await saveTour(selectedDate)
      this.setState({ selectedDate: { ...this.defaultSelectedDate } })
    }
  }

  _isHalfHourDisabled = hour => {
    const momentDate = moment(hour.timestamp)
    const isCurrentTimeBetweenHalfHour = moment().isBetween(
      momentDate,
      momentDate.clone().add(30, 'minutes')
    )
    const isHalfHourAvailable = !hour.available
    return isCurrentTimeBetweenHalfHour || isHalfHourAvailable
  }

  _isInExistingBooking(hoursFromServer, hourDateObject) {
    if (!hoursFromServer || !hoursFromServer.length) {
      return false
    }
    return hoursFromServer.some(hourFromServer =>
      hourFromServer.hours.some(
        hour =>
          moment(hour.timestamp).isSame(hourDateObject, 'hour') &&
          !hour.available
      )
    )
  }

  render() {
    const {
      open,
      fullScreen,
      classes,
      globalStore: { isSavingTour, currentTourDialog },
      tourStore, // eslint-disable-line
      ...otherProps
    } = this.props
    const { selectedDate, calendarBookingDays } = this.state
    const selectableHours = this.selectableHours(selectedDate.date)
    return (
      <Dialog
        data-test="schedule-tour-dialog"
        open={open}
        fullScreen={fullScreen}
        PaperProps={{ style: { width: 560, padding: 24, overflow: 'visible' } }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onEnter={this.getInitialCalendarDays}
        {...otherProps}
      >
        <DialogTitle style={{ padding: '0 0 24px 0' }} disableTypography>
          <Typography variant="h5" className={classes.dialogHeaderText}>
            {'Schedule a tour'}
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{ margin: 0, padding: '0 0 24px 0', overflow: 'visible' }}
        >
          <FormControl fullWidth className="reservation-date-pickers">
            <HourlyDateItem
              calendarBookingDays={calendarBookingDays}
              day={selectedDate}
              dayIndex={0}
              isScheduleTour={true}
              onDateChange={this.onDateChange}
              onNextMonthClick={this.onMonthChange}
              onPrevMonthClick={this.onMonthChange}
              onTimeChange={this.onTimeChange}
              selectableHours={selectableHours}
              timezone={currentTourDialog.timezone}
            />
          </FormControl>
        </DialogContent>
        <DialogActions style={{ margin: 0, padding: 0 }}>
          <Button onClick={this.onCancelClick} variant="text" color="primary">
            Cancel
          </Button>
          <ProgressButton
            disabled={isSavingTour || !selectedDate.startTime}
            onClick={this.onSubmit}
            variant="contained"
            color="primary"
            showProgress={isSavingTour}
            data-test="schedule-tour-dialog-submit"
          >
            Schedule Tour
          </ProgressButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ScheduleTourDialog)
