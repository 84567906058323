import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class VenueApi extends BaseApi {
  constructor() {
    super({ routeName: 'admin/venues' })
  }

  fetchOne = venueId => {
    const url = `/${venueId}`
    return this.request(userStore.jwtToken).get(url)
  }

  update = (venueId, params = {}) => {
    const url = `/${venueId}`
    return this.request(userStore.jwtToken).put(url, { venue: { ...params } })
  }

  setCalendar = (venueId, params = {}) => {
    const url = `/${venueId}/set_calendar`
    return this.request(userStore.jwtToken).put(url, { venue: { ...params } })
  }

  getAllVenueSpaces = (venueId, params) => {
    if (!venueId) return Error('No venue id provided')
    const url = `/${venueId}/spaces`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueConversations = (
    venueId,
    { archived = 0, pagination: { page, perPage }, filters }
  ) => {
    return this.request(userStore.jwtToken).get(`/${venueId}/conversations`, {
      params: { archived, pagination: { page, per_page: perPage }, filters },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  archiveVenueConversation = (venueId, conversationId, archived) => {
    const url = `/${venueId}/conversations/${conversationId}/archive`
    return this.request(userStore.jwtToken).post(url, { archived })
  }

  deleteVenueConversation = (venueId, conversationId) => {
    const url = `/${venueId}/conversations/${conversationId}/delete`
    return this.request(userStore.jwtToken).delete(url)
  }
}

export default new VenueApi()
