import { action, observable } from 'mobx'
import moment from 'moment-timezone'

import { holidays } from '@utilities/holidayUtils'

import rulesApi from '../services/rulesApi'
import venueApi from '../services/venueApi'
import adminSpaceApi from '../services/admin/spaceApi'

import globalStore from './global'
import mediaStore from './media'

import scheduleDays from './initialStates/scheduleDays'

import {
  _deserializeSchedule,
  _deserializeHolidays
} from '@utilities/listSpaceVenueSerializer'

const initialForm = {
  timezone: '',
  monthsInAdvance: 12,
  scheduleDays: [...scheduleDays],
  holidays,
  minutes: '',
  hours: '',
  days: '',
  months: ''
}

class CreateVenueStore {
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0

  @observable.deep
  venue = null
  @observable
  isSavingVenue = false

  @observable.deep
  space = null
  @observable
  isSavingSpace = false

  @observable.deep
  form = {
    ...initialForm
  }
  @observable
  formError = null

  @observable
  timezones = []

  @observable.deep
  rules = []
  @observable
  isLoadingRules = false

  constructor() {
    this.globalStore = globalStore
    this.mediaStore = mediaStore
  }

  @action.bound
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.form = {
      ...initialForm
    }
  }

  @action
  resetFormError = () => {
    this.formError = null
  }

  @action
  setForm = form => {
    this.form = form
  }

  @action
  setFormProp = (key, value) => {
    this.form[key] = value
  }

  @action
  loadTimezones = async () => {
    const timezones = await moment.tz
      .names()
      .filter(name => name.includes('America'))
    this.timezones = timezones
  }

  @action
  loadRules = async () => {
    this.isLoadingRules = true
    try {
      if (!this.rules.length) {
        const { data: rules } = await rulesApi.getAll()
        this.rules = rules
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingRules = false
    }
  }

  @action
  updateVenue = async venueId => {
    this.isSavingVenue = true
    let result = null
    try {
      result = await venueApi.setup({ venueId, formData: this.form })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.venue = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${
          error.data ? error.data.error : error
        }`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingVenue = false
    }
    return this.venue
  }

  @action
  seedFormFromVenue = venue => {
    this.setFormProp(
      'scheduleDays',
      _deserializeSchedule(venue.schedules, this.form.scheduleDays)
    )
    this.setFormProp('monthsInAdvance', venue.max_future_booking_months)
    this.setFormProp('timezone', venue.timezone)
    this.setFormProp(
      'holidays',
      _deserializeHolidays(venue.schedules, this.form.holidays)
    )
    this.setFormBuffers(venue.buffer)
  }

  @action
  setFormBuffers = buffer => {
    const bufferMoment = moment.duration(parseInt(buffer, 10), 'minutes')
    const months = Math.floor(bufferMoment.as('months'))
    bufferMoment.subtract(months, 'months')
    const days = Math.floor(bufferMoment.as('days'))
    bufferMoment.subtract(days, 'days')
    const hours = Math.floor(bufferMoment.as('hours'))
    this.setFormProp('months', months)
    this.setFormProp('days', days)
    this.setFormProp('hours', hours)
  }

  @action
  updateSpaceBuffers = async (venueId, spaceId) => {
    this.isSavingSpace = true
    let result = null
    try {
      result = await adminSpaceApi.updateBuffers(venueId, spaceId, {
        months: this.form.months,
        days: this.form.days,
        hours: this.form.hours
      })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.space = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${error.data.error}`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingSpace = false
    }
    return result
  }
}

export default new CreateVenueStore()
