import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { observer, inject, PropTypes as PropTypesMobx } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Can } from '@casl/react'

import HeaderSearchInput from './HeaderSearchInput'
import UserAvatar from '../Global/UserAvatar'
import CompanyAvatar from '../Global/CompanyAvatar'
import NotificationPopover from './NotificationsPopover/NotificationPopover'

import LoggedInActionsStyles from './LoggedInActions.style'

const ViewProfile = ({ classes, href }) => (
  <Typography
    component={Link}
    to={href}
    onClick={event => event.stopPropagation()}
    variant="body1"
    color="primary"
    className={classes.viewProfileText}
  >
    View Profile
  </Typography>
)

@inject('userStore', 'companyStore')
@observer
class LoggedInActions extends Component {
  static propTypes = {
    handleLogout: PropTypes.func,
    loggedInUser: PropTypesMobx.objectOrObservableObject,
    onSearch: PropTypes.func,
    showSearch: PropTypes.bool
  }

  state = {
    anchorEl: null,
    showMobileSearch: false
  }

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  _handleLogout = () => {
    const { handleLogout } = this.props
    this.handleMenuClose()
    handleLogout()
  }

  _showMobileSearch = value => {
    const { setMobileSearch } = this.props
    if (setMobileSearch) setMobileSearch(value)
    this.setState({ showMobileSearch: value })
  }

  _handleClickCompany = async company => {
    const { history, setCurrentCompany } = this.props
    const companyAbility = await setCurrentCompany(company)
    const url = this._getLocationByContext(company, companyAbility)
    history.push(url)
  }

  _handleClickProfile = () => {
    const { history, currentCompany, currentCompanyAbility } = this.props
    const url = this._getLocationByContext(
      currentCompany,
      currentCompanyAbility
    )
    history.push(url)
  }

  _getLocationByContext = (currentCompany, ability) => {
    if (!currentCompany) {
      return '/admin/dashboard'
    }

    return ability.can('read', 'Company', 'dashboard')
      ? '/admin/company/dashboard'
      : '/admin/company/venues'
  }

  _renderUserMenu = () => {
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    const {
      loggedInUser,
      currentCompany,
      currentCompanyAbility,
      classes
    } = this.props
    const userCompanies =
      loggedInUser && loggedInUser.companies ? loggedInUser.companies : []
    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={this.handleMenuClose}
        data-test="e2e-current-context-menu"
      >
        <MenuItem className={classes.userProfile} divider>
          <Grid
            container
            onClick={() => this._handleClickProfile()}
            data-test="e2e-current-context-menu-item"
          >
            <Grid item xs={3} className={classes.userProfileAvatarWrapper}>
              {currentCompany ? (
                <CompanyAvatar
                  company={currentCompany}
                  height={52}
                  width={52}
                />
              ) : (
                <UserAvatar user={loggedInUser} height={52} width={52} />
              )}
            </Grid>
            <Grid item xs={9}>
              <Typography className={classes.nameText}>
                {currentCompany ? currentCompany.name : loggedInUser.fullname}
              </Typography>
              {currentCompany ? (
                <Fragment>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.locationIcon} />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.locationText}
                        title={currentCompany.location}
                      >
                        {currentCompany.location}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Can
                    I="read profile"
                    of="Company"
                    ability={currentCompanyAbility}
                  >
                    {() => (
                      <ViewProfile
                        classes={classes}
                        href="/admin/company/profile"
                      />
                    )}
                  </Can>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    className={classes.emailText}
                    title={loggedInUser.email}
                  >
                    {loggedInUser.email}
                  </Typography>
                  <ViewProfile
                    classes={classes}
                    href="/admin/account-settings"
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>
        </MenuItem>
        {currentCompany ? (
          <MenuItem
            onClick={() => this._handleClickCompany(null)}
            className={classes.listItem}
          >
            <Grid container alignItems="center">
              <Grid item className={classes.listItemAvatarWrapper}>
                <UserAvatar user={loggedInUser} height={38} width={38} />
              </Grid>
              <Grid item className={classes.listItemContentWrapper}>
                <Typography
                  className={classes.listItemName}
                  title={loggedInUser.fullname}
                >
                  {loggedInUser.fullname}
                </Typography>
                <Typography className={classes.listItemEmail}>
                  {loggedInUser.email}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ) : null}
        {userCompanies.map(company => {
          if (currentCompany && currentCompany.id === company.id) return null
          return (
            <MenuItem
              key={`company-${company.id}`}
              onClick={() => this._handleClickCompany(company)}
              className={classes.listItem}
              data-test="e2e-company-list-item"
            >
              <Grid container alignItems="center">
                <Grid item className={classes.listItemAvatarWrapper}>
                  <CompanyAvatar company={company} height={38} width={38} />
                </Grid>
                <Grid item className={classes.listItemContentWrapper}>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        className={classes.listItemName}
                        title={company.name}
                      >
                        {company.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <LocationOnIcon className={classes.locationIcon} />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.listItemLocation}
                        title={company.location}
                      >
                        {company.location}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
          )
        })}
        <MenuItem
          component={Link}
          to={'/admin/add-company'}
          href={'/admin/add-company'}
          className={classes.addCompany}
          divider
          data-test="e2e-add-company-button"
        >
          <AddCircleOutlineIcon
            style={{ fontSize: '16px', marginRight: 14, opacity: 0.5 }}
          />
          <Typography
            variant="body1"
            color="primary"
            className={classes.addCompanyText}
          >
            New Host Company
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={this._handleLogout}
          className={classes.listItem}
          data-test="e2e-logout-menu-item"
        >
          <Typography className={classes.logoutText}>Log Out</Typography>
        </MenuItem>
      </Menu>
    )
  }

  render() {
    const { anchorEl, showMobileSearch } = this.state
    const open = Boolean(anchorEl)
    const {
      showSearch = true,
      onSearch,
      loggedInUser,
      currentCompany,
      classes
    } = this.props
    return (
      <Grid
        container
        item
        justify="flex-end"
        alignItems="center"
        xs={12}
        data-test="e2e-logged-in-actions"
      >
        <Hidden mdUp>
          <Fragment>
            {showMobileSearch ? (
              <Grid
                container
                item
                justify="flex-end"
                xs={!showMobileSearch ? 7 : 12}
              >
                <HeaderSearchInput
                  onSearch={onSearch}
                  showMobileSearch={true}
                  setMobileSearch={this._showMobileSearch}
                />
              </Grid>
            ) : null}
            {!showMobileSearch && (
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                item
                xs={12}
              >
                {showSearch ? (
                  <Fragment>
                    <Grid item>
                      <HeaderSearchInput
                        onSearch={onSearch}
                        showMobileSearch={false}
                        setMobileSearch={this._showMobileSearch}
                      />
                    </Grid>
                    <Grid item>
                      <div className={classes.headerDivider} />
                    </Grid>
                  </Fragment>
                ) : null}
                <Grid item>
                  <NotificationPopover />
                  <IconButton
                    aria-owns={open ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                    data-test="e2e-user-avatar-button"
                  >
                    {currentCompany ? (
                      <CompanyAvatar
                        company={currentCompany}
                        height={36}
                        width={36}
                      />
                    ) : (
                      <UserAvatar user={loggedInUser} height={36} width={36} />
                    )}
                  </IconButton>
                  {this._renderUserMenu()}
                </Grid>
              </Grid>
            )}
          </Fragment>
        </Hidden>
        <Hidden smDown>
          <Fragment>
            {showSearch ? (
              <Fragment>
                <Grid item className={classes.searchInputContainer}>
                  <HeaderSearchInput onSearch={onSearch} />
                </Grid>
                <Grid item>
                  <div className={classes.headerDivider} />
                </Grid>
              </Fragment>
            ) : null}
            <Grid item>
              <NotificationPopover />
              <IconButton
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenuOpen}
                data-test="e2e-user-avatar-button"
              >
                {currentCompany ? (
                  <CompanyAvatar
                    company={currentCompany}
                    height={36}
                    width={36}
                  />
                ) : (
                  <UserAvatar user={loggedInUser} height={36} width={36} />
                )}
              </IconButton>
              {this._renderUserMenu()}
            </Grid>
          </Fragment>
        </Hidden>
      </Grid>
    )
  }
}

export default withRouter(withStyles(LoggedInActionsStyles)(LoggedInActions))
