export const validate = (formFields, validations) => {
  let isInvalidValid = false
  const errors = {}

  const formFieldsAttributes = Object.keys(validations || {})
  formFieldsAttributes.forEach(attr => {
    if (isInvalidValid) {
      return
    }
    Object.keys(validations[attr]).forEach(_attr => {
      const fieldAttr = formFields[attr]
      const isRequired = validations[attr].required
      const pattern = validations[attr].pattern
      if (isRequired && !fieldAttr) {
        isInvalidValid = true
        errors[attr] = ['is required']
      }
      if (pattern && !fieldAttr.match(RegExp(pattern))) {
        isInvalidValid = true
        errors[attr] = ['invalid format']
      }
    })
  })

  return [!isInvalidValid, errors]
}

export const parseErrors = errors => {
  const parsedErrors = {}
  errors.forEach(error => {
    const { field, message, validation } = error
    if (parsedErrors[field]) {
      parsedErrors[field].push({ message, validation })
    } else {
      parsedErrors[field] = [{ message, validation }]
    }
  })
  return parsedErrors
}

export const parseFormikErrors = errors => {
  const parsedErrors = {}
  errors.forEach(error => {
    const { field, message, validation } = error
    parsedErrors[field] = { message, validation }
  })
  return parsedErrors
}
