import ImagePlaceholder from '../assets/img-venue-placeholder@2x.png'
import ImagePlaceholderWebp from '../assets/img-venue-placeholder@2x.webp'
import { detect } from 'detect-browser'

const browser = detect()

export function canUseWebp() {
  return (
    browser && ['chrome', 'firefox', 'edge', 'opera'].includes(browser.name)
  )
}

export function chooseImgOrWebp(img, imgWebp) {
  return canUseWebp() ? imgWebp : img
}

export const DEFAULT_IMAGE = chooseImgOrWebp(
  ImagePlaceholder,
  ImagePlaceholderWebp
)

export const getResizedImageURL = ({ file, width, height, quality = 80 }) => {
  if (!file) {
    return DEFAULT_IMAGE
  }
  const url = `${process.env.REACT_APP_RESIZER_URL}`
  const paramsArray = [
    `?f=${file}`,
    ...(width ? [`w=${width}`] : []),
    ...(height ? [`h=${height}`] : []),
    ...(quality ? [`q=${quality}`] : []),
    ...(canUseWebp() ? ['t=webp'] : ['t=jpeg'])
  ]
  return url + paramsArray.join('&')
}

export const mapPlaceTitleToImagesTitle = (place, images) => {
  if (!place) return images
  let placeTitle = place.venue
    ? `${place.name} at ${place.venue.name}`
    : place.name
  const address =
    place.venue && !place.address ? place.venue.address : place.address
  if (address) {
    placeTitle += `, ${address.address}, ${address.city.name}, ${
      address.state.code
    }`
  }
  const mappedTitleImages = images.map(image => {
    if (!image.title) {
      image.defaultTitle = placeTitle
    }
    if (!image.description) {
      image.defaultDescription = placeTitle
    }
    return image
  })
  return mappedTitleImages
}
