import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import NotificationListItem from './NotificationListItem'
import Typography from '@material-ui/core/Typography'
import { EventAvailable as EventAvailableIcon } from '@material-ui/icons'
import { AllHtmlEntities } from 'html-entities'
import {
  getNotificationIconByType,
  getNotificationsLinkUrlByType
} from '../../../utilities/notificationUtils'
import { stripHTML } from '../../../utilities/textUtils'

const entities = new AllHtmlEntities()
const styles = theme => ({
  listRoot: {
    [theme.breakpoints.down('sm')]: {
      width: 343
    },
    [theme.breakpoints.up('md')]: {
      width: 437
    },
    display: 'flex',
    flexDirection: 'column'
  },
  emptyIcon: {
    margin: '0 auto',
    fontSize: '6em',
    marginTop: '42px',
    paddingBottom: '14px'
  },
  emptyText: {
    textAlign: 'center',
    fontSize: '19px',
    fontWeight: 300,
    marginBottom: '50px'
  }
})

const _renderNotificationDescription = text => {
  const decodedText = entities.decode(text)
  return <span dangerouslySetInnerHTML={{ __html: decodedText }} />
}

const NotificationList = ({ notifications = [], handleClickItem, classes }) => (
  <List classes={{ root: classes.listRoot }} dense={true}>
    {notifications.map((notification, index) => (
      <NotificationListItem
        key={notification.id}
        icon={getNotificationIconByType(notification.notification_type)}
        linkUrl={getNotificationsLinkUrlByType(notification)}
        title={stripHTML(notification.description, true)}
        divider={index + 1 !== notifications.length}
        venueId={notification.venue_id}
        handleClickItem={handleClickItem}
      >
        {_renderNotificationDescription(notification.description)}
      </NotificationListItem>
    ))}
    {!notifications.length && (
      <Grid container item direction="column" justify="center" xs={12}>
        <EventAvailableIcon color="primary" className={classes.emptyIcon} />
        <Typography classes={{ root: classes.emptyText }}>
          All done! Nothing to see here.
        </Typography>
      </Grid>
    )}
  </List>
)

export default withStyles(styles)(NotificationList)
