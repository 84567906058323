import moment from 'moment'
import { unionBy } from 'lodash'
export function checkForBlockedDates(start, end, dates) {
  const dateFormat = 'YYYY-MM-DD'
  const diff = moment(end).diff(start, 'days') + 1

  for (let i = 0; i < diff; i++) {
    const checkDate = moment(start)
      .add(i, 'd')
      .format(dateFormat)

    if (dates[checkDate] && dates[checkDate].blocked) {
      return true
    }
  }

  return false
}

export function filteredCalendarDays(
  newCalendarDaysSet,
  existinCalendarDaysSet = [],
  currentSpaceTimezone = ''
) {
  const daySet = newCalendarDaysSet.map(set => {
    return set.days
  })
  const days = daySet.concat.apply([], daySet)
  const unionedNewAndExistingDays = unionBy(
    days,
    existinCalendarDaysSet,
    'date'
  )
  return unionedNewAndExistingDays.map(day => ({
    ...day,
    day: currentSpaceTimezone
      ? moment(day.date, 'MM-DD-YYYY')
      : moment(day.date, 'MM-DD-YYYY').tz(currentSpaceTimezone)
  }))
}
