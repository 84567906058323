import BaseApi from './baseApi'
import userStore from '../stores/user'

class VenueTypesApi extends BaseApi {
  constructor() {
    super({ routeName: 'venue-types' })
  }

  getAll = () => {
    return this.request(userStore.jwtToken).get()
  }

  getVenueTypesSpaceTypes = venueTypeId =>
    this.request(userStore.jwtToken).get(`${venueTypeId}/space-types`)

  getVenueTypeGroups = () => this.request(userStore.jwtToken).get(`/groups`)
}

export default new VenueTypesApi()
