import axios from 'axios'
import moment, { tz } from 'moment-timezone'
const apiKey = 'R8U3X2PD7SP7'
export const getTimezoneByCoordinates = async (latitude, longitude) => {
  const timezoneAPI = `http://api.timezonedb.com/v2/get-time-zone?format=json&key=${apiKey}&by=position&lat=${latitude}&lng=${longitude}`
  const timezoneResponse = await axios.get(timezoneAPI)
  if (timezoneResponse.status === 'OK' && timezoneResponse.zoneName) {
    return timezoneResponse.zoneName
  }
  return tz.guess()
}

export const convertItemsInArrayToLocalTimezone = ({
  keys = [],
  arrayOfItems = [],
  dateFormat = null
}) => {
  if (!keys.length || !arrayOfItems.length) return arrayOfItems
  arrayOfItems.forEach(item => {
    keys.forEach(key => {
      if (item[key]) {
        try {
          item[key] = moment.utc(item[key], dateFormat).tz(tz.guess())
        } catch (error) {
          console.error(error)
        }
      }
    })
  })
  return arrayOfItems
}
