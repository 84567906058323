import BaseApi from '../baseApi'
import userStore from '../../stores/user'

class CalendarApi extends BaseApi {
  constructor() {
    super({ routeName: 'admin/venues' })
  }

  create = (venueId, params = {}) => {
    const url = `/${venueId}/calendar`
    return this.request(userStore.jwtToken).post(url, {
      calendar: { ...params }
    })
  }
}

export default new CalendarApi()
