import { action, observable } from 'mobx'
import companyApi from '../services/companyApi'

import globalStore from './global'

const defaultPagination = {
  total: 0,
  perPage: 10,
  page: 1,
  lastPage: 1
}

class WizardStateStore {
  @observable.deep
  states = []
  @observable
  isLoadingStates = false
  @observable
  pagination = {
    ...defaultPagination
  }

  @observable.deep
  state = {}
  @observable
  isLoadingState = false
  @observable
  isSavingState = false
  @observable
  isDeletingState = false

  constructor() {
    this.globalStore = globalStore
  }

  @action
  getWizardProgress = (state, stepCount) => {
    const step = state.state.activeStep + 1
    return Math.floor((step / stepCount) * 100)
  }

  @action
  setWizardState = state => {
    this.state.state = state
  }

  @action
  clearState = () => {
    this.state = {}
  }

  @action
  getAllForCompanyId = async ({
    companyId,
    objectId = null,
    objectType = 'venues',
    statesPagination = {}
  }) => {
    this.isLoadingStates = true
    try {
      const {
        data: { states = [], pagination = {} }
      } = await companyApi.getAllWizardStates(companyId, {
        object_id: objectId,
        object_type: objectType,
        pagination: {
          ...defaultPagination,
          ...statesPagination
        }
      })
      this.states = states
      this.pagination = pagination
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Failed to load venue drafts. ${error.data.error}`
      )
    } finally {
      this.isLoadingStates = false
    }
  }

  @action
  getWizardState = async (companyId, stateId) => {
    this.isLoadingState = true
    try {
      const { data } = await companyApi.getWizardStateById(companyId, stateId)
      this.state = data
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Failed to load state. ${error}`
      )
    } finally {
      this.isLoadingState = false
    }
    return this.state
  }

  @action
  createWizardState = async (companyId, object_type, object_id = null) => {
    this.isSavingState = true
    try {
      const { data } = await companyApi.createWizardState(companyId, {
        object_id,
        object_type,
        state: this.state.state
      })
      this.state = data
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Failed to save state. ${error.data.error}`
      )
    } finally {
      this.isSavingState = false
    }
  }

  @action
  updateWizardState = async companyId => {
    this.isSavingState = true
    try {
      const { data } = await companyApi.updateWizardState(
        companyId,
        this.state.id,
        {
          state: this.state.state
        }
      )
      this.state = data
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Failed to save state. ${error.data.error}`
      )
    } finally {
      this.isSavingState = false
    }
  }

  @action
  deleteWizardState = async (companyId, stateId, finished) => {
    this.isDeletingState = true
    try {
      await companyApi.deleteWizardState(companyId, stateId)
      if (finished) finished()
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Failed to delete state. ${error.data.error}`
      )
    } finally {
      this.isDeletingState = false
    }
  }
}

export default new WizardStateStore()
