import moment from 'moment'

export const mergeVenueWithListSpaceForm = (venue, listSpaceForm) => {
  // Basic Info Form
  listSpaceForm.basicInfoForm.venueId.value = venue.id
  listSpaceForm.basicInfoForm.companyId.value = venue.company_id
  listSpaceForm.basicInfoForm.address.value = _deserializeAddress(
    venue.address || {}
  )
  listSpaceForm.basicInfoForm.venueName.value = venue.name
  listSpaceForm.basicInfoForm.venueDescription.value = venue.description
  listSpaceForm.basicInfoForm.venueTypeGroup.value = venue.venueTypeGroup
  listSpaceForm.basicInfoForm.venueType.value = venue.venueType
  listSpaceForm.basicInfoForm.spaceType.value = venue.spaceType
  listSpaceForm.basicInfoForm.commonAreaSquareFeet.value =
    venue.common_area_square_feet
  listSpaceForm.basicInfoForm.amenities.value = venue.amenities

  // Virtual Tour Form
  listSpaceForm.virtualTourForm.venuePhotos.value = _deserializePhotos(
    venue.medias,
    'venues'
  )
  listSpaceForm.virtualTourForm.commonAreaPhotos.value = _deserializeCommonAreasPhotos(
    venue.medias
  )
  listSpaceForm.virtualTourForm.floorPlanPhoto.value = _deserializeFloorPlanPhoto(
    venue.medias
  )

  // Booking Settings Form
  listSpaceForm.bookingSettingsForm.scheduleDays.error = null
  listSpaceForm.bookingSettingsForm.scheduleDays.value = _deserializeSchedule(
    venue.schedules,
    listSpaceForm.bookingSettingsForm.scheduleDays.value
  )
  listSpaceForm.bookingSettingsForm.maxFutureBookingMonths.value =
    venue.max_future_booking_months
  listSpaceForm.bookingSettingsForm.buffer.value = venue.buffer
  listSpaceForm.bookingSettingsForm.timezone.value = venue.timezone
  listSpaceForm.bookingSettingsForm.holidays.value = _deserializeHolidays(
    venue.schedules
  )
  listSpaceForm.bookingSettingsForm.rules.value = _deserializeRules(venue.rules)
  return {
    ...listSpaceForm
  }
}

export const mergeSpaceWithListSpaceForm = (space, listSpaceForm) => {
  // Basic Info Form
  listSpaceForm.basicInfoForm.venueId.value = space.venueId
  listSpaceForm.basicInfoForm.companyId.value = space.venue.company_id
  listSpaceForm.basicInfoForm.address.value = _deserializeAddress(space.address)
  listSpaceForm.basicInfoForm.description.value = space.description
  listSpaceForm.basicInfoForm.venueTypeGroup.value = space.venueTypeGroup
  listSpaceForm.basicInfoForm.venueType.value = space.venueType
  listSpaceForm.basicInfoForm.spaceType.value = space.spaceType
  listSpaceForm.basicInfoForm.amenities.value = space.amenities

  // Virtual Tour Form
  listSpaceForm.virtualTourForm.spacePhotos.value = _deserializePhotos(
    space.medias,
    'spaces'
  )

  // Booking Settings Form
  listSpaceForm.bookingSettingsForm.buffer.value = space.buffer
  listSpaceForm.bookingSettingsForm.rules.value = _deserializeRules(space.rules)
  listSpaceForm.bookingSettingsForm.spaceTerms.value = _deserializeTerms(
    space.terms
  )
  return {
    ...listSpaceForm
  }
}

const _deserializeAddress = ({
  country = '',
  state = '',
  zipCode = '',
  city = '',
  address = ''
}) => {
  return {
    country: {
      long_name: country && country.name,
      short_name: country && country.name.code
    },
    state: {
      long_name: state && state.name,
      short_name: state && state.code
    },
    zipCode: {
      long_name: zipCode && zipCode.code,
      short_name: zipCode && zipCode.code
    },
    city: {
      long_name: city && city.name,
      short_name: city && city.name
    },
    neighborhood: {
      short_name: '',
      long_name: ''
    },
    short_address: address,
    full_address: `${address}, ${city.name}, ${state.code} ${zipCode.code}, ${
      country.code
    }`
  }
}

const _deserializePhotos = (medias, object_type) => {
  const photos = []
  medias.forEach(media => {
    if (media.object_type === object_type && !media.meta) {
      photos.push({
        mediaId: media.id,
        preview: media.url,
        title: media.title
      })
    }
  })
  return photos
}

const _deserializeCommonAreasPhotos = medias => {
  const commonAreaPhotos = []
  medias.forEach(media => {
    if (
      media.object_type === 'venues' &&
      media.meta &&
      media.meta.is_common_area
    ) {
      commonAreaPhotos.push({
        mediaId: media.id,
        preview: media.url,
        title: media.title
      })
    }
  })
  return commonAreaPhotos
}

const _deserializeFloorPlanPhoto = medias => {
  const floorPlanPhotos = []
  medias.forEach(media => {
    if (
      media.object_type === 'venues' &&
      media.meta &&
      media.meta.is_floor_plan
    ) {
      floorPlanPhotos.push({
        mediaId: media.id,
        preview: media.url,
        title: media.title
      })
    }
  })
  return floorPlanPhotos
}

export const _deserializeSchedule = (venueSchedules, formSchedules) => {
  const defaultSchedule = venueSchedules.find(
    schedule => schedule.schedule_type === 'default'
  )
  const deserializedSchedules = []
  formSchedules.forEach(schedule => {
    const timeframes =
      defaultSchedule &&
      defaultSchedule.timeframes.filter(
        timeframe => timeframe.day_id === schedule.dayId
      )
    const isChecked = timeframes && timeframes.length ? true : false

    const formattedTimeframes = timeframes.map(timeframe => {
      const start_time = timeframe
        ? moment()
            .startOf('day')
            .add(timeframe.start_time, 'hours')
            .format('HH:mm')
        : '08:00'
      const end_time = timeframe
        ? moment()
            .startOf('day')
            .add(timeframe.end_time, 'hours')
            .format('HH:mm')
        : '20:00'

      return {
        start_time,
        end_time
      }
    })

    deserializedSchedules.push({
      name: schedule.name,
      dayId: schedule.dayId,
      isChecked,
      timeframes: formattedTimeframes
    })
  })
  return deserializedSchedules
}

export const _deserializeHolidays = (venueSchedules, holidays) => {
  const holidaySchedule = venueSchedules.find(
    schedule => schedule.schedule_type === 'holiday'
  )
  if (!holidaySchedule) {
    return null
  }

  const deserializedHolidays = []
  holidays.forEach(holiday => {
    const weekday = holidaySchedule.timeframes.find(timeframe => {
      const timeframeDate = timeframe.exception_name
        ? moment().holiday(timeframe.exception_name)
        : moment(timeframe.exception_date)
      const holidayDate = moment(holiday.date, 'MM/DD')
      return (
        timeframeDate.format('MM') === holidayDate.format('MM') &&
        timeframeDate.format('DD') === holidayDate.format('DD')
      )
    })
    const isChecked = Boolean(weekday)
    deserializedHolidays.push({
      ...holiday,
      isChecked
    })
  })
  return deserializedHolidays
}

const _deserializeRules = rules => {
  return rules.map(rule => {
    if (rule.pivot) {
      return {
        ...rule,
        description: rule.pivot.description,
        is_checked: rule.pivot.is_checked
      }
    }
    return rule
  })
}

const _deserializeTerms = spaceTerms => {
  return spaceTerms.map(term => {
    if (term.pivot) {
      return {
        ...term,
        is_default: term.pivot.is_default,
        minimum_duration: term.pivot.minimum_duration,
        rate: term.pivot.rate,
        security_deposit: term.pivot.security_deposit,
        setup_fee: term.pivot.setup_fee
      }
    }
    return term
  })
}
