import moment from 'moment-timezone'
import VIEWS from '@components/SpaceDetail/VIEWS'
import { toJS } from 'mobx'

export function getBookingDailyHours(booking, asMomentObject = false) {
  try {
    const venue = booking.space.venue
    if (!venue) throw Error('getBookingDailyHours requires a venue')
    const startDate = moment
      .utc(booking.start_date)
      .tz(booking.space.venue.timezone)
    const endDate = moment
      .utc(booking.end_date)
      .tz(booking.space.venue.timezone)
    return {
      start_date: asMomentObject
        ? startDate
        : startDate.format('YYYY-MM-DD HH:mm:ss'),
      end_date: asMomentObject ? endDate : endDate.format('YYYY-MM-DD HH:mm:ss')
    }
  } catch (error) {
    console.error(error)
    return {
      start_date: asMomentObject
        ? moment(booking.start_date)
        : booking.start_date,
      end_date: asMomentObject ? moment(booking.end_date) : booking.end_date
    }
  }
}

/**
 *
 * @param {spaceURL} spaceURL
 * @param {form} form
 */
export const getBookNowToRouteObject = ({ spaceURL, form, ...toParams }) => {
  return {
    ...toParams,
    pathname: spaceURL,
    state: {
      view: VIEWS.BOOK_NOW,
      form: form
        ? {
            startDate: form.startDate.format(),
            endDate: form.endDate.format(),
            hourlyDateTimes: form.hourlyDateTimes
              ? toJS(
                  form.hourlyDateTimes.map(dateTime => {
                    return {
                      ...dateTime,
                      date: dateTime.date.format()
                    }
                  })
                )
              : [],
            term: form.term
          }
        : null
    }
  }
}

export const getBookingLastTransaction = ({ booking }) => {
  if (!booking || !booking.transactions || !booking.transactions.length) {
    return {}
  }
  const transaction = booking.transactions.reduce((m, v, i) => {
    return v.updated_at > m.updated_at && i ? v : m
  })
  return transaction
}
