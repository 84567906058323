import { action, observable } from 'mobx'

import userStore from './user'
import globalStore from './global'
import mediaStore from './media'

import venueApi from '@services/venueApi'
import productApi from '@services/productApi'

import { mapPlaceTitleToImagesTitle } from '@utilities/mediaUtils'

const defaultOption = {
  name: '',
  price: '',
  description: '',
  fee: ''
}

const defaultProductForm = {
  groupId: null,
  typeId: null,
  categoryId: null,
  options: [],
  ...defaultOption,
  medias: [],
  availability: null
}

const productsDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

class ProductStore {
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0

  @observable.deep
  product = {}
  @observable.deep
  productForm = {
    ...defaultProductForm
  }
  @observable
  productFormError = null
  @observable
  isLoadingProduct = false
  @observable
  isSavingProduct = false

  @observable.deep
  products = []
  @observable
  isLoadingProducts = false
  @observable
  totalCount = 0

  @observable.deep
  productTypeGroups = []
  @observable
  isLoadingProductTypeGroups = false

  @observable.deep
  productsPagination = {
    ...productsDefaultPagination
  }

  @observable
  isLoadingProductsByVenueType = false
  @observable
  isLoadingProductsBySpaceType = false

  defaultOption

  @observable
  carousels = []
  @observable
  carouselCount = 0
  @observable
  isLoadingProductCarousels = false

  @observable.deep
  featuredProduct = null
  @observable
  isLoadingFeaturedProduct = false

  constructor() {
    this.globalStore = globalStore
    this.mediaStore = mediaStore
  }

  @action.bound
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.productForm = {
      ...defaultProductForm
    }
  }

  @action
  resetFormError = () => {
    this.productFormError = null
  }

  @action
  setProductFormProp = (key, value) => {
    this.productForm[key] = value
  }

  @action
  setForm = form => {
    this.productForm = form
  }

  @action
  setFormProp = (key, value) => {
    this.productForm[key] = value
  }

  @action
  updateMediaInfo = async (currentMedia, info) => {
    const media = await this.mediaStore.update(currentMedia.id, info)
    if (media) {
      this.productForm.medias = this.productForm.medias.map(currentMedia => {
        return currentMedia.id === media.id ? media : currentMedia
      })
      this.globalStore.openNotificationSnackbar(`Photo has been updated.`)
    } else {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again.`
      )
    }
  }

  @action
  canSubmit = () => {
    const {
      groupId,
      typeId,
      categoryId,
      name,
      price,
      description,
      medias
    } = this.productForm
    return (
      groupId &&
      typeId &&
      categoryId &&
      name &&
      price &&
      description &&
      medias &&
      medias.length
    )
  }

  @action
  setProductsPaginationProp = (prop, value) => {
    this.productsPagination[prop] = value
  }

  @action
  resetProductsPagination = () => {
    this.productsPagination = {
      ...productsDefaultPagination
    }
  }

  @action
  getProductById = async (venueId, productId) => {
    this.isLoadingProduct = true
    try {
      const { data: product } = await venueApi.getProductById(
        venueId,
        productId
      )
      this.product = product
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingProduct = false
    }
  }

  @action
  getProductTypeGroups = async venueId => {
    this.isLoadingProductTypeGroups = true
    try {
      const { data: productTypeGroups } = await venueApi.getProductTypeGroups(
        venueId,
        {}
      )
      this.productTypeGroups = productTypeGroups
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingProductTypeGroups = false
    }
  }

  @action
  saveProduct = async venueId => {
    this.isSavingProduct = true
    try {
      const data = {
        ...this.productForm,
        price: Math.round(this.productForm.price * 100) / 100
      }
      const result = await venueApi.createProduct(venueId, data)
      if (result.data.error) {
        if (result.status === 401) {
          userStore.openAuthDialog()
        }
        this.productFormError = result.data.error
        this.globalStore.openNotificationSnackbar(
          `Error: ${this.productFormError}`
        )
      } else {
        this.product = result.data
      }
    } catch (error) {
      this.productFormError = error.data.error
      this.globalStore.openNotificationSnackbar(
        `Error: ${this.productFormError}`
      )
    } finally {
      this.isSavingProduct = false
    }

    return this.product
  }

  @action
  updateProduct = async venueId => {
    this.isSavingProduct = true

    try {
      const data = {
        ...this.productForm,
        price: Math.round(this.productForm.price * 100) / 100
      }
      const result = await venueApi.updateProduct(
        venueId,
        this.product.id,
        data
      )
      if (result.data.error) {
        if (result.status === 401) {
          userStore.openAuthDialog()
        }
        this.productFormError = result.data.error
        this.globalStore.openNotificationSnackbar(
          `Error: ${this.productFormError}`
        )
      } else {
        this.product = result.data
      }
    } catch (error) {
      this.productFormError = error.data.error
      this.globalStore.openNotificationSnackbar(
        `Error: ${this.productFormError}`
      )
    } finally {
      this.isSavingProduct = false
    }

    return this.product
  }

  @action
  togglePublished = async (venueId, productId) => {
    this.isSavingProduct = true
    try {
      const { data: product } = await venueApi.toggleProductPublished(
        venueId,
        productId
      )
      this.product = product
      this.globalStore.openNotificationSnackbar(
        `Service has been ${product.is_published ? 'Published' : 'Unpublished'}`
      )
    } catch (error) {
      const errorMessage = error.data ? error.data.error : error
      this.globalStore.openNotificationSnackbar(`Error: ${errorMessage}`)
    } finally {
      this.isSavingProduct = false
    }
  }

  @action
  deleteProduct = async (venueId, productId) => {
    this.isSavingProduct = true
    try {
      const { data: product } = await venueApi.deleteProduct(venueId, productId)
      this.product = product
      this.globalStore.openNotificationSnackbar('Service has been deleted.')
    } catch (error) {
      const errorMessage = error.data ? error.data.error : error
      this.globalStore.openNotificationSnackbar(`Error: ${errorMessage}`)
    } finally {
      this.isSavingProduct = false
    }
  }

  @action
  getProductsByVenueType = async (venueTypeId, lat, lng) => {
    this.isLoadingProductsByVenueType = true
    try {
      const { data: products } = await venueApi.getProductsByVenueType(
        venueTypeId,
        {
          lat,
          lng
        }
      )
      this.products = products
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingProductsByVenueType = false
    }
  }

  @action
  getProductsBySpaceType = async (spaceTypeId, lat, lng) => {
    this.isLoadingProductsBySpaceType = true
    try {
      const { data: products } = await venueApi.getProductsBySpaceType(
        spaceTypeId,
        {
          lat,
          lng
        }
      )
      this.products = products
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingProductsBySpaceType = false
    }
  }

  getProductCarousels = async (lat, lng, carouselLimit = 3) => {
    this.isLoadingProductCarousels = true
    try {
      const {
        data: { carousels, carouselCount }
      } = await productApi.getCarousels(lat, lng, carouselLimit)
      this.carousels = carousels
      this.carouselCount = carouselCount
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingProductCarousels = false
    }
  }

  @action
  getProductsByBounds = async ({
    bounds,
    productTypeGroupId = null,
    productTypeId = null,
    productCategoryId = null,
    termId = null,
    page = 0,
    limit = 18,
    price = null
  }) => {
    try {
      const {
        data: { products, totalCount }
      } = await productApi.getByBounds(
        bounds,
        productTypeGroupId,
        productTypeId,
        productCategoryId,
        termId,
        page,
        limit,
        price
      )

      products.forEach(product => {
        product.medias = mapPlaceTitleToImagesTitle(product, product.medias)
      })
      this.products = products
      this.totalCount = totalCount
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getFeaturedProduct = async () => {
    this.isLoadingFeaturedProduct = true
    try {
      const { data: featuredProduct } = await productApi.getFeaturedProduct()
      this.featuredProduct = featuredProduct
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingFeaturedProduct = false
    }
  }
}

export default new ProductStore()
