import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class ContactApi extends BaseApi {
  constructor() {
    super({ routeName: 'admin/contacts' })
  }

  fetch = (filters = {}) => {
    return this.request(userStore.jwtToken).get('', {
      params: {
        ...filters
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchOne = contactId => {
    const url = `/${contactId}`
    return this.request(userStore.jwtToken).get(url)
  }

  create = (params = {}) => {
    return this.request(userStore.jwtToken).post('', params)
  }

  update = (contactId, params = {}) => {
    const url = `/${contactId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  destroy = contactId => {
    const url = `/${contactId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  resendInvitation = contactId => {
    const url = `/${contactId}/resendInvitation`
    return this.request(userStore.jwtToken).post(url)
  }
}

export default new ContactApi()
