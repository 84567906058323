export const PLACEHOLDER_IMAGE = 'http://via.placeholder.com/550x550'

export const createWithParams = params => {
  const formattedParams = params.map(param => {
    return `with=${param}`
  })
  return `?${formattedParams.join('&')}`
}

export const getApiUrl = () => {
  const { REACT_APP_API_DOMAIN, REACT_APP_API_URL_PREFIX } = process.env
  return `${REACT_APP_API_DOMAIN}${REACT_APP_API_URL_PREFIX}`
}

export const prefixImageUrlwithS3 = imagePath => {
  const { REACT_APP_S3_BUCKET_URL, REACT_APP_API_DOMAIN } = process.env
  if (typeof imagePath !== 'string') {
    console.error('You must provide a string of the url/path for the image.')
    return PLACEHOLDER_IMAGE
  }
  // TODO: TEMP to get images that live on server.
  if (imagePath.includes('image/') || imagePath.includes('img/')) {
    return `${REACT_APP_API_DOMAIN}/${imagePath}`
  }

  return `${REACT_APP_S3_BUCKET_URL}/${imagePath}`
}

export const getPrimaryImageFromMedias = medias => {
  if (!medias || !medias.length) {
    return { url: '' }
  }

  let primaryImage = medias
    .slice()
    .filter(image => !image.meta || image.meta.is_primary === true)
    .sort((a, b) => a.index_position - b.index_position)[0]

  if (!primaryImage) {
    primaryImage = medias[0]
  }
  return primaryImage
}

/**
 *
 * @param {Array} medias
 * @param {String='is_common_area','is_floor_plan'} metaKey
 * @returns {Array} medias
 */
export const getMediasByMeta = (medias, metaKey) => {
  const emptyMedias = []
  if (!medias || !medias.length) {
    return emptyMedias
  }
  return medias.filter(media => media.meta && media.meta[metaKey])
}

export const getDefaultOrFirstTerm = terms => {
  if (!terms) return { pivot: {}, name: '' }
  return terms.find(term => term.pivot && term.pivot.is_default) || terms[0]
}

export const getPivotValueOrDefault = (objectWithPivot = {}, key) => {
  if (objectWithPivot.hasOwnProperty('pivot')) {
    if (objectWithPivot.pivot.hasOwnProperty(key)) {
      return objectWithPivot.pivot[key]
    }
  }
  return objectWithPivot[key]
}
