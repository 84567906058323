export const CHANNELS = {
  CURRENT_USER: 'currentUser',
  NOTIFICATIONS: 'notifications'
}

export const EVENTS = {
  USER_IDLE_CHECK: 'userIdleCheck',
  USER_NOT_IDLE: 'userIsNotIdle',
  USER_LOGOUT_ALL: 'userLogoutAllSubscriptions',
  USER_ABILITIES_CHANGED: 'abilitiesChanged',
  USER_CONTEXT_SWITCHED: 'userContextSwitched',
  NOTIFICATIONS_NEW: 'new:notifications'
}
