import { plural, singular } from 'pluralize'
import { AllHtmlEntities } from 'html-entities'
const entities = new AllHtmlEntities()

export function pluralByCondition(word, conditionValue) {
  if (conditionValue == true) {
    return plural(word)
  }
  return singular(word)
}

export function stripHTML(html, decode = false) {
  if (decode) {
    html = entities.decode(html)
  }
  return html.replace(/<(?:.|\n)*?>/gm, '')
}
