import { action, observable } from 'mobx'
import venueApi from '../../services/admin/venueApi'
import adminSpaceApi from '../../services/admin/spaceApi'
import calendarApi from '../../services/admin/calendarApi'

import GlobalStore from '../global'

class VenueStore {
  @observable.deep
  venue = {
    spaces: [],
    calendars: []
  }

  @observable
  createNewCalendar = false
  @observable
  disconnectCalendarDialog = false
  @observable
  isCreatingCalendar = false
  @observable
  newCalendarName = ''
  @observable
  calendarSite = ''
  @observable
  calendarSiteId = ''
  @observable
  isSavingCalendars = false
  @observable
  calendarsHaveBeenUpdated = false

  @action
  fetchOne = async id => {
    try {
      const {
        data: { allSpaces, calendars, ...venue }
      } = await venueApi.fetchOne(id)
      this.venue = {
        ...venue,
        calendar_connected: Boolean(venue.tour_calendar_id),
        calendars,
        spaces: allSpaces.map(space => {
          space.calendar_connected = Boolean(space.calendar_id)
          return space
        })
      }
      return this.venue
    } catch (error) {
      return {}
    }
  }

  @action
  setCalendarToSpace = (spaceId, calendarId) => {
    const spaces = this.venue.spaces.map(space => {
      if (space.id === spaceId) {
        space.calendar_id = calendarId
      }
      return space
    })

    this.venue = {
      ...this.venue,
      spaces
    }
  }

  @action
  setCalendarToVenue = calendarId => {
    this.venue = {
      ...this.venue,
      tour_calendar_id: calendarId
    }
  }

  @action
  saveCalendars = async () => {
    try {
      this.isSavingCalendars = true
      let calendarsSaved = 0
      await this.updateVenue({
        tour_calendar_id: this.venue.tour_calendar_id
      })
      await Promise.all(
        this.venue.spaces.map(async space => {
          await this.updateSpace(space.id, { calendar_id: space.calendar_id })
          calendarsSaved++
        })
      )
      this.isSavingCalendars = false
      this.calendarsHaveBeenUpdated = true
      GlobalStore.openNotificationSnackbar(
        calendarsSaved
          ? 'Calendars have been updated successfully!'
          : 'No calendars have been selected to save.'
      )

      return true
    } catch (error) {
      this.isSavingCalendars = false
      return []
    }
  }

  @action
  updateVenue = async (params = {}) => {
    try {
      const { data: venue } = await venueApi.setCalendar(this.venue.id, params)
      this.venue = {
        ...this.venue,
        ...venue,
        calendar_connected: Boolean(venue.tour_calendar_id)
      }
      return true
    } catch (error) {
      return []
    }
  }

  @action
  updateSpace = async (spaceId, params = {}) => {
    const { data } = await adminSpaceApi.setCalendar(
      this.venue.id,
      spaceId,
      params
    )

    this.venue = {
      ...this.venue,
      spaces: this.venue.spaces.map(space => {
        if (space.id === data.id) {
          data.calendar_connected = Boolean(data.calendar_id)
          return data
        }
        return space
      })
    }
  }

  @action
  openCreateCalendarDialog = (calendarSite, calendarSiteId) => {
    this.createNewCalendar = true
    this.calendarSite = calendarSite
    this.calendarSiteId = calendarSiteId
  }

  @action
  openDisconnectCalendarDialog = (calendarSite, calendarSiteId) => {
    this.disconnectCalendarDialog = true
    this.calendarSite = calendarSite
    this.calendarSiteId = calendarSiteId
  }

  @action
  closeCreateCalendarDialog = () => {
    this.createNewCalendar = false
    this.newCalendarName = ''
    this.calendarSite = ''
    this.calendarSiteId = ''
  }

  @action
  closeDisconnectCalendarDialog = () => {
    this.disconnectCalendarDialog = false
    this.calendarName = ''
    this.calendarSite = ''
  }

  @action
  setNewCalendarName = evt => {
    this.newCalendarName = evt.target.value
  }

  @action
  createCalendar = async () => {
    try {
      this.isCreatingCalendar = true
      const { data: calendar } = await calendarApi.create(this.venue.id, {
        summary: this.newCalendarName
      })
      this.venue = {
        ...this.venue,
        calendars: [...this.venue.calendars, calendar]
      }

      switch (this.calendarSite) {
        case 'venues':
          await this.setCalendarToVenue(calendar.id)
          break
        case 'spaces':
          await this.setCalendarToSpace(this.calendarSiteId, calendar.id)
          break
      }

      this.isCreatingCalendar = false
      this.createNewCalendar = false
      this.newCalendarName = ''
      return true
    } catch (error) {
      return []
    }
  }

  @action
  disconnectCalendar = async () => {
    try {
      switch (this.calendarSite) {
        case 'venues':
          await this.setCalendarToVenue(null)
          break
        case 'spaces':
          await this.setCalendarToSpace(this.calendarSiteId, null)
          break
      }
      this.disconnectCalendarDialog = false
      return true
    } catch (error) {
      return []
    }
  }
}

export default new VenueStore()
