import moment from '../assets/vendor/moment-holiday.min'

export const generateHolidays = () => {
  const holidays = [
    "New Year's Day",
    'Martin Luther King Jr. Day',
    "Washington's Birthday",
    'Memorial Day',
    'Independence Day',
    'Labor Day',
    'Columbus Day',
    "Veteran's Day",
    'Thanksgiving Day',
    'Christmas Day'
  ]

  const holidayObject = moment().holidays(holidays)
  return Object.keys(holidayObject).map(holiday => ({
    name: holiday,
    date: holidayObject[holiday],
    isChecked: false
  }))
}
export const holidays = generateHolidays()
