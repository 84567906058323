import qs from 'qs'
import BaseApi from './baseApi'
import userStore from '../stores/user'

class CompanyApi extends BaseApi {
  constructor() {
    super({ routeName: 'companies' })
  }

  fetch = () => {
    return this.request(userStore.jwtToken).get()
  }

  fetchOne = companyId => {
    const url = `/${companyId}`
    return this.request(userStore.jwtToken).get(url)
  }

  create = (company = {}) => {
    return this.request(userStore.jwtToken).post('', company)
  }

  update = (company = {}) => {
    const url = `/${company.id}`
    return this.request(userStore.jwtToken).put(url, { company })
  }

  destroy = companyId => {
    const url = `/${companyId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  deactivate = companyId => {
    const url = `/${companyId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  getTopPerformingVenues = companyId => {
    const url = `/${companyId}/topPerformingVenues`
    return this.request(userStore.jwtToken).get(url)
  }

  getVenues = (companyId, params) => {
    const url = `/${companyId}/venues`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getContacts = (companyId, params) => {
    const url = `/${companyId}/contacts`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchContact = (companyId, contactId) => {
    const url = `/${companyId}/contact/${contactId}`
    return this.request(userStore.jwtToken).get(url)
  }

  createContact = (companyId, params) => {
    const url = `/${companyId}/contact`
    return this.request(userStore.jwtToken).post(url, params)
  }

  deleteContact = (companyId, contactId) => {
    const url = `/${companyId}/contact/${contactId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  deleteVenueContacts = (companyId, contactId) => {
    const url = `/${companyId}/contact/${contactId}/allVenues`
    return this.request(userStore.jwtToken).delete(url)
  }

  updateContact = (companyId, contactId, params) => {
    const url = `/${companyId}/contact/${contactId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  getEmployees = (companyId, params) => {
    const url = `/${companyId}/employees`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchEmployee = (companyId, employeeId) => {
    const url = `/${companyId}/employee/${employeeId}`
    return this.request(userStore.jwtToken).get(url)
  }

  createEmployee = (companyId, params) => {
    const url = `/${companyId}/employee`
    return this.request(userStore.jwtToken).post(url, params)
  }

  updateEmployee = (companyId, employeeId, params) => {
    const url = `/${companyId}/employee/${employeeId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  getAbility = companyId => {
    const url = `/${companyId}/ability`
    return this.request(userStore.jwtToken).get(url)
  }

  setVenueEmployeeIsActive = (companyId, venueId, employeeId, params) => {
    const url = `${companyId}/venue/${venueId}/employee/${employeeId}/isActive`
    return this.request(userStore.jwtToken).put(url, params)
  }

  importCompanyContacts = (companyId, data) => {
    const url = `${companyId}/contacts/bulk-import`
    return this.request(userStore.jwtToken).post(url, data)
  }

  getAllWizardStates = (companyId, params) => {
    const url = `/${companyId}/wizardStates`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  createWizardState = (companyId, params) => {
    const url = `/${companyId}/wizardStates`
    return this.request(userStore.jwtToken).post(url, params)
  }

  getWizardStateById = (companyId, stateId, params = {}) => {
    const url = `/${companyId}/wizardStates/${stateId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  updateWizardState = (companyId, stateId, params) => {
    const url = `/${companyId}/wizardStates/${stateId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  deleteWizardState = (companyId, stateId) => {
    const url = `/${companyId}/wizardStates/${stateId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  createVenue = ({ companyId, formData }) => {
    const url = `/${companyId}/venues`
    return this.request(userStore.jwtToken).post(url, formData)
  }

  getVenuePreview = (companyId, id, params = {}) => {
    const url = `/${companyId}/venues/preview/${id}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

export default new CompanyApi()
