const styles = theme => ({
  avatarRoot: {
    height: 36,
    width: 36
  },
  searchInputContainer: {
    paddingRight: theme.spacing.unit * 3
  },
  headerDivider: {
    borderLeft: '1px solid white',
    height: 30,
    opacity: 0.5
  },
  addCompany: {
    height: 54,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#FF7F85'
  },
  userProfile: {
    height: 84,
    width: 254,
    paddingTop: 0,
    paddingBottom: 0
  },
  userProfileAvatarWrapper: {
    paddingRight: theme.spacing.unit * 2
  },
  nameText: {
    color: '#34495E',
    fontSize: 14,
    lineHeight: '16px',
    marginBottom: theme.spacing.unit * 0.5
  },
  emailText: {
    opacity: 0.5,
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 178,
    marginBottom: theme.spacing.unit * 0.5
  },
  locationText: {
    opacity: 0.5,
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 178,
    paddingLeft: theme.spacing.unit * 0.5
  },
  listItemAvatarWrapper: {
    paddingRight: theme.spacing.unit
  },
  listItemContentWrapper: {
    maxWidth: 208
  },
  listItemName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    lineHeight: '14px',
    marginBottom: theme.spacing.unit * 0.5
  },
  locationIcon: {
    color: '#34495E',
    opacity: 0.5,
    fontSize: 12
  },
  listItemLocation: {
    opacity: 0.5,
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 196,
    paddingLeft: theme.spacing.unit * 0.5
  },
  listItemEmail: {
    opacity: 0.5,
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 248
  },
  viewProfileText: {
    fontSize: 12,
    lineHeight: '16px'
  },
  listItem: {
    height: 54,
    width: 254,
    paddingTop: 0,
    paddingBottom: 0
  },
  addCompanyText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px'
  },
  logoutText: {
    color: '#34495E',
    fontSize: 14,
    lineHeight: '16px'
  }
})

export default styles
