import BaseApi from './baseApi'
import userStore from '../stores/user'
import qs from 'qs'

class TourApi extends BaseApi {
  constructor() {
    super({ routeName: 'tours' })
  }

  fetch = filters => {
    return this.request(userStore.jwtToken).get('', {
      params: {
        filters
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchOne = tourId => {
    const url = `/${tourId}`
    return this.request(userStore.jwtToken).get(url)
  }

  getAll = ({ limit = 20 }) => {
    return this.request(userStore.jwtToken).get('', {
      params: { limit }
    })
  }

  getMyTours = params => {
    const url = '/my'
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  create = tour => {
    return this.request(userStore.jwtToken).post('', tour)
  }

  update = tour => {
    return this.request(userStore.jwtToken).put(`/${tour.id}`, tour)
  }

  cancelTour = tourId => {
    return this.request(userStore.jwtToken).post(`cancel`, {
      tour_id: tourId
    })
  }

  sendTourDetails = (tourId, recipients) => {
    return this.request(userStore.jwtToken).post('email', {
      tour_id: tourId,
      recipients
    })
  }

  getForVenue = (venueId, limit) => {
    return this.request(userStore.jwtToken).get(`venue/${venueId}`, {
      params: { limit }
    })
  }
}

const tourApi = new TourApi()
export default tourApi
