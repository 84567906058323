import BaseApi from './baseApi'
import userStore from '../stores/user'

class AuthApi extends BaseApi {
  constructor() {
    super({ routeName: 'auth' })
  }

  /**
   * @type POST
   * @argument email
   * @argument password
   */
  login = (email, password) => {
    const payload = { email, password }

    return this.request().post('login', payload)
  }

  register = (email, password, first_name, last_name, redirectTo) => {
    const payload = {
      first_name,
      last_name,
      email,
      password,
      redirectTo
    }
    return this.request().post(`register`, payload)
  }

  register = (email, password, first_name, last_name, redirectTo) => {
    const payload = {
      first_name,
      last_name,
      email,
      password,
      redirectTo
    }
    return this.request().post(`register`, payload)
  }

  referralRegister = ({
    email,
    password,
    first_name,
    last_name,
    redirectTo,
    referral_code,
    referral_site
  }) => {
    const payload = {
      first_name,
      last_name,
      email,
      password,
      redirectTo,
      referral_code,
      referral_site
    }
    return this.request().post(`referral_register`, payload)
  }

  requestPasswordReset = email => {
    const payload = {
      email
    }
    return this.request().post(`forgot_password`, payload)
  }

  resetPassword = (email, resetToken, password, passwordConfirm) => {
    const payload = {
      email,
      token: resetToken,
      password,
      password_confirmation: passwordConfirm
    }
    return this.request().post('/reset_password', payload)
  }

  changePassword = (userId, password, newPassword, newPasswordConfirm) => {
    const payload = {
      password,
      new_password: newPassword,
      new_password_confirm: newPasswordConfirm
    }
    return this.request(userStore.jwtToken).post(
      `/${userId}/change_password`,
      payload
    )
  }

  deactivateAccount = userId => {
    return this.request(userStore.jwtToken).post(
      `/${userId}/deactivate_account`
    )
  }

  verifyEmail = payload => {
    return this.request().post('/verify_email', payload)
  }

  changeEmail = (userId, newEmail, newEmailConfirmation, password) => {
    return this.request(userStore.jwtToken).post(`/${userId}/change_email`, {
      password,
      new_email: newEmail,
      new_email_confirmation: newEmailConfirmation
    })
  }
}

export default new AuthApi()
