import { action, observable } from 'mobx'
import venueTypesApi from '../services/venueTypesApi'
import amenitiesApi from '../services/amenitiesApi'
import rulesApi from '../services/rulesApi'
import venueApi from '../services/venueApi'
import termsApi from '../services/termsApi'
import adminSpaceApi from '../services/admin/spaceApi'

import globalStore from './global'
import mediaStore from './media'

const initialForm = {
  name: '',
  floor: '',
  unit: '',
  spaceTypeId: null,
  seats: '',
  size: '',
  amenityIds: [],
  spacePhotos: [],
  floorPlanPhotos: [],
  spaceTerms: [],
  description: '',
  rules: []
}

class CreateSpaceStore {
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0

  @observable.deep
  space = null
  @observable
  isSavingSpace = false

  @observable.deep
  form = {
    ...initialForm
  }
  @observable
  formError = null

  @observable.deep
  venueTypeGroups = []
  @observable
  isLoadingVenueTypeGroups = false

  @observable.deep
  commonAreas = []
  @observable
  isLoadingCommonAreas = false

  @observable.deep
  amenities = []
  @observable
  isLoadingAmenities = false

  @observable.deep
  terms = []
  @observable
  isLoadingTerms = false

  @observable.deep
  rules = []
  @observable
  isLoadingRules = false

  constructor() {
    this.globalStore = globalStore
    this.mediaStore = mediaStore
  }

  @action.bound
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.form = {
      ...initialForm
    }
  }

  @action
  resetFormError = () => {
    this.formError = null
  }

  @action
  setForm = form => {
    this.form = form
  }

  @action
  setFormProp = (key, value) => {
    this.form[key] = value
  }

  @action
  loadVenueTypeGroups = async () => {
    this.isLoadingVenueTypeGroups = true
    try {
      if (!this.venueTypeGroups.length) {
        const {
          data: venueTypeGroups
        } = await venueTypesApi.getVenueTypeGroups()
        this.venueTypeGroups = venueTypeGroups
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueTypeGroups = false
    }
  }

  @action
  loadAmenities = async () => {
    this.isLoadingAmenities = true
    try {
      if (!this.amenities.length) {
        const { data: amenities } = await amenitiesApi.getAll()
        this.amenities = amenities
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingAmenities = false
    }
  }

  @action.bound
  async loadTerms() {
    this.isLoadingTerms = true
    try {
      if (!this.terms.length) {
        const { data: terms } = await termsApi.getTerms()
        this.terms = terms
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingTerms = false
    }
  }

  @action
  loadRules = async () => {
    this.isLoadingRules = true
    try {
      if (!this.rules.length) {
        const { data: rules } = await rulesApi.getAll()
        this.rules = rules
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingRules = false
    }
  }

  @action
  updateMediaInfo = async (currentMedia, info, formProp) => {
    const media = await this.mediaStore.update(currentMedia.id, info)
    if (media) {
      this.form[formProp] = this.form[formProp].map(currentMedia => {
        return currentMedia.id === media.id ? media : currentMedia
      })
    } else {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again.`
      )
    }
  }

  @action
  createSpace = async venueId => {
    this.isSavingSpace = true
    let result = null
    try {
      result = await venueApi.createSpace({ venueId, formData: this.form })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.space = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${error.data.error}`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingSpace = false
    }
    return this.space
  }

  @action
  updateBookingTerms = async (venueId, spaceId) => {
    this.isSavingSpace = true
    let result = null
    try {
      result = await adminSpaceApi.updateTerms(venueId, spaceId, {
        spaceTerms: this.form.spaceTerms
      })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.space = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${error.data.error}`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingSpace = false
    }
    return this.space
  }
}

export default new CreateSpaceStore()
