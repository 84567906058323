import { observable, action, runInAction } from 'mobx'
import WebSocketStore from './webSocketStore'
import userStore from '../stores/user'
import userApi from '../services/userApi'
import adminVenueApi from '../services/admin/venueApi'
import conversationApi from '../services/admin/conversationApi'
import PLACE_TYPES from '../constants/PLACE_TYPES'
import globalStore from './global'
import { trackEvent } from '@utilities/trackingUtils'

const WS_CHANNEL_NAME = 'chat'

class ChatStore {
  @observable
  currentMessages = []

  @observable
  currentConversations = []
  @observable
  archivedConversations = []
  @observable
  unreadMessageCount = 0
  @observable
  isLoadingConversations = false
  @observable
  archived = 0

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable.deep
  filters = {
    sort: 'desc'
  }

  @observable
  isChatConnected = false

  constructor() {
    this._webSocketStore = WebSocketStore
    this._userStore = userStore
  }

  @action
  wsSubscribe = (conversationId, venueId) => {
    this.wsSubscription = this._webSocketStore.subscribe(
      WS_CHANNEL_NAME,
      conversationId
    )
    this._hooks({ subscription: this.wsSubscription, conversationId, venueId })
  }

  wsUnsubscribe = () => {
    if (this.wsSubscription) {
      this.wsSubscription.close()
      this.wsSubscription = null
    }
  }

  _hooks = ({ subscription, conversationId, venueId = null }) => {
    subscription.on('ready', () => {
      subscription.emit('initChat', { conversationId, venueId })
      this.isChatConnected = true
      this.chatSubscriptionError = null
    })
    subscription.on('error', error => {
      // eslint-disable-next-line
      console.error('spacemx ws Chat error', error)
      this.isChatConnected = false
      this.chatSubscriptionError = 'There was an issue. Please refresh.'
    })
    subscription.on('close', () => {
      // eslint-disable-next-line
      console.info('spacemx ws Chat closed')
      this.isChatConnected = false
    })
  }

  @action
  setArchived = archived => {
    runInAction(() => {
      this.archived = archived
    })
  }

  @action
  setFilter = (key, value) => {
    runInAction(() => {
      this.filters[key] = value
    })
  }

  @action
  getVenueConversations = async venueId => {
    this.isLoadingConversations = true
    try {
      const response = await adminVenueApi.getVenueConversations(venueId, {
        archived: this.archived,
        pagination: this.pagination,
        filters: this.filters
      })
      const {
        data: { conversations = [], unreadMessageCount = 0, metadata = {} }
      } = response
      this.currentConversations = conversations
      this.unreadMessageCount = unreadMessageCount
      this.pagination = metadata.pagination
    } catch (error) {
      console.error(error)
      globalStore.openNotificationSnackbar(
        "There was a problem fetching the venue's conversations."
      )
    } finally {
      this.isLoadingConversations = false
    }
  }

  @action
  getMyConversations = async () => {
    this.isLoadingConversations = true
    const response = await userApi.fetchConversations({
      archived: this.archived,
      pagination: this.pagination,
      filters: this.filters
    })
    runInAction(() => {
      const {
        data: { conversations = [], unreadMessageCount = 0, metadata = {} }
      } = response
      this.currentConversations = conversations
      this.unreadMessageCount = unreadMessageCount
      this.pagination = metadata.pagination
      this.isLoadingConversations = false
    })
  }

  @action
  createConversation = async (
    message,
    objectId,
    objectType,
    guestId = null,
    spaceId = null,
    isFromOperator = false
  ) => {
    if (!PLACE_TYPES.includes(objectType))
      throw new Error('Must be a valid place type.')
    const { data: conversation } = await conversationApi.create({
      message,
      objectId,
      objectType,
      guestId,
      spaceId,
      isFromOperator
    })

    trackEvent({
      title: 'contact',
      data: `${isFromOperator ? 'member' : 'owner'} contacted`,
      overrides: { fb: ['contact venue'] }
    })

    return conversation
  }

  @action
  archiveConversation = async (conversationId, archived) => {
    await conversationApi.archive(conversationId, archived)
    this.getMyConversations()
  }

  @action
  destroyConversation = async conversationId => {
    await conversationApi.destroy(conversationId)
    this.getMyConversations()
  }

  @action
  archiveVenueConversation = async (venueId, conversationId, archived) => {
    await adminVenueApi.archiveVenueConversation(
      venueId,
      conversationId,
      archived
    )
    this.getVenueConversations(venueId)
  }

  @action
  destroyVenueConversation = async (venueId, conversationId) => {
    await adminVenueApi.deleteVenueConversation(venueId, conversationId)
    this.getVenueConversations(venueId)
  }

  @action
  handleChangePage = page => {
    this.pagination = {
      ...this.pagination,
      page
    }
  }
}

export default new ChatStore()
