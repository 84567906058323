import { action, observable } from 'mobx'
import adminTourApi from '../../services/admin/tourApi'
import bookingApi from '../../services/admin/bookingApi'
import venueApi from '../../services/venueApi'
import adminVenueApi from '../../services/admin/venueApi'
import GoogleOAuth from '../../services/googleOauth'
import moment from 'moment-timezone'

class CalendarStore {
  @observable
  tours = []

  @observable
  bookings = []

  @observable
  venues = []

  @observable
  updatingFilters = false

  @observable.deep
  filters = {
    viewTypeForTour: 'all',
    viewTypeForBooking: 'all',
    dateTypeForTour: 'day',
    dateTypeForBooking: 'day',
    currentStartDateValueForTour: moment().startOf('day'),
    currentEndDateValueForTour: moment().endOf('day'),
    currentStartDateValueForBooking: moment().startOf('day'),
    currentEndDateValueForBooking: moment().endOf('day'),
    showExternalForTour: false,
    showExternalForBooking: false,
    venue: {
      name: '',
      address: {
        address: '',
        state: {
          name: ''
        },
        zipCode: {
          code: ''
        },
        city: {}
      }
    }
  }

  @observable
  isLoadingGoogleOauth = false
  @observable
  googleOauth = null

  @observable
  isLoadingGoogleAccountInfo = false
  @observable.deep
  googleAccountInfo = {}

  @action
  updateFilter = async (filter, value) => {
    this.filters = {
      ...this.filters,
      [filter]: value
    }
    await this.fetchTours()
    await this.fetchBookings()
    await this.getGoogleOAuthToken(this.filters.venue.id)
  }

  @action
  updateFilterForTour = async (filter, value, skipFetch = false) => {
    if (filter === 'dateTypeForTour') {
      this.filters.currentStartDateValueForTour = moment().startOf(value)
      this.filters.currentEndDateValueForTour = moment().endOf(value)
    }

    this.filters = {
      ...this.filters,
      [filter]: value
    }

    if (!skipFetch) {
      await this.fetchTours()
    }
  }

  @action
  updateFilterForBooking = async (filter, value, skipFetch = false) => {
    if (filter === 'dateTypeForBooking') {
      this.filters.currentStartDateValueForBooking = moment().startOf(value)
      this.filters.currentEndDateValueForBooking = moment().endOf(value)
    }

    this.filters = {
      ...this.filters,
      [filter]: value
    }

    if (!skipFetch) {
      await this.fetchBookings()
    }
  }

  @action
  fetchTours = async () => {
    this.updatingFilters = true
    try {
      const {
        viewTypeForTour,
        dateTypeForTour,
        venue,
        currentStartDateValueForTour,
        currentEndDateValueForTour,
        showExternalForTour
      } = this.filters

      const filtersForTour = {
        view_type: viewTypeForTour,
        date_type: dateTypeForTour,
        start_date_value: currentStartDateValueForTour.format(),
        end_date_value: currentEndDateValueForTour.format(),
        venue_id: venue.id,
        show_external: showExternalForTour
      }

      const {
        data: { tours = [] }
      } = await adminTourApi.fetch({
        venueId: venue.id,
        filters: filtersForTour
      })
      this.tours = tours.map(tour => ({
        ...tour,
        start_date: moment.utc(tour.start_date).tz(venue.timezone),
        end_date: moment.utc(tour.end_date).tz(venue.timezone)
      }))
      return true
    } catch (error) {
      console.error(error)
      return []
    } finally {
      this.updatingFilters = false
    }
  }

  @action
  fetchBookings = async () => {
    this.updatingFilters = true
    try {
      const {
        viewTypeForBooking,
        dateTypeForBooking,
        venue,
        currentStartDateValueForBooking,
        currentEndDateValueForBooking,
        showExternalForBooking
      } = this.filters

      const filtersForBooking = {
        view_type: viewTypeForBooking,
        date_type: dateTypeForBooking,
        start_date_value: currentStartDateValueForBooking.format(),
        end_date_value: currentEndDateValueForBooking.format(),
        venue_id: venue.id,
        show_external: showExternalForBooking
      }

      const {
        data: { bookings = [] }
      } = await bookingApi.fetch(filtersForBooking)
      this.bookings = bookings.map(booking => {
        const { timezone } = venue

        const isHourly = booking.term_name === 'Hourly'
        const start_date = moment
          .utc(booking.start_date, 'YYYY-MM-DD HH:mm:ss')
          .tz(timezone, !isHourly)
        const end_date = moment
          .utc(booking.end_date, 'YYYY-MM-DD HH:mm:ss')
          .tz(timezone, !isHourly)

        return {
          ...booking,
          start_date,
          end_date
        }
      })
      return true
    } catch (error) {
      console.error(error)
      return []
    } finally {
      this.updatingFilters = false
    }
  }

  @action
  setGoogleOAuthToken = async (venueId, oauthToken) => {
    try {
      const { data: updatedVenue } = await venueApi.setGoogleAccount(venueId, {
        venue: { google_auth_token: oauthToken }
      })
      this.filters.venue = updatedVenue
      this.getGoogleOAuthToken(this.filters.venue.id)
      return true
    } catch (error) {
      return []
    }
  }

  @action
  getGoogleOAuthToken = async venueId => {
    try {
      this.isLoadingGoogleAccountInfo = true
      const { data: googleAccountInfo } = await venueApi.getGoogleAccount(
        venueId
      )
      this.googleAccountInfo = googleAccountInfo
      return true
    } catch (error) {
      console.error(error)
      return []
    } finally {
      this.isLoadingGoogleAccountInfo = false
    }
  }

  //This must be moved to venue store and moved the fetch action to the constructor
  @action
  initLoad = async initialVenueId => {
    try {
      const { data: venue } = await adminVenueApi.fetchOne(initialVenueId)
      if (venue) {
        this.filters = {
          ...this.filters,
          venue
        }
        await this.fetchTours()
        await this.fetchBookings()
        await this.initGoogleOauth()
        await this.getGoogleOAuthToken(this.filters.venue.id)
      }
      return true
    } catch (error) {
      return []
    }
  }

  @action
  initGoogleOauth = () => {
    this.isLoadingGoogleOauth = true
    GoogleOAuth.init(oauthInstance => {
      this.googleOauth = oauthInstance
      this.isLoadingGoogleOauth = false
    })
  }

  @action
  googleSignIn = async venue => {
    const code = await this.googleOauth.grantOfflineAccess({
      prompt: 'consent'
    })
    await this.setGoogleOAuthToken(venue.id, code)
    return this.filters.venue
  }

  @action
  googleSignOut = () => {
    // const { googleOauth } = this.state
    // // googleOauth
    // //   .signOut()
    // //   .then(() => this.setState({ currentGoogleAccount: {} }))
  }
}

export default new CalendarStore()
