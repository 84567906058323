import { observable, action, when } from 'mobx'
import UserStore from './user'
import spaceApi from '../services/spaceApi'

class BookSpaceStore {
  @observable
  isSpaceBookingStepperVisible = false

  @observable
  activeStep = 0

  @observable
  activeSubStep = 0

  @observable
  previousStep = 0

  @observable
  previousSubStep = 0

  @observable
  isFinished = false

  @observable
  isSubmitting = false

  @observable
  specialRequirements = ''
  @observable
  individualBilling = false

  @observable
  useLastTransaction = false

  @observable
  venueBilling = false

  @observable
  suggestedSpaces = []

  @observable
  isFetchingSuggestedSpaces = false

  @observable
  selectedTermId = null

  @observable
  selectedDates = null

  @observable
  resetFormOnMount = true

  constructor() {
    this.userStore = UserStore
  }

  @action
  openSpaceBookingStepper = (resetFormOnMount = true) => {
    if (!this.userStore.loggedIn) {
      this.userStore.openAuthDialog('login')
      when(
        () => this.userStore.loggedIn,
        () => (this.isSpaceBookingStepperVisible = true)
      )
      return
    }

    this.resetFormOnMount = resetFormOnMount
    this.isSpaceBookingStepperVisible = true
  }

  @action
  closeSpaceBookingStepper = () => {
    this.isSpaceBookingStepperVisible = false
  }

  @action
  cancelBookingStepper = () => {
    this.closeSpaceBookingStepper()
  }

  @action.bound
  setUseLastTransaction(value) {
    this.useLastTransaction = value
  }

  @action.bound
  setIndividualBilling(value) {
    this.individualBilling = value
  }

  @action.bound
  setVenueBilling(value) {
    this.venueBilling = value
  }

  @action.bound
  setSpecialRequirements(value) {
    this.specialRequirements = value
  }

  @action.bound
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.specialRequirements = ''
    this.individualBilling = false
    this.venueBilling = false
    this.useLastTransaction = false
  }

  @action
  fetchSuggestedSpaces = async ({
    spaceId,
    venueId,
    startDate,
    endDate,
    hourlyDateTimes = null,
    termId
  }) => {
    this.isFetchingSuggestedSpaces = true
    try {
      if (hourlyDateTimes) {
        hourlyDateTimes = hourlyDateTimes.map(hourly_date_time => ({
          start_time: hourly_date_time.startTime,
          end_time: hourly_date_time.endTime,
          ...hourly_date_time
        }))
      }
      const { data } = await spaceApi.fetchSuggestedSpaces({
        spaceId,
        venueId,
        startDate,
        endDate,
        hourlyDateTimes,
        termId
      })
      this.suggestedSpaces = data
    } catch (error) {
      console.error(error)
    } finally {
      this.isFetchingSuggestedSpaces = false
    }
  }
}

export default new BookSpaceStore()
