import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'

/**
 *
 * @param {string} title - Name for the vent
 * @param {Object} data - Data for the event
 * @param {Object} overrides - Overrides for GA or FB specifics
 */
export function trackEvent({ title, data, overrides = {} }) {
  const { ga: [gaTitle, gaData] = [], fb: [fbTitle, fbData] = [] } = overrides
  if (!title || !data) return
  ReactGA.event({
    category: gaTitle || title,
    action: gaData || data
  })
  ReactPixel.trackCustom(fbTitle || title, fbData || data)
}
