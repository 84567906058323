import scheduleDays from './scheduleDays'

export { holidays } from '../../utilities/holidayUtils'

export default {
  basicInfoForm: {
    address: { value: null, error: null },
    amenities: { value: null, error: null },
    commonAreas: { value: null, error: null },
    commonAreaSquareFeet: { value: null, error: null },
    customCommonAreas: { value: null, error: null },
    description: { value: null, error: null },
    is_published: { value: null, error: null },
    mapPinCoordinates: { value: null, error: null },
    name: { value: null, error: null },
    requirements: { value: null, error: null },
    seats: { value: null, error: null },
    spaceCultures: { value: null, error: null },
    spaceId: { value: null, error: null },
    squareFeet: { value: null, error: null },
    unit_number: { value: null, error: null },
    venueDescription: { value: null, error: null },
    venueName: { value: null, error: null },
    venueId: { value: null, error: null },
    venueTypeGroup: { value: null, error: null },
    venueType: { value: null, error: null },
    spaceType: { value: null, error: null },
    companyId: { value: null, error: null }
  },
  virtualTourForm: {
    spacePhotos: { value: null, error: null },
    venuePhotos: { value: null, error: null },
    commonAreaPhotos: { value: null, error: null },
    floorPlanPhoto: { value: null, error: null }
  },
  bookingSettingsForm: {
    spaceTerms: { value: null, error: null },
    timezone: { value: null, error: null },
    scheduleDays: {
      value: [...scheduleDays],
      error: null
    },
    holidays: { value: null, error: null },
    timeframes: { value: null, error: null },
    daysToInclude: { value: null, error: null },
    maxFutureBookingMonths: { value: 12, error: null },
    buffer: { value: null, error: null },
    rules: { value: null, error: null }
  }
}

export const ignoreList = [
  'amenities',
  'commonAreas',
  'commonAreaSquareFeet',
  'customCommonAreas',
  'mapPinCoordinates',
  'seats',
  'squareFeet',
  'unit_number',
  'description',
  'spaceCultures',
  'requirements',
  'is_published',
  'commonAreaPhotos',
  'floorPlanPhoto',
  'scheduleDays',
  'holidays',
  'maxFutureBookingMonths',
  'buffer',
  'rules'
]
