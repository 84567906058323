const stripe_redirect_uri =
  process.env.REACT_APP_APP_URL +
  process.env.REACT_APP_STRIPE_OAUTH_REDIRECT_URI
const stripe_client_id = process.env.REACT_APP_STRIPE_CLIENT_ID
const connectUrl = 'https://connect.stripe.com/express/oauth/authorize'

//https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://stripe.com/connect/default/oauth/test&client_id=ca_DI5IB6cmhIt4p6QKsOVROhOFwGqZvZEl&state={STATE_VALUE}
/**
 *
 * @param {state} {state} unique identifier for security
 * @param {stripe_user_params} {stripe_user_params} ref: https://stripe.com/docs/connect/oauth-reference#express-account-differences
 */
export const generateStripeOAuthLink = ({
  state,
  stripe_user_params = null
}) => {
  let generatedURI = ''
  if (!stripe_client_id) throw new Error('STRIPE CLIENT ID IS REQUIRED')
  if (!stripe_redirect_uri) throw new Error('STRIPE REDIRECT URI IS REQUIRED')
  //state = { ...state, smx_token: uuidv4() }
  state = btoa(`spacemx-entity-id:${state}`)
  generatedURI = `${connectUrl}?redirect_uri=${stripe_redirect_uri}&client_id=${stripe_client_id}&state=${state}&suggested_capabilities[]=card_payments&suggested_capabilities[]=transfers`
  if (stripe_user_params) {
    Object.keys(stripe_user_params).forEach(key => {
      generatedURI += `&stripe_user[${key}]=${stripe_user_params[key]}`
    })
  }
  return generatedURI
}
