module.exports = new (function() {
  this.SPACE = 'spaces'
  this.VENUE = 'venues'
  this.includes = function(itemToCompare) {
    return Object.values(this).includes(itemToCompare)
  }
  this.some = function(itemToCompare) {
    return Object.values(this).some(item => item === itemToCompare)
  }
  this.legalName = function(itemToCompare) {
    const placeIndex = Object.values(this).indexOf(itemToCompare)
    const place = Object.keys(this)[placeIndex]
    return place.charAt(0).toUpperCase() + place.slice(1).toLowerCase()
  }
})()
