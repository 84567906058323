import BaseApi from './baseApi'
import { createWithParams } from '../utilities/apiUtils'
import userStore from '../stores/user'
import qs from 'qs'

class SpaceApi extends BaseApi {
  constructor() {
    super({ routeName: 'spaces' })
  }

  getById = (spaceId, include) => {
    if (!spaceId) return Error('No space id provided')
    const url = `/${spaceId}${include ? createWithParams(include) : ''}`
    return this.request(userStore.jwtToken).get(url)
  }

  getFeaturedSpace = () => {
    const url = `/featured`
    return this.request().get(url)
  }

  getByHome = (lat, lng, carouselLimit = 3) => {
    if (!lat || !lng) return Error('Must provide both lat and lng')
    const body = { lat, lng, carouselLimit }
    return this.request().post('/home', body)
  }

  getByLatLng = (
    lat,
    lng,
    spaceTypeId,
    termId,
    startDate,
    endDate,
    page,
    limit
  ) => {
    if (!lat || !lng) return Error('Must provide both lat and lng')
    const body = { lat, lng, page, limit }
    if (spaceTypeId && spaceTypeId !== 'All Spaces')
      body.spaceTypeId = spaceTypeId
    if (termId && termId !== 'All Terms') body.termId = termId
    if (startDate) body.startDate = startDate
    if (endDate) body.endDate = endDate
    return this.request().post('/latlng', body)
  }

  getByBounds = (
    bounds,
    venueTypeGroupId,
    venueTypeId,
    spaceTypeId,
    termId,
    startDate,
    endDate,
    page,
    limit,
    price
  ) => {
    if (!bounds) return Error('Must provide bounds')
    const NE = bounds.getNorthEast()
    const SW = bounds.getSouthWest()
    const body = {
      NE: {
        lat: NE.lat(),
        lng: NE.lng()
      },
      SW: {
        lat: SW.lat(),
        lng: SW.lng()
      },
      page,
      limit
    }
    if (venueTypeGroupId) body.venueTypeGroupId = venueTypeGroupId
    if (venueTypeId) body.venueTypeId = venueTypeId
    if (spaceTypeId && spaceTypeId !== 'All Spaces')
      body.spaceTypeId = spaceTypeId
    if (termId && termId !== 'All Terms') body.termId = termId
    if (startDate) body.startDate = startDate
    if (endDate) body.endDate = endDate
    if (price) body.price = price
    return this.request().post('/bounds', body)
  }

  syncCalendar = spaceId => {
    return this.request().post(`/${spaceId}/sync-calendar`, {})
  }

  create = space => {
    if (!space) return Error('No space data provided')
    return this.request(userStore.jwtToken).post('', space)
  }

  fetchSuggestedSpaces = ({
    spaceId,
    venueId,
    startDate,
    endDate,
    hourlyDateTimes,
    termId
  }) => {
    return this.request().post(`/${spaceId}/suggested-spaces`, {
      venueId,
      startDate,
      endDate,
      hourlyDateTimes,
      termId
    })
  }

  getSpacesByVenueType = (venueTypeId, params = {}) => {
    if (!venueTypeId) return Error('No venue type id provided')
    const url = `/getByVenueType/${venueTypeId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getSpacesBySpaceType = (spaceTypeId, params = {}) => {
    if (!spaceTypeId) return Error('No space type id provided')
    const url = `/getBySpaceType/${spaceTypeId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

export default new SpaceApi()
