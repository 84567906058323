import React from 'react'
import {
  Snackbar,
  IconButton,
  SnackbarContent,
  withStyles
} from '@material-ui/core'
import { Close, Refresh } from '@material-ui/icons'
import { inject, observer } from 'mobx-react'

const styles = () => ({
  globalSnackBarRoot: {
    backgroundColor: 'rgb(52, 73, 93)',
    marginTop: 8
  },
  globalSnackBarIcon: {
    fontSize: 20
  },
  globalSnackBarIconVariant: {
    opacity: 0.9,
    marginRight: 8
  },
  globalSnackBarMessage: {
    display: 'flex',
    alignItems: 'center'
  }
})

const GlobalSnackBar = inject('globalStore')(
  observer(({ globalStore, open, classes }) => {
    const handleClose = () => {
      globalStore.setGlobalSnackBarOpen(false)
    }

    const handleRefresh = () => {
      console.log('Clearing cache and hard reloading...')
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function(names) {
          for (const name of names) caches.delete(name)
        })
      }
      // delete browser cache and hard reload
      window.location.reload(true)
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={`globalStepper`}
        open={open}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classes.globalSnackBarRoot}
          aria-describedby="global-snackbar"
          message={
            <span id="message-id">
              New content is available; please refresh.
            </span>
          }
          action={[
            <IconButton
              key="refresh"
              aria-label="refresh"
              color="inherit"
              onClick={handleRefresh}
            >
              <Refresh className={classes.globalSnackBarIcon} />
            </IconButton>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close className={classes.globalSnackBarIcon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    )
  })
)

export default withStyles(styles)(GlobalSnackBar)
