export default {
  basicInfoForm: {
    address: validateValue,
    city: validateValue,
    commonAreas: validateLength,
    commonAreaSquareFeet: validateValue,
    country: validateValue,
    mapPinCoordinates: validateValue,
    seats: validateValue,
    spaceTypes: validateLength,
    squareFeet: validateValue,
    state: validateValue,
    street: validateValue,
    venueId: validateValue,
    venueTypes: validateLength,
    zipcode: validateValue,
    name: validateValue,
    description: validateValue,
    spaceCultures: validateLength,
    requirements: validateValue,
    is_published: validateValue
  },
  virtualTourForm: {
    spacePhotos: validateLength,
    venuePhotos: validateLength,
    commonAreaPhotos: validateLength,
    floorPlanPhoto: validateLength
  },
  bookingSettingsForm: {
    spaceTerms: validateTermsHasDefaultAndLength, //validateLength,
    timezone: validateValue,
    scheduleDays: validateScheduleDays,
    holidays: validateLength,
    maxFutureBookingMonths: validateNumber,
    buffer: validateValue,
    rules: validateLength,
    timeframes: validateValue
  }
}

export function isLocalDev() {
  return window.location.hostname === 'localhost'
}

export function isFormValid(form, isSingleValue = false) {
  if (isSingleValue) {
    return !form.error && _isFormValueTrue(form.value)
  }
  const nonValid = Object.values(form).filter(({ value, error }) => {
    return !!error || value == null
  })
  return nonValid.length === 0
}

function _isFormValueTrue(value) {
  if (value === null) {
    return false
  }
  if (typeof value === 'number') {
    return value >= 0
  }
  return value.length || Object.entries(value).length
}

function validateScheduleDays(scheduleDays) {
  const areAnyDaysChecked = scheduleDays.some(
    scheduleDay => scheduleDay.isChecked
  )
  return areAnyDaysChecked ? null : Error('You must select at least one day')
}

function validateTermsHasDefaultAndLength(terms) {
  return terms.some(term => term.is_default === true) &&
    !validateLength(terms.length)
    ? null
    : Error('You must select a default term')
}

function validateValue(value) {
  return !valueExists(value) ? Error('Must not be empty') : null
}

function valueExists(value) {
  return value === null || value.toString().trim() === '' ? false : true
}

function validateLength(value, length) {
  return value && value.length >= length ? Error('Must not be empty') : null
}

function validateNumber(number) {
  return !isNaN(Number(number))
}
