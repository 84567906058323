import BaseApi from './baseApi'
import userStore from '../stores/user'

class CommonAreasApi extends BaseApi {
  constructor() {
    super({ routeName: 'commonAreas' })
  }

  getAll = () => {
    return this.request(userStore.jwtToken).get()
  }
}

export default new CommonAreasApi()
