import { observable, computed, action, runInAction } from 'mobx'
import WebSocketStore from './webSocketStore'
import userStore from './user'
import notificationApi from '../services/notificationApi'
import { CHANNELS, EVENTS } from '../constants/WEBSOCKETS'
class NotificationStore {
  @observable
  wsSubscription = null
  @observable
  latestFiveNotifications = []
  @observable
  currentNotifications = []
  @observable
  isLoadingNotifications = false

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  constructor() {
    this._webSocketStore = WebSocketStore
    this._userStore = userStore
  }

  @action
  fetchNotifications = async personId => {
    this.isLoadingNotifications = true
    const {
      data: { notifications = [], metadata = {} }
    } = await notificationApi.fetchAll({
      personId,
      pagination: this.pagination
    })
    runInAction(() => {
      this.currentNotifications = notifications
      this.pagination = metadata.pagination
      this.isLoadingNotifications = false
    })
  }

  @action
  handleChangePage = page => {
    this.pagination = {
      ...this.pagination,
      page
    }
  }

  @computed
  get hasUnreadNotifications() {
    return this.latestFiveNotifications.some(
      notification => !notification.viewed_on
    )
  }
  @action
  wsSubscribe = personId => {
    this.wsSubscription = this._webSocketStore.subscribe(
      CHANNELS.NOTIFICATIONS,
      personId
    )
    this._hooks(this.wsSubscription)
  }

  wsUnsubscribe = () => {
    this.currentNotifications = []
    this.latestFiveNotifications = []
    if (this.wsSubscription) {
      this.wsSubscription.close()
      this.wsSubscription = null
    }
  }

  _hooks = subscription => {
    subscription.on(EVENTS.NOTIFICATIONS_NEW, latestFiveNotifications => {
      this.latestFiveNotifications = [...latestFiveNotifications]
    })
    // Keep for future reference
    //
    // subscription.on('ready', () => {
    //   console.info('spacemx ws Notifications ready')
    // })
    // subscription.on('error', error => {
    //   console.error('spacemx ws Notifications error', error)
    // })
    subscription.on('close', () => {
      // eslint-disable-next-line no-console
      console.info('spacemx ws Notifications closed')
      this.wsSubscription = null
    })
  }
}

export default new NotificationStore()
