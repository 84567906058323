import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { SingleDatePicker } from 'react-dates'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment-timezone'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { RemoveCircleOutline } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import DATE_FORMATS from '@constants/DATE_FORMATS'

const styles = theme => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing.unit * 2
  },
  timePickers: {
    fontSize: '14px'
  },
  hourFormContainer: {
    display: 'flex'
  },
  hourDropDown: {
    width: 115,
    '&:first-child': {
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up('m')]: {
        marginRight: theme.spacing.unit * 3
      }
    },
    [theme.breakpoints.up('md')]: {
      width: 100
    }
  },
  hourDateForm: {
    display: 'flex',
    position: 'relative'
  },
  removeButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 6,
      right: 0
    }
  }
})

@inject('bookingStore')
@observer
class HourlyDateItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      startTimeOpen: false,
      endTimeOpen: false,
      selectableHours: [],
      today: moment().tz(props.timezone, true)
    }
  }

  // Required for <SingleDatePicker>
  onFocusChange = ({ focused }) => {
    this.setState({ focused })
  }

  _endTimeLogic = (
    hour,
    index,
    day,
    selectableHours,
    firstDisabledHourIndex
  ) => {
    let isAvailable = !hour.disabled

    if (hour.disabled) {
      const previousHour = selectableHours[index - 1]
      isAvailable = Boolean(previousHour) && !previousHour.disabled
    }

    const {
      hour: { timestamp }
    } = hour
    return (
      isAvailable &&
      moment(timestamp, 'MM-DD-YYYY hh:mm:ss').get('hour') > day.startTime &&
      (firstDisabledHourIndex === -1 ||
        index <
          (isAvailable ? firstDisabledHourIndex + 1 : firstDisabledHourIndex))
    )
  }

  _truncatedStartTimes = selectableHours => {
    if (!selectableHours || !selectableHours.length) return []

    const truncatedSelectableHours = [...selectableHours]
    truncatedSelectableHours.length = truncatedSelectableHours.length - 1
    return truncatedSelectableHours
  }

  // Padding the end time is required to make the last hour in the selectableHours
  // array actually bookable. The API will take the very last hour and subtract a
  // second.
  _padEndTime = selectableHours => {
    if (!selectableHours) return []
    const paddedSelectableHours = [...selectableHours]
    const lastSelectableHour = selectableHours[selectableHours.length - 1]
    if (!lastSelectableHour) return []
    paddedSelectableHours.push({
      ...lastSelectableHour,
      hour: {
        available: true,
        timestamp: moment(
          lastSelectableHour.hour.timestamp,
          'MM-DD-YYYY HH:mm:ss'
        )
          .add(1, 'hour')
          .format('MM-DD-YYYY HH:mm:ss')
      }
    })
    return paddedSelectableHours
  }

  renderMenuItems(day, isEndTime = false) {
    const { selectableHours, isScheduleTour, timezone } = this.props
    const venueTimezone = timezone
      ? moment()
          .tz(timezone)
          .format('z')
      : ''
    let filteredHours

    if (isEndTime) {
      const paddedSelectableHours = this._padEndTime(selectableHours)
      // End time cannot be before start time
      const firstDisabledHourIndex = paddedSelectableHours.findIndex(hour => {
        return (
          moment(hour.hour.timestamp, 'MM-DD-YYYY hh:mm:ss').get('hour') >
            day.startTime && hour.disabled
        )
      })
      filteredHours = paddedSelectableHours.filter((hour, index) =>
        this._endTimeLogic(
          hour,
          index,
          day,
          selectableHours,
          firstDisabledHourIndex
        )
      )
      if (!filteredHours.length) {
        filteredHours = selectableHours.filter(({ hour: { timestamp } }) => {
          return (
            moment(timestamp, 'MM-DD-YYYY hh:mm:ss').get('hour') === day.endTime
          )
        })
      }
    } else {
      // Start time cannot be after end time
      filteredHours = selectableHours.filter(
        ({ hour: { timestamp } }) =>
          day.endTime === '' ||
          moment(timestamp, 'MM-DD-YYYY hh:mm:ss').get('hour') < day.endTime
      )
    }

    return filteredHours.length ? (
      filteredHours.map((filteredHour, filteredHourIndex) => {
        const {
          hour: { timestamp },
          disabled
        } = filteredHour
        const timestampMoment = moment(timestamp, 'MM-DD-YYYY hh:mm:ss')
        const timestampHour = timestampMoment.hour()
        if (
          moment(day.date).isSame(moment(), 'day') &&
          timestampHour <= moment().get('hour')
        ) {
          return null
        }
        const itemValue = !isScheduleTour
          ? timestampHour
          : timestampMoment.format('HHmm')
        return (
          <MenuItem
            key={`${filteredHourIndex}-${
              isEndTime ? 'end' : 'start'
            }-${timestampHour}`}
            value={itemValue}
            disabled={isEndTime ? false : disabled}
          >
            {!isScheduleTour ? (
              <div>
                {timestampMoment.format('h A')}{' '}
                <span style={{ color: 'rgba(0,0,0,0.5)' }}>
                  {venueTimezone}
                </span>
              </div>
            ) : (
              timestampMoment.format('hh:mm A')
            )}
          </MenuItem>
        )
      })
    ) : (
      <MenuItem value="">No hours available for this day</MenuItem>
    )
  }

  _isDayDisabled = day => {
    if (day.isBefore(this.state.today, 'day')) return true

    const { calendarBookingDays } = this.props
    const matchedDay = calendarBookingDays.find(calendarDay => {
      const calendarMoment = moment(calendarDay.date, 'MM-DD-YYYY')
      const isMatch = calendarMoment.isSame(day, 'day')
      return isMatch
    })
    return matchedDay
      ? matchedDay.every_hour_unavailable || !matchedDay.available
      : false
  }

  _handleStartTimeOpen = () => {
    const { resetEndTime = null } = this.props
    if (resetEndTime) resetEndTime()
    this.setState({ startTimeOpen: true })
  }

  _handleStartTimeClose = () => {
    this.setState({ startTimeOpen: false, endTimeOpen: true })
  }

  _handleEndTimeOpen = () => {
    this.setState({ endTimeOpen: true })
  }

  _handleEndTimeClose = () => {
    this.setState({ endTimeOpen: false })
  }

  _onDateChange = date => {
    const { onDateChange } = this.props
    onDateChange(date)
    this._handleStartTimeOpen()
  }

  render() {
    const {
      classes,
      day,
      dayIndex,
      isScheduleTour = false,
      onRemoveRow,
      onTimeChange,
      isMobileStepperEnabled,
      timezone,
      getInitialVisibleMonth,
      onNextMonthClick,
      onPreviousMonthClick,
      bookingStore: { isLoadingBookingCalendar }
    } = this.props
    const venueTimezone = timezone
      ? moment()
          .tz(timezone)
          .format(DATE_FORMATS.TIMEZONE)
      : ''
    return (
      <Fragment>
        <Grid
          container
          item
          className={classnames(classes.hourDateForm, classes.root)}
        >
          {isLoadingBookingCalendar && (
            <CircularProgress
              style={{
                width: 40,
                height: 40,
                position: 'absolute',
                zIndex: 200,
                top: 225,
                left: 139
              }}
            />
          )}
          <SingleDatePicker
            date={day.date}
            initialVisibleMonth={getInitialVisibleMonth}
            focused={this.state.focused}
            hideKeyboardShortcutsPanel
            id={dayIndex + '_day_picker'}
            isDayBlocked={this._isDayDisabled}
            noBorder
            numberOfMonths={1}
            onDateChange={this._onDateChange}
            onFocusChange={this.onFocusChange}
            onNextMonthClick={onNextMonthClick}
            onPrevMonthClick={onPreviousMonthClick}
            readOnly
            placeholder={'select date'}
            displayFormat={'MM-DD-YYYY'}
            withPortal={isMobileStepperEnabled}
          />
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={5}
            className={classes.hourFormContainer}
          >
            <FormControl className={classes.hourDropDown}>
              <InputLabel htmlFor="start-time" shrink>
                Start Time
              </InputLabel>
              <Select
                open={this.state.startTimeOpen}
                onClose={this._handleStartTimeClose}
                onOpen={this._handleStartTimeOpen}
                data-test="hourly-start-time-select"
                inputProps={{
                  name: 'startTime',
                  id: 'start-time'
                }}
                classes={{
                  root: classes.timePickers
                }}
                onChange={onTimeChange}
                value={day.startTime}
                disabled={!day.date}
              >
                {this.renderMenuItems(day)}
              </Select>
            </FormControl>
            {!isScheduleTour ? (
              <FormControl className={classes.hourDropDown}>
                <InputLabel htmlFor="end-time" shrink>
                  End Time
                </InputLabel>
                <Select
                  open={this.state.endTimeOpen}
                  onClose={this._handleEndTimeClose}
                  onOpen={this._handleEndTimeOpen}
                  inputProps={{
                    name: 'endTime',
                    id: 'end-time'
                  }}
                  classes={{
                    root: classes.timePickers
                  }}
                  onChange={onTimeChange}
                  value={day.endTime}
                  disabled={!day.date || isScheduleTour}
                >
                  {this.renderMenuItems(day, true)}
                </Select>
              </FormControl>
            ) : (
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: 14,
                  lineHeight: 1,
                  opacity: '0.5',
                  color: '#34495E',
                  marginTop: 24
                }}
              >
                {venueTimezone}
              </Typography>
            )}
          </Grid>
          {!!dayIndex && (
            <IconButton
              size="small"
              title="Remove this entry"
              classes={{
                root: classes.removeButton
              }}
              onClick={onRemoveRow}
            >
              <RemoveCircleOutline />
            </IconButton>
          )}
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(HourlyDateItem)
