import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import classnames from 'classnames'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'

import HeaderLogoLink from './HeaderLogoLink'
import HeaderSearchInput from './HeaderSearchInput'
import LoggedInActions from './LoggedInActions'

const styles = theme => ({
  outerContainer: {
    width: '100%',
    height: 68,
    ['@media print']: {
      display: 'none'
    }
  },
  headerContainer: {
    background: theme.palette.secondary.mainDarker,
    minHeight: 68,
    zIndex: 100
  },
  flex: {
    flex: 1
  },
  signUpButton: {
    marginRight: 8,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16
    }
  },
  authButtonText: {
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
    lineHeight: '16px'
  },
  authButtonSize: {
    minWidth: 72,
    height: 30
  },
  stickyHeader: {
    position: 'fixed',
    top: 0,
    width: '100%'
  }
})
@inject('userStore')
@observer
class Header extends Component {
  static propTypes = {}

  state = {
    showMobileSearch: false
  }

  _showMobileSearch = value => {
    this.setState({ showMobileSearch: value })
  }

  render() {
    const { classes, sticky } = this.props
    const { showMobileSearch } = this.state
    return (
      <div className={classes.outerContainer}>
        <AppBar position="static">
          <Toolbar
            classes={{
              root: classnames(classes.headerContainer, {
                [classes.stickyHeader]: sticky
              })
            }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={showMobileSearch ? 2 : 6}>
                {this._renderLogo()}
              </Grid>
              <Grid item xs={showMobileSearch ? 10 : 6}>
                {this._renderActions()}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    )
  }

  _renderLogo() {
    const { showMobileSearch } = this.state
    return <HeaderLogoLink showLocation={!showMobileSearch} />
  }

  _renderActions() {
    const {
      userStore: {
        loggedIn,
        logout,
        openAuthDialog,
        user,
        setCurrentCompany,
        currentCompanyAbility
      },
      showSearch,
      onSearch,
      classes,
      hideSearch
    } = this.props
    const { showMobileSearch } = this.state
    return (
      <Grid item>
        {loggedIn ? (
          <LoggedInActions
            handleLogout={logout}
            showSearch={showSearch}
            onSearch={onSearch}
            loggedInUser={user}
            currentCompany={user.currentCompany}
            currentCompanyAbility={currentCompanyAbility}
            setCurrentCompany={setCurrentCompany}
            setMobileSearch={this._showMobileSearch}
          />
        ) : (
          <Grid container item justify="flex-end" alignItems="center" xs={12}>
            <Hidden mdUp>
              <Grid
                container
                item
                justify="flex-end"
                alignItems="center"
                xs={12}
              >
                {!hideSearch && (
                  <Grid
                    container
                    item
                    justify="flex-end"
                    xs={showMobileSearch ? 12 : 2}
                    sm={showMobileSearch ? 12 : 2}
                  >
                    <HeaderSearchInput
                      onSearch={onSearch}
                      showMobileSearch={showMobileSearch}
                      setMobileSearch={this._showMobileSearch}
                    />
                  </Grid>
                )}
                {!showMobileSearch && (
                  <Grid
                    container
                    item
                    justify="flex-end"
                    alignItems="center"
                    xs={10}
                    sm={5}
                  >
                    <Button
                      onClick={() => openAuthDialog('register')}
                      size="small"
                      variant="text"
                      className={classnames(
                        classes.signUpButton,
                        classes.authButtonText,
                        classes.authButtonSize
                      )}
                    >
                      {'Sign Up'}
                    </Button>
                    <Button
                      onClick={() => openAuthDialog('login')}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classnames(
                        classes.authButtonText,
                        classes.authButtonSize
                      )}
                    >
                      {'Login'}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid container item justify="flex-end" xs={12}>
                <Grid container item justify="flex-end" xs={4}>
                  {!hideSearch && <HeaderSearchInput onSearch={onSearch} />}
                </Grid>
                <Grid
                  container
                  item
                  justify="flex-end"
                  alignItems="center"
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <Button
                    onClick={() => openAuthDialog('register')}
                    size="small"
                    variant="text"
                    className={classnames(
                      classes.signUpButton,
                      classes.authButtonText,
                      classes.authButtonSize
                    )}
                  >
                    {'Sign Up'}
                  </Button>
                  <Button
                    onClick={() => openAuthDialog('login')}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classnames(
                      classes.authButtonText,
                      classes.authButtonSize
                    )}
                  >
                    {'Login'}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(Header)
