/* eslint-disable import/first */
// NOTE can remove above after CRA upgrade
import React, { Fragment, Component, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import 'typeface-roboto'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider, observer } from 'mobx-react'
import UserStore from './stores/user'
import { Route } from 'react-router-dom'
import { unregister } from './registerServiceWorker'
import moment from 'moment-timezone'
import PrintProvider from 'react-easy-print'
import stores from './stores'
import Theme from './theme'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import ConfirmationDialog from './components/Dialogs/ConfirmationDialog'
import ErrorBoundary from './components/Global/ErrorBoundary'
import ExitConfirmationDialog from './components/Dialogs/ExitConfirmationDialog'
import ForgotPasswordDialog from './components/Dialogs/ForgotPasswordDialog'
import LoginDialog from './components/Dialogs/LoginDialog'
import RegisterDialog from './components/Dialogs/RegisterDialog'
import IdleUserLogout from './components/Global/IdleUserLogout'
import ScrollToTop from './components/Global/ScrollToTop'

import NotificationSnackbar from './components/Global/NotificationSnackbar'
import ScheduleTourDialog from './components/Dialogs/ScheduleTourDialog'
import PolicyDialog from './components/Dialogs/PolicyDialog'
import ThirdPartyServices from './components/Global/ThirdPartyServices'

import './styles/react-dates-overrides.css'
import './styles/styles.css'
import './styles/icons.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import webSocketStore from './stores/webSocketStore'

import 'url-search-params-polyfill'
const Home = lazy(() => import('@pages/Home'))
const Services = lazy(() => import('@pages/Services'))
const SpaceDetail = lazy(() => import('@pages/SpaceDetail'))
const VenueDetail = lazy(() => import('@pages/VenueDetail'))
const ListSpaceSplash = lazy(() => import('@pages/ListSpaceSplash'))
const Login = lazy(() => import('@pages/Login'))
const Search = lazy(() => import('@pages/Search'))
const WhySpacemx = lazy(() => import('@pages/WhySpacemx'))
const SplashPageNashville = lazy(() => import('@pages/SplashPages/Nashville'))
const ManageYourSpace = lazy(() => import('@pages/ManageYourSpace'))
const VenueType = lazy(() => import('@pages/VenueType'))
const SpaceType = lazy(() => import('@pages/SpaceType'))
const AttractNewClients = lazy(() => import('@pages/AttractNewClients'))
const SimplePricing = lazy(() => import('@pages/SimplePricing'))
const PaymentAccountAuthorize = lazy(() =>
  import('@pages/PaymentAccountAuthorize')
)
const ResetPassword = lazy(() => import('@pages/ResetPassword'))
const EmailVerificationRequired = lazy(() =>
  import('@pages/EmailVerificationRequired')
)
const ProductDetail = lazy(() => import('@pages/ProductDetail'))
const Subscription = lazy(() => import('@pages/Subscription'))
const ReferralRegister = lazy(() => import('@pages/ReferralRegister'))
const Unauthorized = lazy(() => import('@pages/Unauthorized'))
const NotFound = lazy(() => import('@pages/NotFound'))
const LoggedOut = lazy(() => import('@pages/LoggedOut'))

import GlobalSnackBar from '@components/Global/GlobalSnackbar'
import CookieConsent from '@components/Global/CookieConsent'

const CompanyRoutes = lazy(() =>
  import(/* webpackChunkName: "CompanyRoutes" */ 'routes/CompanyRoutes')
)
const VenueRoutes = lazy(() =>
  import(/* webpackChunkName: "VenueRoutes" */ 'routes/VenueRoutes')
)
const BookingMemberRoutes = lazy(() =>
  import(
    /* webpackChunkName: "BookingMemberRoutes" */ 'routes/BookingMemberRoutes'
  )
)

import { Helmet } from 'react-helmet'

// TODO R&D overriding or replacing console.error throughout app
// import './utilities/logger'

moment.tz.setDefault(moment.tz.guess())
moment.updateLocale('en', {
  relativeTime: {
    m: 'one minute',
    h: 'one hour',
    d: 'one day',
    M: 'one month',
    y: 'one year'
  }
})

// TODO refactor all @action.bound methods to @action before turning this on
//configure({ enforceActions: true })

const theme = createMuiTheme(Theme)

const getUserConfirmation = (message, callback) => {
  const { openExitConfirmationDialog } = stores.globalStore
  if (message === 'EXIT_CONFIRMATION')
    openExitConfirmationDialog('Any unsaved changes will be lost.', callback)
}

@observer
class Root extends Component {
  componentDidMount() {
    UserStore.getMe(['favorites', 'medias'])
    webSocketStore.connect()
  }

  render() {
    const { notificationSnackbar } = stores.globalStore
    return (
      <Provider {...stores}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>
              SpaceMX: Rent Office Space, Coworking, Meeting Rooms, Music
              Studios, Virtual Offices & more
            </title>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Rent a space in minutes. Or list your space for new revenue. It’s FREE to list on SpaceMX. An online marketplace to List, Find, Book any type of space on demand."
            />
          </Helmet>
          <ThirdPartyServices />
          <PrintProvider>
            <Router getUserConfirmation={getUserConfirmation}>
              <ErrorBoundary>
                <Fragment>
                  <CssBaseline />
                  <ScrollToTop>
                    <Suspense
                      fallback={
                        <Grid container style={{ height: '100vh' }}>
                          <CircularProgress style={{ margin: 'auto' }} />
                        </Grid>
                      }
                    >
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/services" component={Services} />
                        <Route path="/search" component={Search} />
                        <Route path="/not-found" component={NotFound} />
                        <Route path="/unauthorized" component={Unauthorized} />
                        <Route path="/logged-out" component={LoggedOut} />
                        <Route path="/why-spacemx" component={WhySpacemx} />
                        <Route
                          path="/list-creative-space/us/tn/nashville/music-rehearsal-recording-studios"
                          component={SplashPageNashville}
                        />
                        <Route
                          path="/manage-your-space"
                          component={ManageYourSpace}
                        />
                        <Route
                          path="/attract-new-clients"
                          component={AttractNewClients}
                        />
                        <Route
                          path="/simple-pricing"
                          component={SimplePricing}
                        />
                        <Route
                          path="/venues-near-me/:venueTypeSlug+"
                          component={VenueType}
                        />
                        <Route
                          path="/spaces-near-me/:spaceTypeSlug+"
                          component={SpaceType}
                        />
                        <Route
                          path="/space/:spaceSlug+"
                          component={SpaceDetail}
                        />
                        <Route
                          path="/venue/:venueId/services/:productId/subscribe"
                          component={Subscription}
                        />
                        <Route
                          path="/service/:productSlug+"
                          component={ProductDetail}
                        />
                        <Route
                          path="/venue/:venueSlug+"
                          component={VenueDetail}
                        />
                        {/*  List Space  -- Order is important, /success must be before /:venueId? */}
                        <Route
                          path="/list-space-splash"
                          component={ListSpaceSplash}
                        />
                        <Route
                          path="/referral/:referralCode?"
                          component={ReferralRegister}
                        />
                        <Route path="/login" component={Login} />
                        <Route
                          path="/payment-account-authorize"
                          component={PaymentAccountAuthorize}
                        />
                        <Route
                          path="/reset_password"
                          component={ResetPassword}
                        />
                        <Route
                          exact
                          path="/email-verification-required"
                          component={EmailVerificationRequired}
                        />
                        <Route
                          path="/admin/company"
                          component={CompanyRoutes}
                        />
                        <Route path="/admin/venue" component={VenueRoutes} />
                        <Route path="/admin" component={BookingMemberRoutes} />
                        <Route component={Home} />
                      </Switch>
                    </Suspense>
                  </ScrollToTop>
                  <LoginDialog
                    open={stores.userStore.authDialogs.login.isOpen}
                    redirectTo={stores.userStore.authDialogs.login.redirectTo}
                  />
                  <RegisterDialog
                    open={stores.userStore.authDialogs.register.isOpen}
                    redirectTo={
                      stores.userStore.authDialogs.register.redirectTo
                    }
                  />
                  <ForgotPasswordDialog
                    open={stores.userStore.authDialogs.forgot_password.isOpen}
                  />
                  <ScheduleTourDialog
                    open={stores.globalStore.currentTourDialog.open}
                  />
                  <ExitConfirmationDialog
                    open={stores.globalStore.exitConfirmationDialog.open}
                  />
                  <PolicyDialog
                    open={Boolean(stores.globalStore.currentOpenPolicy)}
                  />
                  <ConfirmationDialog />
                  <NotificationSnackbar
                    isOpen={notificationSnackbar.isOpen}
                    message={notificationSnackbar.message}
                    actionTo={notificationSnackbar.actionTo}
                    actionText={notificationSnackbar.actionText}
                    customActions={notificationSnackbar.customActions}
                    showCloseAction={notificationSnackbar.showCloseAction}
                    onClose={stores.globalStore.closeNotificationSnackbar}
                    autoHideDuration={notificationSnackbar.autoHideDuration}
                  />
                  <IdleUserLogout />
                  <GlobalSnackBar
                    open={stores.globalStore.isGlobalSnackBarOpen}
                  />
                </Fragment>
              </ErrorBoundary>
            </Router>
            <CookieConsent />
          </PrintProvider>
        </MuiThemeProvider>
      </Provider>
    )
  }
}

ReactDOM.render(<Root />, document.getElementById('root'))
unregister()
