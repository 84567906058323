const formatMoney = (num, includeCents = false) => {
  if (num === undefined) return 0
  const moneyString = `$${String(parseFloat(num, 10).toFixed(2)).replace(
    /(\d)(?=(\d{3})+\.)/g,
    '$1,'
  )}`
  return includeCents ? moneyString : moneyString.split('.')[0]
}

export default formatMoney
