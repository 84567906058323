import BaseApi from './baseApi'
import userStore from '../stores/user'
import qs from 'qs'

class NotificationApi extends BaseApi {
  constructor() {
    super({ routeName: 'notifications' })
  }

  fetchAll = ({ personId, pagination: { page, perPage }, filters }) => {
    return this.request(userStore.jwtToken).get('', {
      params: { personId, pagination: { page, per_page: perPage }, filters },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

const notificationApi = new NotificationApi()
export default notificationApi
