import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  withMobileDialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router'

const styles = theme => ({
  inputSpacing: { marginBottom: theme.spacing.unit * 4 },
  dialogActionsContainer: {
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  dialogHeaderText: { float: 'left' },
  closeIcon: {
    float: 'right',
    marginTop: theme.spacing.unit * -2,
    marginRight: theme.spacing.unit * -2
  },
  dialogContentRoot: {
    paddingBottom: 4
  }
})

@inject('globalStore')
@observer
class ExitConfirmationDialog extends Component {
  _handleCancel = () => {
    const {
      globalStore: { closeExitConfirmationDialog, exitConfirmationDialog }
    } = this.props
    closeExitConfirmationDialog()
    if (exitConfirmationDialog.callback) {
      exitConfirmationDialog.callback(false)
    }
  }

  _handleOk = () => {
    const {
      globalStore: { closeExitConfirmationDialog, exitConfirmationDialog }
    } = this.props
    closeExitConfirmationDialog()
    if (exitConfirmationDialog.callback) {
      exitConfirmationDialog.callback(true)
    }
  }

  render() {
    const {
      open,
      fullScreen,
      classes,
      globalStore: { exitConfirmationDialog }
    } = this.props
    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        PaperProps={{ style: { width: 560 } }}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        onClose={this._handleCancel}
      >
        <DialogTitle disableTypography style={{ paddingBottom: 24 }}>
          <Typography
            variant="h5"
            className={classes.dialogHeaderText}
            id="e2e-list-space-login-heading-selector"
          >
            {"Are you sure you'd like to exit?"}
          </Typography>
          <IconButton
            onClick={this._handleCancel}
            aria-label="Close"
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContentRoot
          }}
        >
          <Typography>{exitConfirmationDialog.message}</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={this._handleOk}
            data-test="e2e-login-button-space-selector"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this._handleCancel}
            data-test="e2e-login-button-space-selector"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withRouter(
  withStyles(styles)(withMobileDialog()(ExitConfirmationDialog))
)
