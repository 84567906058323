import { action, observable } from 'mobx'
import companyApi from '../services/companyApi'

import globalStore from './global'

class VenuePreviewStore {
  @observable.deep
  venue = null
  @observable
  isLoadingVenuePreview = false

  constructor() {
    this.globalStore = globalStore
  }

  @action
  getVenuePreview = async (companyId, stateId) => {
    this.isLoadingVenuePreview = true
    try {
      const { data: venue } = await companyApi.getVenuePreview(
        companyId,
        stateId
      )
      this.venue = venue
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error fetching the venue preview.'
      )
    } finally {
      this.isLoadingVenuePreview = false
    }
    return true
  }
}

export default new VenuePreviewStore()
