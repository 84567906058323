import { action, observable } from 'mobx'
import favoritesApi from '../services/favoritesApi'

const favoritesDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

const favoritesDefaultFilters = {
  sort: 'desc',
  type: 'space',
  city_id: 'all'
}

class FavoritesStore {
  @observable
  currentFavoritesCities = []

  @observable
  favorites = []
  @observable
  isLoadingFavorites = false

  _defaultSort = {
    value: 'desc',
    sort_by: 'created_at',
    direction: 'desc'
  }

  @observable.deep
  favoritesPagination = {
    ...favoritesDefaultPagination
  }
  @observable.deep
  favoritesFilters = {
    ...favoritesDefaultFilters
  }

  @action
  getMyFavorites = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingFavorites = true
    try {
      const {
        data: { favorites, pagination }
      } = await favoritesApi.getMyFavorites({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.favoritesFilters
        }
      })
      this.favorites = favorites
      this.favoritesPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingFavorites = false
    }
  }

  @action
  getFavoritedCities = async type => {
    try {
      const {
        data: { cities: favoritedCities }
      } = await favoritesApi.getFavoritedCities(type)

      const formattedCities = favoritedCities.map(city => ({
        label: city.name,
        value: city.city_id
      }))

      this.currentFavoritesCities = formattedCities
      this.loadingFavoritedCities = false
    } catch (fetchingError) {
      this.loadingFavoritedCities = false
    }
  }

  @action
  setFavoritesPaginationProp = (prop, value) => {
    this.favoritesPagination[prop] = value
  }

  @action
  resetFavoritesPagination = () => {
    this.favoritesPagination = {
      ...favoritesDefaultPagination
    }
  }

  @action
  setFavoritesFiltersProp = (prop, value) => {
    this.favoritesFilters[prop] = value
  }

  @action
  resetFavoritesFilters = () => {
    this.favoritesFilters = {
      ...favoritesDefaultFilters
    }
  }
}

export default new FavoritesStore()
