import { observable, action, when } from 'mobx'
import tourApi from '../services/tourApi'
import moment from 'moment'
import UserStore from './user'

const initialDate = () => {
  const now = moment()
  // if later than 8pm set tomorrow.
  // TODO should be latest time that venue/space has set
  if (now.hour() > 20) {
    now.add(1, 'day')
  }

  return {
    date: now,
    startTime: '',
    endTime: ''
  }
}

const defaultForm = {
  selectedDate: initialDate(),
  tourId: null,
  specialRequirements: '',
  leaseExpire: '',
  whenString: '',
  sqft: 0,
  peopleCount: 0,
  termId: 1
}

class TourSpaceStore {
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0
  @observable
  isFinished = false
  @observable
  isSubmitting = false

  @observable.deep
  form = {
    ...defaultForm
  }

  @observable
  isTourStepperVisible = false

  constructor() {
    this.userStore = UserStore
  }

  @action
  openTourStepper = () => {
    if (!this.userStore.loggedIn) {
      this.userStore.openAuthDialog('login')
      when(
        () => this.userStore.loggedIn,
        () => (this.isTourStepperVisible = true)
      )
      return
    }
    this.isTourStepperVisible = true
  }

  @action
  closeTourStepper = () => {
    this.isTourStepperVisible = false
  }

  @action
  cancelTourStepper = () => {
    this.closeTourStepper()
  }

  @action.bound
  setFormAttribute(attr, value) {
    this.form[attr] = value
  }

  @action.bound
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0

    this.form = { ...defaultForm }
  }

  @action
  saveTour = async tourObject => {
    this.isSavingTour = true
    let result
    try {
      tourObject.object_type = 'venues'
      tourObject.object_id = tourObject.venue_id
      result = await tourApi.create(tourObject)
      this.form.tourId = result.data.id
    } catch (error) {
      console.error(error)
    } finally {
      this.isSavingTour = false
    }

    return result
  }
}

export default new TourSpaceStore()
