import BaseApi from './baseApi'
import userStore from '../stores/user'

class MediaApi extends BaseApi {
  constructor() {
    super({ routeName: 'media' })
  }

  getUploadImageXHRParams() {
    return {
      endpoint: `${this.baseURL}/uploadImage`,
      fieldName: 'image',
      ...this.authorizationHeader
    }
  }

  updateIndex = (medias = []) => {
    const url = `/update-index`
    return this.request(userStore.jwtToken).put(url, { medias })
  }

  update = (mediaId, params = {}) => {
    const url = `/${mediaId}`
    return this.request(userStore.jwtToken).put(url, { media: params })
  }
}

export default new MediaApi()
