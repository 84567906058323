import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class AdminTourApi extends BaseApi {
  fetch = ({ venueId, filters }) => {
    const url = `admin/venue/${venueId}/tours`
    return this.request(userStore.jwtToken).get(url, {
      params: {
        filters
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchOne = ({ venueId, tourId }) => {
    const url = `admin/venue/${venueId}/tours/${tourId}`
    return this.request(userStore.jwtToken).get(url)
  }
}

const adminTourApi = new AdminTourApi()
export default adminTourApi
