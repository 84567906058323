import { action, observable, computed } from 'mobx'

import globalStore from './global'
import userStore from './user'
import userApi from 'services/userApi'
import referralApi from 'services/admin/referralApi'

const defaultReferralParams = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company: '',
  notes: '',
  medias: [],
  is_venue_billing: false
}

class ReferralStore {
  @observable
  isOpenNewReferralForm = false

  @observable
  filterBy = 'all'

  @observable
  sortBy = null

  @observable.deep
  referrals = []
  @observable
  isLoadingReferrals = false
  @observable
  isSavingReferrals = false

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable.deep
  referralParams = {
    ...defaultReferralParams
  }

  @observable.deep
  referral = {
    person: {
      user: null
    }
  }

  @observable
  currentReferralMetrics = {
    potential: 0,
    earned: 0,
    bookings: 0,
    venues: 0
  }

  @observable
  isLoadingReferralMetrics = false

  @observable
  isCashingOutEarnedReferrals = false

  constructor() {
    this.globalStore = globalStore
    this.userStore = userStore
  }

  @action
  setItemsPerPage = value => {
    this.pagination.perPage = value
  }

  @action
  handleOpenNewReferralFormDialog = () => {
    this.isOpenNewReferralForm = true
  }

  @action
  handleCloseNewReferralFormDialog = () => {
    this.isOpenNewReferralForm = false
  }

  @action
  handleNewReferralSubmit = async ({ email, first_name, last_name }) => {
    try {
      this.isSavingReferrals = true
      const { data: newReferral } = await userApi.createNewReferral({
        email,
        first_name,
        last_name
      })
      if (!newReferral.id) {
        throw Error('Your referral could not be created. Please try again')
      }
      await Promise.all([
        this.fetchCurrentReferralMetrics(),
        this.fetchReferrals()
      ])
      this.globalStore.openNotificationSnackbar(
        `You have successfully sent a referral to ${email}!`
      )
    } catch (error) {
      throw error
    } finally {
      this.isSavingReferrals = false
    }
  }

  @action
  handleResendInvitation = async referralId => {
    try {
      this.isLoadingReferrals = true
      await referralApi.resend(referralId)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingReferrals = false
    }
  }

  @action
  handleCancelInvitation = async referralId => {
    try {
      this.isLoadingReferrals = true
      await referralApi.destroy(referralId)
      await this.fetchReferrals()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingReferrals = false
    }
  }

  @action
  handleChangePage = page => {
    this.pagination = {
      ...this.pagination,
      page
    }
  }

  @action
  handleChangeFilter = value => {
    this.filterBy = value
    this.pagination = {
      ...this.pagination,
      page: 1
    }
  }

  @action
  handleChangeSort = value => {
    this.sortBy = value
    this.pagination = {
      ...this.pagination,
      page: 1
    }
  }

  @action
  fetchReferrals = async () => {
    this.isLoadingReferrals = true
    try {
      const data = await userApi.fetchReferrals({
        pagination: {
          page: this.pagination.page,
          per_page: this.pagination.perPage
        },
        filters: this.filterBy
      })
      const { referrals = [], metadata = {} } = data
      this.referrals = referrals
      this.pagination = metadata.pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingReferrals = false
    }
  }

  @action
  fetchCurrentReferralMetrics = async () => {
    this.isLoadingReferralMetrics = true
    try {
      const {
        data: { potential_earnings, earned, bookings_paid, venues_paid }
      } = await userApi.fetchReferralMetrics()
      this.currentReferralMetrics = {
        potential: potential_earnings,
        earned,
        bookings: bookings_paid,
        venues: venues_paid
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingReferralMetrics = false
    }
  }

  @action
  cashOutEarnedReferrals = async earnings => {
    try {
      this.isCashingOutEarnedReferrals = true
      await userApi.cashOutEarnedReferrals()
      await this.fetchCurrentReferralMetrics()
      this.resetFilters()
      await this.fetchReferrals()
      this.globalStore.openNotificationSnackbar(
        `You have successfully cashed out $${earnings}`
      )
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `There was an issue cashing out your earnings.  Please try again.`
      )
    } finally {
      this.isCashingOutEarnedReferrals = false
    }
  }

  resetFilters = () => {
    this.pagination = {
      total: 0,
      perPage: 10,
      page: 1,
      lastPage: 1
    }
    this.filterBy = 'all'
  }

  getUserReferralCode({ userId }) {
    const code = btoa(userId * 100).replace(/=/g, '')
    return code
  }

  parseUserReferralCode(referralCode) {
    const userId = atob(referralCode)
    return userId / 100
  }

  @computed
  get currentUserReferralCode() {
    const userName = this.userStore.user.fullname.replace(' ', '').toLowerCase()
    return `${
      process.env.REACT_APP_APP_URL
    }/referral/${userName}-${this.getUserReferralCode({
      userId: this.userStore.user.id
    })}`
  }
}

export default new ReferralStore()
