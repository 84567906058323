import { action } from 'mobx'
import mediaApi from '../services/mediaApi'

class MediaStore {
  @action
  updateIndex = async medias => {
    try {
      await mediaApi.updateIndex(medias)
      return true
    } catch (error) {
      console.warn(error)
      return false
    }
  }

  @action
  update = async (mediaId, params = {}) => {
    try {
      const {
        data: { media }
      } = await mediaApi.update(mediaId, params)
      return media
    } catch (error) {
      console.warn(error)
      return false
    }
  }
}

export default new MediaStore()
