import { action, observable } from 'mobx'
import transactionsApi from '../services/transactionsApi'
import venueApi from '../services/venueApi'
import globalStore from './global'
import moment from 'moment-timezone'

import { COLUMNS, getColumns } from '@components/Admin/Venues/Reporting/COLUMNS'

const defaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

const defaultVenueTransactionsFilters = {
  spaces: 1,
  services: 1,
  daily: 1,
  monthly: 1,
  hourly: 1,
  creditCard: 1,
  venueBill: 1,
  completed: 1,
  pending: 1,
  cancelled: 1,
  paid: 1,
  refunded: 1
}

const DEFAULT_COLUMNS = [
  COLUMNS.ITEM.value,
  COLUMNS.NAME.value,
  COLUMNS.COMPANY.value,
  COLUMNS.START_DATE.value,
  COLUMNS.END_DATE.value,
  COLUMNS.DURATION.value,
  COLUMNS.TRANSACTION_STATUS.value,
  COLUMNS.PAYMENT.value,
  COLUMNS.PAYMENT_STATUS.value,
  COLUMNS.AMOUNT_PAID.value
]

class TransactionsStore {
  @observable
  currentTransactions = []

  @observable
  isLoadingTransactions = false
  @observable
  loadingTransactionsError = null

  @observable.deep
  filters = {
    status: 'all'
  }

  @observable
  sort = this._defaultSort

  @observable
  venueId = null

  @observable
  currentVenueTransactions = []

  _defaultSort = {
    value: 'desc',
    sort_by: 'created_at',
    direction: 'desc'
  }

  @observable.deep
  pagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable
  search = ''

  @observable.deep
  transactions = []

  @observable.deep
  venueTransactionsPagination = {
    ...defaultPagination
  }

  @observable.deep
  venueTransactionsFilters = {
    ...defaultVenueTransactionsFilters
  }

  @observable
  transactionsStartDate = moment().startOf('month')

  @observable
  transactionsEndDate = moment().endOf('month')

  @observable
  venueTransactionsSortBy = 'time'
  @observable
  venueTransactionsSortDirection = 'desc'

  @observable
  columns = [
    ...getColumns().filter(column => DEFAULT_COLUMNS.includes(column.value))
  ]

  constructor() {
    this.globalStore = globalStore
  }

  @action
  toggleColumn = value => {
    if (this.columns.find(setColumn => setColumn.value === value)) {
      this.columns = getColumns().filter(
        defaultColumn =>
          defaultColumn.value !== value &&
          this.columns.find(
            setColumn => setColumn.value === defaultColumn.value
          )
      )
    } else {
      this.columns = getColumns().filter(
        defaultColumn =>
          this.columns.find(
            setColumn => setColumn.value === defaultColumn.value
          ) || defaultColumn.value === value
      )
    }
  }

  @action
  setTransactionsDates = (startDate, endDate) => {
    this.transactionsStartDate = startDate
    this.transactionsEndDate = endDate
  }

  @action
  changeVenueTransactionsSort = (sortBy, direction) => {
    this.venueTransactionsSortBy = sortBy
    this.venueTransactionsSortDirection = direction
  }

  @action
  changeSearch = value => {
    this.search = value
  }

  @action
  changeVenueTransactionsFilters = (key, value) => {
    this.venueTransactionsFilters[key] = value
  }

  @action
  resetVenueTransactions = () => {
    this.transactions = []
    this.resetVenueTransactionsPagination()
    this.transactionsStartDate = moment().startOf('month')
    this.transactionsEndDate = moment().endOf('month')
    this.venueTransactionsFilters = {
      ...defaultVenueTransactionsFilters
    }
    this.columns = [
      ...getColumns().filter(column => DEFAULT_COLUMNS.includes(column.value))
    ]
    this.venueTransactionsSortBy = 'time'
    this.venueTransactionsSortDirection = 'desc'
  }

  @action
  setAccountSettingsTransactionsFilter = (
    filterAttribute,
    selectedFilterValue
  ) => {
    this.filters[filterAttribute] = selectedFilterValue
    this.getMyTransactionsByMyBookings()
  }

  @action
  setAccountSettingsTransactionsSortBy = selectedSortValue => {
    this.sort = {
      value: selectedSortValue,
      sort_by: 'created_at',
      direction: selectedSortValue
    }
    this.getMyTransactionsByMyBookings()
  }

  @action
  setDefaultTransactionsSortFilter = () => {
    this.filters = {
      status: 'all'
    }
    this.sort = this._defaultSort
  }

  @action
  getMyTransactions = async () => {
    const { value, ...sortingOptions } = this.sort // eslint-disable-line
    this.isLoadingTransactions = true
    try {
      const {
        data: { transactions, metadata }
      } = await transactionsApi.getMyTransactions({
        filters: this.filters.status === 'all' ? {} : this.filters,
        sort: sortingOptions,
        page: this.pagination.page,
        items_per_page: this.pagination.perPage
      })

      this.currentTransactions = transactions
      this.pagination = metadata.pagination
      this.isLoadingTransactions = false
    } catch (fetchingError) {
      this.loadingTransactionsError = fetchingError
      this.isLoadingTransactions = false
    }
  }

  @action
  getMyTransactionsByMyBookings = async () => {
    const { value, ...sortingOptions } = this.sort // eslint-disable-line
    this.isLoadingTransactions = true
    try {
      const {
        data: { transactions, metadata }
      } = await transactionsApi.getMyTransactionsByMyBookings({
        filters: this.filters.status === 'all' ? {} : this.filters,
        sort: sortingOptions,
        page: this.pagination.page,
        items_per_page: this.pagination.perPage
      })

      this.currentTransactions = transactions
      this.pagination = metadata.pagination
      this.isLoadingTransactions = false
    } catch (fetchingError) {
      this.loadingTransactionsError = fetchingError
      this.isLoadingTransactions = false
    }
  }

  @action
  setVenueSettingsTransactionsFilter = (
    filterAttribute,
    selectedFilterValue
  ) => {
    this.filters[filterAttribute] = selectedFilterValue
    this.getTransactionsByVenue()
  }

  @action
  setVenueSettingsTransactionsSortBy = selectedSortValue => {
    this.sort = {
      value: selectedSortValue,
      sort_by: 'created_at',
      direction: selectedSortValue
    }
    this.getTransactionsByVenue()
  }

  @action
  getTransactionsByVenue = async venueId => {
    const { value, ...sortingOptions } = this.sort // eslint-disable-line
    if (venueId && this.venueId !== venueId) {
      this.venueId = venueId
    }

    this.isLoadingTransactions = true
    try {
      const { data: transactions } = await venueApi.getVenueTransactions({
        filters: this.filters.status === 'all' ? {} : this.filters,
        sort: sortingOptions,
        venueId: venueId || this.venueId
      })

      this.currentVenueTransactions = transactions
      this.isLoadingTransactions = false
    } catch (fetchingError) {
      this.loadingTransactionsError = fetchingError
      this.isLoadingTransactions = false
    }
  }

  @action
  searchTransactionsByVenue = async (venueId, paginationInfo = {}) => {
    this.isLoadingTransactions = true
    try {
      const pagination = {
        ...this.venueTransactionsPagination,
        ...paginationInfo
      }
      const {
        data: { transactions, pagination: newPagination }
      } = await venueApi.searchTransactions(venueId, {
        search: this.search,
        filters: this.venueTransactionsFilters,
        startDate: this.transactionsStartDate.utc().format(),
        endDate: this.transactionsEndDate.utc().format(),
        sort: {
          sort_by: this.venueTransactionsSortBy,
          direction: this.venueTransactionsSortDirection
        },
        pagination
      })

      this.transactions = transactions
      this.venueTransactionsPagination = newPagination
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error fetching the report.'
      )
    } finally {
      this.isLoadingTransactions = false
    }
  }

  @action
  xlsVenueTransactions = async venue => {
    this.isLoadingTransactions = true
    try {
      const { data } = await venueApi.xlsTransactions(venue.id, {
        search: this.search,
        filters: this.venueTransactionsFilters,
        startDate: this.transactionsStartDate.utc().format(),
        endDate: this.transactionsEndDate.utc().format(),
        sort: {
          sort_by: this.venueTransactionsSortBy,
          direction: this.venueTransactionsSortDirection
        },
        columns: this.columns.map(column => column.value)
      })
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      const { name, timezone } = venue
      const filename = `SpaceMX ${name} Report ${moment()
        .tz(timezone)
        .format('YYYY-MM-DD HH:mm')}`
      link.setAttribute('download', `${filename}.xlsx`)
      link.click()
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error fetching the report.'
      )
    } finally {
      this.isLoadingTransactions = false
    }
  }

  @action
  resetVenueTransactionsPagination = () => {
    this.venueTransactionsPagination = {
      ...defaultPagination
    }
  }

  @action
  setItemsPerPage = value => {
    this.pagination.perPage = value
  }

  @action
  handleChangePage = page => {
    this.pagination = {
      ...this.pagination,
      page
    }
    this.getMyTransactionsByMyBookings()
  }
}

export default new TransactionsStore()
