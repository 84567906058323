import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
const styles = theme => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  button: {
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  label: {},
  root: {},
  sizeSmall: {}
})

const ProgressButton = ({
  children,
  showProgress,
  style,
  classes,
  ...buttonProps
}) => {
  const { progress, wrapper, button, ...otherClasses } = classes
  return (
    <div style={{ position: 'relative', ...style }} className={wrapper}>
      <Button
        {...buttonProps}
        disabled={buttonProps.disabled || !!showProgress}
        className={button}
        classes={otherClasses}
      >
        {children}
      </Button>
      {showProgress && <CircularProgress className={progress} size={24} />}
    </div>
  )
}

ProgressButton.propTypes = {
  children: PropTypes.any,
  showProgress: PropTypes.bool
}

export default withStyles(styles)(ProgressButton)
