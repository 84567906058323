const { REACT_APP_GOOGLE_API_KEY, REACT_APP_GOOGLE_CLIENT_ID } = process.env

class GoogleOAuth {
  static DiscoveryUrl =
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
  static Scopes = [
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ]

  static init(onUpdateSigninStatus) {
    if (!window.gapi) {
      const initClient = GoogleOAuth._initClient(onUpdateSigninStatus)
      const loadService = GoogleOAuth._loadService(initClient)
      GoogleOAuth._loadScript(loadService)
    } else {
      const oauthInstance = window.gapi.auth2.getAuthInstance()
      onUpdateSigninStatus(oauthInstance)
    }
  }

  static _loadScript(loadService) {
    const ScriptID = 'google-oauth-id'
    if (!document.getElementById(ScriptID)) {
      const firstScriptTag = document.getElementsByTagName('script')[0]
      const scriptTag = document.createElement('script')
      scriptTag.async = 'async'
      scriptTag.defer = 'defer'
      scriptTag.id = ScriptID
      scriptTag.src = '//apis.google.com/js/api.js'
      scriptTag.onload = loadService
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag)
    }
  }

  static _loadService(initClient) {
    return function() {
      window.gapi.load('client:auth2', initClient)
    }
  }

  static _initClient(onUpdateSigninStatus) {
    return function() {
      window.gapi.client
        .init({
          apiKey: REACT_APP_GOOGLE_API_KEY,
          discoveryDocs: [GoogleOAuth.DiscoveryUrl],
          clientId: REACT_APP_GOOGLE_CLIENT_ID,
          scope: GoogleOAuth.Scopes.join(' ')
        })
        .then(function() {
          const oauthInstance = window.gapi.auth2.getAuthInstance()
          onUpdateSigninStatus(oauthInstance)
        })
    }
  }
}

export default GoogleOAuth
