export const TERMS = {
  HOURLY: {
    ID: 1,
    NAME: 'Hourly',
    UOT: 'hours'
  },
  DAILY: {
    ID: 2,
    NAME: 'Daily',
    UOT: 'days'
  },
  // WEEKLY: {
  //   ID: null,
  //   NAME: 'Weekly'
  // },
  MONTHLY: {
    ID: 3,
    NAME: 'Monthly',
    UOT: 'months'
  }
  // YEARLY: {
  //   NAME: 'Yearly'
  // }
}

export const getTermById = termId => {
  return Object.values(TERMS).find(term => term.ID === termId)
}

export const getTermByName = termName => {
  return Object.values(TERMS).find(term => term.NAME === termName)
}
