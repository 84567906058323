import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class ReviewApi extends BaseApi {
  constructor() {
    super({ routeName: 'admin/reviews' })
  }

  fetchPendings = (params = {}) => {
    return this.request(userStore.jwtToken).get('/pendings', {
      params: {
        ...params
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

export default new ReviewApi()
