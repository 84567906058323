import AdminCalendar from './admin/calendar'
import AdminContact from './admin/contact'
import AdminVenue from './admin/venue'
import AdminReview from './admin/review'

import search from './search'
import space from './space'
import venue from './venue'
import company from './company'
import employee from './employee'
import contact from './contact'
import global from './global'
import UiStore from './UiStore'
import user from './user'
import listSpace from './listSpace'
import dashboard from './dashboard'
import favorites from './favorites'
import chat from './chat'
import transactions from './transactions'
import webSocketStore from './webSocketStore'
import notificationStore from './notificationStore'
import booking from './booking'
import tour from './tour'
import bookSpace from './bookSpace'
import tourSpace from './tourSpace'
import media from './media'
import subscription from './subscription'
import product from './product'
import referrals from './referrals'
import createVenue from './createVenue'
import venueSetup from './venueSetup'
import createSpace from './createSpace'
import wizardState from './wizardState'
import venuePreview from './venuePreview'
import spacePreview from './spacePreview'

const Store = {
  UiStore,
  adminCalendarStore: AdminCalendar,
  adminContactStore: AdminContact,
  adminVenueStore: AdminVenue,
  adminReviewStore: AdminReview,
  tourStore: tour,
  bookingStore: booking,
  globalStore: global,
  webSocketStore,
  searchStore: search,
  spaceStore: space,
  venueStore: venue,
  companyStore: company,
  contactStore: contact,
  employeeStore: employee,
  userStore: user,
  listSpaceStore: listSpace,
  dashboardStore: dashboard,
  favoritesStore: favorites,
  chatStore: chat,
  transactionsStore: transactions,
  notificationStore,
  bookSpaceStore: bookSpace,
  tourSpaceStore: tourSpace,
  mediaStore: media,
  subscriptionStore: subscription,
  productStore: product,
  referralStore: referrals,
  createVenueStore: createVenue,
  venueSetupStore: venueSetup,
  createSpaceStore: createSpace,
  wizardStateStore: wizardState,
  venuePreviewStore: venuePreview,
  spacePreviewStore: spacePreview
}

export default Store
