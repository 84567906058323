import { tz } from 'moment-timezone'

export default class ListSpaceFormSerializer {
  constructor(form) {
    this.form = form

    let cleanedForm = this._cleanAndPrepareForm(form)
    cleanedForm.venueTypeGroup =
      cleanedForm.venueTypeGroup && cleanedForm.venueTypeGroup
    cleanedForm.venueType = cleanedForm.venueType && cleanedForm.venueType
    cleanedForm.spaceType = cleanedForm.spaceType && cleanedForm.spaceType
    if (cleanedForm.commonAreas)
      cleanedForm.commonAreas = cleanedForm.commonAreas.map(({ id }) => id)
    cleanedForm = this._prepareScheduleTimeframes(cleanedForm)
    cleanedForm = this._prepareHolidayTimeframes(cleanedForm)

    cleanedForm.timezone = cleanedForm.timezone || tz.guess()

    this.json = cleanedForm
  }

  _cleanAndPrepareForm(form) {
    let cleanForm = { ...form }
    cleanForm = this._flattenForm(cleanForm)
    return cleanForm
  }

  _flattenForm(form) {
    Object.entries(form).forEach(value => {
      form[value[0]] = value[1].value
    })
    return form
  }

  _prepareScheduleTimeframes(form) {
    const { scheduleDays } = form
    if (!scheduleDays.length) return form
    const checkedDaysTimeframes = scheduleDays.reduce(
      (checkedDaysTimeframes, scheduleDay) => {
        if (scheduleDay.isChecked) {
          const { dayId, timeframes } = scheduleDay
          timeframes.forEach(({ start_time, end_time }) => {
            checkedDaysTimeframes.push({
              day_id: dayId,
              start_time,
              end_time
            })
          })
        }
        return checkedDaysTimeframes
      },
      []
    )
    form.timeframes = checkedDaysTimeframes
    return { ...form }
  }

  _prepareHolidayTimeframes(form) {
    const { holidays } = form
    if (!holidays || !holidays.length) return form
    const checkedHolidays = holidays.filter(holiday => holiday.isChecked)
    form.holidays = checkedHolidays
    return { ...form }
  }

  static json() {
    return this.json
  }
}
