import BaseApi from './baseApi'
import userStore from '../stores/user'

class CulturesApi extends BaseApi {
  constructor() {
    super({ routeName: 'cultures' })
  }

  getAll = () => {
    return this.request(userStore.jwtToken).get()
  }
}

const culturesApi = new CulturesApi()
export default culturesApi
