import { observable, action, computed } from 'mobx'
import initialForm from './initialStates/listSpaceForm'
import validations from './validations'
import spaceApi from '../services/spaceApi'
import venueApi from '../services/venueApi'
import ListSpaceFormSerializer from '../utilities/listSpaceFormSerializer'
import {
  mergeVenueWithListSpaceForm,
  mergeSpaceWithListSpaceForm
} from '../utilities/listSpaceVenueSerializer'
import mediaStore from './media'
import globalStore from './global'
import userStore from './user'
import { generateStripeOAuthLink } from '../utilities/stripeUtils'
import companyApi from '../services/companyApi'
import { trackEvent } from '@utilities/trackingUtils'

class ListSpaceStore {
  @observable
  validations = validations
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0
  @observable
  isFinished = false
  @observable.deep
  form = { ...initialForm }
  @observable
  formFinished = false
  @observable
  formHasErrors = false
  @observable
  isSubmitting = false
  @observable
  currentListSpaceSpace = {}

  @observable
  isSaveAndContinueDialogOpen = false

  formsToSubmit = ['basicInfoForm', 'virtualTourForm', 'bookingSettingsForm']

  constructor() {
    this.validations = validations
    this.mediaStore = mediaStore
    this.globalStore = globalStore
    this.userStore = userStore
  }

  @action
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action.bound
  updateFormAttribute = ({ form, pair }) => {
    const attribute = Object.keys(pair)[0]
    const value = pair[attribute]
    const newForm = { ...this.form[form] }

    const validator = this.validations[form][attribute]
    const validationResult = validator && validator(value)
    newForm[attribute] = {
      value,
      error: validationResult ? validationResult.message : null
    }
    this.form = { ...this.form, [form]: { ...newForm } }
  }

  @action.bound
  async submitForm(formsToSubmit = null, applicationFinished = false) {
    const formNames = formsToSubmit || this.formsToSubmit
    this.isSubmitting = true

    const serializedForm = this.getCombinedAndSerializedForm(
      this.form,
      formNames
    )

    if (this.userStore.isCurrentCompanySet && !serializedForm.companyId) {
      serializedForm.companyId = this.userStore.user.currentCompany.id
    }

    try {
      const {
        data: { data: space, errors }
      } = await spaceApi.create(serializedForm.json)
      if (space) {
        this.form.basicInfoForm.spaceId = {
          value: space.id,
          error: null
        }
        if (!this.form.basicInfoForm.companyId.value) {
          await this.fetchAndSetUserCompany(space.venue.company_id)
        }
        this.currentListSpaceSpace = space
        this.formHasErrors = false
      }
      if (errors.length) {
        // TODO Add errors to validations/form
        // TODO display errors on form and prevent
        errors.forEach(error => {
          if (this.form[error.form] && this.form[error.form][error.attribute]) {
            this.form[error.form][error.attribute].error = error.error
          }
        })
        this.formHasErrors = true
      }
    } catch (error) {
      console.error(error)
      return false
    } finally {
      this.isSubmitting = false
      if (!this.formHasErrors && applicationFinished) {
        this.formFinished = true
        trackEvent({
          title: 'list space',
          data: 'space listed'
        })
      }
    }
    return !this.formHasErrors
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.currentListSpaceSpace = {}
    this.form = { ...initialForm }
  }

  @action.bound
  resetFormExceptTypeSelection = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.currentListSpaceSpace = {}
    const existingVenueTypes = this.form.basicInfoForm.venueTypes
    this.form = {
      ...initialForm,
      basicInfoForm: {
        ...initialForm.basicInfoForm,
        venueTypes: {
          ...existingVenueTypes
        }
      }
    }
  }

  @action
  showSaveAndContinueDialog = () => {
    this.isSaveAndContinueDialogOpen = true
  }

  @action
  hideSaveAndContinueDialog = () => {
    this.isSaveAndContinueDialogOpen = false
  }

  @computed
  get totalCombinedSquareFootage() {
    const { squareFeet, commonAreaSquareFeet } = this.form.basicInfoForm
    if (squareFeet.value === null || commonAreaSquareFeet.value === null)
      return null
    return (
      parseInt(squareFeet.value, 10) + parseInt(commonAreaSquareFeet.value, 10)
    )
  }

  @computed
  get canSubmit() {
    const { venueId, address } = this.form.basicInfoForm
    return venueId.value !== null && address.value
  }

  @action
  combineVenueWithListSpaceForm = venue => {
    const newForm = mergeVenueWithListSpaceForm(venue, this.form)
    this.form = newForm
  }

  @action
  combineSpaceWithListSpaceForm = space => {
    const newForm = mergeSpaceWithListSpaceForm(space, this.form)
    this.form = newForm
  }

  getCombinedAndSerializedForm = (form, formNames) => {
    const combinedForm = this._mergeForms(form, formNames)
    return new ListSpaceFormSerializer(combinedForm)
  }

  _mergeForms(form, formNames) {
    let combinedForm
    formNames.forEach(formName => {
      combinedForm = { ...combinedForm, ...form[formName] }
    })
    return combinedForm
  }

  @action
  updateSpaceMediaInfo = async (currentMedia, info) => {
    const media = await this.mediaStore.update(currentMedia.mediaId, info)
    if (media) {
      this.form.virtualTourForm.spacePhotos.value = this.form.virtualTourForm.spacePhotos.value.map(
        currentMedia => {
          return currentMedia.id === media.id ? media : currentMedia
        }
      )
    }
  }

  @action
  getStripeURLFromVenue = async venueId => {
    const {
      data: { data: venue }
    } = await venueApi.getVenueById(venueId, {
      include: ['address']
    })
    const stripe_user_params = {
      email: venue.email,
      url: venue.website,
      country: venue.address.country.code,
      phone_number: venue.phone,
      business_name: venue.name,
      business_type: 'company'
    }
    const stripeURL = generateStripeOAuthLink({
      stripe_user_params,
      state: venue.id
    })
    return stripeURL
  }

  fetchAndSetUserCompany = async companyId => {
    this.form.basicInfoForm.companyId = {
      value: companyId,
      error: null
    }
    if (!this.userStore.isCurrentCompanySet) {
      const { data: company } = await companyApi.fetchOne(companyId)
      if (company) {
        this.userStore.setCurrentCompany(company)
        this.globalStore.openNotificationSnackbar(
          `New "${
            company.name
          }" has been created and now set as your current context. `
        )
      }
    }
  }
}

export default new ListSpaceStore()
