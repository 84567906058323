import BaseApi from './baseApi'
import userStore from '../stores/user'
import qs from 'qs'

class TransactionsApi extends BaseApi {
  constructor() {
    super({ routeName: 'transactions' })
  }

  getMyTransactions = ({
    limit = 20,
    filters = {},
    sort = {},
    page,
    items_per_page
  }) => {
    return this.request(userStore.jwtToken).get('', {
      params: { filters, sort, limit, page, items_per_page },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getMyTransactionsByMyBookings = ({
    limit = 20,
    filters = {},
    sort = {},
    page,
    items_per_page
  }) => {
    return this.request(userStore.jwtToken).get('/my-bookings', {
      params: { filters, sort, limit, page, items_per_page },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getMyTransactionById = ({ limit = 20, filters = {}, sort = {} }) => {
    return this.request(userStore.jwtToken).get('', {
      params: { filters, sort, limit },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

const transactionsApi = new TransactionsApi()
export default transactionsApi
