const emptyTimeframe = {
  start_time: '08:00',
  end_time: '20:00'
}

const checkedAndTimeframes = {
  isChecked: false,
  timeframes: [emptyTimeframe]
}

export default [
  { name: 'Monday', dayId: 1, ...checkedAndTimeframes },
  {
    name: 'Tuesday',
    dayId: 2,
    ...checkedAndTimeframes
  },
  { name: 'Wednesday', dayId: 3, ...checkedAndTimeframes },
  { name: 'Thursday', dayId: 4, ...checkedAndTimeframes },
  { name: 'Friday', dayId: 5, ...checkedAndTimeframes },
  { name: 'Saturday', dayId: 6, ...checkedAndTimeframes },
  { name: 'Sunday', dayId: 7, ...checkedAndTimeframes }
]
