import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import PRIVACY_POLICY from '../../constants/PRIVACY_POLICY'
import TERMS_AND_CONDITIONS_OF_USE from '../../constants/TERMS_AND_CONDITIONS_OF_USE'
const styles = theme => ({
  inputSpacing: { marginBottom: theme.spacing.unit * 3 },
  dialogActionsContainer: {
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2
  },
  dialogHeaderText: { float: 'left' },
  closeIcon: {
    float: 'right',
    marginTop: theme.spacing.unit * -2,
    marginRight: theme.spacing.unit * -2
  },
  dialogContainer: {
    boxShadow:
      '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px 0 rgba(0,0,0,0.2)',
    height: 560,
    width: 'calc(100% - 70px)'
  },
  dialogContent: {
    fontSize: '14px'
  }
})

@inject('globalStore')
@observer
class PolicyDialog extends Component {
  policyDialogs = {
    space_rules: {
      title: 'Space Rules',
      content: <PRIVACY_POLICY />
    },
    privacy_policy: {
      title: 'Privacy Policy',
      content: <PRIVACY_POLICY />
    },
    cancellation_policy: {
      title: 'Cancellation Policy',
      content: <TERMS_AND_CONDITIONS_OF_USE /> // TODO replace with cancellation policy
    },
    terms_and_conditions: {
      title: 'Terms and Conditions',
      content: <TERMS_AND_CONDITIONS_OF_USE />
    }
  }
  render() {
    const {
      open,
      fullScreen,
      classes,
      globalStore: { currentOpenPolicy, closePolicyDialog }
    } = this.props

    const policy = this.policyDialogs[currentOpenPolicy] || {}
    return (
      <Dialog
        open={Boolean(open)}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        onClose={closePolicyDialog}
        BackdropProps={{
          classes: {
            root: classes.backdropModal
          }
        }}
      >
        <DialogTitle disableTypography style={{ paddingBottom: 24 }}>
          <Typography variant="h5" className={classes.dialogHeaderText}>
            {policy ? policy.title : null}
          </Typography>
          <IconButton
            onClick={closePolicyDialog}
            aria-label="Close"
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{policy ? policy.content : null}</DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withMobileDialog()(PolicyDialog))
