// #lizard forgives the complexity

import { action, observable, computed, runInAction, when } from 'mobx'

import api from '../utilities/api'
import amenitiesApi from '../services/amenitiesApi'
import culturesApi from '../services/culturesApi'
import termsApi from '../services/termsApi'
import spaceTypesApi from '../services/spaceTypesApi'
import venueTypesApi from '../services/venueTypesApi'
import productApi from '../services/productApi'
import moment from 'moment-timezone'
import rulesApi from '../services/rulesApi'
import tourApi from '../services/tourApi'
import venueApi from '../services/venueApi'
import userStore from './user'

import { trackEvent } from '@utilities/trackingUtils'

class GlobalStore {
  @computed
  get userCity() {
    const { city, state } = this
    if (!city && !state) return ''
    return `${this.city}, ${this.state}`
  }

  @computed
  get mapsApiUrl() {
    return `https://maps.googleapis.com/maps/api/js?key=${
      this.mapsApiKey
    }&v=3.exp&libraries=geometry,drawing,places`
  }

  @observable
  mapsApiKey = null

  @observable
  latitude = null
  @observable
  longitude = null
  @observable
  country = null
  @observable
  state = null
  @observable
  city = null
  @observable
  initialCitySlug = null

  @observable
  headerSearchVisibile = false

  @observable
  amenities = []
  @observable
  isLoadingAmenities = false

  @observable
  cultures = []
  @observable
  isLoadingCultures = false

  @observable
  terms = []
  @observable
  isLoadingTerms = false

  @observable
  spaceTypes = []
  @observable
  isLoadingSpaceTypes = false

  @observable
  venueTypes = []
  @observable
  isLoadingVenueTypes = false

  @observable
  productTypes = []
  @observable
  isLoadingProductTypes = false

  @observable
  productTypeGroups = []
  @observable
  isLoadingProductTypeGroups = false

  @observable
  isLoadingMeta = false

  @observable
  timezones = []

  @observable
  rules = []

  @observable
  isScheduleTourDialogOpen = false
  @observable
  isSavingTour = false

  @observable.deep
  exitConfirmationDialog = {
    open: false,
    callback: null,
    message: null
  }

  @observable.deep
  confirmationDialog = {
    open: false,
    callback: null,
    message: null,
    confirmText: null,
    cancelText: null,
    cancelOnly: false
  }

  @observable
  notificationSnackbar = {
    isOpen: false,
    action: null,
    message: null
  }

  @observable
  currentOpenPolicy = null

  @observable
  error404 = false

  @observable
  mobileSideNavOpen = false
  @observable
  mobileTopBarText = null

  @observable
  isGlobalSnackBarOpen = false

  @action
  setGlobalSnackBarOpen = isOpen => {
    this.isGlobalSnackBarOpen = isOpen
  }

  @action.bound
  setMobileSideNavOpen = open => {
    this.mobileSideNavOpen = open
  }

  @action.bound
  setMobileTopBarText = text => {
    this.mobileTopBarText = text
  }

  @action.bound
  async loadMeta(getSpaces = null) {
    this.isLoadingMeta = true
    try {
      const metaResponse = await api.getMeta()
      const {
        country,
        city,
        state,
        lat,
        lon,
        initialCitySlug,
        mapsApiKey
      } = metaResponse.data

      //Logo
      this.mapsApiKey = mapsApiKey

      //User Info
      this.country = country
      this.city = city
      this.state = state
      this.latitude = lat
      this.longitude = lon
      this.initialCitySlug = initialCitySlug

      if (getSpaces) getSpaces(this.latitude, this.longitude)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingMeta = false
    }
  }

  @action.bound
  async loadAmenities() {
    this.isLoadingAmenities = true
    const amenities = await amenitiesApi.getAll()
    this.isLoadingAmenities = false
    this.amenities = amenities.data
  }

  @action.bound
  async loadCultures() {
    this.isLoadingCultures = true
    const cultures = await culturesApi.getAll()
    this.isLoadingCultures = false
    this.cultures = cultures.data
  }

  @action.bound
  async loadTerms() {
    this.isLoadingTerms = true
    const terms = await termsApi.getTerms()
    this.isLoadingTerms = false
    this.terms = terms.data
  }

  @action.bound
  async loadSpaceTypes() {
    this.isLoadingSpaceTypes = true
    try {
      if (!this.spaceTypes.length) {
        const spaceTypes = await spaceTypesApi.getAll()
        this.spaceTypes = spaceTypes.data
      }
    } catch (error) {
      throw error
    } finally {
      this.isLoadingSpaceTypes = false
    }
  }

  @action.bound
  async loadVenueTypes() {
    this.isLoadingVenueTypes = true
    const venueTypes = await venueTypesApi.getAll()
    this.isLoadingVenueTypes = false
    this.venueTypes = venueTypes.data
  }

  @action.bound
  async loadProductTypeGroups() {
    this.isLoadingProductTypeGroups = true
    const productTypeGroups = await productApi.getAllProductTypeGroups()
    this.isLoadingProductTypeGroups = false
    this.productTypeGroups = productTypeGroups.data
  }

  @action.bound
  async loadProductTypes() {
    this.isLoadingProductTypes = true
    const productTypes = await productApi.getAllProductTypes()
    this.isLoadingProductTypes = false
    this.productTypes = productTypes.data
  }

  getSpaceTypeByName = spaceTypeName => {
    if (this.spaceTypes.length) {
      const spaceType = this.spaceTypes.find(
        spaceType =>
          spaceType.title.toLowerCase() === spaceTypeName.toLowerCase()
      )
      return spaceType ? spaceType : 'All Spaces'
    }
    return 'All Spaces'
  }

  @action.bound
  async loadTimezones() {
    const timezones = await moment.tz
      .names()
      .filter(name => name.includes('America'))
    runInAction(() => {
      this.timezones = timezones
    })
  }

  @action.bound
  async loadRules() {
    const rules = await rulesApi.getAll()
    this.rules = rules.data
  }

  _defaultCurrentTourDialog = {
    open: false,
    objectId: null,
    objectType: null,
    timezone: ''
  }

  @observable
  currentTourDialog = { ...this._defaultCurrentTourDialog }

  @action
  openTourDialog = currentTourDialog => {
    if (!userStore.loggedIn) {
      userStore.openAuthDialog('login')
      when(
        () => userStore.loggedIn,
        () => (this.currentTourDialog = { ...currentTourDialog, open: true })
      )
      return
    }
    this.currentTourDialog = { ...currentTourDialog, open: true }
  }

  @action
  closeTourDialog = () => {
    this.currentTourDialog = { ...this._defaultCurrentTourDialog }
  }

  @action
  openExitConfirmationDialog = (message, callback) => {
    this.exitConfirmationDialog = {
      open: true,
      message,
      callback
    }
  }

  @action
  closeExitConfirmationDialog = () => {
    this.exitConfirmationDialog = {
      open: false,
      message: null,
      callback: null
    }
  }

  @action
  openConfirmationDialog = ({
    callback,
    cancelText = null,
    cancelOnly = false,
    confirmText = null,
    description = null,
    message
  }) => {
    this.confirmationDialog = {
      callback,
      cancelText,
      cancelOnly,
      confirmText,
      description,
      message,
      open: true
    }
  }

  @action
  closeConfirmationDialog = () => {
    this.confirmationDialog = {
      open: false,
      callback: null,
      message: null,
      confirmText: null,
      cancelText: null,
      cancelOnly: false
    }
  }

  /**
   * @param dialog 'space_rules' | 'cancellation_policy' | 'terms_and_conditions'
   */
  @action
  openPolicyDialog = dialog => {
    this.currentOpenPolicy = dialog
  }
  @action
  closePolicyDialog = () => {
    this.currentOpenPolicy = null
  }

  @action
  saveTour = async tourObject => {
    this.isSavingTour = true
    try {
      tourObject.object_type = this.currentTourDialog.objectType
      tourObject.object_id = this.currentTourDialog.objectId
      const { data: tour } = await tourApi.create(tourObject)
      if (tour.error) throw new Error(tour.error)
      this.currentTourDialog = { ...this._defaultCurrentTourDialog }
      this.openNotificationSnackbar(
        `Your tour has been scheduled for ${tourObject.date.format(
          'MMM DD, YYYY'
        )} at ${moment(tourObject.startTime, 'Hm').format('hh:mma')}`,
        {
          actionTo: `/admin/tours/${tour.id}`,
          actionText: 'VIEW TOUR',
          showCloseAction: true
        },
        null
      )
      trackEvent({
        title: 'schedule tour',
        data: 'tour scheduled',
        overrides: { fb: ['schedule tour'] }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.isSavingTour = false
    }
  }

  @action
  openNotificationSnackbar = (message, actions = {}, autoHideDuration) => {
    const {
      actionTo = null,
      actionText = null,
      customActions = null,
      showCloseAction = false
    } = actions
    this.notificationSnackbar = {
      isOpen: true,
      message,
      actionTo,
      actionText,
      customActions,
      showCloseAction,
      autoHideDuration
    }
  }

  @action
  closeNotificationSnackbar = () => {
    this.notificationSnackbar = {
      isOpen: false,
      action: null,
      message: null
    }
  }

  @action
  sendVenueSuggestion = async (venueInfo = {}) => {
    try {
      await venueApi.suggestPlace(venueInfo)
      this.openNotificationSnackbar(
        'Thanks for letting us know about this new venue.'
      )
    } catch (error) {
      console.error(error)
    }
  }
}

export default new GlobalStore()
