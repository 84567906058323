import React, { Component, Fragment } from 'react'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

const styles = theme => {
  return {
    inputRoot: {
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '16px',
      width: '100%',
      '&:after': { borderBottomColor: theme.palette.common.white },
      '&:before': { borderBottomColor: theme.palette.common.white },
      '&:hover:not([disabled]):before': {
        borderBottomColor: `${theme.palette.common.white} !important`
      }
    },
    paper: {
      position: 'absolute',
      zIndex: 100,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0
    },
    menuItem: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '16px'
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 184
    }
  }
}

@inject('searchStore')
@observer
class HeaderSearchInput extends Component {
  static propTypes = {}
  state = {
    autoCompleteIsOpen: false,
    showMobileSearch: false
  }

  componentDidMount = async () => {
    const { location, showMobileSearch = false } = this.props
    if (location.search) {
      const searchQueryParams = new URLSearchParams(location.search)
      const query = searchQueryParams.get('location')

      if (query) {
        this._search(query)
      }
    }

    this.setState({ showMobileSearch })
  }

  _search = async query => {
    const {
      history,
      searchStore: {
        setQuery,
        setResultString,
        setLocation,
        setBounds,
        resetCurrentPage
      },
      onSearch
    } = this.props

    try {
      const results = await geocodeByAddress(query)
      const viewportBounds = results[0].geometry.viewport

      setQuery(query)
      setResultString(query)
      setLocation(query)
      setBounds(viewportBounds)
      resetCurrentPage()
      if (onSearch) {
        onSearch(null, viewportBounds)
      } else {
        history.push(`/search?location=${query}`)
      }
    } catch (error) {
      console.error('Error', error)
    }
  }

  _handleOnChange = query => {
    const {
      searchStore: { setQuery }
    } = this.props
    setQuery(query)
    this.setState({ autoCompleteIsOpen: true })
  }

  _handleOnClear = () => {
    const {
      setMobileSearch = null,
      searchStore: { setQuery, query }
    } = this.props

    this.setState({
      autoCompleteIsOpen: false
    })

    if (query) setQuery(null)

    if (setMobileSearch) setMobileSearch(false)

    this.setState({ showMobileSearch: false })
  }

  _handleOnSelect = async query => {
    const {
      searchStore: { setQuery }
    } = this.props
    setQuery(query)
    this.setState({ autoCompleteIsOpen: false })
    this._handleCloseMenu()

    if (query) this._search(query)
  }

  _handleCloseMenu = () => {
    this.setState({ autoCompleteIsOpen: false })
  }

  _handleSearchClick = () => {
    const {
      searchStore: { query }
    } = this.props
    if (query) this._search(query)
  }

  _handleShowSearchClick = () => {
    const { setMobileSearch = null } = this.props

    if (setMobileSearch) setMobileSearch(true)
    this.setState({ showMobileSearch: true })
  }

  _renderSearchInput = () => {
    const {
      classes,
      searchStore: { query }
    } = this.props
    const { autoCompleteIsOpen, showMobileSearch } = this.state
    const searchOptions = {
      types: ['geocode']
    }

    return (
      <Grid item xs={showMobileSearch ? 9 : 8} md={showMobileSearch ? 9 : 10}>
        <FormControl fullWidth>
          <PlacesAutocomplete
            value={query || ''}
            onChange={this._handleOnChange}
            onSelect={this._handleOnSelect}
            debounce={300}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions = [],
              getSuggestionItemProps,
              loading
            }) => (
              <div>
                {/* // TODO Something wrong with this use of PlacesAutoComplete */}
                <Input
                  placeholder="Search"
                  {...getInputProps()}
                  autoFocus
                  classes={{
                    root: classes.inputRoot
                  }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        color: 'white'
                      }}
                    >
                      <ClearIcon onClick={this._handleOnClear} />
                    </InputAdornment>
                  }
                />
                {autoCompleteIsOpen ? (
                  <Paper className={classes.paper} square>
                    {loading && (
                      <Grid container justify="center" alignItems="center">
                        <CircularProgress style={{ margin: 'auto' }} />
                      </Grid>
                    )}
                    {!loading && (
                      <Fragment>
                        {!suggestions.length && (
                          <MenuItem
                            key={'no results'}
                            className={classes.menuItem}
                            onClose={this._handleCloseMenu}
                            selected={false}
                          >
                            No Results
                          </MenuItem>
                        )}
                        {suggestions.length
                          ? suggestions.map(suggestion => {
                              return (
                                <MenuItem
                                  key={suggestion.index}
                                  {...getSuggestionItemProps(suggestion)}
                                  onClose={this._handleCloseMenu}
                                  className={classes.menuItem}
                                  selected={suggestion.active}
                                  title={suggestion.description}
                                >
                                  <span className={classes.description}>
                                    {suggestion.description}
                                  </span>
                                </MenuItem>
                              )
                            })
                          : null}
                      </Fragment>
                    )}
                  </Paper>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
        </FormControl>
      </Grid>
    )
  }

  render() {
    const { showMobileSearch } = this.state

    return (
      <Grid container item justify="flex-end" alignItems="center" xs={12}>
        <Grid
          container
          item
          justify="flex-end"
          alignItems="center"
          xs={showMobileSearch ? 3 : 12}
          md={showMobileSearch ? 3 : 2}
        >
          <IconButton
            style={{ color: 'white' }}
            onClick={
              !showMobileSearch
                ? this._handleShowSearchClick
                : this._handleSearchClick
            }
          >
            <SearchIcon />
          </IconButton>
        </Grid>
        <Hidden smDown>{this._renderSearchInput()}</Hidden>
        <Hidden mdUp>
          {showMobileSearch ? this._renderSearchInput() : null}
        </Hidden>
      </Grid>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(HeaderSearchInput)
