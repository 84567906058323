import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import FullStory, { FullStoryAPI } from 'react-fullstory'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

const appUrl = process.env.REACT_APP_APP_URL
const FULLSTORY_ORG_ID = 'EVZXJ'
const isProduction = process.env.NODE_ENV === 'production'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment:
    process.env.NODE_ENV || process.env.SENTRY_ENVIRONMENT || getEnvironment()
})

function getEnvironment() {
  if (process.env.REACT_APP_APP_URL.includes('development')) {
    return 'development'
  } else if (process.env.REACT_APP_APP_URL.includes('qa')) {
    return 'qa'
  } else {
    return isProduction ? 'production' : 'localhost'
  }
}

@inject('userStore')
@observer
class ThirdPartyServices extends Component {
  _renderGoogleAnalytics() {
    if (isProduction) {
      ReactGA.initialize('UA-84116311-1')
      ReactGA.tag
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }
  _renderGoogleTagManager() {
    if (isProduction) {
      loadGoogleAdwords()
    }
  }
  _renderFullStory() {
    const {
      userStore: { loggedIn, user }
    } = this.props
    if (loggedIn) {
      // Identify method
      FullStoryAPI('identify', user.email, {
        displayName: user.fullname
      })
    }
    return <FullStory org={FULLSTORY_ORG_ID} />
  }
  _renderFBPixel() {
    if (isProduction) {
      const FBCODE = '638082133367648'
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false // enable logs
      }
      ReactPixel.init(FBCODE, null, options)

      ReactPixel.pageView()
    }
  }
  render() {
    const bannedUrls = ['localhost']
    const isBannedUrl = bannedUrls.some(url => appUrl.includes(url))
    this._renderGoogleAnalytics()
    this._renderGoogleTagManager()
    this._renderFBPixel()
    return <Fragment>{!isBannedUrl ? this._renderFullStory() : null}</Fragment>
  }
}

export default ThirdPartyServices

/**
 *
 * @param {function} callback Function to be called once google adwords has loaded
 *
 * <!-- Global site tag (gtag.js) - Google Ads: 741690050 -->
 * <script async src="https://www.googletagmanager.com/gtag/js?id=AW-741690050"></script>
 * <script>
 *   window.dataLayer = window.dataLayer || [];
 *   function gtag(){dataLayer.push(arguments);}
 *   gtag('js', new Date());
 *   gtag('config', 'AW-741690050');
 * </script>
 *
 */
function loadGoogleAdwords(callback) {
  const adwordsID = 'AW-741690050'
  const existingScript = document.getElementById('googleAdwords')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${adwordsID}`
    script.id = 'googleAdwords'
    document.body.appendChild(script)

    script.onload = () => {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      const code = `
        window.dataLayer = window.dataLayer || []
        function gtag() {
          window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', '${adwordsID}')
      `
      try {
        s.appendChild(document.createTextNode(code))
        document.body.appendChild(s)
      } catch (e) {
        s.text = code
        document.body.appendChild(s)
      }
    }
  }

  if (existingScript && callback) callback()
}
