import BaseApi from './baseApi'
import userStore from '../stores/user'
import qs from 'qs'

class BookingApi extends BaseApi {
  constructor() {
    super({ routeName: 'bookings' })
  }

  fetch = filters => {
    return this.request(userStore.jwtToken).get('', {
      params: {
        filters
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchOne = bookingId => {
    const url = `/${bookingId}`
    return this.request(userStore.jwtToken).get(url)
  }

  getAll = ({ limit = 20 }) => {
    return this.request(userStore.jwtToken).get('', {
      params: { limit }
    })
  }

  fetchBookingsByVenueId = ({ venueId, limit = 20 }) => {
    return this.request(userStore.jwtToken).get('for-venue', {
      params: { venueId, limit }
    })
  }

  getMyBookings = params => {
    const url = '/my'
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  create = booking => {
    return this.request(userStore.jwtToken).post('', booking)
  }

  update = booking => {
    return this.request(userStore.jwtToken).put(`/${booking.id}`, booking)
  }

  cancelBooking = bookingId => {
    return this.request(userStore.jwtToken).post(`cancel`, {
      booking_id: bookingId
    })
  }

  sendBookingDetails = (bookingId, recipients) => {
    return this.request(userStore.jwtToken).post('email', {
      booking_id: bookingId,
      recipients
    })
  }

  isBookingAvailable = booking => {
    return this.request(userStore.jwtToken).post('isAvailable', booking)
  }

  updateSecurityDepositWithheld = (
    bookingId = null,
    amount = 0,
    reason = ''
  ) => {
    return this.request(userStore.jwtToken).post(
      `${bookingId}/updateSecurityDepositWithheld`,
      {
        amount,
        reason
      }
    )
  }

  getForVenue = (venueId, limit) => {
    return this.request(userStore.jwtToken).get(`venue/${venueId}`, {
      params: { limit }
    })
  }

  fetchBookingHistory = (bookingId, params) => {
    return this.request(userStore.jwtToken).get(`${bookingId}/history`, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

const bookingApi = new BookingApi()
export default bookingApi
