import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'
const styles = theme => ({
  closeIcon: {
    color: 'white',
    height: theme.spacing.unit * 3,
    width: theme.spacing.unit * 3,
    padding: 0
  },
  actionToButton: {
    marginRight: theme.spacing.unit * 2,
    padding: '8px 6px'
  }
})
class NotificationSnackbar extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string,
    onClose: PropTypes.func,
    autoHideDuration: PropTypes.number,
    customActions: PropTypes.array,
    actionTo: PropTypes.string,
    actionText: PropTypes.string,
    showCloseAction: PropTypes.bool
  }
  state = {
    open: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps != this.props) {
      this.setState({ open: nextProps.isOpen })
    }
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({ open: false })
    if (onClose !== undefined) {
      onClose()
    }
  }

  _renderAction = () => {
    const {
      actionTo = null,
      actionText = 'Go',
      customActions,
      showCloseAction,
      classes
    } = this.props
    return (
      <div className={classes.closeIconActionWrapper}>
        {customActions ||
          (Boolean(actionTo) && (
            <Button
              onClick={this.handleClose}
              component={Link}
              to={actionTo}
              color="primary"
              className={classes.actionToButton}
            >
              {actionText}
            </Button>
          )) ||
          null}
        {showCloseAction && (
          <IconButton
            className={classes.closeIcon}
            size="small"
            onClick={this.handleClose}
          >
            <CloseIcon styles={{ fontSize: 16 }} />
          </IconButton>
        )}
      </div>
    )
  }

  render() {
    const { isOpen, message, onClose, autoHideDuration = 4000 } = this.props
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{message}</span>}
        action={this._renderAction()}
      />
    )
  }
}

export default withStyles(styles)(NotificationSnackbar)
