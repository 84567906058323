import { observable, action, runInAction, toJS } from 'mobx'
import venueTypesApi from '../services/venueTypesApi'
import venueApi from '../services/venueApi'
import venueAdminApi from '../services/admin/venueApi'
import adminVenueApi from '../services/admin/venueApi'
import globalStore from './global'
import mediaStore from './media'
import userStore from './user'
import { mapPlaceTitleToImagesTitle } from '../utilities/mediaUtils'

const userVenuesDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

const productsDefaultPagination = {
  total: 0,
  perPage: 8,
  page: 1,
  lastPage: 1
}

const venueSubscriptionsDefaultPagination = {
  total: 0,
  perPage: 3,
  page: 1,
  lastPage: 1
}

const currentVenueSpacesDefaultPagination = {
  total: 0,
  perPage: 10,
  page: 1,
  lastPage: 1
}

class VenueStore {
  @observable
  currentListSpaceVenue = null
  @observable
  currentPage = 0
  @observable.deep
  currentVenue = null
  @observable
  currentVenueSpaces = []
  @observable
  isLoadingCreateVenue = false
  @observable
  isLoadingListSpaceVenue = false
  @observable
  isLoadingNearByVenues = false
  @observable
  isLoadingVenue = false
  @observable
  isLoadingVenueCommonAreas = false
  @observable
  isLoadingVenuesForUser = false
  @observable
  isLoadingVenuesForCompany = false
  @observable
  isLoadingVenueTypes = false
  @observable
  isLoadingVenueTypeGroups = false
  @observable
  isLoadingVenueSpaces = false
  @observable
  isSavingVenue = false
  @observable
  rowsPerPage = 20
  @observable
  venueCommonAreas = []
  @observable
  venueTypes = []
  @observable
  venueTypeGroups = []
  @observable
  currentVenueTypeGroup = null
  @observable
  venueTypesSpaceTypes = []
  @observable.deep
  venues = []
  @observable
  isTogglingVenueActive = false
  @observable
  toggleVenueActiveError = ''
  @observable
  isDeletingVenue = false

  @observable.deep
  userVenuesPagintaion = {
    ...userVenuesDefaultPagination
  }

  @observable.deep
  userVenuesInclude = []

  @observable
  isLoadingVenueMetrics = false
  @observable.deep
  currentMetrics = {
    BookingsCount: 0,
    ToursCount: 0,
    ActiveCustomersCount: 0,
    ProspectiveCustomersCount: 0
  }

  @observable
  sidebarCounts = {}
  @observable
  isLoadingSidebarCounts = false

  @observable
  currentProduct = null
  @observable
  isLoadingVenueProduct = false
  @observable
  isLoadingProductPreview = false
  @observable.deep
  venueProducts = []
  @observable
  productsPagination = {
    ...productsDefaultPagination
  }
  @observable
  isLoadingVenueProducts = false

  @observable
  venueTopPerformingProducts = []
  @observable
  isLoadingVenueTopPerformingProducts = false

  @observable.deep
  venueSubscriptions = []
  @observable
  productSubscriptionsPagination = {
    ...venueSubscriptionsDefaultPagination
  }
  @observable
  isLoadingVenueSubscriptions = false

  @observable
  currentVenueSpacesPagination = {
    ...currentVenueSpacesDefaultPagination
  }

  @observable
  isSyncingCalendar = false

  @observable.deep
  venueSpacesByAvailability = []
  @observable
  isLoadingVenueSpacesByAvailability = false

  @observable
  isLoadingVenuesByType = false

  constructor() {
    this.globalStore = globalStore
    this.mediaStore = mediaStore
    this.userStore = userStore
  }

  @action
  getProductById = async (venueId, productId) => {
    this.isLoadingVenueProduct = true
    try {
      const { data: product } = await venueApi.getProductById(
        venueId,
        productId
      )
      this.currentProduct = product
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueProduct = false
    }
  }

  @action
  getVenueProducts = async (venueId, productId = null) => {
    this.isLoadingVenueProducts = true
    try {
      const {
        data: { products, pagination }
      } = await venueApi.getVenueProducts(venueId, {
        pagination: this.productsPagination,
        exclude: productId
      })
      this.venueProducts = products
      this.productsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueProducts = false
    }
  }

  @action
  getVenueTopPerformingProducts = async venueId => {
    this.isLoadingVenueTopPerformingProducts = true
    try {
      const {
        data: topPerformingProducts
      } = await venueApi.getVenueTopPerformingProducts(venueId)
      this.venueTopPerformingProducts = topPerformingProducts
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueTopPerformingProducts = false
    }
  }

  @action
  getProductPreview = async (venueId, productId) => {
    this.isLoadingProductPreview = true
    try {
      const { data: product } = await venueApi.getProductPreview(
        venueId,
        productId
      )
      this.currentProduct = product
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error fetching the product preview.'
      )
    } finally {
      this.isLoadingProductPreview = false
    }
    return true
  }

  @action
  getVenuePublicProducts = async (venueId, productId = null) => {
    this.isLoadingVenueProducts = true
    try {
      const {
        data: { products, pagination }
      } = await venueApi.getVenuePublicProducts(venueId, {
        pagination: this.productsPagination,
        exclude: productId
      })
      this.venueProducts = products
      this.productsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueProducts = false
    }
  }

  @action
  togglePublished = async (venueId, productId) => {
    this.isSavingProduct = true
    try {
      const { data: product } = await venueApi.toggleProductPublished(
        venueId,
        productId
      )
      this.globalStore.openNotificationSnackbar(
        `Service has been ${product.is_published ? 'Published' : 'Unpublished'}`
      )
      this.getVenueProducts(venueId)
    } catch (error) {
      const errorMessage = error.data ? error.data.error : error
      this.globalStore.openNotificationSnackbar(`Error: ${errorMessage}`)
    } finally {
      this.isSavingProduct = false
    }
  }

  @action
  deleteProduct = async (venueId, productId) => {
    this.isSavingProduct = true
    try {
      await venueApi.deleteProduct(venueId, productId)
      this.globalStore.openNotificationSnackbar('Service has been deleted.')
      this.getVenueProducts(venueId)
    } catch (error) {
      const errorMessage = error.data ? error.data.error : error
      this.globalStore.openNotificationSnackbar(`Error: ${errorMessage}`)
    } finally {
      this.isSavingProduct = false
    }
  }

  @action
  setProductsPaginationProp = (prop, value) => {
    this.productsPagination[prop] = value
  }

  @action
  resetProductsPagination = () => {
    this.productsPagination = {
      ...productsDefaultPagination
    }
  }

  @action
  getVenueSubscriptions = async venueId => {
    this.isLoadingVenueSubscriptions = true
    try {
      const {
        data: { subscriptions, pagination }
      } = await venueApi.getVenueSubscriptions(venueId, {
        pagination: this.productSubscriptionsPagination
      })
      this.venueSubscriptions = subscriptions
      this.venueSubscriptionsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueSubscriptions = false
    }
  }

  @action
  setProductSubscriptionsPaginationProp = (prop, value) => {
    this.productSubscriptionsPagination[prop] = value
  }

  @action
  resetProductSubscriptionsPagination = () => {
    this.venueSubscriptionsPagination = {
      ...venueSubscriptionsDefaultPagination
    }
  }

  @action
  getVenueMetrics = async venueId => {
    this.isLoadingVenueMetrics = true
    try {
      const { data: currentMetrics } = await venueApi.getVenueMetrics(venueId)
      this.currentMetrics = currentMetrics
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueMetrics = false
    }
  }

  @action
  setCurrentVenue = currentVenue => {
    this.currentVenue = currentVenue
    userStore.updateUserCurrentVenue(currentVenue)
  }

  @action
  setCurrentVenueProp = (name, value) => {
    this.currentVenue[name] = value
  }

  @action.bound
  setCurrentVenueAddress(address) {
    if (!this.currentVenue.address) this.currentVenue.address = {}
    this.currentVenue.address.address = address
  }

  @action.bound
  setCurrentVenueGeocodedLocation(address) {
    this.currentVenue.address.geoCodedLocation = address
  }

  @action.bound
  setCurrentVenueAddressTwo(address) {
    if (!this.currentVenue.address) this.currentVenue.address = {}
    this.currentVenue.address.address_two = address
  }

  @action.bound
  setCurrentVenueTypeGroup(venueTypeGroup) {
    this.currentVenueTypeGroup = venueTypeGroup
  }

  @action.bound
  setCurrentVenueRecieveNotifications(recieveNotifications) {
    this.currentVenue.recieve_notifications = recieveNotifications
  }

  @action
  toggleVenueActive = async venue => {
    this.isTogglingVenueActive = true
    const { is_published } = venue
    const published = !is_published
    try {
      await venueApi.updateVenuePublishedStatus({
        venueId: venue.id,
        is_published: published
      })
      venue.is_published = published
      this.toggleVenueActiveError = ''
      this.globalStore.openNotificationSnackbar(
        `${venue.name} has been marked as ${
          venue.is_published ? 'Published' : 'Not Published'
        }`
      )
      return venue
    } catch (error) {
      const errorMessage = error.data ? error.data.error : error
      this.toggleVenueActiveError = errorMessage
      venue.is_published = !published
      this.globalStore.openNotificationSnackbar(`Error: ${errorMessage}`)
      return venue
    } finally {
      this.isTogglingVenueActive = false
    }
  }

  @action
  toggleCurrentVenueActive = async () => {
    const venue = await this.toggleVenueActive(this.currentVenue)
    this.currentVenue.is_published = venue.is_published
  }

  @action.bound
  setCurrentPage(currentPage) {
    this.currentPage = currentPage
  }

  @action.bound
  resetCurrentPage() {
    this.setCurrentPage(0)
  }

  @action.bound
  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage
  }

  @action.bound
  resetRowsPerPage() {
    this.setRowsPerPage(20)
  }

  @action.bound
  async getVenues() {
    const {
      data: { data: venuesData }
    } = await venueApi.getAll()
    this.venues = venuesData
  }

  @action.bound
  async saveCurrentVenue(currentVenue) {
    this.isSavingVenue = true
    /* eslint-disable */
    if (!currentVenue) {
      currentVenue = this.currentVenue
    }
    const { address, ...remainingVenueProperties } = currentVenue
    const { geoCodedLocation, ...existingAddress } = address
    const updatedAddress = geoCodedLocation || existingAddress
    const updatedVenue = {
      address: updatedAddress,
      ...remainingVenueProperties
    }

    const {
      spaces,
      allSpaces,
      terms,
      schedules,
      rules,
      ...venue
    } = Object.assign({}, updatedVenue)
    /* eslint-enable */
    try {
      const response = await venueApi.update(venue)
      const venueResponse = response.data.data
      venueResponse.medias = mapPlaceTitleToImagesTitle(
        venueResponse,
        venueResponse.medias
      )
      this.setCurrentVenue(Object.assign(currentVenue, venueResponse))
      this.globalStore.openNotificationSnackbar(
        `"${venue.name}" Venue has been updated.`
      )
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again.`
      )
      console.error(error)
    } finally {
      this.isSavingVenue = false
    }
  }

  @action.bound
  async saveCurrentVenueHolidays(data) {
    await venueApi.updateHolidays(this.currentVenue.id, data)
  }

  @action.bound
  async saveCurrentVenueBusinessHours(data) {
    await venueApi.updateBusinessHours(this.currentVenue.id, data)
  }

  @action.bound
  async saveCurrentVenueBuffers(data) {
    await venueApi.updateBuffers(this.currentVenue.id, data)
  }

  @action.bound
  async saveCurrentVenueRules(data) {
    await venueApi.updateRules(this.currentVenue.id, data)
  }

  @action.bound
  async getVenuesForUser(include, publishedOnly = null) {
    this.isLoadingVenuesForUser = true

    if (include) {
      this.userVenuesInclude = include
    }

    const venuesResponse = await venueApi.getForUser({
      include: toJS(this.userVenuesInclude),
      pagination: {
        page: this.userVenuesPagintaion.page,
        perPage: this.userVenuesPagintaion.perPage
      },
      filters: { publishedOnly }
    })

    const {
      data,
      metadata: { pagination }
    } = venuesResponse.data
    this.venues = data
    this.userVenuesPagintaion = pagination
    this.isLoadingVenuesForUser = false
  }

  @action.bound
  getListSpaceUserVenueById = async (
    venueId,
    include = [
      'address',
      'medias',
      'amenities',
      'schedules',
      'rules',
      'venueTypeGroup.venueTypes',
      'venueType.spaceTypes'
    ]
  ) => {
    this.isLoadingListSpaceVenue = true
    const {
      data: { data: venueData }
    } = await venueApi.getVenueById(venueId, {
      include
    })
    this.currentListSpaceVenue = venueData
    this.isLoadingListSpaceVenue = false
  }

  @action.bound
  resetListSpaceVenue = async () => {
    this.currentListSpaceVenue = null
    this.isLoadingListSpaceVenue = false
  }

  @action.bound
  async getVenueById(
    venueId,
    include = ['address', 'medias', 'rules'],
    includeNearbyVenues = false,
    cb = null
  ) {
    this.isLoadingVenue = true
    const response = await venueApi.getVenueById(venueId, {
      include
    })
    const venueResponse = response.data.data
    venueResponse.medias = mapPlaceTitleToImagesTitle(
      venueResponse,
      venueResponse.medias
    )
    this.currentVenue = venueResponse
    this.isLoadingVenue = false

    if (includeNearbyVenues) {
      const { data: nearbyVenuesData } = await venueApi.getNearbyVenuesById(
        venueId,
        {
          pagination: { page: 1, perPage: 8 }
        }
      )
      if (nearbyVenuesData) {
        this.currentVenue.nearByVenues = nearbyVenuesData
      }
    }
    if (cb) cb()
  }

  fetchOne = async venueId => {
    this.isLoadingVenue = true
    try {
      const response = await adminVenueApi.fetchOne(venueId)
      const venueResponse = response.data
      venueResponse.medias = mapPlaceTitleToImagesTitle(
        venueResponse,
        venueResponse.medias
      )
      venueResponse.allSpaces.forEach(space => {
        space.venue = {
          venueType: venueResponse.venueType
        }
      })
      this.currentVenue = venueResponse
      this.userStore.updateUserCurrentVenue(this.currentVenue)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenue = false
    }
    return this.currentVenue
  }

  @action
  getVenueSpaces = async (
    venueId,
    spacePagination = this.currentVenueSpacesPagination,
    include = ['spaceType', 'venue.venueType', 'amenities']
  ) => {
    this.isLoadingVenueSpaces = true
    try {
      const {
        data: {
          data: spaces,
          metadata: { pagination }
        }
      } = await venueApi.getVenueSpaces(venueId, {
        pagination: {
          page: spacePagination.page,
          perPage: spacePagination.perPage
        },
        include
      })
      const spacesResponse = spaces
      spacesResponse.forEach(space => {
        space.medias = mapPlaceTitleToImagesTitle(space, space.medias)
      })
      this.currentVenueSpaces = spacesResponse
      this.currentVenueSpacesPagination = pagination
      if (this.currentVenue && this.currentVenue.id === venueId) {
        this.currentVenue.spaces = spacesResponse
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueSpaces = false
    }
  }

  @action
  getVenueSpacesByAvailability = async (venueId, bookingForm = {}) => {
    this.isLoadingVenueSpacesByAvailability = true

    if (bookingForm.hourlyDateTimes) {
      bookingForm.hourlyDateTimes = bookingForm.hourlyDateTimes.map(
        hourly_date_time => ({
          start_time: hourly_date_time.startTime,
          end_time: hourly_date_time.endTime,
          ...hourly_date_time
        })
      )
    }

    try {
      const { data: spaces } = await venueApi.getVenueSpacesByAvailability(
        venueId,
        {
          ...bookingForm
        }
      )
      this.venueSpacesByAvailability = spaces
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueSpacesByAvailability = false
    }
  }

  @action
  resetVenueSpacesByAvailability = () => {
    this.venueSpacesByAvailability = []
  }

  @action
  getAllVenueSpaces = async (
    venueId,
    spacePagination = this.currentVenueSpacesPagination,
    include = ['spaceType', 'venue.venueType']
  ) => {
    this.isLoadingVenueSpaces = true
    try {
      const {
        data: {
          data: spaces,
          metadata: { pagination }
        }
      } = await venueAdminApi.getAllVenueSpaces(venueId, {
        pagination: {
          page: spacePagination.page,
          perPage: spacePagination.perPage
        },
        include
      })
      const spacesResponse = spaces
      spacesResponse.forEach(space => {
        space.medias = mapPlaceTitleToImagesTitle(space, space.medias)
      })
      this.currentVenueSpaces = spacesResponse
      this.currentVenueSpacesPagination = pagination
      if (this.currentVenue && this.currentVenue.id === venueId) {
        this.currentVenue.spaces = spacesResponse
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueSpaces = false
    }
  }

  @action
  resetCurrentVenueSpacesPagination = () => {
    this.currentVenueSpacesPagination = {
      ...currentVenueSpacesDefaultPagination
    }
  }

  @action
  getNearByVenuesById = async venueId => {
    this.isLoadingNearByVenues = true
    try {
      const {
        data: { data: nearbyVenues }
      } = await venueApi.getNearbyVenuesById(venueId, {
        pagination: { page: 1, perPage: 8 },
        include: ['venueType'],
        filters: { distance: 25 }
      })
      if (nearbyVenues) {
        if (this.currentVenue) {
          nearbyVenues.venues.forEach(venue => {
            venue.medias = mapPlaceTitleToImagesTitle(venue, venue.medias)
          })
          this.currentVenue.nearByVenues = nearbyVenues.venues
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingNearByVenues = false
    }
  }

  @action
  getVenuesByVenueType = async (venueTypeId, lat, lng) => {
    this.isLoadingVenuesByType = true
    try {
      const { data: venues } = await venueApi.getVenuesByVenueType(
        venueTypeId,
        {
          lat,
          lng
        }
      )
      this.venues = venues
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenuesByType = false
    }
  }

  @action
  loadVenueTypes = async () => {
    this.isLoadingVenueTypes = true
    if (!this.venueTypes.length) {
      const { data: venueTypes } = await venueTypesApi.getAll()
      this.venueTypes = venueTypes
    }
    this.isLoadingVenueTypes = false
  }

  @action
  loadVenueTypeGroups = async () => {
    this.isLoadingVenueTypeGroups = true
    if (!this.venueTypeGroups.length) {
      const { data: venueTypeGroups } = await venueTypesApi.getVenueTypeGroups()
      this.venueTypeGroups = venueTypeGroups
    }
    this.isLoadingVenueTypeGroups = false
  }

  @action
  setVenueTypesSpaceTypes = venueTypeSpaceTypes => {
    this.venueTypesSpaceTypes = venueTypeSpaceTypes
  }

  @action.bound
  async loadVenueCommonAreas(venueId) {
    this.isLoadingVenueCommonAreas = true
    try {
      const venueCommonAreas = await venueApi.getVenueCommonAreasByVenueId(
        venueId
      )
      runInAction(() => {
        this.isLoadingVenueCommonAreas = false
        this.venueCommonAreas = venueCommonAreas.data
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  @action
  async createVenue({
    venueTypeGroupId,
    venueTypeId,
    spaceTypeId,
    address,
    mapPinCoordinates
  }) {
    this.isLoadingCreateVenue = true
    try {
      const newVenueRequest = await venueApi.create({
        venueTypeGroupId,
        venueTypeId,
        spaceTypeId,
        address,
        mapPinCoordinates
      })
      runInAction(() => {
        this.isLoadingCreateVenue = false
      })
      if (newVenueRequest.data.error) return null
      return newVenueRequest.data.data
    } catch (error) {
      console.warn('error', error)
      return null
    }
  }

  @action
  handleChangeUserVenuesPage = page => {
    this.userVenuesPagintaion = {
      ...this.userVenuesPagintaion,
      page
    }
    this.getVenuesForUser(null, null)
  }

  @action
  resetUserVenuesPagination = () => {
    this.userVenuesPagintaion = {
      ...userVenuesDefaultPagination
    }
  }

  @action
  removeCurrentVenuePaymentAccount = async venue => {
    if (venue.is_published) {
      this.globalStore.openConfirmationDialog({
        message:
          'You cannot remove the payment account from a published Venue.',
        cancelOnly: true
      })
      return
    }
    venue.payment_account = null
    await this.saveCurrentVenue(venue)
  }

  @action
  syncCalendar = async id => {
    this.isSyncingCalendar = true
    try {
      if (!id && !this.currentVenue.calendar_id) {
        return
      }
      await venueApi.syncCalendar(id || this.currentVenue.id)
    } catch (e) {
      console.error('error syncing google calendar: ', e)
    } finally {
      this.isSyncingCalendar = false
    }
  }

  @action
  deleteVenue = async venueId => {
    this.isDeletingVenue = true
    try {
      await venueApi.delete(venueId)
      this.isDeletingVenue = false
      this.globalStore.openNotificationSnackbar('Venue has been deleted.')
      return true
    } catch ({ data }) {
      this.globalStore.openNotificationSnackbar(`Error: ${data.message}`)
      return false
    }
  }

  @action
  updateMediaInfo = async (currentMedia, info) => {
    const media = await this.mediaStore.update(currentMedia.id, info)
    if (media) {
      this.currentVenue.medias = this.currentVenue.medias.map(currentMedia => {
        return currentMedia.id === media.id ? media : currentMedia
      })
      this.globalStore.openNotificationSnackbar(
        `"${this.currentVenue.name}" Venue has been updated.`
      )
    } else {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again.`
      )
    }
  }

  @action
  addMediaToVenue = media => {
    media.object_id = this.currentVenue.id
    media.object_type = 'venues'
    media.index_position = this.currentVenue.medias.length + 1
    this.currentVenue.medias.push(media)
  }

  @action
  removeMediaFromVenue = media => {
    const index = this.currentVenue.medias.indexOf(media)
    if (index > -1) {
      this.currentVenue.medias.splice(index, 1)
    }
  }

  @action
  fetchSidebarCounts = async venueId => {
    this.isLoadingSidebarCounts = true
    try {
      const { data: sidebarCounts } = await venueApi.fetchSidebarCounts(venueId)
      this.sidebarCounts = sidebarCounts
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        'There was an error fetching the venues sidebar counts.'
      )
      return false
    } finally {
      this.isLoadingSidebarCounts = false
    }
    return true
  }
}

export default new VenueStore()
