import React from 'react'
import { withRouter } from 'react-router-dom'
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { state } = location
    if (
      !(state && state.disableScrollTop) &&
      this.props.location !== prevProps.location
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
