import React from 'react'

const termsAndConditionsOfUse = () => (
  <div style={{ paddingBottom: '24px' }}>
    <span style={{ fontFamily: 'Roboto, sans-serif' }}>
      <b>Terms and Conditions of Use</b>
      <p>
        The following terms and conditions ({'"Terms of Use"'}) govern the use
        of the website (the {'"Site"'}
        ), any mobile device software applications (the {'"Mobile Software"'}
        ), and any related services (together with the Site and Mobile Software,
        the {'"Service"'}) made available by <b>SpaceMX LLC,</b> ({'“us”'},{' '}
        {'“we,”'} or
        {'“The Company”'}
        ). By accessing, using or participating in the Service, you ({
          '"you"'
        }{' '}
        or the {'"User"'}) agree to be bound by these Terms of Use. We may
        modify, add, or delete portions of these Terms of Use, including the
        pricing terms, at any time. If you do not agree to these Terms of Use,
        you must cease using the Service. If we change these Terms of Use, we
        will post a notice that we have made changes to these Terms of Use on
        the Site for at least 7 days after the changes are posted and will
        indicate at the bottom of the Terms of Use the date these terms were
        last revised. Any revisions to these Terms of Use will become effective
        on the earlier of (i) the end of such 7-day period or (ii) the first
        time you access or use the Service after such changes have been posted.
        If you do not agree to abide by these Terms of Use, you are not
        authorized to use, access or participate in (or continue to use, access
        or participate in) the Service.
      </p>
      <p>
        THE SERVICE COMPRISES AN ONLINE PLATFORM THROUGH WHICH HOSTS (DEFINED
        BELOW) MAY CREATE LISTINGS FOR SPACES (DEFINED BELOW), AND GUESTS
        (DEFINED BELOW) MAY LEARN ABOUT AND BOOK SPACES DIRECTLY WITH THE HOSTS.
        YOU UNDERSTAND AND AGREE THAT THE COMPANY IS NOT A PARTY TO ANY
        AGREEMENTS ENTERED INTO BETWEEN HOSTS AND GUESTS, NOR IS THE COMPANY A
        REAL ESTATE BROKER, AGENT OR INSURER, EVEN IF GUESTS AND HOSTS USE THE
        SPACE LICENSE AGREEMENT (AS DEFINED BELOW) FOR THEIR AGREEMENT. THE
        COMPANY HAS NO CONTROL OVER THE CONDUCT OF HOSTS, GUESTS AND OTHER USERS
        OF THE SERVICE OR ANY SPACES, AND DISCLAIMS ALL LIABILITY IN THIS REGARD
        TO THE MAXIMUM EXTENT PERMITTED BY LAW. NOTWITHSTANDING ANYTHING TO THE
        CONTRARY IN THESE TERMS OF USE OR THE SPACE LICENSE AGREEMENT, NO
        PORTION OF THE FEES WILL DEEMED TO BE COMPENSATION FOR ANYTHING OTHER
        THAN THE PROVISION OF THE SERVICE.
      </p>
      <p>
        These Terms of Use include a release by you of all claims for damages
        against us that may arise out of your use of the Service. By accessing
        or using the Service, you are agreeing to this release.
      </p>
      <p>
        IF YOU ARE A RESIDENT OF THE UNITED STATES OR ACCESS OR USE THE SERVICE
        IN THE UNITED STATES, BY ACCEPTING THESE TERMS, YOU ARE AGREEING TO THE
        ARBITRATION AGREEMENT (UNLESS YOU FOLLOW THE OPT-OUT PROCEDURE) AND
        CLASS ACTION WAIVER DESCRIBED IN THE SECTION TITLED “ARBITRATION” BELOW
        TO RESOLVE ANY DISPUTES WITH THE COMPANY.
      </p>
      <b>Accounts; Registration Data; Account Security</b>
      <p>
        In order to reserve or otherwise schedule time in a workspace (a
        {'"Space”'}) from another User through the Service, or to offer for
        booking or otherwise make available your Space to another User through
        the Service, you must register for a User account. In these Terms of
        Use, Users that reserve or otherwise schedule time in a Space on the
        Service are referred to as “Guests” and Users that offer for booking or
        otherwise make available their Space through the Service are referred to
        as “Hosts.” In order to refer Guests or Members to the Service and in
        order to receive a Referral Fee (as defined in the Professional Terms),
        you must register for a {'“Professional”'} account. In these Terms of
        Use, Users with Professional accounts will be referred to as{' '}
        {'"Professionals."'}A User may be a Guest, a Host and/or a Professional,
        and may register for both a User account and a Professional account.
      </p>
      <p>
        In connection with registering for, using or participating in the
        Service (whether as a Guest, Host and/or Professional), you agree to (i)
        provide accurate, current and complete information about you and your
        organization as requested by The Company ({'"Registration Data"'}
        ); (ii) maintain the confidentiality of your password and other
        information related to the security of your account; (iii) maintain and
        promptly update the Registration Data and any other information you
        provide to The Company, to keep such information accurate, current and
        complete; and (iv) be fully responsible for all use of your account and
        for any actions that take place through your account.
      </p>
      <b>Hosts</b>
      <p>
        Use of the Service by Hosts is also governed by our Host Terms located
        at{' '}
        <a href="https://www.spacemx.com">
          <b>www.spacemx.com</b>
        </a>{' '}
        (the {'“Host Terms”'}
        ), which are incorporated into these Terms of Use by reference. By using
        the Service as a Host, you are consenting to the terms of the Host Terms
        as well.
      </p>
      <b>Professionals</b>
      <p>
        Use of the Service by Professionals is also governed by our Professional
        Terms located at{' '}
        <a href="https://www.spacemx.com">
          <b>www.spacemx.com</b>
        </a>{' '}
        (the {'“Professional Terms”'}
        ), which are incorporated into these Terms of Use by reference. By using
        the Service as a Professional, you are consenting to the terms of the
        Professional Terms as well.
      </p>
      <p>
        Transactions Between Guests, Hosts and Professionals on The Company
        Platform You acknowledge that the Service is a forum to allow anyone to
        find, schedule time in, reserve, book, offer to book, and make available
        Spaces. Your transactions, communications and interactions with any
        other Users (including any interactions between a Guest and a Host or
        between a Professional and a Guest), including transactions,
        communications and interactions initiated through the Service, are
        solely between you and such Users, and you are solely responsible for
        such transactions, communications and interactions. You acknowledge that
        The Company does not itself own or operate any Spaces nor does it sell,
        resell, furnish, provide, rent, re-rent, manage or control any Spaces,
        and The Company does not act as an agent or broker for any Host or any
        Guest. Instead, The Company provides the Service as a platform and its
        responsibilities are limited to: (i) facilitating the availability of
        the Site, Mobile Software and the Service to allow Guests, Hosts and
        Professionals to transact and communicate directly with one another, and
        (ii) serving as a limited payment collection agent if elected to do so
        by the Host under the terms of the section heading “Payment,” below. The
        Company is not party to transactions or communications between Guests,
        Host and Professionals, other than to the limited extent set out in
        these Terms of Use. In addition, The Company does not screen Hosts,
        Guests or Professionals, or the Spaces themselves – each Host is solely
        responsible for screening and assessing any Guests it is considering
        granting access to its Space, and each Guest is solely responsible for
        assessing the suitability of any Space it is considering using. The
        Company has no control over the quality, suitability or availability of
        any Space, or over the reliability, integrity or conduct of any Host,
        Guest or Professional. Notwithstanding these Terms of Use or the Host
        Terms, to the extent that The Company offers Space through the Service
        as a Host, it is subject to the terms applicable to Hosts.
      </p>
      <b>Agreements Between Guests and Hosts</b>
      <p>
        By completing a booking for a Space via the Service, each Guest
        acknowledges and agrees that it is entering into an agreement directly
        with the applicable Host, the terms of which are located at:{' '}
        <a href="https://www.spacemx.com">
          <b>www.spacemx.com</b>
        </a>
      </p>
      <ul>
        <li>for Hourly Bookings: (the “Hourly Space License Agreement”), or</li>
        <li>for Monthly Bookings: (the “Monthly Space License Agreement”),</li>
      </ul>
      <p>
        and which include any and all additional terms, conditions and policies
        provided by the Host relating to the use of such Space, including
        building security procedures, IT access and use procedures, maximum
        occupancy limitations and other terms or procedures provided by the Host
        (the {'"House Rules”'} and, together with the Hourly Space License
        Agreement or the Monthly Space License Agreement, as applicable, the{' '}
        {'“Space License Agreement”'}
        ).
      </p>
      <p>
        The following terms in the Space License Agreement shall be defined for
        the applicable booking in accordance with the Registration Data and
        Profile for the applicable Space, Guest and Host.
      </p>
      <b>For Hourly Bookings:</b>
      <ul>
        <li>
          the “Agreement Time” shall mean the date and time the booking is
          booked through the Service;
        </li>
        <li>
          the “Host” shall mean the Host and the Host’s email and address shall
          have the values set by the Host in the Host’s Profile as of the
          Agreement Time;
        </li>
        <li>
          the “Guest” shall mean the Guest and the Guest’s email and address
          shall have the values set by the Guest in the Guest’s Profile as of
          the Agreement Time;
        </li>
        <li>
          the “Space” shall be the Space the Guest reserves by booking a booking
          through the Service;
        </li>
        <li>
          the “Maximum Occupancy” shall be the value set by the Host in the
          Profile for the Space as of the Agreement Time;
        </li>
        <li>
          the “Building” shall be the Building in which the Space is located as
          set by the Host in the Profile for the Space as of the Agreement Time;
        </li>
        <li>
          the “Term” shall be the term of the booking specified by the Guest and
          authorized by the Host;
        </li>
        <li>
          the “Fees” shall be the Fees set by the Host for the Space including
          the “Hourly Charge” as set by the Host in the Profile for the Space as
          of the Agreement Time;
        </li>
        <li>
          the “Taxes” shall be the taxes set by the Host and collected by The
          Company on the Host’s behalf.
        </li>
      </ul>
      <b>For Monthly BOokings:</b>
      <ul>
        <li>
          the “Agreement Date” shall mean the date the booking is booked through
          the Service;
        </li>
        <li>
          the “Cancellation Terms” shall mean Flexible Cancellation Terms as
          selected for the Space by the Host as of the Agreement Date;
        </li>
        <li>
          the “Host” shall mean the Host and the Host’s email and address shall
          have the values set by the Host in the Host’s Profile as of the as of
          the Agreement Date;
        </li>
        <li>
          the “Guest” shall mean the Guest and the Guest’s email and address
          shall have the values set by the Guest in the Guest’s Profile as of
          the as of the Agreement Date;
        </li>
        <li>
          the “Space” shall be the Space the Guest reserves by booking a booking
          through the Service;
        </li>
        <li>
          the “Maximum Occupancy” shall be the value set by the Host in the
          Profile for the Space as of the Agreement Date;
        </li>
        <li>
          the “Building” shall be the Building in which the Space is located as
          set by the Host in the Profile for the Space as of the Agreement Date;
        </li>
        <li>
          the “Term” shall mean the period of time beginning on the Start Date
          and ending on the later of the End Date, if specified, or the Minimum
          Term number of months after the Start Date of the booking specified by
          the Guest and authorized by the Host;
        </li>
        <li>
          the “Fees” shall be the Fees set by the Host for the Space including
          the “Monthly Charge” and the “Setup Charge” as set by the Host in the
          Profile for the Space as of the Agreement Date;
        </li>
        <li>
          the “Deposit” shall be equal to the Monthly Charge for one month
          unless otherwise specified in the House Rules by the Host as of the
          Agreement Time;
        </li>
        <li>
          the “Taxes” shall be the taxes set by the Host and collected by The
          Company on the Host’s behalf.
        </li>
      </ul>
      <p>
        In the event of any conflict between the Space License Agreement and
        these Terms of Use, these Terms of Use shall govern and control.
      </p>
      <p>
        While The Company is not a party to the Space License Agreement between
        a Guest and a Host, a Guest’s use of the Service is dependent upon such
        Guest’s compliance with the Space License Agreement. Consequently, as a
        Guest, you agree to abide by, and cause your employees, agents, guests,
        invitees, contractors, directors, shareholders, members and
        representatives to abide by, the Space License Agreement. In the event
        of any conflict between the terms and conditions of the House Rules and
        the Hourly Space License Agreement or the Monthly Space License
        Agreement, as applicable, the terms set forth in the House Rules shall
        control. Any acknowledgement and acceptance of the Space License
        Agreement, as well as of any applicable House Rules, by a Guest as a
        part of completing a booking establishes an agreement directly between
        the Guest and the applicable Host. The Hosts, not The Company, are
        solely responsible for honoring any confirmed bookings and making
        available any Spaces reserved through the Service. Guests, and not The
        Company, will be responsible for complying with the applicable Space
        License Agreement and performing their obligations under any such
        agreements. The Company is not a party to those agreements, and The
        Company disclaims all liability arising from or related to any such
        agreements. The Company does not act as an insurer or as a Guest’s or
        Host’s real estate broker.
      </p>
      <p>
        The Company may, but has no obligation, to monitor disputes between you
        and any other Users relating to the Service. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW, The Company IS NOT RESPONSIBLE OR LIABLE IN
        ANY MANNER FOR ANY LOSS OR DAMAGE ARISING OUT OF YOUR TRANSACTIONS,
        COMMUNICATIONS AND INTERACTIONS WITH ANY OTHER USERS, INCLUDING ANY
        SPACE LICENSE AGREEMENTS THAT YOU ENTER INTO. THE COMPANY DOES NOT
        CONTROL THE CONTENT CONTAINED IN ANY LISTINGS BY HOSTS OR IN ANY
        COLLECTIONS (AS DEFINED BELOW), OR THE CONDITION, LEGALITY OR
        SUITABILITY OF ANY SPACES. YOU HEREBY RELEASE THE COMPANY FROM ANY AND
        ALL CLAIMS, CAUSES OF ACTIONS, OBLIGATIONS OR LIABILITIES ARISING FROM
        OR RELATING TO SUCH TRANSACTIONS, COMMUNICATIONS AND INTERACTIONS,
        INCLUDING ANY SPACE LICENSE AGREEMENTS THAT YOU ENTER INTO, LISTINGS ON
        THE SITE, COLLECTIONS SENT TO YOU BY ANOTHER USER AND THE QUALITY,
        CONDITIONS OR SUITABILITY OF ANY SPACE.
      </p>
      <p>
        You hereby acknowledge that you are aware of California Civil Code
        Section 1542, which provides as follows:§
      </p>
      <p>
        A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
        KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
        RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
        SETTLEMENT WITH THE DEBTOR.
      </p>
      <p>
        With full awareness and understanding of the above provisions, you
        hereby waive any rights you may have under Section 1542, as well as
        under any other statutes or common law principles of similar effect.
      </p>
      <b>Payment</b>
      <p>
        You agree to the fees and charges and other pricing terms applicable to
        your use of the Service as specified in these Terms of Use and as
        otherwise communicated to you through the Service, including any
        applicable Fees (defined below) and Service Charges (defined below). All
        fees and charges paid by Users in connection with the Service are
        non-refundable, except as expressly stated in these Terms of Use. The
        Company may change the fees and charges for the Service at any time in
        its sole discretion.
      </p>
      <p>
        If you are a Guest and you have been duly authorized to book the Space
        by the Host, when you complete a booking for a Space on the Service, you
        agree to pay the applicable fee for using the Space as set forth in the
        offering for such Space (the “Fee”) based on the type of booking booked
        (i.e. Hourly Booking vs. Monthly Booking (each, defined below)). The Fee
        for an Hourly Booking is the rate per hour of the booking and the Fee
        for a Monthly Booking is the rate per calendar month of the booking.
        “Hourly Bookings” are bookings with duration of 24 hours or less
        including any bookings made for a full day, as well as recurring
        bookings where each individual booking is a full day or less. “Monthly
        Bookings” are bookings with a minimum duration of one calendar month,
        including bookings that automatically renew on a month-to-month basis.
      </p>
      <p>
        You will be billed the Fee through one of the two following options, as
        determined by the Host: <br />
        (1) The Company will ask for your credit card information and billing
        address or other payment information, and The Company will process your
        Fee payment on behalf of the Host (the “The Company Billing Option”), or
        (2) the Host will bill you directly for the Fee, and the Host will
        process your Fee payment directly (the “Host Billing Option”). Under The
        Company Billing Option, The Company is acting as the Host’s limited
        payment collection agent, and the Fee paid through this option shall be
        considered the same as a payment made directly to the Host.
      </p>
      <p>
        The amount and timing of the Fee payments processed by The Company on
        behalf of the Host is based on the type of booking booked. For Hourly
        Bookings booked under The Company Billing Option, The Company will
        process payment for such bookings 24 hours before the booking starts.
        For Hourly Bookings, you will be charged the Fee for the total number of
        hours booked including any taxes as set forth in the offering for the
        Space (the “Hourly Booking Payment”). Any changes you make to your
        booking after it is made may result in an adjustment to the Hourly
        Booking Payment for the booking subject to the Cancellation Terms
        (defined below). In the event you use the Space for longer than the
        amount of time you booked for your booking, the applicable Host may
        adjust the Hourly Booking Payment for your booking based on your actual
        use of the Space. If you believe a change to your Hourly Booking Payment
        made by a Host was in error, you may request a refund by providing
        notice to The Company through the Service. The Company may provide you a
        refund at The Company’s sole discretion.
      </p>
      <p>
        Monthly Bookings may only be booked under the Company Billing Option and
        require a minimum length of one calendar month. For Monthly Bookings,
        The Company will process the initial payment for such bookings within 24
        hours after you complete your booking on the Service. For Monthly
        Bookings, the initial payment will be equal to (1) the Setup Charge (as
        defined in the Space License Agreement), (2) (i) the Fee for the first
        full calendar month of the booking (if you start your booking on the
        first calendar day of the month), or (ii) a pro-rated portion of the Fee
        for the first partial month of the booking (if you start your booking
        after the first calendar day of the month), and (3) any applicable
        taxes. In addition, The Company will also process an additional amount
        equal to the Fee for a full calendar month (the “Deposit”).
      </p>
      <p>
        Renewals of Monthly Bookings, if any, shall be in accordance with the
        Space
      </p>
      <b>License Agreement.</b>
      <p>
        The Deposit will be collected by The Company on behalf of the Host and
        held for the benefit of the Guest and Host during the term of the
        booking pursuant to the terms and conditions of the Space License
        Agreement.
      </p>
      <p>
        If you are a Host or a Professional, you acknowledge that The Company
        accepts payments from Guests as a limited collection agent for the Host
        and that The Company’s obligation to pay the Host or a Professional is
        subject to and conditional upon successful receipt of the associated
        payments from Guests. The Company does not guarantee payments to Hosts
        or Professionals for amounts that have not been successfully received by
        The Company from Guests.
      </p>
      <p>
        Any bookings that are cancelled by the Guest or by the Space at the
        {"Guest's"} request are subject to the Cancellation Terms.
      </p>
      <b>Cancellation Terms</b>
      <p>
        Cancellations of any Space License Agreement shall be governed by the
        terms and conditions of the Space License Agreement (the
        {'"Cancellation Terms"'}
        ).{' '}
      </p>{' '}
      <b>Privacy</b>
      <p>
        Use of the Service is also governed by our Privacy Policy, a copy of
        which is located at www.spacemx.com/privacy (the “Privacy Policy”). By
        using the Service, you are consenting to the terms of the Privacy
        Policy.
      </p>{' '}
      <b> Proprietary Rights in Site Content; Limited License</b>
      <p>
        All content on the Site and otherwise available through the Service,
        including User Content, designs, text, graphics, images, video,
        information, software, audio and other files, and their selection and
        arrangement (the {'"Site Content"'}
        ), are the proprietary property of The Company, its Users or its
        licensors. No Site Content may be modified, copied, distributed, framed,
        reproduced, republished, downloaded, scraped, displayed, posted,
        transmitted, or sold in any form or by any means, in whole or in part,
        other than as expressly permitted in these Terms of Use. Users may
        access and use the Service and the Site Content and download or print a
        reasonable number of copies of portions of the Site Content to which the
        User has properly gained access (a) solely for the {"User's"} personal,
        non-commercial use, or (b) solely for the purpose of referring third
        parties to the Service or for compiling a collection of Space listings
        via the Service (a “Collection”), provided in each case that the User
        maintains all copyright or other proprietary notices on all copies of
        such Site Content.
      </p>
      <p>
        Except for your own User Content or for use in a Collection, you may not
        publish or otherwise distribute Site Content, including via the Internet
        or any intranet or extranet site, or incorporate the Site Content in any
        other database or compilation (other than in a Collection). You may not
        use any data mining, robots, scraping or similar data gathering or
        extraction methods to obtain Site Content. Any use of the Site or the
        Site Content other than as specifically authorized herein is prohibited
        and will automatically terminate your rights with respect to use of the
        Service and the Site Content granted herein. All rights of The Company
        or its licensors that are not expressly granted in these Terms of Use
        are reserved to The Company and its licensors.
      </p>
      <b>User Content Posted on the Service</b>
      <p>
        You may be able to display certain information on designated portions of
        the Site or otherwise through the Service regarding you or your company
        or organization including, if you are a Host, your Space (a{' '}
        {'"Profile"'}
        ). Your Profile will display to other Users certain of your Registration
        Data and other content about you or your company or your organization
        including, if you are a Host, your Space, that you upload or otherwise
        provide to The Company for use in your Profile (collectively the
        {'"Profile Content"'}
        ). You agree to provide accurate and current information in your Profile
        and to promptly update the Profile Content to keep it accurate and
        current. You are solely responsible for the Profile Content, messages,
        notes, text, information, offerings, images, links and any other content
        that you upload, publish, display or otherwise provide to The Company
        for display (hereinafter, {'"post"'}) on or through the Service, or
        transmit to or share with other Users or to third parties via the
        Service (collectively, {'"User Content"'}
        ). You may not post, transmit, or distribute User Content that you did
        not create or that you do not have permission to post. You understand
        and agree that The Company may, but is not obligated to, review the Site
        and may delete or remove (without notice) any Site Content or User
        Content in its sole discretion, for any reason or no reason, including
        User Content that The Company believes violates these Terms of Use. The
        Company has no backup or storage obligations regarding User Content. You
        are solely responsible at your sole cost and expense for creating backup
        copies and replacing any User Content you post or store on the Site or
        provide to The Company.
      </p>
      <p>
        The Company does not verify the accuracy, completeness, reliability or
        authenticity of any User Content, including any Space descriptions or
        Space availability information provided by Hosts or Professionals, and
        makes no representations or warranties with respect to any User Content.
      </p>
      <p>
        When you post User Content to the Site, you grant to The Company an
        irrevocable, perpetual, non-exclusive, transferable, fully paid,
        worldwide license (with the right to sublicense) to use, reproduce,
        publicly perform, publicly display, modify, translate, excerpt (in whole
        or in part), publish and distribute such User Content. Subject to the
        rights granted to us in these Terms of Use, you retain ownership of your
        User Content.
      </p>
      <b>Trademarks</b>
      <p>
        <b>{'"SpaceMX"'}</b> is a registered trademark of SpaceMX LLC{' '}
        {'"The Company"'} together with the other graphics, logos, designs, page
        headers, button icons, scripts and service names on the Site are the
        trademarks or trade dress of The Company in the U.S. and other countries
        ({'"The Company Marks"'}
        ). You may not use The Company Marks, including as part of trademarks
        and/or as part of domain names, in connection with any product or
        service in any manner that is likely to cause confusion or create the
        impression that The Company endorses any product or service. You may not
        reproduce or use The Company Marks without the prior written permission
        of The Company.
      </p>
      <b>User Conduct</b>
      <p>
        You agree not to do any of the following in connection with your use of
        the Service and to otherwise use the Service in compliance with these
        Terms of Use.
      </p>
      <ul>
        <li>
          use automated scripts to collect information from or otherwise
          interact with the Service;
        </li>
        <li>
          use manual or automated software, devices, scripts, robots, backdoors
          or other means or processes to access,
          {'"scrape," "crawl" or "spider"'} any web pages or other services
          contained in the Service;
        </li>
        <li>
          impersonate any person or entity, or falsely state or otherwise
          misrepresent you or your affiliation with any person or entity,
          including giving the impression that any content you upload, post,
          transmit, distribute or otherwise make available emanates from The
          Company;
        </li>
        <li>
          upload, post, transmit, distribute or otherwise make available any
          unsolicited or unauthorized advertising, solicitations, promotional
          materials,
          {'"junk mail," "spam," "chain letters," "pyramid schemes,"'} or any
          other prohibited form of solicitation;
        </li>
        <li>
          upload, post, transmit, distribute, store or otherwise make publicly
          available on the Site any private information of any third party,
          including, addresses, phone numbers, email addresses, Social Security
          numbers and credit card numbers;
        </li>
        <li>
          interfere with or damage the Service, including, without limitation,
          through the use of viruses, cancel bots, Trojan horses, harmful code,
          flood pings, denial-of-service attacks, backdoors, packet or IP
          spoofing, forged routing or electronic mail address information or
          similar methods or technology;
        </li>
        <li>
          intimidate or harass another, or promote sexually explicit material,
          violence or discrimination based on race, sex, religion, nationality,
          disability, sexual orientation or age;
        </li>
        <li>
          upload, post, transmit, distribute or otherwise make available any
          material which does or may infringe any copyright, trade mark or other
          intellectual property rights of any other person;
        </li>
        <li>
          upload, post, transmit, distribute or otherwise make available any
          material which is defamatory of any person, obscene, offensive,
          hateful or inflammatory;
        </li>
        <li>
          upload, post, transmit, distribute, store or otherwise make available
          content that would constitute, encourage or provide instructions for a
          criminal offense;
        </li>
        <li>
          use or attempt to use
          {"another's"} account, service or system without authorization from
          The Company, or create a false identity on the Service;
        </li>
        <li>
          use the Service in a manner that may create a conflict of interest or
          undermine the purposes of the Service, such as trading reviews with
          other Users or writing or soliciting shill reviews;
        </li>
        <li>
          use the Service in a manner that violates any law (including the
          CAN-SPAM Act of 2003) or otherwise conduct illegal activities in
          connection with your use of the service
        </li>
        <li>
          upload, post, transmit, distribute, store or otherwise make available
          any answers, responses, comments, opinions, analysis or
          recommendations that you are not properly licensed or otherwise
          qualified to provide; or
        </li>
        <li>
          upload, post, transmit, distribute, store or otherwise make available
          content that, in the sole judgment of The Company, is objectionable or
          which restricts or inhibits any other person from using the Site, or
          which may expose The Company or its users to any harm or liability of
          any type.
        </li>
      </ul>
      <b>No High Risk Use</b>
      <p>
        You may not use the Service in any situation where failure or fault of
        any kind of the Service could lead to death or serious bodily injury of
        any person, or to physical or environmental damage ({'"High Risk Use"'}
        ). High Risk Use is STRICTLY PROHIBITED, and The Company expressly
        disclaims any liability that may result from your High Risk Use of the
        Service or any Space, to the extent permitted under applicable law. High
        Risk Use includes, for example, the following: aircraft or other modes
        of human mass transportation, nuclear or chemical facilities, and Class
        III medical devices under the Federal Food, Drug and Cosmetic Act.
      </p>
      <b>Additional Representations and Warranties</b>
      <p>
        In addition to any other representations and warranties in these Terms
        of Use, you represent and warrant that:
      </p>
      <ul>
        <li>
          the User Content and all other content submitted to the Service
          through your account or otherwise posted, transmitted, or distributed
          by you on or through the Service: (i) does not violate or infringe
          upon the rights of any third party (including copyright, trademark,
          privacy, publicity or other personal or proprietary rights), (ii) does
          not contain libelous, defamatory or otherwise unlawful material, and
          (iii) is truthful and accurate; and
        </li>
        <li>
          you are not located in a country that is subject to a U.S. government
          embargo or that has been designated by the U.S. government as a{' '}
          {'"terrorist supporting"'} country, and you are not listed on any U.S.
          government list of prohibited or restricted parties.
        </li>
      </ul>{' '}
      <b>Third Party Websites and Content</b>{' '}
      <p>
        The Site may contain (or you may be sent through the Service) links to
        other web sites ({'"Third Party Sites"'}) as well as articles,
        photographs, text, graphics, pictures, designs, sound, video,
        information, and other content or items belonging to or originating from
        third parties (the {'"Third Party Content"'}
        ). Such Third Party Sites and Third Party Content are not investigated,
        monitored or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third Party Sites accessed
        through the Site or any Third Party Content posted on, available through
        the Site, including the content, accuracy, offensiveness, opinions,
        reliability, privacy practices or other policies of or contained in the
        Third Party Sites or the Third Party Content. Inclusion of, linking to
        or permitting the use of any Third Party Site or any Third Party Content
        does not imply approval or endorsement thereof by The Company. If you
        decide to leave the Site and access the Third Party Sites or to access
        or use any Third Party Content, you do so at your own risk and you
        should be aware that our terms and policies no longer govern. You should
        review the applicable terms and policies, including privacy and data
        gathering practices, of any site to which you navigate from the Site or
        relating to any applications you use or install from the site.
      </p>{' '}
      <b>Eligibility</b>
      <p>
        This Service is intended solely for users who are 18 years of age or
        older. Any registration by, use of or access to the Service by anyone
        under 18 is unauthorized and in violation of these Terms of Use. By
        using the Service, you represent and warrant that you are 18 years of
        age or older.
      </p>{' '}
      <b>Mobile Software License</b>
      <p>
        Subject to the terms of these Terms of Use, The Company grants you a
        non-transferable, non-exclusive license to download, install and use one
        copy of the Mobile Software in object code form only on a mobile device
        that you own or control. You may not derive or attempt to derive the
        source code of all or any portion of the Mobile Software, permit any
        third party to derive or attempt to derive such source code, or reverse
        engineer, decompile, disassemble, or translate the Mobile Software or
        any part thereof.
      </p>
      <p>
        The Company and its licensors own and shall retain all intellectual
        property rights and other rights in and to the Mobile Software, and any
        changes, modifications or corrections thereto.
      </p>
      <p>
        The following terms and conditions apply to you only if you are using
        Mobile Software from the Apple App Store. To the extent the other terms
        and conditions of these Terms of Use are less restrictive than, or
        otherwise conflict with, the terms and conditions of this paragraph, the
        more restrictive or conflicting terms and conditions in this paragraph
        apply, but solely with respect to Mobile Software from the Apple App
        Store. You acknowledge and agree that these Terms of Use are solely
        between you and The Company, not Apple, and that Apple has no
        responsibility for the Mobile Software or content thereof. Your use of
        the Mobile Software must comply with the App Store Terms of Service. You
        acknowledge that Apple has no obligation whatsoever to furnish any
        maintenance and support services with respect to the Mobile Software. In
        the event of any failure of the Mobile Software to conform to any
        applicable warranty, you may notify Apple, and Apple will refund the
        purchase price,
      </p>
      <p>
        if any, for the Mobile Software to you; to the maximum extent permitted
        by applicable law, Apple will have no other warranty obligation
        whatsoever with respect to the Mobile Software, and any other claims,
        losses, liabilities, damages, costs or expenses attributable to any
        failure to conform to any warranty will be solely governed by these
        Terms of Use. You and The Company acknowledge that Apple is not
        responsible for addressing any claims of you or any third party relating
        to the Mobile Software or your possession and/or use of the Mobile
        Software, including, but not limited to: (i) product liability claims;
        (ii) any claim that the Mobile Software fails to conform to any
        applicable legal or regulatory requirement; and (iii) claims arising
        under consumer protection or similar legislation. You and The Company
        acknowledge that, in the event of any third party claim that the Mobile
        Software or your possession and use of that Mobile Software infringes
        that third {"party's"} intellectual property rights, The Company, not
        Apple, will be solely responsible for the investigation, defense,
        settlement and discharge of any such intellectual property infringement
        claim to the extent required by these Terms of Use. You must comply with
        applicable third party terms of agreement when using the Mobile
        Software. You and The Company acknowledge and agree that Apple, and
        {"Apple's"} subsidiaries, are third party beneficiaries of these Terms
        of Use as they relate to your license of the Mobile Software, and that,
        upon your acceptance of the Terms of Use, Apple will have the right (and
        will be deemed to have accepted the right) to enforce these Terms of Use
        against you as a third party beneficiary thereof.
      </p>
      <b>Copyright Policy</b>
      <p>
        The Company respects the intellectual property rights of others and
        expects Users of the Service to do the same. The Company complies with
        the federal Digital Millennium Copyright Act (“DMCA”), the text of which
        may be found on the U.S. Copyright Office Website at{' '}
        <a href="http://www.copyright.gov/legislation/dmca.pdf">
          http://www.copyright.gov/legislation/dmca.pdf
        </a>
        . We will respond to notices of alleged copyright infringement that
        comply with the DMCA and other applicable law and are properly provided
        to us.
      </p>
      <p>
        If you believe that any User Content has been copied or used in a way
        that constitutes copyright infringement, please provide us with the
        following information:
      </p>
      <ul>
        <li>
          a physical or electronic signature of the copyright owner or a person
          authorized to act on their behalf;
        </li>
        <li>
          identification of the copyrighted work claimed to have been infringed;
        </li>
        <li>
          identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
        </li>
        <li>
          your contact information, including your address, telephone number,
          and an email address;
        </li>
        <li>
          a statement by you that you have a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law; and
        </li>
        <li>
          a statement that the information in the notification is accurate, and,
          under penalty of perjury, that you are authorized to act on behalf of
          the copyright owner. If you believe that your User Content that was
          removed after we received a notice of copyright infringement is not
          actually infringing, or that you have the necessary rights to post
          your User Content, please send us a counter-notice containing the
          following information:
        </li>
        <li>
          your physical or electronic signature (with your full legal name); •
          identification of the User Content that has been removed or to which
          access has been disabled and the location at which the User Content
          appeared before it was removed or disabled;
        </li>
        <li>
          a statement that you have a good faith belief, under penalty of
          perjury, that the User Content was removed or disabled as a result of
          mistake or a misidentification of the User Content; and
        </li>
        <li>
          your name, address, telephone number, and email address, and a
          statement that you will accept service of process from the person who
          provided the original notification of the alleged infringement.
        </li>
      </ul>
      <p>
        If we receive a counter-notice, we may send a copy of the counter-notice
        to the person alleging copyright infringement and inform that person
        that we may replace the removed User Content in ten business days.
        Unless the original person alleging copyright infringement files an
        action seeking a court order against the User Content provider, member
        or User, the removed User Content may be replaced, in 10 to 14 business
        days or more after receipt of the counter-notice, at The Company’s sole
        discretion.
      </p>
      <p>
        Filing a counter-notification may lead to legal proceedings between you
        and the complaining party to determine ownership. Be aware that there
        may be adverse legal consequences in your country if you make a false or
        bad faith allegation by using this process.
      </p>
      <p>
        We reserve the right to remove User Content alleged to be infringing
        without prior notice and at our sole discretion. In appropriate
        circumstances, The Company may also terminate a {"User's"} account if
        the User is determined to be a repeat infringer. The Company’s
        designated copyright agent for notice of alleged copyright infringement
        appearing on the Services is:
      </p>
      <b>SpaceMX LLC</b>
      <br />
      <b>388 Market Street, Suite 1300</b>
      <br />
      <b>San Francisco, CA 94111</b>
      <br />
      <b>415-837-3200</b>
      <br />
      <br />
      <b>Disclaimers</b>
      <p>
        The Company is not responsible or liable in any manner for any User
        Content or Third Party Content posted on the Site or displayed in
        connection with the Service, including but not limited to the accuracy
        of any Space descriptions, Space availability information or Guest
        information. Although we provide rules for User conduct and postings, we
        do not control and are not responsible for what Users post, transmit or
        distribute on the Site and are not responsible for any offensive,
        inappropriate, obscene, unlawful or otherwise objectionable content you
        may encounter on the Site or in connection with any User Content or
        Third Party Content. The Company is not responsible for the conduct,
        whether online or offline, of any User, including any {"Guest's"}
        non-compliance with any terms, conditions and policies relating to the
        use of any Space. The Service may be temporarily unavailable from time
        to time for maintenance or other reasons. The Company shall have no
        responsibility for any interruption, delay in operation or transmission,
        theft or destruction of, unauthorized access to, or alteration of, User
        communications or any other content made available via the Service.
        Under no circumstances will The Company be responsible for any personal
        injury or death resulting from the use of the Service, any User Content
        or Third Party Content, or any use of Spaces, products or services
        provided by Users.
      </p>
      <p>
        THE COMPANY PROVIDES THE SERVICE, INCLUDING ALL CONTENT THEREIN, TO
        HOSTS, GUESTS AND PROFESSIONALS {'"AS IS,"'} AND, EXCEPT AS PROVIDED
        HEREIN, AND THE COMPANY AND ITS SUPPLIERS DISCLAIM ANY AND ALL OTHER
        REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SERVICE AND THE
        SPACES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF
        TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
        NON-INFRINGEMENT. THE COMPANY CANNOT GUARANTEE AND DOES NOT PROMISE TO
        HOSTS, GUESTS AND PROFESSIONALS, AND HOSTS AND PROFESSIONALS CANNOT
        GUARANTEE AND DO NOT PROMISE TO GUESTS, ANY SPECIFIC RESULTS FROM USE OF
        THE SERVICE OR A SPACE. WITHOUT LIMITING THE FOREGOING, THE COMPANY DOES
        NOT REPRESENT OR WARRANT THAT THE SOFTWARE, CONTENT OR MATERIALS MADE
        AVAILABLE THROUGH THE SERVICE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT
        OR ERROR-FREE; THE COMPANY DOES NOT REPRESENT OR WARRANT THAT THE
        SERVICE OR ANY SPACE BOOKING OR BOOKING WILL MEET YOUR REQUIREMENTS;
        THAT ANY SPACE MEETS APPLICABLE LEGAL STANDARDS AND IS SAFE AND SUITABLE
        FOR YOUR INTENDED USE; AND THE COMPANY DOES NOT REPRESENT OR WARRANT
        THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
        UNINTERRUPTED OR SECURE, THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED,
        OR THAT THE SERVICE, OR ITS SERVERS, ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. THE DISCLAIMERS SET FORTH ABOVE IN THIS SECTION
        APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
      </p>
      <b>Limitation on Liability</b>
      <p>
        IN NO EVENT WILL THE COMPANY OR ITS DIRECTORS, EMPLOYEES, AGENTS,
        AFFILIATES OR SUPPLIERS, BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
        DAMAGES, INCLUDING FOR ANY LOST PROFITS, LOST DATA, PERSONAL INJURY OR
        PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, ARISING FROM YOUR USE OF THE
        SERVICE, ANY CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR
        DOWNLOADED FROM THE SERVICE, OR A {"GUEST'S"} USE OF A SPACE, EVEN IF
        THE COMPANY IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. WITHOUT LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED
        BY APPLICABLE LAW, IN NO EVENT WILL THE COMPANY BE RESPONSIBLE OR LIABLE
        IN ANY MANNER FOR ANY LOSS OR DAMAGE ARISING OUT OF ANY TRANSACTIONS,
        COMMUNICATIONS, INTERACTIONS OR DISPUTES BETWEEN A GUEST AND A HOST OR
        BETWEEN A PROFESSIONAL ON THE ONE HAND AND A GUEST OR HOST ON THE OTHER
        HAND. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, AND TO
        THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY’S LIABILITY
        TO YOU OR ANY PARTY CLAIMING THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND
        REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE AMOUNT PAID, IF
        ANY, BY YOU TO THE COMPANY FOR THE SERVICE IN THE 12 MONTHS PRIOR TO THE
        INITIAL ACTION GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE
        EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
      </p>
      <b>Termination</b>
      <p>
        The Company may terminate or suspend your account or ability to use the
        Service, in whole or in part, without notice in the event that (i) you
        violate these Terms of Use, the Space License Agreement (including any
        applicable House Rules) or violate any other rules that govern the use
        of the Service, (ii) your conduct may harm The Company or others or
        cause The Company or others to incur liability, (iii) you receive
        negative feedback from other Users, or (iv) as otherwise determined by
        The Company in its sole discretion. If we terminate or suspend your
        account or ability to use the Service, (a) any pending or accepted
        future bookings as either Guest or Host will be immediately terminated;
        (b) we may communicate to your Guests or Hosts that such bookings have
        been cancelled; (c) for Hosts, we may refund your Guests in full for any
        and all confirmed bookings; (d) you will not be entitled to any
        compensation for bookings that were cancelled as a result of a
        suspension or termination of your account; and (e) for Professionals,
        you will not be entitled to any Referral Fees (as defined in the
        Professional Terms) for bookings that end after the suspension or
        termination of your account.
      </p>
      <p>
        The Company may modify the Service, and all content, software and other
        items used or contained in the Service, at any time. References on the
        Service to any companies, organizations, products, or services, or other
        information do not constitute or imply endorsement, sponsorship or
        recommendation thereof, or any affiliation therewith, by The Company.
      </p>
      <p>
        You may terminate your Company account at any time by providing The
        Company with notice of termination in accordance with the instructions
        available through the Service. Your access to, use of, or participation
        in the Service, including any content therein, may be prevented by The
        Company at any time after your termination of your account.
        Notwithstanding anything herein to the contrary, upon termination of
        your account, The Company will remove your Profile and cease displaying
        your Profile Content, including your Space listings if you are a Host
        and your Collections, on or through the Service.
      </p>
      <b>Governing Law; Arbitration</b>
      <p>
        THIS SECTION INCLUDES AN ARBITRATION AGREEMENT AND AN AGREEMENT THAT ALL
        CLAIMS WILL BE BROUGHT ONLY IN AN INDIVIDUAL CAPACITY (AND NOT AS A
        CLASS ACTION OR OTHER REPRESENTATIVE PROCEEDING). PLEASE READ IT
        CAREFULLY. YOU MAY OPT OUT OF THE ARBITRATION AGREEMENT BY FOLLOWING THE
        OPT OUT PROCEDURE DESCRIBED BELOW.
      </p>
      <b>Informal Process First</b>
      <p>
        You agree that if you have any dispute with The Company relating in any
        way to these Terms of Use or from access to or use of the Service, you
        will first contact us and attempt to resolve the dispute with us
        informally. Arbitration (“Arbitration Agreement”) If we are unable to
        resolve the dispute informally, we each agree to resolve any claim,
        dispute, or controversy (excluding claims for injunctive or other
        equitable relief) arising out of or in connection with or relating to
        these Terms of Use by binding arbitration. If you are an individual you
        may opt out of this Arbitration Agreement within 30 days of the first of
        the date you access or use the Service by following the procedure
        described below.
      </p>
      <p>
        Arbitration is more informal than a lawsuit in court. There is no judge
        or jury in arbitration. Instead, the dispute is resolve by a neutral
        arbitrator. Court review of an arbitration award is limited. Except to
        the extent the parties agree otherwise, arbitrators can award the same
        damages and relief that a court can award. You agree that the U.S.
        Federal Arbitration Act governs the interpretation and enforcement of
        this provision, and that you and The Company are each waiving the right
        to a trial by jury or to participate in a class action. This Arbitration
        Agreement will survive any termination of these Terms of Use.
      </p>
      <p>
        If you intend to seek arbitration, after following the informal dispute
        resolution procedure, you must first send written notice to The Company
        of your intent to arbitrate (“Notice”). The Notice to The Company should
        be sent by sending the Notice by registered or certified mail to 388
        Market Street, Suite 1300 San Francisco, CA 94111. The Notice must (i)
        describe the nature and basis of the claim or dispute; and (ii) set
        forth the specific relief sought. The arbitration will be administered
        by the International Institute for Conflict Prevention and Resolution
        (“CPR”) under the CPR Rules for Administered Arbitration by a single
        arbitrator. The arbitration will be conducted in the County of San
        Francisco, California, U.S.A.
      </p>
      <p>
        Each party will be responsible for paying any CPR, administrative and
        arbitrator fees (other than the initial filing fees) in accordance with
        CPR Rules, except that The Company will pay for your reasonable filing,
        administrative, and arbitrator fees if your claim for damages does not
        exceed $75,000 and is non-frivolous (as measured by the standards set
        forth in Federal Rule of Civil Procedure 11(b)). Nothing in this
        Arbitration Agreement shall prevent either party from seeking injunctive
        or other equitable relief from the courts for matters related to data
        security, intellectual property, or unauthorized access to the Service.
      </p>
      <p>
        If you do not want to arbitrate disputes with The Company and you are an
        individual, you may opt out of this Arbitration Agreement by sending an
        email to:{' '}
        <a href="mailto:legal@spacemx.com">
          <b>legal@spacemx.com</b>
        </a>{' '}
        within 30 days of the first of the date you access or use the Service.
      </p>
      <p>
        ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT
        AS A PLAINTIFF OR CLASS GUEST IN ANY PURPORTED CLASS OR REPRESENTATIVE
        PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT
        CONSOLIDATE MORE THAN ONE {"PERSON'S"} CLAIMS. YOU AGREE THAT, BY
        ENTERING INTO THESE TERMS OF USE, YOU AND THE COMPANY ARE EACH WAIVING
        THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
      </p>
      <p>
        If the prohibition against class actions and other claims brought on
        behalf of third parties contained above is found to be unenforceable,
        then the entirety of this Arbitration Agreement will be null and void.
      </p>
      <p>
        If for any reason a claim proceeds in court rather than in arbitration,
        the dispute will be governed by the laws of the State of California and
        the FAA without regard to or application of its conflict of law
        provisions or your state or country of residence, and will be brought
        solely in the federal or state courts located in the County of San
        Francisco, California, United States. You consent to the jurisdiction of
        and venue in such courts and waive any objection as to inconvenient
        forum.
      </p>
      <b>Indemnity</b>
      <p>
        You agree to indemnify and hold The Company harmless from and against
        any loss, liability, claim, demand, damages, costs and expenses,
        including reasonable attorneys’ fees, arising out of or in connection
        with any User Content you post or distribute on or through the Service,
        your use of or participation in the Service, your interactions with
        other Users of the Service, and any violation of these Terms of Use, the
        Space License Agreement, the Host Terms, the Professional Terms or of
        any law or the rights of any third party.
      </p>
      <b>Miscellaneous</b>
      <p>
        These Terms of Use, the Host Terms, the Professional Terms and the
        Privacy Policy constitute the entire agreement between you and The
        Company regarding the use of the Service and supersede any prior
        agreements between you and The Company relating to your use of the
        Service. The failure of The Company to exercise or enforce any right or
        provision of these Terms of Use, the Host Terms or the Professional
        Terms shall not constitute a waiver of such right or provision in that
        or any other instance. If any provision of these Terms of Use, the Host
        Terms or the Professional Terms is determined to be unlawful, void or
        for any reason unenforceable, such determination shall not affect the
        validity and enforceability of any of the remaining provisions. You may
        not assign, transfer or delegate in any manner these Terms of Use, the
        Host Terms or the Professional Terms or the rights and obligations
        hereunder or thereunder to any third party.
      </p>
    </span>
  </div>
)

export default termsAndConditionsOfUse
