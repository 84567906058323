import { action, observable } from 'mobx'
import moment from 'moment-timezone'
import tourApi from '../services/tourApi'
import operatorTourApi from '../services/admin/tourApi'
import calendarApi from '../services/calendarApi'
import VenueStore from './venue'

const toursDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

const toursDefaultFilters = {
  sort: 'desc',
  type: 'all',
  time: null,
  date_type: 'month',
  date_value: moment().month()
}

class TourStore {
  constructor() {
    this.venueStore = VenueStore
  }
  @observable.deep
  tour = {
    guest: {},
    space: {
      venue: {},
      address: {
        state: {},
        zipCode: {}
      },
      medias: []
    }
  }
  @observable
  isLoadingTour = false
  @observable
  isUpdatingTour = false
  @observable
  isLoadingTourCalendar = false
  @observable
  calendarBookingDays = []
  @observable
  monthsFetched = []

  @observable.deep
  futureTours = []
  @observable
  isLoadingFutureTours = false
  @observable.deep
  pastTours = []
  @observable
  isLoadingPastTours = false
  @observable.deep
  tours = []
  @observable
  isLoadingTours = false

  @observable.deep
  toursPagination = {
    ...toursDefaultPagination
  }
  @observable.deep
  toursFilters = {
    ...toursDefaultFilters
  }

  @action
  initEditTour = async tourId => {
    try {
      await this.fetchOne(tourId)
      await this.venueStore.syncCalendar(this.tour.venue.id)
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getForVenue = async (venueId, limit = 3) => {
    this.isLoadingTours = true
    try {
      const {
        data: { tours }
      } = await tourApi.getForVenue(venueId, limit)
      this.tours = tours
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingTours = false
    }
  }

  @action
  fetchOne = async id => {
    this.isLoadingTour = true
    try {
      const { data } = await tourApi.fetchOne(id)
      return (this.tour = data)
    } catch (error) {
      return {}
    } finally {
      this.isLoadingTour = false
    }
  }
  @action
  fetchOneForOperator = async ({ venueId, tourId }) => {
    this.isLoadingTour = true
    try {
      const { data } = await operatorTourApi.fetchOne({ venueId, tourId })
      return (this.tour = data)
    } catch (error) {
      return {}
    } finally {
      this.isLoadingTour = false
    }
  }

  @action
  doesPassEditPolicy = (user, tour, currentVenueAbility = null) => {
    return (
      this.doesPassCancellationPolicy(tour) &&
      (tour.organizer_id === user.id ||
        (currentVenueAbility && currentVenueAbility.can('update', 'Tour')))
    )
  }

  @action
  doesPassCancellationPolicy(tour) {
    const { space = {}, venue = {} } = tour

    const venueTimeZone = space.venue ? space.venue.timezone : venue.timezone
    const tourStartDate = venueTimeZone
      ? moment.utc(tour.start_date).tz(venueTimeZone)
      : moment.utc(tour.start_date)
    return tour.cancel_date === null && moment().isBefore(tourStartDate)
  }

  @action
  getInitialCalendarDays = async ({ currentVenue }) => {
    try {
      const today = moment()
      const todayMonth = today.month() + 1
      const newCalendarTourDays = await calendarApi.getBlockedTourCalendarDatesByPlaceId(
        currentVenue.id,
        'venues',
        todayMonth,
        today.year(),
        3,
        true,
        this.tour ? this.tour.id : null
      )
      if (!newCalendarTourDays.data.length) return
      this.calendarBookingDays = this.filterCalendarDays(
        newCalendarTourDays.data
      )
      this.monthsFetched = [
        { month: todayMonth - 1, year: today.year() },
        { month: todayMonth, year: today.year() }
      ]
    } catch (error) {
      console.error(error)
    }
  }

  filterCalendarDays(calendarDaysSet) {
    const daySet = calendarDaysSet.map(set => {
      return set.days
    })
    return [].concat.apply([], daySet)
  }

  @action
  getBlockedTourCalendarDatesByPlaceId = async (
    place_id,
    place_type,
    month,
    year,
    numberOfMonthsToGet,
    includeHours = false,
    tour_id = null
  ) => {
    this.isLoadingTourCalendar = true
    let newCalendarTourDays
    try {
      newCalendarTourDays = await calendarApi.getBlockedTourCalendarDatesByPlaceId(
        place_id,
        place_type,
        month,
        year,
        numberOfMonthsToGet,
        includeHours,
        tour_id
      )
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingTourCalendar = false
    }
    return newCalendarTourDays
  }

  @action
  setExistingBookingDays = async ({
    currentVenue,
    monthNumber,
    yearNumber
  }) => {
    try {
      const newCalendarTourDaysResponse = await calendarApi.getBlockedTourCalendarDatesByPlaceId(
        currentVenue.id,
        'venues',
        monthNumber + 1 === 13 ? 1 : monthNumber + 1,
        monthNumber + 1 === 13 ? yearNumber + 1 : yearNumber,
        1,
        true
      )
      if (!newCalendarTourDaysResponse.data.length) return
      const newCalendarBookingDays = this.calendarBookingDays.concat(
        this._filterCalendarDays(newCalendarTourDaysResponse.data)
      )
      this.calendarBookingDays = newCalendarBookingDays
      this.monthsFetched = [
        ...this.monthsFetched,
        { month: monthNumber, year: yearNumber }
      ]
    } catch (error) {
      console.error(error)
    }
  }

  @action
  updateTour = async ({
    tour_id,
    venue_id,
    specialRequirements,
    termId,
    date,
    startTime,
    endTime,
    when,
    leaseExpire,
    peopleCount,
    sqft,
    organizerId,
    attendeeIds
  }) => {
    let result
    this.isUpdatingTour = true
    try {
      result = await tourApi.update({
        id: tour_id,
        venue_id,
        specialRequirements,
        termId,
        date,
        startTime,
        endTime,
        when,
        leaseExpire,
        peopleCount,
        sqft,
        organizerId,
        attendeeIds
      })
      this.tour = result.data
    } catch (error) {
      console.error(error)
    } finally {
      this.isUpdatingTour = false
    }
    return result
  }

  @action
  getMyFutureTours = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingFutureTours = true
    try {
      this.toursFilters.time = 'future'
      this.toursFilters.sort = 'asc'
      const {
        data: { tours, pagination }
      } = await tourApi.getMyTours({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.toursFilters
        }
      })
      this.futureTours = tours
      this.toursPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingFutureTours = false
    }
  }

  @action
  getMyPastTours = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingPastTours = true
    try {
      this.toursFilters.time = 'past'
      this.toursFilters.sort = 'desc'
      const {
        data: { tours, pagination }
      } = await tourApi.getMyTours({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.toursFilters
        }
      })
      this.pastTours = tours
      this.toursPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingPastTours = false
    }
  }

  @action
  getMyTours = async ({ total = 0, perPage = 3, page = 1, lastPage = 1 }) => {
    this.isLoadingTours = true
    try {
      const {
        data: { tours, pagination }
      } = await tourApi.getMyTours({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.toursFilters
        }
      })
      this.tours = tours
      this.toursPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingTours = false
    }
  }

  @action
  setToursPaginationProp = (prop, value) => {
    this.toursPagination[prop] = value
  }

  @action
  resetToursPagination = () => {
    this.toursPagination = {
      ...toursDefaultPagination
    }
  }

  @action
  setToursFiltersProp = (prop, value) => {
    this.toursFilters[prop] = value
  }

  @action
  resetToursFilters = () => {
    this.toursFilters = {
      ...toursDefaultFilters
    }
  }
}

export default new TourStore()
