import { action, observable, computed } from 'mobx'
import moment from 'moment-timezone'
import bookingApi from '../services/bookingApi'
import calendarApi from '../services/calendarApi'
import { filteredCalendarDays } from '../utilities/calendarUtils'
import { TERMS } from '../constants/TERMS'
import SpaceStore from './space'
import { convertItemsInArrayToLocalTimezone } from '@utilities/dateUtils'
import { getBookingLastTransaction } from '@utilities/bookingUtils'

const bookingsDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

const bookingsDefaultFilters = {
  sort: 'desc',
  type: 'all',
  time: null,
  date_type: 'month',
  date_value: moment().month()
}

export const defaultBooking = {
  guest: {},
  space: {
    venue: {},
    address: {
      country: {},
      city: {},
      state: {},
      zipCode: {}
    },
    medias: [],
    terms: []
  }
}

class BookingStore {
  constructor() {
    this.spaceStore = SpaceStore
  }

  @observable.deep
  booking = defaultBooking

  @observable
  isBookingLoading = true
  @observable
  isLoadingBookingCalendar = false
  @observable
  isLoadingBookingAvailable = false
  @observable
  isLoadingBookingSecurityDepositWithheld = false

  @observable
  calendarBookingDays = []

  @observable.deep
  futureBookings = []
  @observable
  isLoadingFutureBookings = false
  @observable.deep
  pastBookings = []
  @observable
  isLoadingPastBookings = false
  @observable.deep
  bookings = []
  @observable
  isLoadingBookings = false
  @observable
  isLoadingVenueBookings = false

  @observable.deep
  bookingsPagination = {
    ...bookingsDefaultPagination
  }
  @observable.deep
  bookingsFilters = {
    ...bookingsDefaultFilters
  }

  @observable.deep
  bookingHistory = []
  @observable
  isLoadingBookingHistory = false

  @observable.deep
  bookingHistoryPagination = {
    total: 0,
    perPage: 10,
    page: 1,
    lastPage: 1
  }

  @observable
  bookingHistorySortBy = 'desc'

  @observable
  isBookingAvailable = null

  @computed
  get currentBookingLastTransaction() {
    return getBookingLastTransaction({ booking: this.booking })
  }

  @action
  initEditBooking = async bookingId => {
    try {
      await this.fetchOne(bookingId)
      await this.spaceStore.syncCalendar(this.booking.space_id)
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getForVenue = async (venueId, limit = 3) => {
    this.isLoadingVenueBookings = true
    try {
      const {
        data: { bookings }
      } = await bookingApi.getForVenue(venueId, limit)
      this.bookings = bookings
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueBookings = false
    }
  }

  @action
  setBooking = booking => {
    this.booking = booking
  }

  @action
  resetBooking = () => {
    this.booking = defaultBooking
  }

  @action
  fetchOne = async id => {
    this.isBookingLoading = true
    try {
      const { data: booking } = await bookingApi.fetchOne(id)
      this.booking = booking
    } catch (error) {
      console.error(error)
    } finally {
      this.isBookingLoading = false
    }
  }

  @action
  getBookingDuration = booking => {
    const { start_date, end_date } = booking
    return end_date.to(start_date, true)
  }

  @action
  doesPassEditPolicy = (user, booking, currentVenueAbility = null) => {
    return this.doesPassCancellationPolicy(user, booking, currentVenueAbility)
  }

  @action
  doesPassCancellationPolicy(user, booking, currentVenueAbility = null) {
    const {
      space: {
        venue: { timezone: venueTimeZone }
      }
    } = booking

    const bookingStartDate = moment.utc(booking.start_date).tz(venueTimeZone)
    let daysToSubtract
    switch (booking.term_name) {
      case TERMS.HOURLY.NAME:
        break
      case TERMS.DAILY.NAME:
        break
      case TERMS.MONTHLY.NAME:
        daysToSubtract = 30
        break
      default:
        daysToSubtract = 1
        break
    }
    return (
      booking.cancel_date === null &&
      moment().isBefore(bookingStartDate.subtract(daysToSubtract, 'day')) &&
      (booking.organizer_id === user.id ||
        (currentVenueAbility && currentVenueAbility.can('update', 'Booking')))
    )
  }

  @action
  getBlockedBookingCalendarDatesBySpaceId = async (
    space_id,
    month,
    year,
    numberOfMonthsToGet,
    includeHours = false,
    booking_id = null,
    timezone = null
  ) => {
    this.isLoadingBookingCalendar = true
    try {
      const {
        data
      } = await calendarApi.getBlockedBookingCalendarDatesBySpaceId(
        space_id,
        month,
        year,
        numberOfMonthsToGet,
        includeHours,
        booking_id
      )
      this.calendarBookingDays = filteredCalendarDays(
        data,
        this.calendarBookingDays,
        timezone
      )
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingBookingCalendar = false
    }
  }

  @action
  fetchIsBookingAvailable = async booking => {
    this.isLoadingBookingAvailable = true

    if (booking.hourlyDateTimes) {
      booking.hourlyDateTimes = booking.hourlyDateTimes.map(
        hourly_date_time => ({
          start_time: hourly_date_time.startTime,
          end_time: hourly_date_time.endTime,
          ...hourly_date_time
        })
      )
    }

    try {
      await bookingApi.isBookingAvailable(booking)
      this.isBookingAvailable = true
      return true
    } catch (error) {
      this.isBookingAvailable = false
      return false
    } finally {
      this.isLoadingBookingAvailable = false
    }
  }

  @action
  updateSecurityDepositWithheld = async (amount, reason) => {
    if (!this.booking) return false
    this.isLoadingBookingSecurityDepositWithheld = true
    try {
      const { data: booking } = await bookingApi.updateSecurityDepositWithheld(
        this.booking.id,
        amount,
        reason
      )
      this.booking = booking
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingBookingSecurityDepositWithheld = false
    }
    return true
  }

  @action
  getMyFutureBookings = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingFutureBookings = true
    try {
      this.bookingsFilters.time = 'future'
      this.bookingsFilters.sort = 'asc'
      const {
        data: { bookings, pagination }
      } = await bookingApi.getMyBookings({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.bookingsFilters
        }
      })
      this.futureBookings = bookings
      this.bookingsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingFutureBookings = false
    }
  }

  @action
  getMyPastBookings = async ({
    total = 0,
    perPage = 20,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingPastBookings = true
    try {
      this.bookingsFilters.time = 'past'
      this.bookingsFilters.sort = 'desc'
      const {
        data: { bookings, pagination }
      } = await bookingApi.getMyBookings({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.bookingsFilters
        }
      })
      this.pastBookings = bookings
      this.bookingsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingPastBookings = false
    }
  }

  @action
  getMyBookings = async ({
    total = 0,
    perPage = 3,
    page = 1,
    lastPage = 1
  }) => {
    this.isLoadingBookings = true
    try {
      const {
        data: { bookings, pagination }
      } = await bookingApi.getMyBookings({
        pagination: {
          total,
          perPage,
          page,
          lastPage
        },
        filters: {
          ...this.bookingsFilters
        }
      })
      this.bookings = bookings
      this.bookingsPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingBookings = false
    }
  }

  @action
  handleBookingHistoryChangeSort = value => {
    this.bookingHistorySortBy = value
    this.bookingHistoryPagination = {
      ...this.bookingHistoryPagination,
      page: 1
    }
  }

  @action
  fetchBookingHistory = async () => {
    try {
      this.isLoadingBookingHistory = true
      const {
        data: { history, pagination }
      } = await bookingApi.fetchBookingHistory(this.booking.id, {
        pagination: this.bookingHistoryPagination,
        filters: { sort: this.bookingHistorySortBy }
      })
      const tzHistory = convertItemsInArrayToLocalTimezone({
        keys: ['created_at'],
        arrayOfItems: history,
        dateFormat: 'YYYY-MM-DD HH:mm:ss'
      })
      this.bookingHistory = tzHistory
      this.bookingHistoryPagination = pagination
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingBookingHistory = false
    }
  }

  @action
  setBookingsPaginationProp = (prop, value) => {
    this.bookingsPagination[prop] = value
  }

  @action
  resetBookingsPagination = () => {
    this.bookingsPagination = {
      ...bookingsDefaultPagination
    }
  }

  @action
  setBookingsFiltersProp = (prop, value) => {
    this.bookingsFilters[prop] = value
  }

  @action
  resetBookingsFilters = () => {
    this.bookingsFilters = {
      ...bookingsDefaultFilters
    }
  }
}
export const bookingStore = BookingStore
export default new BookingStore()
