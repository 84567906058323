import React, { useState } from 'react'
import { Cookies } from 'react-cookie-consent'
import { Snackbar, Button } from '@material-ui/core'

function CookieConsent() {
  const isCookieConsented = Cookies.get('CookieConsent')

  const [isSnackBarVisible, setIsSnackBarVisible] = useState(!isCookieConsented)

  const handleAccept = () => {
    Cookies.set('CookieConsent', 'true')
    setIsSnackBarVisible(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={isSnackBarVisible}
      autoHideDuration={null}
      onClose={handleAccept}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={
        <span id="message-id">
          This website uses cookies to enhance the user experience.
        </span>
      }
      action={[
        <Button
          key="undo"
          color="secondary"
          size="small"
          variant="contained"
          onClick={handleAccept}
        >
          I UNDERSTAND
        </Button>
      ]}
    />
  )
}

export default CookieConsent
