import axios from 'axios'

const { REACT_APP_API_DOMAIN, REACT_APP_API_URL_PREFIX } = process.env
const axiosOptions = {
  validateStatus: function() {
    return true
  },
  headers: { Accept: 'application/json' }
}

class Api extends Object {
  apiRoot = `${REACT_APP_API_DOMAIN}${REACT_APP_API_URL_PREFIX}`

  constructor(props) {
    super(props)
    this.session = axios.create(axiosOptions)
  }

  getSpaceById = spaceId => {
    return axios.get(`${this.apiRoot}/spaces/${spaceId}`)
  }

  getSpaceByProfileSlug = profileSlug => {
    return axios.get(`${this.apiRoot}/spaces/profile/${profileSlug}`)
  }

  getCitySpaces = (citySlug, spaceType) => {
    const payload = { citySlug, spaceType }
    return axios.post(`${this.apiRoot}/spaces/city`, payload)
  }

  getHomeSpaces = citySlug => {
    const payload = { citySlug }
    return axios.post(`${this.apiRoot}/spaces/home`, payload)
  }

  getRandomSpaces = () => {
    return axios.get(`${this.apiRoot}/spaces/random`)
  }

  getCities = () => {
    return axios.get(`${this.apiRoot}/cities`)
  }

  getHomeSpaces = (citySlug, state) => {
    const payload = { citySlug, state }
    return this.session.post(`${this.apiRoot}/spaces/home`, payload)
  }

  getCities = () => {
    return this.session.get(`${this.apiRoot}/cities`)
  }

  getLocationSuggestions = userInput => {
    const payload = { userInput }
    return this.session.get(`${this.apiRoot}/location_autocompletes`, {
      params: payload
    })
  }

  getReviews = () => {
    return this.session.get(`${this.apiRoot}/reviews`)
  }

  getAgendas = () => {
    return this.session.get(`${this.apiRoot}/agenda`)
  }

  getNotifications = () => {
    return this.session.get(`${this.apiRoot}/notifications`)
  }

  getCustomers = () => {
    return this.session.get(`${this.apiRoot}/customers`)
  }

  getFavorites = () => {
    return this.session.get(`${this.apiRoot}/favorites`)
  }

  getBillings = () => {
    return this.session.get(`${this.apiRoot}/billings`)
  }

  getUsers = () => {
    return this.session.get(`${this.apiRoot}/users`)
  }

  getMessages = () => {
    return this.session.get(`${this.apiRoot}/messages`)
  }

  getMeta = () => {
    return this.session.get(`${this.apiRoot}/meta`)
  }

  // Auth
  login = (email, password) => {
    const payload = { email, password }
    return this.session.post(`${this.apiRoot}/auth/login`, payload)
  }
  register = fields => {
    return this.session.post(`${this.apiRoot}/auth/register`, fields)
  }

  forgotPassword = email => {
    return this.session.post(`${this.apiRoot}/auth/forgot_password`, {
      email
    })
  }
}

const api = new Api()
export default api
