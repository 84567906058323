import React from 'react'
import NOTIFICATION_TYPES from '../constants/NOTIFICATION_TYPES'

import {
  Message,
  Schedule,
  LocationCity,
  Subscriptions
} from '@material-ui/icons'

export const getNotificationIconByType = notificationType => {
  switch (notificationType) {
    case NOTIFICATION_TYPES.BOOKING:
      return <LocationCity />
    case NOTIFICATION_TYPES.SUBSCRIPTION:
      return <LocationCity />
    case NOTIFICATION_TYPES.TOUR:
      return <Schedule />
    case NOTIFICATION_TYPES.REVIEW:
      return <Subscriptions />
    case NOTIFICATION_TYPES.MESSAGE:
      return <Message />
    default:
      return null
  }
}

export const getNotificationsLinkUrlByType = ({
  notification_type,
  entity_id,
  venue_id
}) => {
  const is_for_operator = Boolean(venue_id)
  switch (notification_type) {
    case NOTIFICATION_TYPES.BOOKING:
      return `/admin/${is_for_operator ? 'venue/' : ''}bookings/${entity_id}`
    case NOTIFICATION_TYPES.TOUR:
      return `/admin/${is_for_operator ? 'venue/' : ''}tours/${entity_id}`
    case NOTIFICATION_TYPES.SUBSCRIPTION:
      return is_for_operator
        ? `/admin/venue/subscriptions/${entity_id}`
        : `/admin/subscriptions/${entity_id}`
    case NOTIFICATION_TYPES.REVIEW:
      return `/admin/${is_for_operator ? 'venue/' : 'my/'}reviews`
    case NOTIFICATION_TYPES.MESSAGE:
      return `/admin/${
        is_for_operator ? 'venue/' : ''
      }conversation/${entity_id}`
    default:
      return null
  }
}
