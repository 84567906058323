import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class OperatorReviewApi extends BaseApi {
  constructor() {
    super({ routeName: 'admin/operator/reviews' })
  }

  fetch = (venueId, params = {}) => {
    return this.request(userStore.jwtToken).get(`${venueId}`, {
      params: {
        ...params
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchPendings = venueId => {
    return this.request(userStore.jwtToken).get(`${venueId}/pendings`)
  }

  fetchRatings = (venueId, params = {}) => {
    return this.request(userStore.jwtToken).get(`${venueId}/ratings`, {
      params: {
        ...params
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

export default new OperatorReviewApi()
