import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class SpaceApi extends BaseApi {
  constructor() {
    super({ routeName: 'venues' })
  }

  fetchOne = (venueId, spaceId, include) => {
    const url = `${venueId}/space/${spaceId}`
    return this.request(userStore.jwtToken).get(url, {
      params: {
        include
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  update = (venueId, spaceId, params = {}) => {
    const url = `${venueId}/space/${spaceId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  setCalendar = (venueId, spaceId, params = {}) => {
    const url = `${venueId}/space/${spaceId}/set_calendar`
    return this.request(userStore.jwtToken).put(url, { space: { ...params } })
  }

  duplicate = (venueId, spaceId, space) => {
    if (!space) throw Error('No space data provided')
    return this.request(userStore.jwtToken).post(
      `${venueId}/space/${spaceId}/duplicate`
    )
  }

  destroy = (venueId, spaceId) => {
    if (!spaceId) throw Error('No space ID provided')
    return this.request(userStore.jwtToken).delete(
      `${venueId}/space/${spaceId}`
    )
  }

  updateRules = (venueId, spaceId, data) => {
    if (!data) return Error('No rules data provided')
    return this.request(userStore.jwtToken).put(
      `${venueId}/space/${spaceId}/update-rules`,
      data
    )
  }

  updateBuffers = (venueId, spaceId, data) => {
    if (!data) return Error('No buffers data provided')
    return this.request(userStore.jwtToken).put(
      `${venueId}/space/${spaceId}/update-buffer`,
      data
    )
  }

  updateTerms = (venueId, spaceId, data) => {
    if (!data) return Error('No rules data provided')
    return this.request(userStore.jwtToken).put(
      `${venueId}/space/${spaceId}/update-terms`,
      data
    )
  }

  updateSpacePublishedStatus = ({ venueId, spaceId, is_published }) => {
    return this.request(userStore.jwtToken).post(
      `${venueId}/space/${spaceId}/update-published-status`,
      { is_published }
    )
  }
}

export default new SpaceApi()
