import { action, observable } from 'mobx'
import venueTypesApi from '../services/venueTypesApi'
import amenitiesApi from '../services/amenitiesApi'
import commonAreasApi from '../services/commonAreasApi'
import rulesApi from '../services/rulesApi'
import companyApi from '../services/companyApi'
import venueApi from '../services/venueApi'
import adminSpaceApi from '../services/admin/spaceApi'

import globalStore from './global'
import mediaStore from './media'

const initialForm = {
  name: '',
  address: {
    full_address: ''
  },
  mapPinCoordinates: null,
  venueTypeGroupId: null,
  venueTypeId: null,
  size: null,
  commonAreaIds: [],
  amenityIds: [],
  venuePhotos: [],
  commonAreaPhotos: [],
  floorPlanPhotos: [],
  description: '',
  rules: []
}

class CreateVenueStore {
  @observable
  activeStep = 0
  @observable
  activeSubStep = 0
  @observable
  previousStep = 0
  @observable
  previousSubStep = 0

  @observable.deep
  venue = null
  @observable
  isSavingVenue = false

  @observable.deep
  form = {
    ...initialForm
  }
  @observable
  formError = null

  @observable.deep
  venueTypeGroups = []
  @observable
  isLoadingVenueTypeGroups = false

  @observable.deep
  commonAreas = []
  @observable
  isLoadingCommonAreas = false

  @observable.deep
  amenities = []
  @observable
  isLoadingAmenities = false

  @observable.deep
  rules = []
  @observable
  isLoadingRules = false

  @observable.deep
  state = {}
  @observable
  isLoadingState = false
  @observable
  isSavingState = false

  @observable.deep
  venue = null
  @observable
  isSavingVenue = false
  @observable.deep
  space = null
  @observable
  isSavingSpace = false

  constructor() {
    this.globalStore = globalStore
    this.mediaStore = mediaStore
  }

  @action
  updateStep = ({ step, subStep, finished }) => {
    this.previousStep = this.activeStep
    this.activeStep = step
    this.activeSubStep = subStep
    this.isFinished = finished
  }

  @action
  resetFormError = () => {
    this.formError = null
  }

  @action
  resetForm = () => {
    this.activeStep = 0
    this.activeSubStep = 0
    this.previousStep = 0
    this.previousSubStep = 0
    this.form = {
      ...initialForm
    }
  }

  @action
  setForm = form => {
    this.form = form
  }

  @action
  setFormProp = (key, value) => {
    this.form[key] = value
  }

  @action
  loadVenueTypeGroups = async () => {
    this.isLoadingVenueTypeGroups = true
    try {
      if (!this.venueTypeGroups.length) {
        const {
          data: venueTypeGroups
        } = await venueTypesApi.getVenueTypeGroups()
        this.venueTypeGroups = venueTypeGroups
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingVenueTypeGroups = false
    }
  }

  @action
  loadCommonAreas = async () => {
    this.isLoadingCommonAreas = true
    try {
      if (!this.commonAreas.length) {
        const { data: commonAreas } = await commonAreasApi.getAll()
        this.commonAreas = commonAreas
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingCommonAreas = false
    }
  }

  @action
  loadAmenities = async () => {
    this.isLoadingAmenities = true
    try {
      if (!this.amenities.length) {
        const { data: amenities } = await amenitiesApi.getAll()
        this.amenities = amenities
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingAmenities = false
    }
  }

  @action
  loadRules = async () => {
    this.isLoadingRules = true
    try {
      if (!this.rules.length) {
        const { data: rules } = await rulesApi.getAll()
        this.rules = rules
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingRules = false
    }
  }

  @action
  updateMediaInfo = async (currentMedia, info, formProp) => {
    const media = await this.mediaStore.update(currentMedia.id, info)
    if (media) {
      this.form[formProp] = this.form[formProp].map(currentMedia => {
        return currentMedia.id === media.id ? media : currentMedia
      })
    } else {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again.`
      )
    }
  }

  @action
  createVenue = async companyId => {
    this.isSavingVenue = true
    let result = null
    try {
      result = await companyApi.createVenue({ companyId, formData: this.form })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.venue = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${error.data.error}`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingVenue = false
    }
    return this.venue
  }

  @action
  updateVenueRules = async venueId => {
    this.isSavingVenue = true
    let result = null
    try {
      result = await venueApi.updateRules(venueId, {
        rules: this.form.rules
      })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.venue = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${error.data.error}`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingVenue = false
    }
    return result
  }

  @action
  updateSpaceRules = async (venueId, spaceId) => {
    this.isSavingSpace = true
    let result = null
    try {
      result = await adminSpaceApi.updateRules(venueId, spaceId, {
        rules: this.form.rules
      })
      if (result.data.error) {
        this.formError = result.data.error
      } else {
        this.space = result.data
      }
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again. ${error.data.error}`
      )
      this.formError = error.data.error
    } finally {
      this.isSavingSpace = false
    }
    return result
  }
}

export default new CreateVenueStore()
