import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  withMobileDialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router'

import ProgressButton from '../Global/ProgressButton'

const styles = theme => ({
  inputSpacing: { marginBottom: theme.spacing.unit * 4 },
  dialogActionsContainer: {
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    [theme.breakpoints.only('sm')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing.unit
    },
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  dialogHeaderText: { float: 'left' },
  closeIcon: {
    float: 'right',
    marginTop: theme.spacing.unit * -2,
    marginRight: theme.spacing.unit * -2
  },
  dialogContentRoot: {
    paddingBottom: 4
  }
})

@inject('globalStore')
@observer
class ConfirmationDialog extends Component {
  state = {
    loading: false
  }
  _handleCancel = () => {
    const {
      globalStore: { closeConfirmationDialog, confirmationDialog }
    } = this.props
    closeConfirmationDialog()
    if (confirmationDialog.callback) {
      confirmationDialog.callback(false)
    }
  }

  _handleOk = async () => {
    const {
      globalStore: { closeConfirmationDialog, confirmationDialog }
    } = this.props
    if (confirmationDialog.callback) {
      this.setState({ loading: true })
      await confirmationDialog.callback(true)
      closeConfirmationDialog()
    } else {
      closeConfirmationDialog()
    }
    this.setState({ loading: false })
  }

  render() {
    const {
      fullScreen,
      classes,
      globalStore: {
        confirmationDialog: {
          cancelText,
          confirmText,
          description,
          message,
          open,
          cancelOnly
        }
      }
    } = this.props
    const { loading } = this.state
    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        onClose={this._handleCancel}
        data-test="e2e-confirmation-dialog"
      >
        <DialogTitle disableTypography style={{ paddingBottom: 24 }}>
          <Typography
            variant="h5"
            className={classes.dialogHeaderText}
            id="e2e-list-space-login-heading-selector"
          >
            {message}
          </Typography>
        </DialogTitle>
        {description ? (
          <DialogContent>
            <Typography>{description}</Typography>
          </DialogContent>
        ) : null}
        <DialogActions className={classes.dialogActionsContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={this._handleCancel}
            data-test="e2e-confirmation-dialog-cancel"
          >
            {cancelText ? cancelText : 'Cancel'}
          </Button>
          {!cancelOnly && (
            <ProgressButton
              data-test="e2e-confirmation-dialog-confirm"
              color="primary"
              variant="contained"
              onClick={this._handleOk}
              showProgress={loading}
            >
              {confirmText ? confirmText : 'Confirm'}
            </ProgressButton>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withRouter(
  withStyles(styles)(withMobileDialog()(ConfirmationDialog))
)
