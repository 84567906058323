import React from 'react'
import { observer, inject } from 'mobx-react'
import Idle from 'react-idle'

import NotificationSnackbar from '@components/Global/NotificationSnackbar'

const IDLE_TIME = process.env.REACT_APP_IDLE_TIMEOUT_MINUTES
  ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES, 10) * 60 * 1000
  : 1000 * 60 * 15

const IdleUserLogout = inject('userStore')(
  observer(({ userStore: { loggedIn, setIdleTimeout, clearIdleTimeout } }) => {
    return loggedIn ? (
      <Idle
        timeout={IDLE_TIME}
        onChange={({ idle }) => {
          if (idle) {
            setIdleTimeout()
          } else {
            clearIdleTimeout()
          }
        }}
        render={({ idle }) => {
          return idle ? (
            <NotificationSnackbar
              isOpen={true}
              message={'You will be logged out in 10 seconds due to idle time'}
              showCloseAction={true}
              onClose={() => true}
              autoHideDuration={10000}
            />
          ) : null
        }}
      />
    ) : null
  })
)

export default IdleUserLogout
