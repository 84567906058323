import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  withMobileDialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import ProgressButton from '../Global/ProgressButton'

const styles = theme => ({
  inputSpacing: { marginBottom: theme.spacing.unit * 3 },
  dialogActionsContainer: {
    paddingLeft: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  dialogHeaderText: {
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px'
    }
  },
  closeIcon: {
    float: 'right',
    marginTop: -8,
    marginRight: theme.spacing.unit * -2,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * -2
    }
  },
  dialogContentRoot: {
    paddingBottom: 4
  },
  notificationContainer: {
    backgroundColor: '#ECF0F1',
    border: '1px solid #CDD9DC',
    borderRadius: 3,
    padding: theme.spacing.unit * 2,
    maxWidth: '97.66667%',
    marginBottom: theme.spacing.unit * 3
  },
  dialogContainer: {
    boxShadow:
      '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px 0 rgba(0,0,0,0.2)',
    height: 'auto',
    minHeight: 366,
    width: 'calc(100% - 70px)',
    [theme.breakpoints.up('md')]: {
      width: 560
    }
  },
  linkButtonLabel: {
    fontWeight: 500
  },
  loginError: {
    marginBottom: theme.spacing.unit
  }
})

@inject('userStore')
@observer
class LoginDialog extends Component {
  _defaultState = {
    email: {
      value: '',
      error: null
    },
    password: {
      value: '',
      error: null
    },
    loginFailed: false
  }

  constructor(props) {
    super(props)
    this.state = this._defaultState
  }

  _handleEnterPress = ({ key }) => {
    if (key === 'Enter') this.handleSubmit()
  }

  _handleClose = event => {
    if (event) event.stopPropagation()

    const { redirect, location, redirectHomeOnClose } = this.props

    this.setState(this._defaultState, () => {
      this.props.userStore.closeAuthDialog('login')
      this.props.userStore.clearEmailVerificationResponse()
      if (redirect && location) {
        this._redirectHomeOrLastPage()
      } else if (redirectHomeOnClose) {
        this.props.history.push('/')
      }
    })
  }

  _handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: { value: value, error: null } })
  }

  handleSubmit = async () => {
    const { email, password } = this.state
    const { userStore, redirect, location, redirectTo } = this.props

    if (!email.value || !password.value) {
      this.setState({
        email: {
          ...this.state.email,
          error: !email.value ? 'Invalid Email' : null
        },
        password: {
          ...this.state.password,
          error: !password.value ? 'Invalid Password' : null
        }
      })
      return
    }

    const login = await userStore.login(email.value, password.value)
    if (login && login.errors) {
      this.setState({
        loginFailed: true
      })
      return
    }

    if ((redirect && location) || redirectTo) {
      this._redirectHomeOrLastPage()
    }

    this.setState(this._defaultState, this._handleClose())
  }

  _redirectHomeOrLastPage = () => {
    const { redirect, location, redirectTo } = this.props
    if (redirectTo) {
      this.props.history.push(redirectTo)
    }
    if (redirect && location) {
      if (location.state) {
        this.props.history.push(location.state.from.pathname)
      } else {
        this.props.history.push('/')
      }
    }
  }

  render() {
    const {
      open,
      fullScreen,
      classes,
      userStore: { emailVerificationResponse, isUserLoggingIn }
    } = this.props
    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
        BackdropProps={{
          classes: {
            root: classes.backdropModal
          }
        }}
        disableBackdropClick={true}
        disableEscapeKeyDown={false}
        onClose={this._handleClose}
        onKeyPress={this._handleEnterPress}
      >
        <DialogTitle disableTypography style={{ paddingBottom: 24 }}>
          <Typography
            variant="h5"
            className={classes.dialogHeaderText}
            id="e2e-list-space-login-heading-selector"
          >
            Log in to your SpaceMX account
          </Typography>
          <IconButton
            onClick={this._handleClose}
            aria-label="Close"
            className={classes.closeIcon}
            data-test="login-dialog-close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContentRoot
          }}
        >
          <Grid data-test="login-dialog" container spacing={0}>
            <Grid item xs={12} className={classes.inputSpacing}>
              {Boolean(emailVerificationResponse) && (
                <Paper className={classes.notificationContainer}>
                  <Typography>{emailVerificationResponse}</Typography>
                </Paper>
              )}
              <TextField
                label="Email"
                name="email"
                fullWidth
                placeholder="Enter your email address"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.email.value}
                error={!!this.state.email.error}
                onChange={event => this._handleChange(event)}
                helperText={this.state.email.error}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputSpacing}>
              <TextField
                label="Password"
                type="password"
                name="password"
                fullWidth
                placeholder="Enter your password"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.password.value}
                error={!!this.state.password.error}
                onChange={event => this._handleChange(event)}
                helperText={this.state.password.error}
              />
            </Grid>
          </Grid>
          {this.state.loginFailed ? (
            <Typography color="error" className={classes.loginError}>
              Invalid email or password.
            </Typography>
          ) : null}
          <Typography>
            Forgot your password?
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={() =>
                this.props.userStore.openAuthDialog('forgot_password')
              }
              classes={{
                label: classes.linkButtonLabel
              }}
              data-test="e2e-login-dialog-forgot-password-button"
            >
              {'Click Here'}
            </Button>
          </Typography>
          <Typography>
            Don’t have an account?
            <Button
              color="primary"
              size="small"
              disableFocusRipple
              onClick={() => this.props.userStore.openAuthDialog('register')}
              classes={{
                label: classes.linkButtonLabel
              }}
            >
              Sign Up
            </Button>
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <ProgressButton
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            data-test="login-dialog-login-button"
            showProgress={isUserLoggingIn}
          >
            Log in
          </ProgressButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withRouter(withStyles(styles)(withMobileDialog()(LoginDialog)))
