import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'

import { getResizedImageURL } from '../../utilities/mediaUtils'

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.1rem'
  }
})

@observer
class UserAvatar extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    style: PropTypes.object,
    classes: PropTypes.object
  }

  state = {
    failed: false
  }

  handleFailedAvatar = () => {
    this.setState({ failed: true })
  }

  render() {
    const { user, classes, height = 48, width = 48, style } = this.props
    const { first_name, last_name, medias = {} } = user

    return user && !this.state.failed && medias.length && medias[0] ? (
      <Avatar
        classes={{ root: classes.avatar }}
        style={{ height, width, ...style }}
        src={
          Boolean(medias[0].url) &&
          getResizedImageURL({
            file: medias[0].url,
            width,
            height
          })
        }
        imgProps={{
          onError: this.handleFailedAvatar
        }}
      />
    ) : (
      <Avatar
        alt={`${first_name} ${last_name}`}
        classes={{ root: classes.avatar }}
        style={{ height, width, ...style }}
      >
        {`${first_name ? first_name[0] : ''}${last_name ? last_name[0] : ''}`}
      </Avatar>
    )
  }
}

export default withStyles(styles)(UserAvatar)
