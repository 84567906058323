import { observable, action, computed } from 'mobx'
import api from '../utilities/api'
import bookingApi from '../services/bookingApi'
import spaceTypesApi from '../services/spaceTypesApi'
import userStore from './user'
import spaceApi from '../services/spaceApi'
import adminSpaceApi from '../services/admin/spaceApi'
import { TERMS } from '../constants/TERMS'
import venueApi from '../services/venueApi'
import reviewApi from '../services/reviewsApi'
import globalStore from './global'
import { round } from 'lodash'
import mediaStore from './media'
import { mapPlaceTitleToImagesTitle } from '../utilities/mediaUtils'
import { trackEvent } from '@utilities/trackingUtils'

class SpaceStore {
  _defaultBookingForm = {
    price: this.spacePriceByTerm,
    term: null,
    startDate: null,
    endDate: null,
    hourlyDateTimes: [
      {
        date: null,
        startTime: '',
        endTime: ''
      }
    ],
    months: null,
    stripeToken: null,
    payment: {},
    id: null
  }

  @observable.deep
  spaces = []
  @observable.deep
  homeSpaces = { carousels: null, carouselCount: 0 }
  @observable
  featuredSpace = null
  @observable
  totalCount = 0
  @observable.deep
  currentSpace = null
  @observable
  isSavingSpace = false
  @observable
  isLoadingCurrentOtherSpaces = false
  @observable
  searchLocation = 'San Francisco, CA'
  currentSlug = 'san-francisco-california-us'

  @observable
  activeStep
  @observable.deep
  bookingForm = {}
  @observable
  disableResetBookingForm = false
  @observable
  bookingFormError = ''
  @observable
  bookingIsAvailable = true
  @observable
  showBookingModal = false
  @observable
  bookingCreated = false

  @observable
  isLoading = false

  @observable
  spaceTypes = []
  @observable
  isLoadingSpaceTypes = false

  @observable
  isTogglingSpaceActive = false
  @observable
  toggleSpaceActiveError = ''

  @observable
  currentSpaceReviews = []
  @observable
  isLoadingCurrentSpaceReviews = false

  @observable
  isDeletingSpace = false

  @observable
  isSyncingCalendar = false

  @observable
  isLoadingSpacesByVenueType = false
  @observable
  isLoadingSpacesByType = false

  constructor() {
    this.spaces = []
    this.bookingForm = {
      ...this._defaultBookingForm
    }
    this.globalStore = globalStore
    this.mediaStore = mediaStore
  }

  @action
  getReviewsForCurrentSpace = async () => {
    this.isLoadingCurrentSpaceReviews = true
    try {
      const { data: reviews } = await reviewApi.getForSpace(
        this.currentSpace.id
      )
      this.currentSpaceReviews = reviews
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingCurrentSpaceReviews = false
    }
  }

  @action
  setCurrentSpace = currentSpace => {
    this.currentSpace = currentSpace
  }

  @action.bound
  setCurrentSpaceName(name) {
    this.currentSpace.name = name
  }

  @action.bound
  setCurrentSpaceDescription(description) {
    this.currentSpace.description = description
  }

  @action.bound
  setCurrentSpaceSeatsAvailable(seatsAvailable) {
    this.currentSpace.seats = seatsAvailable
  }

  @action.bound
  setCurrentSpaceSQFT(square_feet) {
    this.currentSpace.square_feet = square_feet
  }

  @action.bound
  setCurrentSpaceType(spaceType) {
    this.currentSpace.spaceType = spaceType
  }

  @action.bound
  setCurrentSpaceAmenities(amenities) {
    this.currentSpace.amenities = amenities
  }

  @action
  toggleSpaceActive = async space => {
    this.isTogglingSpaceActive = true
    const { is_published } = space
    const published = !is_published
    try {
      await adminSpaceApi.updateSpacePublishedStatus({
        venueId: space.venue_id,
        spaceId: space.id,
        is_published: published
      })
      space.is_published = published
      this.toggleSpaceActiveError = ''
      this.globalStore.openNotificationSnackbar(
        `${space.name} has been marked as ${
          space.is_published ? 'Published' : 'Not Published'
        }`
      )
      return space
    } catch (error) {
      const errorMessage = error.data ? error.data.error : error
      this.toggleSpaceActiveError = errorMessage
      space.is_published = !published
      this.globalStore.openNotificationSnackbar(`Error: ${errorMessage}`)
      return space
    } finally {
      this.isTogglingSpaceActive = false
    }
  }

  @action
  toggleCurrentSpaceActive = async finished => {
    const space = this.toggleSpaceActive(this.currentSpace)
    this.currentSpace.is_published = space.is_published
    if (finished) finished()
  }

  @action.bound
  setCurrentSpaceMedias(medias) {
    this.currentSpace.medias = medias
  }

  @action.bound
  setCurrentSpaceProp({ key, value }) {
    if (key || value) {
      this.currentSpace[key] = value
    }
  }

  @action.bound
  async saveCurrentSpaceBuffers(data) {
    const { venue_id, id } = this.currentSpace
    await adminSpaceApi.updateBuffers(venue_id, id, data)
  }

  @action.bound
  async saveCurrentSpaceTerms(data) {
    const { venue_id, id } = this.currentSpace
    await adminSpaceApi.updateTerms(venue_id, id, data)
  }

  @action.bound
  async saveCurrentSpaceRules(data) {
    const { venue_id, id } = this.currentSpace
    await adminSpaceApi.updateRules(venue_id, id, data)
  }

  @action.bound
  async saveCurrentSpace() {
    this.isSavingSpace = true
    try {
      const space = Object.assign({}, this.currentSpace)
      delete space.spaces
      delete space.terms
      const response = await adminSpaceApi.update(
        space.venue_id,
        space.id,
        space
      )
      const spaceResponse = response.data.data
      spaceResponse.medias = mapPlaceTitleToImagesTitle(
        spaceResponse,
        spaceResponse.medias
      )
      this.currentSpace = {
        ...this.currentSpace,
        ...response.data.data
      }
      this.isSavingSpace = false
      this.globalStore.openNotificationSnackbar(
        `"${this.currentSpace.name}" Space has been updated.`
      )
    } catch (error) {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong.  Please try again.`
      )
      this.isSavingSpace = false
    }
  }

  @action.bound
  async duplicate(space) {
    this.isDuplicatingSpace = true
    try {
      await adminSpaceApi.duplicate(space.venue_id, space.id, space)
      this.globalStore.openNotificationSnackbar(
        `${space.name} has been duplicated.`
      )
    } catch (error) {
      console.error(error)
      this.globalStore.openNotificationSnackbar(
        `Something went wrong.  Please try again.`
      )
    } finally {
      this.isDuplicatingSpace = false
    }
  }

  @action.bound
  async getSpacesByLatLng(
    lat,
    lng,
    spaceTypeId = null,
    termId = null,
    startDate = null,
    endDate = null,
    page = 0,
    limit = 18
  ) {
    try {
      const { data } = await spaceApi.getByLatLng(
        lat,
        lng,
        spaceTypeId,
        termId,
        startDate,
        endDate,
        page,
        limit
      )
      const spacesResponse = data ? data.spaces : []
      spacesResponse.forEach(space => {
        space.medias = mapPlaceTitleToImagesTitle(space, space.medias)
      })
      this.spaces = spacesResponse
      this.totalCount = data ? data.totalCount : []
    } catch (error) {
      console.error(error)
    }
  }

  @action.bound
  async getSpacesByHome(lat, lng, carouselLimit = 3) {
    this.isLoading = true
    try {
      const spacesResponse = await spaceApi.getByHome(lat, lng, carouselLimit)
      this.homeSpaces = spacesResponse.data
    } catch (error) {
      console.error(error)
      this.homeSpaces = { carousels: null, carouselCount: 0 }
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async getFeaturedSpace() {
    this.isLoading = true
    try {
      const { data: featureSpace } = await spaceApi.getFeaturedSpace()
      this.featuredSpace = featureSpace
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
    }
  }

  @action.bound
  async getSpacesByBounds({
    bounds,
    venueTypeGroupId = null,
    venueTypeId = null,
    spaceTypeId = null,
    termId = null,
    startDate,
    endDate,
    page = 0,
    limit = 18,
    price = null
  }) {
    try {
      const response = await spaceApi.getByBounds(
        bounds,
        venueTypeGroupId,
        venueTypeId,
        spaceTypeId,
        termId,
        startDate,
        endDate,
        page,
        limit,
        price
      )
      const spacesResponse = response.data.spaces
      spacesResponse.forEach(space => {
        space.medias = mapPlaceTitleToImagesTitle(space, space.medias)
      })
      this.spaces = spacesResponse
      this.totalCount = response.data.totalCount
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getSpaceById = async (id, include, force = false, cb = null) => {
    this.isLoading = true
    if (this.currentSpace && this.currentSpace.id === id && !force) {
      return
    }
    try {
      const response = await spaceApi.getById(id, include)

      const spaceResponse = response.data.data
      spaceResponse.medias = mapPlaceTitleToImagesTitle(
        spaceResponse,
        spaceResponse.medias
      )
      if (spaceResponse.venue) {
        spaceResponse.venue.medias = mapPlaceTitleToImagesTitle(
          spaceResponse.venue,
          spaceResponse.venue.medias
        )
      }

      this.currentSpace = spaceResponse
      if (cb) cb()
    } catch ({ data, status }) {
      console.warn(status, data)
    } finally {
      this.isLoading = false
    }
  }

  @action
  fetchOne = async (venueId, spaceId, include) => {
    this.isLoading = true
    if (this.currentSpace && this.currentSpace.id === spaceId) {
      return
    }
    const {
      data: { space }
    } = await adminSpaceApi.fetchOne(venueId, spaceId, include)
    const spaceResponse = space
    spaceResponse.medias = mapPlaceTitleToImagesTitle(
      spaceResponse,
      spaceResponse.medias
    )
    this.currentSpace = spaceResponse
    this.isLoading = false
  }

  @action
  loadCurrentSpaceVenueSpaces = async () => {
    if (!this.currentSpace) return
    this.isLoadingCurrentOtherSpaces = true
    try {
      const {
        data: {
          data: { spaces, venueTypes }
        }
      } = await venueApi.getVenueById(this.currentSpace.venue.id, {
        include: []
      })
      if (spaces) {
        if (!this.currentSpace) return
        spaces.forEach(space => {
          space.medias = mapPlaceTitleToImagesTitle(space, space.medias)
          if (venueTypes) {
            space.venue = {
              venueTypes
            }
          }
        })
        this.currentSpace = {
          ...this.currentSpace,
          otherSpaces: spaces
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingCurrentOtherSpaces = false
    }
  }

  @action
  clearCurrentSpace = () => {
    if (this.currentSpace) this.currentSpace = null
  }

  @action
  setActiveStep = STEP => {
    this.activeStep = STEP
  }

  @computed
  get spaceRateByTerm() {
    const { currentSpace, bookingForm } = this
    let bookingTerm = bookingForm.term
    bookingTerm = this._checkTermExistings(bookingForm.term, currentSpace.terms)
    return this.getSpaceRateByTerm(bookingTerm, currentSpace.terms)
  }

  @computed
  get spaceSetupFeeByTerm() {
    const { currentSpace, bookingForm } = this
    let bookingTerm = bookingForm.term
    bookingTerm = this._checkTermExistings(bookingForm.term, currentSpace.terms)
    return this.getSpaceSetupFeeByTerm(bookingTerm, currentSpace.terms)
  }

  @computed
  get spaceSecurityDepositByTerm() {
    const { currentSpace, bookingForm } = this
    let bookingTerm = bookingForm.term
    bookingTerm = this._checkTermExistings(bookingForm.term, currentSpace.terms)
    return this.getSpaceSecurityDepositByTerm(bookingTerm, currentSpace.terms)
  }

  @computed
  get spaceFeesTotal() {
    return this.spaceSetupFeeByTerm + this.spaceSecurityDepositByTerm
  }

  @computed
  get spaceTotalDailyPrice() {
    const { startDate, endDate } = this.bookingForm
    return this._getPriceFromDateRange(this.spaceRateByTerm, startDate, endDate)
  }

  _getPriceFromDateRange(rate, startDate, endDate) {
    //TODO refactor to allow multiple 'term' types
    const isInvalid =
      !startDate ||
      !endDate ||
      (!startDate._isAMomentObject || !endDate._isAMomentObject)
    if (isInvalid) return null
    return rate * this.spaceTotalDays
  }

  @computed
  get spaceTotalDays() {
    const { startDate, endDate } = this.bookingForm
    return endDate
      .clone()
      .add(1, 'days')
      .startOf('day')
      .diff(startDate.clone().startOf('day'), 'days')
  }

  @computed
  get spaceTotalOneTimeFee() {
    const { term, hourlyDateTimes } = this.bookingForm
    let totalPrice = 0
    const rate = this.spaceRateByTerm
    switch (term) {
      case TERMS.HOURLY.ID:
        hourlyDateTimes.map(dateItem => {
          const dateItemTotalHours =
            parseInt(dateItem.endTime) - parseInt(dateItem.startTime)
          totalPrice = totalPrice + dateItemTotalHours * rate
          totalPrice +=
            this.spaceSetupFeeByTerm + this.spaceSecurityDepositByTerm
        })
        return totalPrice
      case TERMS.DAILY.ID:
        totalPrice = this.spaceTotalDailyPrice
        break
      case TERMS.MONTHLY.ID:
        totalPrice = this.spaceRateByTerm
        break
    }
    return (
      totalPrice + this.spaceSetupFeeByTerm + this.spaceSecurityDepositByTerm
    )
  }

  @computed
  get currentSpaceDefaultTerm() {
    const { currentSpace } = this
    if (!currentSpace) return null
    const { terms } = currentSpace
    if (!terms) return null
    const defaultTerm = terms.find(term => term.pivot.is_default) || terms[0]
    return defaultTerm.id
  }

  _checkTermExistings(bookingTermId, terms) {
    if (terms.some(term => term.id === bookingTermId)) {
      return bookingTermId
    }
    return terms[0].id
  }

  @action.bound
  async getReviews(id) {
    return await api.getReviews(id).then(({ data }) => {
      this.reviews = data
    })
  }

  @action
  toggleBookingModal() {
    this.showBookingModal = !this.showBookingModal
  }

  @action
  setDisableResetBookingForm = disable => {
    this.disableResetBookingForm = disable
  }

  @action
  resetBookingForm = () => {
    if (!this.disableResetBookingForm) {
      this.bookingForm = { ...this._defaultBookingForm }
      this.bookingIsAvailable = true
    }
  }

  @action.bound
  async getSpaceByProfileSlug(profileSlug) {
    this.isLoading = true
    try {
      const response = await api.getSpaceByProfileSlug(profileSlug)
      if (response) {
        const spaceResponse = response
        spaceResponse.medias = mapPlaceTitleToImagesTitle(
          spaceResponse,
          spaceResponse.medias
        )
        this.currentSpace = spaceResponse
      }
      this.isLoading = false
    } catch (error) {
      console.warn(error)
      this.isLoading = false
    }
  }

  @action
  handleBookingFormChange = (propName, e, isPaymentProperty = false) => {
    if (
      (this.bookingForm && e.target.value === undefined) ||
      this.bookingForm[propName] === e.target.value
    ) {
      return
    }

    if (!isPaymentProperty) {
      if (propName === 'term') {
        this.bookingForm.startDate = null
        this.bookingForm.endDate = null
      }
      this.bookingForm[propName] = e.target.value
    } else {
      this.bookingForm.payment[propName] = e.target.value
    }
    this.bookingIsAvailable = true
  }

  @action
  setBookingFormProp = (propName, value, setBookingIsAvailable = true) => {
    this.bookingForm[propName] = value
    if (setBookingIsAvailable) {
      this.bookingIsAvailable = true
    }
  }

  @action
  setBookingFormHourlyDate = (newDate, dayIndex) => {
    const { hourlyDateTimes } = this.bookingForm
    hourlyDateTimes[dayIndex].date = newDate
    this.setBookingFormProp('hourlyDateTimes', hourlyDateTimes)
    this.bookingIsAvailable = true
  }

  @action
  setBookingFormDates = (startDate, endDate, calculate = true) => {
    const { bookingForm } = this

    bookingForm.startDate = startDate ? startDate.clone().startOf('day') : null
    bookingForm.endDate = endDate ? endDate.clone().endOf('day') : null

    if (calculate && startDate && endDate) {
      bookingForm.price = this.spaceTotalOneTimeFee
      this.bookingIsAvailable = true
    }
  }

  @action
  setBookingFormMonthlyDate = (startDate, calculate = true) => {
    const { bookingForm } = this

    bookingForm.startDate = startDate ? startDate.clone().startOf('day') : null
    bookingForm.endDate = startDate
      ? startDate
          .clone()
          .startOf('day')
          .add(bookingForm.months, 'month')
          .subtract(1, 'day')
      : null

    if (calculate && startDate) {
      bookingForm.price = this.spaceTotalOneTimeFee
      this.bookingIsAvailable = true
    }
  }

  @action
  setBookingFormMonths = months => {
    const { bookingForm } = this

    bookingForm.months = months
    bookingForm.endDate = bookingForm.startDate
      ? bookingForm.startDate
          .clone()
          .startOf('day')
          .add(months, 'month')
          .subtract(1, 'day')
      : null
    this.bookingIsAvailable = true
  }

  @action
  setBookingIsAvailable = isAvailable => {
    this.bookingIsAvailable = isAvailable
  }

  @action
  _setBookingFormHourlyDateTimesFormatted = requestBookingForm => {
    requestBookingForm.hourly_date_times = this.bookingForm.hourlyDateTimes.map(
      hourly_date_time => ({
        start_time: hourly_date_time.startTime,
        end_time: hourly_date_time.endTime,
        ...hourly_date_time
      })
    )
  }

  @action
  _setBookingFormDatesFormatted = requestBookingForm => {
    requestBookingForm.start_date = this.bookingForm.startDate.format(
      'YYYY/MM/DD HH:mm:ss'
    )
    requestBookingForm.end_date = this.bookingForm.endDate.format(
      'YYYY/MM/DD HH:mm:ss'
    )
  }

  @action
  _formatBookingFormDates = requestBookingForm => {
    switch (this.bookingForm.term) {
      case TERMS.HOURLY.ID:
        this._setBookingFormHourlyDateTimesFormatted(requestBookingForm)
        break
      case TERMS.DAILY.ID:
        this._setBookingFormDatesFormatted(requestBookingForm)
        break
      case TERMS.MONTHLY.ID:
        this._setBookingFormDatesFormatted(requestBookingForm)
        break
      default:
        this._setBookingFormDatesFormatted(requestBookingForm)
        break
    }
  }

  @action.bound
  async saveBooking(nextSTEP, operatorParams = {}) {
    const { bookingForm } = this
    const requestBookingForm = {
      space_id: this.currentSpace.id,
      term_id: bookingForm.term,
      stripe_token: bookingForm.stripeToken ? bookingForm.stripeToken.id : null,
      price: Math.round(this.spaceTotalOneTimeFee * 100) / 100,
      card_id: bookingForm.card_id,
      ...operatorParams
    }
    this.isLoading = true

    this._formatBookingFormDates(requestBookingForm)

    let result = null
    try {
      result = await bookingApi.create(requestBookingForm)
      this.isLoading = false
      if (result.data.error) {
        if (result.status === 401) {
          userStore.openAuthDialog()
        }
        this.bookingFormError = result.data.error
        this.globalStore.openNotificationSnackbar(
          `Error: ${this.bookingFormError}`
        )
      } else {
        if (result.data.length === 1) {
          this.bookingForm.id = result.data[0].id
        }
        this.setActiveStep(nextSTEP)
        trackEvent({
          title: 'book now',
          data: 'space booked'
        })
      }
    } catch (error) {
      console.warn(error)
      this.bookingFormError = error.data.error
      this.globalStore.openNotificationSnackbar(
        `Error: ${this.bookingFormError}`
      )
    } finally {
      this.isLoading = false
    }

    return result
  }

  @action.bound
  async updateBooking(nextSTEP, operatorParams = {}, bookingId) {
    const { bookingForm } = this
    const requestBookingForm = {
      id: bookingId,
      space_id: this.currentSpace.id,
      term_id: bookingForm.term,
      stripe_token: bookingForm.stripeToken ? bookingForm.stripeToken.id : null,
      price: this.spaceTotalOneTimeFee,
      card_id: bookingForm.card_id,
      ...operatorParams
    }
    this.isLoading = true

    this._formatBookingFormDates(requestBookingForm)

    let result = null
    try {
      result = await bookingApi.update(requestBookingForm)
      this.isLoading = false
      if (result.data.error) {
        if (result.status === 401) {
          userStore.openAuthDialog()
        }
        this.bookingFormError = result.data.error
        this.globalStore.openNotificationSnackbar(
          `Error: ${this.bookingFormError}`
        )
      } else {
        if (result.data.length === 1) {
          this.bookingForm.id = result.data[0].id
        }
        this.setActiveStep(nextSTEP)
        trackEvent({
          title: 'book now',
          data: 'space booked'
        })
      }
    } catch (error) {
      console.warn(error)
      this.bookingFormError = error.data.error
      this.globalStore.openNotificationSnackbar(
        `Error: ${this.bookingFormError}`
      )
    } finally {
      this.isLoading = false
    }

    return result
  }

  @action.bound
  resetBookingFormError = () => {
    this.bookingFormError = ''
  }

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading
  }

  @computed
  get totalPrice() {
    const { bookingForm } = this
    if (bookingForm.price > 0) {
      return bookingForm.price
    }
    let totalPrice = 0
    if (bookingForm.hourlyDateTimes.length) {
      bookingForm.hourlyDateTimes.forEach(hourlyDateTime => {
        totalPrice += this.getHourlyDateTimePrice(hourlyDateTime)
      })
    }
    return totalPrice
  }

  @computed
  get isBookingFormSet() {
    const { startDate, hourlyDateTimes, term } = this.bookingForm
    return Boolean(
      (startDate || (hourlyDateTimes && hourlyDateTimes.length)) && term
    )
  }

  @action
  getHourlyDateTimePrice = hourlyDateTime => {
    const price = this.getAmountOfHours(hourlyDateTime) * this.spaceRateByTerm
    return round(price, 2)
  }

  @action
  getAmountOfHours = hourlyDateTime => {
    const startDateTime = hourlyDateTime.date
      .clone()
      .hour(hourlyDateTime.startTime)
    const endDateTime = hourlyDateTime.date.clone().hour(hourlyDateTime.endTime)
    return endDateTime.diff(startDateTime, 'hours')
  }

  @action.bound
  async loadSpaceTypes() {
    this.isLoadingSpaceTypes = true
    const spaceTypes = await spaceTypesApi.getAll()
    this.isLoadingSpaceTypes = false
    this.spaceTypes = spaceTypes.data
  }

  @action
  getSpaceRateByTerm(selectedTermId, terms) {
    const term = terms.find(term => term.id === selectedTermId)
    return term ? term.pivot.rate : terms[0].pivot.rate
  }

  @action
  getSpaceSetupFeeByTerm(selectedTermId, terms) {
    const term = terms.find(term => term.id === selectedTermId)
    return term ? term.pivot.setup_fee : terms[0].pivot.setup_fee
  }

  @action
  getSpaceSecurityDepositByTerm(selectedTermId, terms) {
    const term = terms.find(term => term.id === selectedTermId)
    return term ? term.pivot.security_deposit : terms[0].pivot.security_deposit
  }

  _tempTermSwitch(rates, term_id) {
    let rate
    switch (term_id) {
      case TERMS.HOURLY.ID:
        rate = rates.hour
        break
      case TERMS.DAILY.ID:
        rate = rates.day
        break
      case TERMS.MONTHLY.ID:
        rate = rates.month
        break
      case TERMS.YEARLY.ID:
        rate = rates.year
        break
      default:
        break
    }

    // TODO Remove when Rate column is fixed
    // Set rate to first non-null rate if selected term is null.
    if (!rate) {
      for (const rateKey in rates) {
        if (rates[rateKey]) {
          rate = rates[rateKey]
          break
        }
      }
    }

    return rate
  }

  @action
  syncCalendar = async id => {
    this.isSyncingCalendar = true
    try {
      if (!id && !this.currentSpace && !this.currentSpace.calendar_id) {
        return
      }
      await spaceApi.syncCalendar(id || this.currentSpace.id)
    } catch (e) {
      console.error('error syncing google calendar: ', e)
    } finally {
      this.isSyncingCalendar = false
    }
  }

  @action
  deleteSpace = async (venueId, spaceId) => {
    this.isDeletingSpace = true
    try {
      await adminSpaceApi.destroy(venueId, spaceId)
      this.globalStore.openNotificationSnackbar('Space has been deleted.')
      return true
    } catch (error) {
      this.globalStore.openNotificationSnackbar(`Error: ${error}`)
      return false
    } finally {
      this.isDeletingSpace = false
    }
  }

  @action
  updateMediaInfo = async (currentMedia, info) => {
    const media = await this.mediaStore.update(currentMedia.id, info)
    if (media) {
      this.currentSpace.medias = this.currentSpace.medias.map(currentMedia => {
        return currentMedia.id === media.id ? media : currentMedia
      })
      this.globalStore.openNotificationSnackbar(
        `"${this.currentSpace.name}" Space has been updated.`
      )
    } else {
      this.globalStore.openNotificationSnackbar(
        `Something went wrong. Please try again.`
      )
    }
  }

  @action
  addMediaToSpace = media => {
    media.object_id = this.currentSpace.id
    media.object_type = 'spaces'
    media.index_position = this.currentSpace.medias.length + 1
    this.currentSpace.medias.push(media)
  }

  @action
  removeMediaFromSpace = media => {
    const index = this.currentSpace.medias.indexOf(media)
    if (index > -1) {
      this.currentSpace.medias.splice(index, 1)
    }
  }

  @action
  getSpacesByVenueType = async (venueTypeId, lat, lng) => {
    this.isLoadingSpacesByVenueType = true
    try {
      const { data: spaces } = await spaceApi.getSpacesByVenueType(
        venueTypeId,
        {
          lat,
          lng
        }
      )
      this.spaces = spaces
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSpacesByVenueType = false
    }
  }

  @action
  getSpacesBySpaceType = async (spaceTypeId, lat, lng) => {
    this.isLoadingSpacesByType = true
    try {
      const { data: spaces } = await spaceApi.getSpacesBySpaceType(
        spaceTypeId,
        {
          lat,
          lng
        }
      )
      this.spaces = spaces
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingSpacesByType = false
    }
  }
}

export default new SpaceStore()
