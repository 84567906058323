import { action, observable, toJS } from 'mobx'
import globalStore from './global'
import userStore from './user'
import companyApi from '../services/companyApi'
import { validate, parseFormikErrors } from '../utilities/formUtils'

const companyVenuesDefaultPagination = {
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1
}

const defaultCompanyParams = {
  name: '',
  website: '',
  location: '',
  location_details: '',
  is_active: true,
  about_us: '',
  medias: []
}

class CompanyStore {
  @observable.deep
  companyParams = {
    ...defaultCompanyParams
  }
  @observable.deep
  companyErrors = {}
  @observable
  companyIsValid = false

  @observable.deep
  currentCompany = {
    ...defaultCompanyParams
  }
  @observable
  isLoading = false

  @observable.deep
  topPerformingVenues = []
  @observable
  isLoadingTopPerformingVenues = false

  @observable
  currentCompanyMetrics = {
    BookingsCount: 0,
    ToursCount: 0,
    ActiveCustomersCount: 0,
    ProspectiveCustomersCount: 0
  }
  @observable
  isLoadingDefaultVenue = false
  @observable.deep
  companyVenuesPagination = {
    ...companyVenuesDefaultPagination
  }
  @observable.deep
  companyVenuesInclude = []
  @observable.deep
  venues = []
  @observable
  isLoadingCompanyVenues = false

  @observable.deep
  defaultVenue = null
  @observable
  isLoadingDefaultVenue = false

  @observable
  isOpenAddCompanyDialog = false

  companyParamsValidations = {
    name: {
      required: true
    }
  }

  @observable
  isDeactivatingCompany = false

  @action
  fetch = async () => {
    try {
      this.isLoading = true
      const { data } = await companyApi.fetch()
      return data
    } catch (error) {
      return []
    } finally {
      this.isLoading = false
    }
  }

  @action
  create = async (name, finished) => {
    try {
      this.isSavingCompany = true
      const { data: company } = await companyApi.create({
        company: { ...this.companyParams, name }
      })
      this.currentCompany = company
      userStore.setCurrentCompany(company)
      await userStore.getMe()
      this._resetCompanyValues()
      if (finished) finished()
      return {}
    } catch ({ data, status }) {
      this.companyErrors = parseFormikErrors(data)
      return { errors: this.companyErrors }
    } finally {
      this.isSavingCompany = false
    }
  }

  @action
  update = async () => {
    try {
      this.isLoading = true
      const { data } = await companyApi.update(this.currentCompany)
      this.currentCompany = data
      globalStore.openNotificationSnackbar('Company profile updated.')
    } catch (error) {
      console.error(error)
      globalStore.openNotificationSnackbar(
        'There was a problem updating your company profile.'
      )
    } finally {
      this.isLoading = false
    }
  }

  @action
  setCurrentCompany = async company => {
    this.currentCompany = company
  }

  updateAttribute = (property, value) => {
    this.currentCompany = { ...this.currentCompany, [property]: value }
  }

  @action.bound
  async getTopPerformingVenues(companyId) {
    this.isLoadingTopPerformingVenues = true
    try {
      const {
        data: { topPerformingVenues, metrics }
      } = await companyApi.getTopPerformingVenues(companyId)
      this.topPerformingVenues = topPerformingVenues
      this.currentCompanyMetrics = metrics
    } catch (error) {
      console.error(error)
      globalStore.openNotificationSnackbar(
        'There was a problem fetching the top performing venues'
      )
    } finally {
      this.isLoadingTopPerformingVenues = false
    }
  }

  @action
  handleChangeCompanyVenuesPage = page => {
    this.companyVenuesPagination = {
      ...this.companyVenuesPagination,
      page
    }
  }

  @action
  resetCompanyVenuesPagination = () => {
    this.companyVenuesPagination = {
      ...companyVenuesDefaultPagination
    }
  }

  @action.bound
  async getVenues(
    companyId,
    include,
    publishedOnly = null,
    include_default = null
  ) {
    this.isLoadingCompanyVenues = true
    try {
      if (include) {
        this.companyVenuesInclude = include
      }

      const {
        data: { venues, pagination, count }
      } = await companyApi.getVenues(companyId, {
        include: toJS(this.companyVenuesInclude),
        pagination: {
          page: this.companyVenuesPagination.page,
          perPage: this.companyVenuesPagination.perPage
        },
        filters: { publishedOnly },
        include_default
      })
      this.venues = venues
      this.companyVenuesPagination = { ...pagination, total: count }
    } catch (error) {
      console.error(error)
      globalStore.openNotificationSnackbar(
        'There was a problem fetching the company venues'
      )
    } finally {
      this.isLoadingCompanyVenues = false
    }
  }

  @action
  handleOpenAddCompanyDialog = () => {
    this.isOpenAddCompanyDialog = true
  }

  @action
  handleCloseAddCompanyDialog = () => {
    this._resetCompanyValues()
    this.isOpenAddCompanyDialog = false
  }

  @action
  handleChangeCompanyFieldValue = evt => {
    this.companyParams = {
      ...this.companyParams,
      [evt.target.name]: evt.target.value
    }

    const [isValid] = validate(
      this.companyParams,
      this.companyParamsValidations
    )
    this.companyIsValid = isValid
  }

  _resetCompanyValues = () => {
    this.companyParams = {
      ...defaultCompanyParams
    }

    this.companyErrors = {}
  }

  deactivate = async companyId => {
    this.isDeactivatingCompany = true
    try {
      await companyApi.deactivate(companyId)
      return true
    } catch (error) {
      console.error(error)
      globalStore.openNotificationSnackbar(error.data.error)
      return false
    } finally {
      this.isDeactivatingCompany = false
    }
  }
}

export default new CompanyStore()
