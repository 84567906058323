import BaseApi from './baseApi'
import userStore from '../stores/user'

class SpaceTypesApi extends BaseApi {
  getAll = () => {
    return this.request(userStore.jwtToken).get('space-types')
  }
}

export default new SpaceTypesApi()
