export const COLUMNS = {
  START_DATE: {
    label: 'Start Date & Time',
    value: 'start_time'
  },
  END_DATE: {
    label: 'End Date & Time',
    value: 'end_time'
  },
  DURATION: {
    label: 'Duration',
    value: 'duration'
  },
  ITEM: {
    label: 'Item',
    value: 'item'
  },
  NAME: {
    label: 'Name',
    value: 'name'
  },
  COMPANY: {
    label: 'Company',
    value: 'company'
  },
  TRANSACTION_STATUS: {
    label: 'Transaction Status',
    value: 'transaction_status'
  },
  PAYMENT: {
    label: 'Payment Type',
    value: 'payment'
  },
  PAYMENT_STATUS: {
    label: 'Payment Status',
    value: 'payment_status'
  },
  TERM: {
    label: 'Term',
    value: 'term'
  },
  BASE_PRICE: {
    label: 'Base Price',
    value: 'base_price'
  },
  BASE_SETUP_FEE: {
    label: 'Base Setup Fee',
    value: 'base_setup_fee'
  },
  BASE_TOTAL: {
    label: 'Base Total',
    value: 'base_total'
  },
  SECURITY_DEPOSIT: {
    label: 'Security Deposit',
    value: 'security_deposit'
  },
  AMOUNT_PAID: {
    label: 'Sales Amount',
    value: 'amount_paid'
  },
  REFUNDED: {
    label: 'Refund',
    value: 'refunded'
  },
  SPACEMX_FEE: {
    label: 'SpaceMX Fee',
    value: 'spacemx_fee'
  },
  VENUE_PAYOUT: {
    label: 'Venue Payout Amount',
    value: 'venue_payout_amount'
  },
  CREATED_ON: {
    label: 'Created On',
    value: 'created_on'
  },
  CANCELLED_ON: {
    label: 'Cancelled On',
    value: 'cancelled_on'
  },
  BOOKING_ID: {
    label: 'Booking ID',
    value: 'booking_id'
  },
  SUBSCRIPTION_ID: {
    label: 'Subscription ID',
    value: 'subscription_id'
  },
  TRANSACTION_ID: {
    label: 'Transaction ID',
    value: 'transaction_id'
  }
}

export const getColumnByLabel = label => {
  return Object.values(COLUMNS).find(column => column.label === label)
}

export const getColumnByValue = value => {
  return Object.values(COLUMNS).find(column => column.value === value)
}

export const getLabels = () => {
  return Object.values(COLUMNS).map(column => column.label)
}

export const getValues = () => {
  return Object.values(COLUMNS).map(column => column.value)
}

export const getColumns = () => {
  return Object.values(COLUMNS)
}
