import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  withMobileDialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import ProgressButton from '../Global/ProgressButton'

const styles = theme => ({
  inputSpacing: { marginBottom: theme.spacing.unit * 3 },
  dialogActionsContainer: {
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  dialogHeaderText: { float: 'left' },
  closeIcon: {
    float: 'right',
    marginTop: theme.spacing.unit * -2,
    marginRight: theme.spacing.unit * -2
  }
})

@inject('userStore')
@observer
class LoginDialog extends React.Component {
  _defaultState = {
    email: {
      value: '',
      error: null
    }
  }

  constructor(props) {
    super(props)
    this.state = this._defaultState
  }

  _handleClose = () => {
    this.setState(this._defaultState)
    this.props.userStore.closeAuthDialog('forgot_password')
  }

  _handleChange = event => {
    const { target } = event
    this.setState({ [target.name]: { value: target.value } })
  }

  handleSubmit = async () => {
    const { email } = this.state
    const { userStore, redirect, location } = this.props

    if (!email.value) {
      this.setState({
        email: { error: !email.value ? 'Invalid Email' : null }
      })
      return
    }

    const passwordReset = await userStore.requestPasswordReset(email.value)
    if (passwordReset && passwordReset.errors) {
      this.setState({
        email: { error: !passwordReset.errors['email'] }
      })
      return
    }

    if (redirect && location) {
      if (location.state) {
        this.props.history.push(location.state.from.pathname)
      } else {
        this.props.history.push('/')
      }
    }

    this._handleClose()
  }

  render() {
    const {
      open,
      fullScreen,
      classes,
      userStore: { isResettingPassword }
    } = this.props
    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        PaperProps={{ style: { width: 560 } }}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        onClose={this._handleClose}
        data-test="e2e-forgot-password-dialog"
      >
        <DialogTitle style={{ paddingBottom: 24 }}>
          <Typography variant="h5" className={classes.dialogHeaderText}>
            Reset your password
          </Typography>
          <IconButton
            onClick={this._handleClose}
            aria-label="Close"
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {this.state.message && <Card>{this.state.message}</Card>}
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.inputSpacing}>
              <TextField
                id="email"
                label="Email"
                name="email"
                fullWidth
                placeholder="Enter your email address"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.email.value}
                error={this.state.email.error}
                onChange={event => this._handleChange(event)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <ProgressButton
            showProgress={isResettingPassword}
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            data-test="e2e-forgot-password-reset-button"
          >
            Reset
          </ProgressButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withRouter(withStyles(styles)(withMobileDialog()(LoginDialog)))
