// #lizard forgives the complexity

import qs from 'qs'
import BaseApi from './baseApi'
import userStore from '../stores/user'

class VenueApi extends BaseApi {
  constructor() {
    super({ routeName: 'venues' })
  }

  getAll = params => {
    return this.request().get('/', {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getForUser = params => {
    return this.request(userStore.jwtToken).get('/my', {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueById = (venueId, params) => {
    if (!venueId) return Error('No venue id provided')

    return this.request(userStore.jwtToken).get(`/${venueId}`, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueSpaces = (venueId, params) => {
    if (!venueId) return Error('No venue id provided')
    const url = `/${venueId}/spaces`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueSpacesByAvailability = (venueId, params) => {
    const url = `/${venueId}/spacesByAvailability`
    return this.request(userStore.jwtToken).post(url, params)
  }

  getVenueCommonAreasByVenueId = venueTypeId =>
    this.request().get(`${venueTypeId}/common-areas`)

  getNearbyVenuesById = (venueId, params) => {
    return this.request().get(`${venueId}/near-by`, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueTransactions = ({ limit = 20, filters = {}, sort = {}, venueId }) => {
    if (!venueId) return Error('No venue id provided')
    return this.request(userStore.jwtToken).get(`${venueId}/transactions`, {
      params: { filters, sort, limit },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  create = formData => {
    return this.request(userStore.jwtToken).post('', formData)
  }

  update = venue => {
    if (!venue) return Error('No venue data provided')
    return this.request(userStore.jwtToken).put(`/${venue.id}`, venue)
  }

  delete = venueId => {
    if (!venueId) return Error('No venue ID provided')
    return this.request(userStore.jwtToken).delete(`/${venueId}`)
  }

  updateHolidays = (venueId, data) => {
    if (!data) return Error('No holiday data provided')
    return this.request(userStore.jwtToken).put(
      `/${venueId}/update-holidays`,
      data
    )
  }

  updateBusinessHours = (venueId, data) => {
    if (!data) return Error('No business hours data provided')
    return this.request(userStore.jwtToken).put(
      `/${venueId}/update-business-hours`,
      data
    )
  }

  updateBuffers = (venueId, data) => {
    if (!data) return Error('No buffers data provided')
    return this.request(userStore.jwtToken).put(
      `/${venueId}/update-buffer`,
      data
    )
  }

  updateRules = (venueId, data) => {
    if (!data) return Error('No rules data provided')
    return this.request(userStore.jwtToken).put(
      `/${venueId}/update-rules`,
      data
    )
  }

  finalizePaymentAccount = (venueId, accountCode) => {
    if (!accountCode) throw new Error('No account code provided')

    return this.request(userStore.jwtToken).post(
      `/${venueId}/finalize-payment-account`,
      { account_code: accountCode }
    )
  }

  updateVenuePublishedStatus = ({ venueId, is_published }) => {
    return this.request(userStore.jwtToken).post(
      `/${venueId}/update-published-status`,
      { is_published }
    )
  }

  setGoogleAccount = (venueId, params = {}) => {
    return this.request(userStore.jwtToken).put(
      `/${venueId}/set_google_account`,
      params
    )
  }

  getGoogleAccount = venueId => {
    return this.request(userStore.jwtToken).get(
      `/${venueId}/get_google_account`
    )
  }

  syncCalendar = venueId => {
    return this.request().post(`/${venueId}/sync-calendar`, {})
  }

  suggestPlace = (venueInfo = {}) => {
    return this.request().post(`/suggest-place`, { venue: venueInfo })
  }

  getVenueMetrics = venueId => {
    return this.request(userStore.jwtToken).get(`/${venueId}/metrics`)
  }

  getContacts = (venueId, params) => {
    const url = `/${venueId}/contacts`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchContact = (venueId, contactId) => {
    const url = `/${venueId}/contact/${contactId}`
    return this.request(userStore.jwtToken).get(url)
  }

  createContact = (venueId, params) => {
    const url = `/${venueId}/contact`
    return this.request(userStore.jwtToken).post(url, params)
  }

  deleteContact = (venueId, contactId) => {
    const url = `/${venueId}/contact/${contactId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  updateContact = (venueId, contactId, params) => {
    const url = `/${venueId}/contact/${contactId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  importVenueContacts = (venueId, data) => {
    const url = `${venueId}/contacts/bulk-import`
    return this.request(userStore.jwtToken).post(url, data)
  }

  getEmployees = (venueId, params) => {
    const url = `/${venueId}/employees`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchEmployee = (venueId, employeeId) => {
    const url = `/${venueId}/employee/${employeeId}`
    return this.request(userStore.jwtToken).get(url)
  }

  createEmployee = (venueId, params) => {
    const url = `/${venueId}/employee`
    return this.request(userStore.jwtToken).post(url, params)
  }

  updateEmployee = (venueId, employeeId, params) => {
    const url = `/${venueId}/employee/${employeeId}`
    return this.request(userStore.jwtToken).put(url, params)
  }

  fetchSidebarCounts = venueId => {
    const url = `/${venueId}/sidebarCounts`
    return this.request(userStore.jwtToken).get(url)
  }

  getAbility = venueId => {
    const url = `/${venueId}/ability`
    return this.request(userStore.jwtToken).get(url)
  }

  getProductById = (venueId, productId) => {
    const url = `/${venueId}/products/${productId}`
    return this.request(userStore.jwtToken).get(url)
  }

  getVenueProducts = (venueId, params) => {
    const url = `/${venueId}/products`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueTopPerformingProducts = venueId => {
    const url = `/${venueId}/topPerformingProducts`
    return this.request(userStore.jwtToken).get(url)
  }

  getVenuePublicProducts = (venueId, params) => {
    const url = `/${venueId}/publicProducts`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getProductTypeGroups = (venueId, params) => {
    const url = `/${venueId}/productGroups`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueSubscriptions = (venueId, params) => {
    const url = `/${venueId}/subscriptions`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getVenueSubscriptionById = (venueId, subscriptionId) => {
    const url = `/${venueId}/subscriptions/${subscriptionId}`
    return this.request(userStore.jwtToken).get(url)
  }

  createProduct = (venueId, params) => {
    const url = `/${venueId}/products`
    return this.request(userStore.jwtToken).post(url, params)
  }

  updateProduct = (venueId, productId, params) => {
    const url = `/${venueId}/products/${productId}`
    return this.request(userStore.jwtToken).post(url, params)
  }

  createSubscription = (venueId, productId, params) => {
    const url = `/${venueId}/products/${productId}/subscriptions`
    return this.request(userStore.jwtToken).post(url, params)
  }

  updateSubscription = (venueId, productId, subscriptionId, params) => {
    const url = `/${venueId}/products/${productId}/subscriptions/${subscriptionId}`
    return this.request(userStore.jwtToken).post(url, params)
  }

  cancelSubscription = (venueId, productId, subscriptionId) => {
    const url = `/${venueId}/products/${productId}/subscriptions/${subscriptionId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  isSubscriptionAvailable = (venueId, productId, params) => {
    const url = `/${venueId}/products/${productId}/isAvailable`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getSubscriptions = (venueId, params) => {
    const url = `/${venueId}/subscriptions`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  toggleProductPublished = (venueId, productId) => {
    const url = `/${venueId}/products/${productId}/togglePublished`
    return this.request(userStore.jwtToken).post(url)
  }

  deleteProduct = (venueId, productId) => {
    const url = `/${venueId}/products/${productId}`
    return this.request(userStore.jwtToken).delete(url)
  }

  sendSubscriptionDetails = (
    venueId,
    productId,
    subscriptionId,
    recipients
  ) => {
    const url = `/${venueId}/products/${productId}/subscriptions/${subscriptionId}/email`
    return this.request(userStore.jwtToken).post(url, {
      recipients
    })
  }

  createSpace = ({ venueId, formData }) => {
    const url = `/${venueId}/spaces`
    return this.request(userStore.jwtToken).post(url, formData)
  }

  setup = ({ venueId, formData }) => {
    const url = `/${venueId}/setup`
    return this.request(userStore.jwtToken).post(url, formData)
  }

  getSpacePreview = (venueId, stateId, params = {}) => {
    const url = `/${venueId}/spaces/preview/${stateId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getProductPreview = (venueId, productId) => {
    const url = `/${venueId}/services/preview/${productId}`
    return this.request(userStore.jwtToken).get(url)
  }

  searchTransactions = (venueId, params = {}) => {
    if (!venueId) return Error('No venue id provided')
    const url = `${venueId}/searchTransactions`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  xlsTransactions = (venueId, params = {}) => {
    if (!venueId) return Error('No venue id provided')
    const url = `${venueId}/xlsTransactions`
    return this.request(userStore.jwtToken).post(url, params, {
      headers: { Accept: 'application/vnd.openxmlformats' },
      responseType: 'blob'
    })
  }

  getVenuesByVenueType = (venueTypeId, params = {}) => {
    if (!venueTypeId) return Error('No venue type id provided')
    const url = `/getByVenueType/${venueTypeId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getProductsByVenueType = (venueTypeId, params = {}) => {
    if (!venueTypeId) return Error('No venue type id provided')
    const url = `/products/getByVenueType/${venueTypeId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  getProductsBySpaceType = (spaceTypeId, params = {}) => {
    if (!spaceTypeId) return Error('No space type id provided')
    const url = `/products/getBySpaceType/${spaceTypeId}`
    return this.request(userStore.jwtToken).get(url, {
      params,
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }

  fetchSubscriptionHistory = (venueId, productId, subscriptionId, params) => {
    return this.request(userStore.jwtToken).get(
      `${venueId}/products/${productId}/subscriptions/${subscriptionId}/history`,
      {
        params,
        paramsSerializer: params => qs.stringify(params, { encode: false })
      }
    )
  }
}

export default new VenueApi()
