import { observable, action, computed } from 'mobx'
import spaceStore from './space'
import globalStore from './global'
import venueStore from './venue'
import productStore from './product'
import { createBrowserHistory } from 'history'
import qs from 'qs'
import S from 'string'
import moment from 'moment'
import formatMoney from 'utilities/formatMoney'

import { SEARCH_TYPES } from '@constants/SEARCH_TYPES'

const history = createBrowserHistory()

class SearchStore {
  @observable
  query = null
  @observable
  resultString = null
  @observable.deep
  bounds = {
    getNorthEast: () => {
      return {
        lat: () => 38.24557445713539,
        lng: () => -121.89377286376953
      }
    },
    getSouthWest: () => {
      return {
        lat: () => 37.30120990443992,
        lng: () => -122.94502713623046
      }
    }
  }
  @observable
  termId = 'All Terms'
  @observable
  startDate = null
  @observable
  endDate = null
  @observable
  currentPage = 0
  @observable
  rowsPerPage = 18
  @observable
  zoom = 14
  @observable
  isLoading = true
  @observable
  isQueryParamsLoading = false
  @observable
  filtersApplied = {
    spaceType: false,
    dateRange: false,
    term: false
  }
  @observable
  currentTypeFilter = {
    venueTypeGroup: null,
    venueType: null,
    spaceType: null,
    productTypeGroup: null,
    productCategory: null,
    productType: null,
    isApplied: false,
    isExpanded: false
  }
  defaultPrice = { min: 0, max: 2500 }
  @observable
  currentPriceFilter = {
    min: null,
    max: null,
    isApplied: false,
    isExpanded: false
  }
  @observable
  isMoreOptionsVisible = false
  @observable
  currentVenueTypeGroup = null

  @observable.deep
  spacePoints = null

  @observable
  searchType = 'spaces'

  constructor() {
    this.globalStore = globalStore
    this.venueStore = venueStore
  }

  @action
  toggleSearchType = searchType => {
    this.searchType = searchType
  }

  @action.bound
  setQuery(query) {
    this.query = query
  }

  @action.bound
  setResultString(resultString) {
    this.resultString = resultString
  }

  @action.bound
  setBounds(bounds) {
    this.bounds = bounds
  }

  @action.bound
  setSpacePoints(spacePoints) {
    this.spacePoints = spacePoints
  }

  @action
  setTypeFilters = () => {
    const {
      venueTypeGroup,
      venueType,
      spaceType,
      productTypeGroup,
      productCategory,
      productType
    } = this.currentTypeFilter
    this._updateQueryParams({
      ...(venueTypeGroup && {
        venue_type_group: venueTypeGroup.id
      }),
      ...(venueType && {
        venue_type: venueType.id
      }),
      ...(spaceType && { space_type: spaceType.id }),
      ...(productTypeGroup && {
        product_type_group: productTypeGroup.id
      }),
      ...(productCategory && {
        product_category: productCategory.id
      }),
      ...(productType && { product_type: productType.id })
    })
    this.filtersApplied.venueTypeGroup = Boolean(venueTypeGroup)
    this.filtersApplied.venueType = Boolean(venueType)
    this.filtersApplied.spaceType = Boolean(spaceType)
    this.filtersApplied.productTypeGroup = Boolean(productTypeGroup)
    this.filtersApplied.productCategory = Boolean(productCategory)
    this.filtersApplied.productType = Boolean(productType)
    this.setCurrentTypeFilter('isApplied', true)
  }

  @action.bound
  setTermId(termId) {
    this.isLoading = true
    this.termId = termId
    if (termId !== 'All Terms') {
      const term = this.globalStore.terms.find(term => term.id === termId)
      this._updateQueryParams({ term: S(term.name).underscore().s })
      this.filtersApplied.term = true
    } else {
      this._removeQueryParams(['term'])
      this.filtersApplied.term = false
    }
  }

  @action.bound
  setDates(startDate, endDate) {
    this.startDate = startDate
    this.endDate = endDate

    const queryParams = {
      start_date: startDate ? startDate.format('YYYY-MM-DD h:mm') : null,
      end_date: endDate ? endDate.format('YYYY-MM-DD h:mm') : null
    }

    if (!this.filtersApplied.dateRange && Object.keys(queryParams).length) {
      this.filtersApplied.dateRange = true
    }

    if (!Object.keys(queryParams).length) {
      this.filtersApplied.dateRange = false
    }

    this._updateQueryParams(queryParams)
  }

  @action
  setFiltersDefault = () => {
    this.termId = 'All Terms'
    this.startDate = null
    this.endDate = null
  }

  @action.bound
  setCurrentPage(currentPage) {
    this.currentPage = currentPage
  }

  @action.bound
  resetCurrentPage() {
    this.setCurrentPage(0)
  }

  @action.bound
  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage
  }

  @action.bound
  setZoom(zoom) {
    this.zoom = zoom
  }

  @action.bound
  setLocation(location) {
    this._updateQueryParams({ location: location })
  }

  @action
  getByBounds = async () => {
    if (this.searchType === SEARCH_TYPES.SPACES) {
      await this.getSpacesByBounds()
    } else {
      await this.getProductsByBounds()
    }
  }

  @action.bound
  async getSpacesByBounds(rowsPerPage = null) {
    this.isLoading = true
    const isCurrentPriceFilterSet =
      this.currentPriceFilter.isApplied &&
      this.currentPriceFilterAsArray.every(price => price !== null)
    try {
      await spaceStore.getSpacesByBounds({
        bounds: this.bounds,
        venueTypeGroupId: this.currentTypeFilter.venueTypeGroup
          ? this.currentTypeFilter.venueTypeGroup.id
          : null,
        venueTypeId: this.currentTypeFilter.venueType
          ? this.currentTypeFilter.venueType.id
          : null,
        spaceTypeId: this.currentTypeFilter.spaceType
          ? this.currentTypeFilter.spaceType.id
          : null,
        termId: this.termId,
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.currentPage,
        limit: rowsPerPage ? rowsPerPage : this.rowsPerPage,
        price: isCurrentPriceFilterSet ? this.currentPriceFilterAsArray : null
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  @action
  getProductsByBounds = async (rowsPerPage = null) => {
    this.isLoading = true
    const isCurrentPriceFilterSet =
      this.currentPriceFilter.isApplied &&
      this.currentPriceFilterAsArray.every(price => price !== null)

    const {
      productTypeGroup,
      productType,
      productCategory
    } = this.currentTypeFilter
    try {
      await productStore.getProductsByBounds({
        bounds: this.bounds,
        productTypeGroupId: productTypeGroup ? productTypeGroup.id : null,
        productTypeId: productType ? productType.id : null,
        productCategoryId: productCategory ? productCategory.id : null,
        termId: this.termId,
        page: this.currentPage,
        limit: rowsPerPage ? rowsPerPage : this.rowsPerPage,
        price: isCurrentPriceFilterSet ? this.currentPriceFilterAsArray : null
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async getSpacesByLatLng(rowsPerPage = null) {
    this.isLoading = true
    try {
      await spaceStore.getSpacesByLatLng(
        this.lat,
        this.lng,
        this.spaceTypeId,
        this.termId,
        this.startDate,
        this.endDate,
        this.currentPage,
        rowsPerPage ? rowsPerPage : this.rowsPerPage
      )
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  @action
  setIsQueryParamsLoading = isLoading => {
    this.isQueryParamsLoading = isLoading
  }

  @action
  _updateQueryParams = (params = {}) => {
    const { location } = window
    const { search } = location
    const searchParams = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    return history.push({
      pathname: location.pathname,
      search: qs.stringify(
        {
          ...searchParams,
          ...params
        },
        { addQueryPrefix: true, encode: false }
      )
    })
  }

  @action
  _removeQueryParams = (params = []) => {
    const { location } = window
    const { search } = location
    const searchParams = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    params.forEach(param => {
      delete searchParams[param]
    })

    return history.push({
      pathname: location.pathname,
      search: qs.stringify(
        {
          ...searchParams
        },
        { addQueryPrefix: true }
      )
    })
  }

  @action
  toggleMoreOptionsVisible = () => {
    this.isMoreOptionsVisible = !this.isMoreOptionsVisible
  }

  @action
  setCurrentTypeFilter = (filterKey, filterValue) => {
    if (filterValue) {
      this.currentTypeFilter[filterKey] = filterValue
    }
  }

  @action
  setCurrentPriceFilter = ([min, max]) => {
    this.currentPriceFilter.min = min
    this.currentPriceFilter.max = max
  }

  @action
  setSearchParamsToCurrentTypeFilter = (
    searchQueryParams,
    venueTypeGroups,
    productTypeGroups
  ) => {
    let shouldSetTypeFilters = false
    if (searchQueryParams.venue_type_group) {
      this.setCurrentTypeFilter('venueTypeGroup', {
        ...venueTypeGroups.find(
          group => group.id === +searchQueryParams.venue_type_group
        )
      })
      shouldSetTypeFilters = true
    }
    if (searchQueryParams.venue_type) {
      this.setCurrentTypeFilter('venueType', {
        ...this.currentTypeFilter.venueTypeGroup.venueTypes.find(
          venueType => venueType.id === +searchQueryParams.venue_type
        )
      })
      shouldSetTypeFilters = true
    }
    if (searchQueryParams.space_type) {
      const spaceTypes = searchQueryParams.venue_type
        ? this.currentTypeFilter.venueType.spaceTypes
        : this.globalStore.spaceTypes
      const spaceType = spaceTypes.find(
        spaceType => spaceType.id === +searchQueryParams.space_type
      )
      this.setCurrentTypeFilter('spaceType', spaceType)
      shouldSetTypeFilters = true
    }
    if (searchQueryParams.product_type_group) {
      this.setCurrentTypeFilter('productTypeGroup', {
        ...productTypeGroups.find(
          group => group.id === +searchQueryParams.product_type_group
        )
      })
      shouldSetTypeFilters = true
    }
    if (searchQueryParams.product_category) {
      this.setCurrentTypeFilter('productCategory', {
        ...this.currentTypeFilter.productTypeGroup.productCategories.find(
          productCategory =>
            productCategory.id === +searchQueryParams.product_category
        )
      })
      shouldSetTypeFilters = true
    }
    if (searchQueryParams.product_type) {
      const productTypes = searchQueryParams.product_category
        ? this.currentTypeFilter.productCategory.productTypes
        : this.globalStore.productTypes
      const productType = productTypes.find(
        productType => productType.id === +searchQueryParams.product_type
      )
      this.setCurrentTypeFilter('productType', productType)
      shouldSetTypeFilters = true
    }
    if (shouldSetTypeFilters) this.setTypeFilters()
  }

  @action
  initialSearchPageLoad = async location => {
    await this.globalStore.loadTerms()
    await this.globalStore.loadMeta()
    await this.venueStore.loadVenueTypeGroups()
    await this.globalStore.loadSpaceTypes()
    await this.globalStore.loadProductTypeGroups()
    await this.globalStore.loadProductTypes()
    const venueTypeGroups = this.venueStore.venueTypeGroups
    const productTypeGroups = this.globalStore.productTypeGroups
    if (location.spacePoints) this.spacePoints = location.spacePoints
    if (location.search) {
      const searchQueryParams = qs.parse(location.search)
      if (searchQueryParams.search_type) {
        this.searchType = searchQueryParams.search_type
      }
      this.setSearchParamsToCurrentTypeFilter(
        searchQueryParams,
        venueTypeGroups,
        productTypeGroups
      )
      this.loadQueryParamForTerm(searchQueryParams.term)
      this.loadQueryParamsForDate(
        searchQueryParams.start_date,
        searchQueryParams.end_date
      )
      this.loadQueryParamsForPrice(searchQueryParams.price)
    }
    this.setIsQueryParamsLoading(false)
  }

  @action
  loadQueryParamForTerm = term => {
    if (term) {
      const termInstance = this.globalStore.terms.find(
        termItem =>
          termItem.name ===
          term
            .toLowerCase()
            .split('_')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
      )
      if (termInstance) {
        this.setTermId(termInstance.id)
      }
    }
  }

  @action
  loadQueryParamsForDate = (start_date, end_date) => {
    let startDate = null
    let endDate = null

    if (start_date) {
      startDate = moment(start_date, 'YYYY-MM-DD')
    }

    if (end_date) {
      endDate = moment(end_date, 'YYYY-MM-DD')
    }

    this.setDates(startDate, endDate)
  }

  @action
  loadQueryParamsForPrice = (priceArray = []) => {
    if (priceArray && priceArray.length) {
      this.setCurrentPriceFilter(priceArray.map(price => +price))
      this.currentPriceFilter.isApplied = true
    }
  }

  @action
  applyCurrentTypeFilter = async () => {
    this.isLoading = true
    try {
      this.setTypeFilters()
      this.resetCurrentPage()
      await this.getByBounds()
      this.currentTypeFilter.isApplied = true
      this.toggleTypeFilterExpanded()
    } catch (error) {
      console.error(error)
      this.currentTypeFilter.isApplied = false
    } finally {
      this.isLoading = false
    }
  }

  @action
  resetCurrentTypeFilter = async (skipRefresh = false) => {
    this.currentTypeFilter = {
      ...this.currentTypeFilter,
      venueTypeGroup: null,
      venueType: null,
      spaceType: null,
      productTypeGroup: null,
      productCategory: null,
      productType: null,
      isApplied: false
    }
    this._removeQueryParams([
      'venue_type_group',
      'venue_type',
      'space_type',
      'product_type_group',
      'product_category',
      'product_type'
    ])
    this.resetCurrentPage()
    if (!skipRefresh) {
      await this.getByBounds()
    }
  }

  @action
  applyCurrentPriceFilter = async () => {
    this.isLoading = true
    try {
      this._updateQueryParams({ price: this.currentPriceFilterAsArray })
      this.resetCurrentPage()
      this.currentPriceFilter.isApplied = true
      await this.getByBounds()
      this.togglePriceFilterExpanded()
    } catch (error) {
      console.error(error)
      this.currentPriceFilter.isApplied = false
    } finally {
      this.isLoading = false
    }
  }

  @action
  resetCurrentPriceFilter = async (skipRefresh = false) => {
    this.currentPriceFilter = {
      ...this.currentPriceFilter,
      min: this.defaultPrice.min,
      max: this.defaultPrice.max,
      isApplied: false
    }
    this._removeQueryParams(['price'])
    this.resetCurrentPage()
    if (!skipRefresh) {
      await this.getByBounds()
    }
  }

  @action
  resetAllMoreOptionsFilters = async () => {
    this.resetCurrentTypeFilter(true)
    this.resetCurrentPriceFilter(true)
    await this.getByBounds()
  }

  resetAll = () => {
    this.resetCurrentTypeFilter(true)
    this.resetCurrentPriceFilter(true)
    this.isMoreOptionsVisible = false
    this.searchType = 'spaces'
  }

  @computed
  get currentTypeFilterAppliedText() {
    const {
      venueTypeGroup,
      venueType,
      spaceType,
      productTypeGroup,
      productCategory,
      productType,
      isApplied
    } = this.currentTypeFilter
    if (isApplied) {
      let textItems

      if (this.searchType === SEARCH_TYPES.SPACES) {
        textItems = [
          ...(venueTypeGroup ? [venueTypeGroup.title] : []),
          ...(venueType ? [venueType.title] : []),
          ...(spaceType ? [spaceType.title] : [])
        ]
      } else {
        textItems = [
          ...(productTypeGroup ? [productTypeGroup.title] : []),
          ...(productCategory ? [productCategory.title] : []),
          ...(productType ? [productType.title] : [])
        ]
      }
      return textItems.length ? textItems.join(' / ') : null
    }
    return null
  }

  @computed
  get currentPriceFilterAppliedText() {
    const { min, max, isApplied } = this.currentPriceFilter
    const minText = min !== null ? formatMoney(min) : null
    const maxText =
      max === 10000 ? '$10,000+' : max !== null ? formatMoney(max) : null
    return isApplied ? `${minText} to ${maxText}` : null
  }

  @computed
  get currentPriceFilterAsArray() {
    const { min, max } = this.currentPriceFilter
    return [min, max]
  }

  @computed
  get numberOfMoreOptionsFiltersApplied() {
    let moreFiltersApplied = 0
    moreFiltersApplied += this.currentTypeFilter.isApplied ? 1 : 0
    moreFiltersApplied += this.currentPriceFilter.isApplied ? 1 : 0
    return moreFiltersApplied
  }

  @action
  toggleTypeFilterExpanded = () => {
    this.currentTypeFilter.isExpanded = !this.currentTypeFilter.isExpanded
  }

  @action
  togglePriceFilterExpanded = () => {
    this.currentPriceFilter.isExpanded = !this.currentPriceFilter.isExpanded
  }
}

export default new SearchStore()
