import BaseApi from '../baseApi'
import userStore from '../../stores/user'
import qs from 'qs'

class BookingApi extends BaseApi {
  constructor() {
    super({ routeName: 'admin/bookings' })
  }

  fetch = filters => {
    return this.request(userStore.jwtToken).get('', {
      params: {
        filters
      },
      paramsSerializer: params => qs.stringify(params, { encode: false })
    })
  }
}

const bookingApi = new BookingApi()
export default bookingApi
