import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'

const styles = {
  listItemRoot: {
    padding: 0
  },
  listItem: {
    paddingTop: 16,
    paddingBottom: 16
  },
  listItemIcon: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 16,
    height: 16,
    width: 16
  },
  listItemText: {
    padding: '0 16px 0 0'
  },
  listItemTextPrimary: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}

const renderComponentLink = (linkUrl, venueId, handleClickItem) => {
  if (!linkUrl) return null
  const componentProps = {
    button: true,
    href: linkUrl
  }
  if (venueId) {
    componentProps.onClick = handleClickItem
  } else {
    componentProps.component = Link
    componentProps.to = linkUrl
  }
  return componentProps
}

const NotificationListItem = inject('userStore')(
  ({
    icon,
    text,
    title,
    linkUrl,
    children,
    divider,
    venueId = null,
    history,
    userStore: { setCurrentVenueByVenueIdAndRedirect },
    classes
  }) => {
    return (
      <ListItem
        {...renderComponentLink(linkUrl, venueId, () => {
          setCurrentVenueByVenueIdAndRedirect({
            venueId,
            handleNavigate: () => history.push(linkUrl)
          })
        })}
        divider={divider}
        classes={{ root: classes.listItemRoot }}
      >
        {icon !== undefined && (
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          title={title || children}
        >
          {children || text}
        </ListItemText>
      </ListItem>
    )
  }
)

export default withRouter(withStyles(styles)(NotificationListItem))
